import React, { useEffect, useRef, useState } from "react";
import { ResponsiveTreeMap } from "@nivo/treemap";
import { Box, Flex, VStack } from "@chakra-ui/react";
import Big from "big.js";
import { distinctValues } from "../../../utils";
import { ChartProperties } from "../../../types/Insight";
import NoDataAvailable from "../../insights/components/NoDataAvailable";

export interface SizeByStrategyChartProps {
  data: any[]; // SizeByStrategyData[];
  chartProperties: ChartProperties;
  initialWidth?: number;
  initialHeight?: number;
}

interface TreeChartItem {
  level: number;
  label: string;
  value?: number;
  confidence?: number;
  children?: TreeChartItem[];
  parent?: string;
}

export const SizeByStrategyChart = (props: SizeByStrategyChartProps) => {
  const {
    data,
    chartProperties,
    initialWidth = 800,
    initialHeight = 400,
  } = props;

  const [containerWidth, setContainerWidth] = useState<number>(initialWidth);
  const [containerHeight, setContainerHeight] = useState<number>(initialHeight);

  const rootNode = useRef<TreeChartItem | undefined>(undefined);

  const [selectedNode, setSelectedNode] = React.useState<
    TreeChartItem | undefined
  >(undefined);

  const containerRef = useRef<HTMLDivElement>(null);

  const getFormattedLabel = (e: TreeChartItem) => {
    const value = e.value
      ? e.value >= 1000000
        ? `${new Big(e.value).div(1000000).round(0).toString()}m`
        : e.value >= 1000
        ? `${new Big(e.value).div(1000).round(0).toString()}k`
        : `${e.value}`
      : "";

    let label = "";
    if (selectedNode?.level !== 0) {
      label = `${e.label}`;
    }

    return label
      ? `${label}: ${value}${!e.confidence ? " (Traded Value)" : ""}`
      : `${value}`;
  };

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (!entries.length) return;
      const { width, height } = entries[0].contentRect;
      setContainerWidth(width);
      setContainerHeight(height);
    };

    const observer = new ResizeObserver((entries) => handleResize(entries));
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    if (data) {
      const filteredData = data.filter(
        (d) => d.value !== 0 && d.riskpercent > 1
      );
      const strategies = distinctValues(filteredData.map((d) => d.strategy));

      const children = strategies.map((strategy) => {
        const symbols = filteredData.filter((d) => d.strategy === strategy);
        return {
          level: 1,
          label: strategy,
          confidence:
            symbols.filter((x) => x.confidence !== 1).length > 0 ? 0 : 1,
          children: symbols.map((s) => {
            return {
              level: 2,
              label: s.symbol,
              value: s.value,
              confidence: s.confidence,
              parent: strategy,
            } as TreeChartItem;
          }),
        };
      }) as TreeChartItem[];

      const reportData: TreeChartItem = {
        level: 0,
        label: "Size By Strategy - (Open Positions >= 1% Relative Size)",
        confidence:
          filteredData.filter((x) => x.confidence !== 1).length > 0 ? 0 : 1,
        children,
      };

      rootNode.current = reportData;
      setSelectedNode(reportData as TreeChartItem);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [data]);

  if (!chartProperties || !data) return <NoDataAvailable title="this chart" />;

  return (
    <VStack margin={2} h="95%" align="start" ref={containerRef}>
      <Box overflow={"visible"} h="full" w="full">
        <Flex h="full" w="full" pb={1}>
          <ResponsiveTreeMap
            data={selectedNode as any}
            identity="label"
            value="value"
            innerPadding={4}
            margin={{ top: 4, right: 4, bottom: 4, left: 4 }}
            parentLabelPosition="top"
            parentLabelSize={24}
            parentLabelPadding={4}
            colors={{ scheme: "nivo" }}
            theme={{
              tooltip: {
                container: {
                  background: "rgba(0, 0, 0, 0.92)",
                  color: "white",
                  fontSize: "14px",
                  borderRadius: "4px",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                  padding: "8px",
                },
              },
            }}
            nodeOpacity={0.35}
            outerPadding={8}
            label={(e) => {
              return getFormattedLabel(e.data as TreeChartItem);
            }}
            labelSkipSize={(selectedNode?.level ?? 0) !== 0 ? 0 : 30}
            labelTextColor={{
              from: "color",
              modifiers: [["brighter", 10]],
            }}
            parentLabelTextColor={{
              from: "color",
              modifiers: [["brighter", 10]],
            }}
            borderColor={{
              from: "color",
              modifiers: [["brighter", 0.4]],
            }}
            animate={true}
            motionConfig="stiff"
            onClick={(e) => {
              if (e.data.level === 0) {
                return;
              }
              if (selectedNode?.level === 0) {
                const node = rootNode.current?.children?.find(
                  (x) =>
                    x.level === 1 &&
                    (x.label === e.data.label || x.label === e.data.parent)
                );
                setSelectedNode(node);
              } else if (selectedNode?.level !== 0) {
                setSelectedNode(rootNode.current);
              }
            }}
          />
        </Flex>
      </Box>
    </VStack>
  );
};
