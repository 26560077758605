import { useAtomValue } from "jotai";
import { useFilters } from "../../../hooks/useFilters";
import { InsightNumberProps } from "../../../types/Insight";
import { AccountAtom, TimeFrameAtom } from "../../../store";
import { useQuery } from "@tanstack/react-query";
import { InsightStateEnum } from "../InsightStateEnum";
import { useEffect, useMemo, useState } from "react";
import { resolveNamedTimeFrame } from "../../../utils";
import { runInsight } from "../../../api";
import { BaseInsightProps } from "../BaseInsightProps";
import InsightNumberDemoDataMap from "./data";
import { useSearchParams } from "react-router-dom";

interface UseInsightNumberController {
  insightState: InsightStateEnum;
  supported: boolean;
  formattedValue?: string;
  prefix?: string;
  value: number;
  suffix?: string;
  preview?: boolean;
  change?: number;
  changeSuffix?: string;
  decimals?: number;
}

export const useInsightNumberController = (
  props: BaseInsightProps
): UseInsightNumberController => {
  const { insight } = props;

  const filters = useFilters();
  const accountState = useAtomValue(AccountAtom);
  const timeFrame = useAtomValue(TimeFrameAtom);

  const [insightState, setInsightState] = useState<InsightStateEnum>(
    InsightStateEnum.Loading
  );

  const [searchParams] = useSearchParams();
  const pid = parseInt(searchParams.get("pid") ?? "");
  const accountId = pid || accountState?.selectedAccount?.id;

  // uses react query to cache result
  const result = useQuery({
    queryKey: [
      props.demo === true ? "insight-number" : "insight-number-demo",

      insight.insightId,
      insight.query,
      insight.type,
      accountId,
      filters.selectedUnderlying,
      filters.selectedTag,
      filters.selectedStrategy,
      filters.selectedBook,
      filters.selectedPositionGroup,
      timeFrame.timeFrame,
    ],
    queryFn: async () => {
      if (props.demo === true && props?.insight?.query) {
        return InsightNumberDemoDataMap[props?.insight?.query];
      } else {
        const timeframe = resolveNamedTimeFrame(timeFrame.timeFrame);
        return await runInsight(
          accountId,
          insight.insightId,
          insight.query!,
          insight.type,
          timeframe,
          filters.selectedUnderlying,
          filters.selectedTag,
          filters.selectedStrategy,
          filters.selectedBook,
          filters.selectedPositionGroup
        );
      }
    },
    enabled:
      accountId !== undefined &&
      insight !== undefined &&
      insight.insightId !== undefined &&
      insight.query !== undefined &&
      insight.type !== undefined,
  });

  const insightResult: InsightNumberProps | undefined = useMemo(() => {
    if (result.isSuccess && result.data) {
      const data = result.data as InsightNumberProps;
      if (data) {
        let prefix = data.prefix ?? "$";
        if (data.value < 0) {
          prefix = "-" + prefix;
        }

        const values = {
          ...data,
          prefix: prefix,
          value: data.value,
          suffix: data.suffix ?? "",
          change: data.change !== undefined ? data.change : 0,
          changeSuffix: data.changeSuffix ?? "",
          decimals: data.decimals !== undefined ? data.decimals : 2,
          formattedValue: "",
        };

        let formattedValue = data.value.toFixed(values.decimals);
        formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        values.formattedValue =
          data.value < 0 ? formattedValue.substring(1) : formattedValue;

        return values;
      }
      return undefined;
    }
  }, [props.demo, result.isSuccess, result.data]);

  useEffect(() => {
    if (props.demo) {
      setInsightState(InsightStateEnum.Success);
    } else {
      if (!result.isSuccess && !result.isError) {
        setInsightState(InsightStateEnum.Loading);
      } else if (result.isSuccess) {
        if (result.data) {
          setInsightState(InsightStateEnum.Success);
        } else {
          setInsightState(InsightStateEnum.NoData);
        }
        setInsightState(InsightStateEnum.Success);
      } else if (result.isError) {
        setInsightState(InsightStateEnum.Error);
      }
    }
  }, [result, props.demo]);

  return {
    insightState,
    supported:
      insightResult?.supported === undefined ? true : insightResult!.supported,
    formattedValue: insightResult?.formattedValue,
    prefix: insightResult?.prefix,
    value: insightResult?.value || 0,
    suffix: insightResult?.suffix,
    preview: insightResult?.preview,
    change: insightResult?.change,
    changeSuffix: insightResult?.changeSuffix,
    decimals: insightResult?.decimals,
  };
};
