import React, { FC } from "react";
import { Box, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { MdSearch } from "react-icons/md";

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const SearchInput: FC<SearchInputProps> = ({
  value,
  onChange,
  placeholder = "Search",
}) => {
  return (
    <Box pr="10px" pl="10px">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <MdSearch fontSize="24px" color="gray" />
        </InputLeftElement>
        <Input
          placeholder={placeholder}
          border="1px solid var(--gray, #ccc)"
          pl="2rem" // space for icon
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </InputGroup>
    </Box>
  );
};

export default SearchInput;
