import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  HStack,
  ModalFooter,
  Box,
  DrawerFooter,
} from "@chakra-ui/react";

import InsightsManagerHeader from "./InsightsManagerHeader";
import InsightsManagerContent from "./InsightsManagerContent";

interface InsightsManagerDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  isUserAdmin: boolean;
  controller: any;
}

const InsightsManagerDrawer: React.FC<InsightsManagerDrawerProps> = ({
  isOpen,
  onClose,
  isUserAdmin,
  controller,
}) => {
  return (
    <Drawer onClose={onClose} size="100%" isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent bg="var(--bg)">
        <DrawerCloseButton />
        <HStack pr="12px">
          <InsightsManagerHeader
            isUserAdmin={isUserAdmin}
            controller={controller}
          />
        </HStack>
        <DrawerBody p="0" pb="100px">
          <InsightsManagerContent controller={controller} onClose={onClose} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default InsightsManagerDrawer;
