export const PNL_BY_DATE = {
  data: [
    {
      domain: "2017-08-09",
      realisedPnl: 0,
    },
    {
      domain: "2017-08-10",
      realisedPnl: 0,
    },
    {
      domain: "2017-08-14",
      realisedPnl: 113.66,
    },
    {
      domain: "2017-08-16",
      realisedPnl: 0,
    },
    {
      domain: "2017-08-18",
      realisedPnl: 0,
    },
    {
      domain: "2017-08-22",
      realisedPnl: 0,
    },
    {
      domain: "2017-08-23",
      realisedPnl: 0,
    },
    {
      domain: "2017-08-24",
      realisedPnl: -255.99,
    },
    {
      domain: "2017-08-25",
      realisedPnl: 0,
    },
    {
      domain: "2017-08-28",
      realisedPnl: 0,
    },
    {
      domain: "2017-08-30",
      realisedPnl: 0,
    },
    {
      domain: "2017-08-31",
      realisedPnl: -1019.98,
    },
    {
      domain: "2017-09-06",
      realisedPnl: 0,
    },
    {
      domain: "2017-09-11",
      realisedPnl: 0,
    },
    {
      domain: "2017-09-12",
      realisedPnl: -100.02,
    },
    {
      domain: "2017-09-13",
      realisedPnl: -230.68,
    },
    {
      domain: "2017-09-18",
      realisedPnl: -847.06,
    },
    {
      domain: "2017-09-19",
      realisedPnl: 0,
    },
    {
      domain: "2017-09-20",
      realisedPnl: -631.03,
    },
    {
      domain: "2017-09-21",
      realisedPnl: 0,
    },
    {
      domain: "2017-09-22",
      realisedPnl: 0,
    },
    {
      domain: "2017-09-25",
      realisedPnl: 0,
    },
    {
      domain: "2017-09-29",
      realisedPnl: 0,
    },
    {
      domain: "2017-10-03",
      realisedPnl: 0,
    },
    {
      domain: "2017-10-05",
      realisedPnl: -11.78,
    },
    {
      domain: "2017-10-06",
      realisedPnl: 0,
    },
    {
      domain: "2017-10-09",
      realisedPnl: 0,
    },
    {
      domain: "2017-10-13",
      realisedPnl: -1.53,
    },
    {
      domain: "2017-10-16",
      realisedPnl: 145.08,
    },
    {
      domain: "2017-10-17",
      realisedPnl: -237.92,
    },
    {
      domain: "2017-10-18",
      realisedPnl: 0,
    },
    {
      domain: "2017-10-23",
      realisedPnl: 0,
    },
    {
      domain: "2017-10-24",
      realisedPnl: 0,
    },
    {
      domain: "2017-10-25",
      realisedPnl: 0,
    },
    {
      domain: "2017-10-26",
      realisedPnl: 0,
    },
    {
      domain: "2017-10-27",
      realisedPnl: -603.1,
    },
    {
      domain: "2017-10-30",
      realisedPnl: 0,
    },
    {
      domain: "2017-10-31",
      realisedPnl: 380.29,
    },
    {
      domain: "2017-11-01",
      realisedPnl: 0,
    },
    {
      domain: "2017-11-02",
      realisedPnl: -694.07,
    },
    {
      domain: "2017-11-03",
      realisedPnl: 0,
    },
    {
      domain: "2017-11-06",
      realisedPnl: -598.3,
    },
    {
      domain: "2017-11-08",
      realisedPnl: 439.49,
    },
    {
      domain: "2017-11-15",
      realisedPnl: 139.89,
    },
    {
      domain: "2017-11-16",
      realisedPnl: 0,
    },
    {
      domain: "2017-11-17",
      realisedPnl: 0,
    },
    {
      domain: "2017-11-20",
      realisedPnl: -7.81,
    },
    {
      domain: "2017-11-21",
      realisedPnl: 6712.27,
    },
    {
      domain: "2017-11-22",
      realisedPnl: 0,
    },
    {
      domain: "2017-11-27",
      realisedPnl: 0,
    },
    {
      domain: "2017-11-28",
      realisedPnl: 3025.82,
    },
    {
      domain: "2017-11-29",
      realisedPnl: 2730.9,
    },
    {
      domain: "2017-12-01",
      realisedPnl: 1762.05,
    },
    {
      domain: "2017-12-04",
      realisedPnl: -702.98,
    },
    {
      domain: "2017-12-07",
      realisedPnl: 10.92,
    },
    {
      domain: "2017-12-08",
      realisedPnl: 0,
    },
    {
      domain: "2017-12-11",
      realisedPnl: -1188.01,
    },
    {
      domain: "2017-12-12",
      realisedPnl: -1240.92,
    },
    {
      domain: "2017-12-13",
      realisedPnl: 0,
    },
    {
      domain: "2017-12-18",
      realisedPnl: 0,
    },
    {
      domain: "2017-12-21",
      realisedPnl: 2704.08,
    },
    {
      domain: "2017-12-22",
      realisedPnl: -12.98,
    },
    {
      domain: "2017-12-26",
      realisedPnl: 0,
    },
    {
      domain: "2017-12-27",
      realisedPnl: 929.08,
    },
    {
      domain: "2017-12-28",
      realisedPnl: 0,
    },
    {
      domain: "2018-01-02",
      realisedPnl: 0,
    },
    {
      domain: "2018-01-03",
      realisedPnl: 0,
    },
    {
      domain: "2018-01-04",
      realisedPnl: 1053.11,
    },
    {
      domain: "2018-01-05",
      realisedPnl: -1472.4,
    },
    {
      domain: "2018-01-08",
      realisedPnl: 0,
    },
    {
      domain: "2018-01-09",
      realisedPnl: 0,
    },
    {
      domain: "2018-01-11",
      realisedPnl: -1239.24,
    },
    {
      domain: "2018-01-18",
      realisedPnl: 0,
    },
    {
      domain: "2018-01-23",
      realisedPnl: 7200.13,
    },
    {
      domain: "2018-01-29",
      realisedPnl: 2746.39,
    },
    {
      domain: "2018-01-30",
      realisedPnl: 12046.04,
    },
    {
      domain: "2018-01-31",
      realisedPnl: 0.15,
    },
    {
      domain: "2018-02-01",
      realisedPnl: 448.05,
    },
    {
      domain: "2018-02-02",
      realisedPnl: -855.97,
    },
    {
      domain: "2018-02-05",
      realisedPnl: 0,
    },
    {
      domain: "2018-02-06",
      realisedPnl: 1877.82,
    },
    {
      domain: "2018-02-08",
      realisedPnl: 59.86,
    },
    {
      domain: "2018-02-09",
      realisedPnl: 0,
    },
    {
      domain: "2018-02-13",
      realisedPnl: 0,
    },
    {
      domain: "2018-02-15",
      realisedPnl: 0,
    },
    {
      domain: "2018-02-16",
      realisedPnl: 0,
    },
    {
      domain: "2018-02-20",
      realisedPnl: 0,
    },
    {
      domain: "2018-02-22",
      realisedPnl: -569.68,
    },
    {
      domain: "2018-02-23",
      realisedPnl: -1164.92,
    },
    {
      domain: "2018-03-01",
      realisedPnl: 0,
    },
    {
      domain: "2018-03-05",
      realisedPnl: 0,
    },
    {
      domain: "2018-03-08",
      realisedPnl: 0,
    },
    {
      domain: "2018-03-12",
      realisedPnl: 0,
    },
    {
      domain: "2018-03-13",
      realisedPnl: 0,
    },
    {
      domain: "2018-03-14",
      realisedPnl: 0,
    },
    {
      domain: "2018-03-16",
      realisedPnl: -1240.92,
    },
    {
      domain: "2018-03-23",
      realisedPnl: 0,
    },
    {
      domain: "2018-03-26",
      realisedPnl: 7.35,
    },
    {
      domain: "2018-03-28",
      realisedPnl: 500.71,
    },
    {
      domain: "2018-04-03",
      realisedPnl: 0,
    },
    {
      domain: "2018-04-09",
      realisedPnl: 0,
    },
    {
      domain: "2018-04-13",
      realisedPnl: 0,
    },
    {
      domain: "2018-04-17",
      realisedPnl: 0,
    },
    {
      domain: "2018-04-18",
      realisedPnl: -575.14,
    },
    {
      domain: "2018-04-20",
      realisedPnl: 0,
    },
    {
      domain: "2018-04-26",
      realisedPnl: -1021.98,
    },
    {
      domain: "2018-04-27",
      realisedPnl: 0,
    },
    {
      domain: "2018-04-30",
      realisedPnl: -5119.91,
    },
    {
      domain: "2018-05-01",
      realisedPnl: 52.92,
    },
    {
      domain: "2018-05-07",
      realisedPnl: 2616.57,
    },
    {
      domain: "2018-05-09",
      realisedPnl: 1484.87,
    },
    {
      domain: "2018-05-11",
      realisedPnl: -525.98,
    },
    {
      domain: "2018-05-14",
      realisedPnl: -657.97,
    },
    {
      domain: "2018-05-16",
      realisedPnl: -983.52,
    },
    {
      domain: "2018-05-21",
      realisedPnl: -2457.59,
    },
    {
      domain: "2018-05-23",
      realisedPnl: -1202.18,
    },
    {
      domain: "2018-05-24",
      realisedPnl: 464.55,
    },
    {
      domain: "2018-05-30",
      realisedPnl: 0,
    },
    {
      domain: "2018-06-01",
      realisedPnl: 48.02,
    },
    {
      domain: "2018-06-11",
      realisedPnl: -323.35,
    },
    {
      domain: "2018-06-15",
      realisedPnl: 689.01,
    },
    {
      domain: "2018-06-18",
      realisedPnl: 4434.01,
    },
    {
      domain: "2018-06-20",
      realisedPnl: -565.91,
    },
    {
      domain: "2018-06-22",
      realisedPnl: -356.81,
    },
    {
      domain: "2018-06-25",
      realisedPnl: -1958.03,
    },
    {
      domain: "2018-06-27",
      realisedPnl: -1378.61,
    },
    {
      domain: "2018-07-02",
      realisedPnl: 0,
    },
    {
      domain: "2018-07-03",
      realisedPnl: 0,
    },
    {
      domain: "2018-07-06",
      realisedPnl: 0,
    },
    {
      domain: "2018-07-09",
      realisedPnl: 0,
    },
    {
      domain: "2018-07-13",
      realisedPnl: 0,
    },
    {
      domain: "2018-07-16",
      realisedPnl: 2050.07,
    },
    {
      domain: "2018-07-17",
      realisedPnl: 0,
    },
    {
      domain: "2018-07-18",
      realisedPnl: 0,
    },
    {
      domain: "2018-07-19",
      realisedPnl: 0,
    },
    {
      domain: "2018-07-20",
      realisedPnl: -3686.82,
    },
    {
      domain: "2018-07-23",
      realisedPnl: 0,
    },
    {
      domain: "2018-07-27",
      realisedPnl: 417.95,
    },
    {
      domain: "2018-07-30",
      realisedPnl: -303.73,
    },
    {
      domain: "2018-08-01",
      realisedPnl: -2093.01,
    },
    {
      domain: "2018-08-02",
      realisedPnl: 0,
    },
    {
      domain: "2018-08-03",
      realisedPnl: 0,
    },
    {
      domain: "2018-08-06",
      realisedPnl: 188.59,
    },
    {
      domain: "2018-08-07",
      realisedPnl: 0,
    },
    {
      domain: "2018-08-08",
      realisedPnl: 0,
    },
    {
      domain: "2018-08-09",
      realisedPnl: -534.03,
    },
    {
      domain: "2018-08-13",
      realisedPnl: -7.7,
    },
    {
      domain: "2018-08-14",
      realisedPnl: -1111.97,
    },
    {
      domain: "2018-08-17",
      realisedPnl: 0,
    },
    {
      domain: "2018-08-20",
      realisedPnl: 0,
    },
    {
      domain: "2018-08-27",
      realisedPnl: 4645.92,
    },
    {
      domain: "2018-08-30",
      realisedPnl: -305.92,
    },
    {
      domain: "2018-09-05",
      realisedPnl: 2522.03,
    },
    {
      domain: "2018-09-11",
      realisedPnl: 0,
    },
    {
      domain: "2018-09-12",
      realisedPnl: 0,
    },
    {
      domain: "2018-09-14",
      realisedPnl: 325.07,
    },
    {
      domain: "2018-09-17",
      realisedPnl: 0,
    },
    {
      domain: "2018-09-24",
      realisedPnl: -157.97,
    },
    {
      domain: "2018-09-25",
      realisedPnl: 3459.13,
    },
    {
      domain: "2018-09-26",
      realisedPnl: 0,
    },
    {
      domain: "2018-09-28",
      realisedPnl: 0,
    },
    {
      domain: "2018-10-01",
      realisedPnl: 0,
    },
    {
      domain: "2018-10-02",
      realisedPnl: -385.97,
    },
    {
      domain: "2018-10-03",
      realisedPnl: -805.35,
    },
    {
      domain: "2018-10-05",
      realisedPnl: -1288.7,
    },
    {
      domain: "2018-10-08",
      realisedPnl: -4603.33,
    },
    {
      domain: "2018-10-17",
      realisedPnl: 0,
    },
    {
      domain: "2018-10-18",
      realisedPnl: -387.83,
    },
    {
      domain: "2018-10-22",
      realisedPnl: 0,
    },
    {
      domain: "2018-10-24",
      realisedPnl: 0,
    },
    {
      domain: "2018-11-01",
      realisedPnl: 0,
    },
    {
      domain: "2018-11-02",
      realisedPnl: -412.16,
    },
    {
      domain: "2018-11-05",
      realisedPnl: -568.91,
    },
    {
      domain: "2018-11-09",
      realisedPnl: 0,
    },
    {
      domain: "2018-11-13",
      realisedPnl: 2019.12,
    },
    {
      domain: "2018-11-16",
      realisedPnl: 0,
    },
    {
      domain: "2018-11-19",
      realisedPnl: -1018.97,
    },
    {
      domain: "2018-11-20",
      realisedPnl: -200,
    },
    {
      domain: "2018-11-23",
      realisedPnl: -746.98,
    },
    {
      domain: "2018-11-26",
      realisedPnl: -1543.66,
    },
    {
      domain: "2018-11-29",
      realisedPnl: 88.04,
    },
    {
      domain: "2018-11-30",
      realisedPnl: 1022.01,
    },
    {
      domain: "2018-12-10",
      realisedPnl: 0,
    },
    {
      domain: "2018-12-14",
      realisedPnl: -422.05,
    },
    {
      domain: "2018-12-17",
      realisedPnl: 0,
    },
    {
      domain: "2018-12-18",
      realisedPnl: 0,
    },
    {
      domain: "2018-12-19",
      realisedPnl: 0,
    },
    {
      domain: "2018-12-24",
      realisedPnl: -4582.15,
    },
    {
      domain: "2018-12-26",
      realisedPnl: 0,
    },
    {
      domain: "2019-01-02",
      realisedPnl: -1109.3,
    },
    {
      domain: "2019-01-07",
      realisedPnl: 0,
    },
    {
      domain: "2019-01-08",
      realisedPnl: -881.97,
    },
    {
      domain: "2019-01-09",
      realisedPnl: -1273.4,
    },
    {
      domain: "2019-01-11",
      realisedPnl: 169.09,
    },
    {
      domain: "2019-01-17",
      realisedPnl: 0,
    },
    {
      domain: "2019-01-18",
      realisedPnl: 0,
    },
    {
      domain: "2019-01-22",
      realisedPnl: -487.55,
    },
    {
      domain: "2019-01-23",
      realisedPnl: -538.82,
    },
    {
      domain: "2019-01-25",
      realisedPnl: 0,
    },
    {
      domain: "2019-01-30",
      realisedPnl: 548.02,
    },
    {
      domain: "2019-02-01",
      realisedPnl: 0,
    },
    {
      domain: "2019-02-04",
      realisedPnl: 0,
    },
    {
      domain: "2019-02-05",
      realisedPnl: 1627.06,
    },
    {
      domain: "2019-02-07",
      realisedPnl: 92.01,
    },
    {
      domain: "2019-02-08",
      realisedPnl: 0,
    },
    {
      domain: "2019-02-11",
      realisedPnl: 0,
    },
    {
      domain: "2019-02-13",
      realisedPnl: -435.99,
    },
    {
      domain: "2019-02-14",
      realisedPnl: -577.99,
    },
    {
      domain: "2019-02-15",
      realisedPnl: 755,
    },
    {
      domain: "2019-02-21",
      realisedPnl: -853.26,
    },
    {
      domain: "2019-02-22",
      realisedPnl: 0,
    },
    {
      domain: "2019-02-25",
      realisedPnl: 933.99,
    },
    {
      domain: "2019-02-26",
      realisedPnl: 1161.07,
    },
    {
      domain: "2019-02-27",
      realisedPnl: 606.19,
    },
    {
      domain: "2019-02-28",
      realisedPnl: -490.14,
    },
    {
      domain: "2019-03-01",
      realisedPnl: 1407.98,
    },
    {
      domain: "2019-03-05",
      realisedPnl: 0,
    },
    {
      domain: "2019-03-07",
      realisedPnl: 0,
    },
    {
      domain: "2019-03-08",
      realisedPnl: -468.23,
    },
    {
      domain: "2019-03-11",
      realisedPnl: -386,
    },
    {
      domain: "2019-03-12",
      realisedPnl: -8.26,
    },
    {
      domain: "2019-03-13",
      realisedPnl: -779.03,
    },
    {
      domain: "2019-03-14",
      realisedPnl: -483.91,
    },
    {
      domain: "2019-03-15",
      realisedPnl: 0,
    },
    {
      domain: "2019-03-20",
      realisedPnl: -485.91,
    },
    {
      domain: "2019-03-25",
      realisedPnl: 0,
    },
    {
      domain: "2019-03-26",
      realisedPnl: 0,
    },
    {
      domain: "2019-03-27",
      realisedPnl: 0,
    },
    {
      domain: "2019-03-28",
      realisedPnl: -460.11,
    },
    {
      domain: "2019-03-29",
      realisedPnl: 479.09,
    },
    {
      domain: "2019-04-01",
      realisedPnl: 542.91,
    },
    {
      domain: "2019-04-02",
      realisedPnl: 0,
    },
    {
      domain: "2019-04-03",
      realisedPnl: -192,
    },
    {
      domain: "2019-04-04",
      realisedPnl: 203,
    },
    {
      domain: "2019-04-08",
      realisedPnl: 359.59,
    },
    {
      domain: "2019-04-10",
      realisedPnl: 0,
    },
    {
      domain: "2019-04-12",
      realisedPnl: -3.99,
    },
    {
      domain: "2019-04-15",
      realisedPnl: 0,
    },
    {
      domain: "2019-04-16",
      realisedPnl: 1163.18,
    },
    {
      domain: "2019-04-17",
      realisedPnl: 169.08,
    },
    {
      domain: "2019-04-18",
      realisedPnl: 814.08,
    },
    {
      domain: "2019-04-22",
      realisedPnl: 0,
    },
    {
      domain: "2019-04-23",
      realisedPnl: -64.9,
    },
    {
      domain: "2019-04-26",
      realisedPnl: 0,
    },
    {
      domain: "2019-04-29",
      realisedPnl: -448.11,
    },
    {
      domain: "2019-05-01",
      realisedPnl: 0,
    },
    {
      domain: "2019-05-10",
      realisedPnl: -868.99,
    },
    {
      domain: "2019-05-15",
      realisedPnl: 0,
    },
    {
      domain: "2019-05-17",
      realisedPnl: 0,
    },
    {
      domain: "2019-05-20",
      realisedPnl: -234.93,
    },
    {
      domain: "2019-05-28",
      realisedPnl: 206.95,
    },
    {
      domain: "2019-05-29",
      realisedPnl: 0,
    },
    {
      domain: "2019-05-31",
      realisedPnl: 0,
    },
    {
      domain: "2019-06-03",
      realisedPnl: -1654.9,
    },
    {
      domain: "2019-06-07",
      realisedPnl: 0,
    },
    {
      domain: "2019-06-12",
      realisedPnl: 249.07,
    },
    {
      domain: "2019-06-14",
      realisedPnl: -477.48,
    },
    {
      domain: "2019-06-17",
      realisedPnl: 505.87,
    },
    {
      domain: "2019-06-18",
      realisedPnl: -82.9,
    },
    {
      domain: "2019-06-19",
      realisedPnl: -156.76,
    },
    {
      domain: "2019-06-20",
      realisedPnl: 0,
    },
    {
      domain: "2019-06-24",
      realisedPnl: 198.68,
    },
    {
      domain: "2019-06-26",
      realisedPnl: 221.85,
    },
    {
      domain: "2019-06-27",
      realisedPnl: 907.98,
    },
    {
      domain: "2019-06-28",
      realisedPnl: 0,
    },
    {
      domain: "2019-07-02",
      realisedPnl: 0,
    },
    {
      domain: "2019-07-03",
      realisedPnl: 0,
    },
    {
      domain: "2019-07-08",
      realisedPnl: 403.07,
    },
    {
      domain: "2019-07-10",
      realisedPnl: 0,
    },
    {
      domain: "2019-07-12",
      realisedPnl: 403.05,
    },
    {
      domain: "2019-07-15",
      realisedPnl: 0,
    },
    {
      domain: "2019-07-17",
      realisedPnl: -52.01,
    },
    {
      domain: "2019-07-18",
      realisedPnl: 257.04,
    },
    {
      domain: "2019-07-19",
      realisedPnl: -24.06,
    },
    {
      domain: "2019-07-22",
      realisedPnl: -398,
    },
    {
      domain: "2019-07-25",
      realisedPnl: 0,
    },
    {
      domain: "2019-07-26",
      realisedPnl: 169.96,
    },
    {
      domain: "2019-07-29",
      realisedPnl: -1171.99,
    },
    {
      domain: "2019-07-31",
      realisedPnl: 221.15,
    },
    {
      domain: "2019-08-01",
      realisedPnl: 0,
    },
    {
      domain: "2019-08-02",
      realisedPnl: 104.78,
    },
    {
      domain: "2019-08-07",
      realisedPnl: 0,
    },
    {
      domain: "2019-08-08",
      realisedPnl: 0,
    },
    {
      domain: "2019-08-09",
      realisedPnl: -990.91,
    },
    {
      domain: "2019-08-19",
      realisedPnl: -299.64,
    },
    {
      domain: "2019-08-20",
      realisedPnl: -2332.78,
    },
    {
      domain: "2019-08-21",
      realisedPnl: 182.04,
    },
    {
      domain: "2019-08-22",
      realisedPnl: 2128.11,
    },
    {
      domain: "2019-08-26",
      realisedPnl: 289.87,
    },
    {
      domain: "2019-08-30",
      realisedPnl: 0,
    },
    {
      domain: "2019-09-06",
      realisedPnl: 0,
    },
    {
      domain: "2019-09-09",
      realisedPnl: -3264.96,
    },
    {
      domain: "2019-09-13",
      realisedPnl: 573.88,
    },
    {
      domain: "2019-09-16",
      realisedPnl: -1840.1,
    },
    {
      domain: "2019-09-18",
      realisedPnl: 0,
    },
    {
      domain: "2019-09-19",
      realisedPnl: -1008.04,
    },
    {
      domain: "2019-09-20",
      realisedPnl: 1935.6,
    },
    {
      domain: "2019-09-26",
      realisedPnl: -1682.17,
    },
    {
      domain: "2019-09-27",
      realisedPnl: 0,
    },
    {
      domain: "2019-10-01",
      realisedPnl: 139.1,
    },
    {
      domain: "2019-10-02",
      realisedPnl: 0,
    },
    {
      domain: "2019-10-04",
      realisedPnl: 0,
    },
    {
      domain: "2019-10-14",
      realisedPnl: -708.01,
    },
    {
      domain: "2019-10-18",
      realisedPnl: -1107.02,
    },
    {
      domain: "2019-10-21",
      realisedPnl: 0,
    },
    {
      domain: "2019-10-25",
      realisedPnl: -491.59,
    },
    {
      domain: "2019-10-31",
      realisedPnl: 282.58,
    },
    {
      domain: "2019-11-01",
      realisedPnl: 0,
    },
    {
      domain: "2019-11-06",
      realisedPnl: 112,
    },
    {
      domain: "2019-11-08",
      realisedPnl: -454.23,
    },
    {
      domain: "2019-11-14",
      realisedPnl: 0,
    },
    {
      domain: "2019-11-15",
      realisedPnl: -708.06,
    },
    {
      domain: "2019-11-18",
      realisedPnl: -881.87,
    },
    {
      domain: "2019-11-19",
      realisedPnl: 0,
    },
    {
      domain: "2019-11-21",
      realisedPnl: 1006.41,
    },
    {
      domain: "2019-11-26",
      realisedPnl: 0,
    },
    {
      domain: "2019-11-27",
      realisedPnl: -4281.58,
    },
    {
      domain: "2019-12-02",
      realisedPnl: 0,
    },
    {
      domain: "2019-12-03",
      realisedPnl: 0,
    },
    {
      domain: "2019-12-06",
      realisedPnl: 370.88,
    },
    {
      domain: "2019-12-09",
      realisedPnl: -9.09,
    },
    {
      domain: "2019-12-10",
      realisedPnl: -720.32,
    },
    {
      domain: "2019-12-12",
      realisedPnl: 0,
    },
    {
      domain: "2019-12-13",
      realisedPnl: -911.02,
    },
    {
      domain: "2019-12-17",
      realisedPnl: 0,
    },
    {
      domain: "2019-12-18",
      realisedPnl: 0,
    },
    {
      domain: "2019-12-20",
      realisedPnl: 480.27,
    },
    {
      domain: "2019-12-23",
      realisedPnl: 0,
    },
    {
      domain: "2019-12-27",
      realisedPnl: 0,
    },
    {
      domain: "2020-01-02",
      realisedPnl: -511.23,
    },
    {
      domain: "2020-01-03",
      realisedPnl: 0,
    },
    {
      domain: "2020-01-06",
      realisedPnl: 0,
    },
    {
      domain: "2020-01-07",
      realisedPnl: 0,
    },
    {
      domain: "2020-01-09",
      realisedPnl: 103.98,
    },
    {
      domain: "2020-01-17",
      realisedPnl: 228.97,
    },
    {
      domain: "2020-01-30",
      realisedPnl: 0,
    },
    {
      domain: "2020-02-03",
      realisedPnl: 69.98,
    },
    {
      domain: "2020-02-04",
      realisedPnl: 0,
    },
    {
      domain: "2020-02-05",
      realisedPnl: 1162.35,
    },
    {
      domain: "2020-02-06",
      realisedPnl: 0,
    },
    {
      domain: "2020-02-07",
      realisedPnl: 2622.11,
    },
    {
      domain: "2020-02-10",
      realisedPnl: 2537.82,
    },
    {
      domain: "2020-02-12",
      realisedPnl: -605.25,
    },
    {
      domain: "2020-02-14",
      realisedPnl: 0,
    },
    {
      domain: "2020-02-21",
      realisedPnl: 1013.92,
    },
    {
      domain: "2020-02-25",
      realisedPnl: 144.77,
    },
    {
      domain: "2020-03-02",
      realisedPnl: 0,
    },
    {
      domain: "2020-03-03",
      realisedPnl: 0,
    },
    {
      domain: "2020-03-04",
      realisedPnl: 0,
    },
    {
      domain: "2020-03-06",
      realisedPnl: -2324.01,
    },
    {
      domain: "2020-03-13",
      realisedPnl: -3303.82,
    },
    {
      domain: "2020-03-16",
      realisedPnl: -2299.92,
    },
    {
      domain: "2020-03-17",
      realisedPnl: -16631.1,
    },
    {
      domain: "2020-03-18",
      realisedPnl: -4878.78,
    },
    {
      domain: "2020-03-25",
      realisedPnl: 0,
    },
    {
      domain: "2020-03-31",
      realisedPnl: -0.78,
    },
    {
      domain: "2020-04-01",
      realisedPnl: 0,
    },
    {
      domain: "2020-04-02",
      realisedPnl: 0,
    },
    {
      domain: "2020-04-08",
      realisedPnl: -156.83,
    },
    {
      domain: "2020-04-15",
      realisedPnl: 103.97,
    },
    {
      domain: "2020-04-24",
      realisedPnl: 0,
    },
    {
      domain: "2020-04-29",
      realisedPnl: 0,
    },
    {
      domain: "2020-05-01",
      realisedPnl: 0,
    },
    {
      domain: "2020-05-04",
      realisedPnl: 0,
    },
    {
      domain: "2020-05-07",
      realisedPnl: -720.07,
    },
    {
      domain: "2020-05-19",
      realisedPnl: 191.89,
    },
    {
      domain: "2020-05-28",
      realisedPnl: 0,
    },
    {
      domain: "2020-06-01",
      realisedPnl: 0,
    },
    {
      domain: "2020-06-02",
      realisedPnl: -1095.66,
    },
    {
      domain: "2020-06-03",
      realisedPnl: 1271.22,
    },
    {
      domain: "2020-06-08",
      realisedPnl: -1136.04,
    },
    {
      domain: "2020-06-16",
      realisedPnl: -1082.51,
    },
    {
      domain: "2020-06-18",
      realisedPnl: 0,
    },
    {
      domain: "2020-06-22",
      realisedPnl: 0,
    },
    {
      domain: "2020-06-26",
      realisedPnl: 0,
    },
    {
      domain: "2020-06-30",
      realisedPnl: 294.75,
    },
    {
      domain: "2020-07-01",
      realisedPnl: 0,
    },
    {
      domain: "2020-07-02",
      realisedPnl: -1346.55,
    },
    {
      domain: "2020-07-09",
      realisedPnl: 0,
    },
    {
      domain: "2020-07-20",
      realisedPnl: 0,
    },
    {
      domain: "2020-07-24",
      realisedPnl: -0.4,
    },
    {
      domain: "2020-07-27",
      realisedPnl: 0,
    },
    {
      domain: "2020-07-29",
      realisedPnl: 0,
    },
    {
      domain: "2020-08-03",
      realisedPnl: 0,
    },
    {
      domain: "2020-08-04",
      realisedPnl: 0,
    },
    {
      domain: "2020-08-12",
      realisedPnl: -481.02,
    },
    {
      domain: "2020-08-13",
      realisedPnl: 0,
    },
    {
      domain: "2020-08-14",
      realisedPnl: 1343.94,
    },
    {
      domain: "2020-08-17",
      realisedPnl: 0,
    },
    {
      domain: "2020-08-18",
      realisedPnl: -51.01,
    },
    {
      domain: "2020-08-21",
      realisedPnl: 0,
    },
    {
      domain: "2020-08-25",
      realisedPnl: -1.9,
    },
    {
      domain: "2020-08-26",
      realisedPnl: 0,
    },
    {
      domain: "2020-08-28",
      realisedPnl: 0,
    },
    {
      domain: "2020-08-31",
      realisedPnl: 0,
    },
    {
      domain: "2020-09-01",
      realisedPnl: 0,
    },
    {
      domain: "2020-09-02",
      realisedPnl: -345.15,
    },
    {
      domain: "2020-09-11",
      realisedPnl: -1069.15,
    },
    {
      domain: "2020-09-14",
      realisedPnl: -245.09,
    },
    {
      domain: "2020-09-16",
      realisedPnl: 0,
    },
    {
      domain: "2020-09-17",
      realisedPnl: 0,
    },
    {
      domain: "2020-09-21",
      realisedPnl: 1601.71,
    },
    {
      domain: "2020-09-23",
      realisedPnl: 755.84,
    },
    {
      domain: "2020-09-24",
      realisedPnl: -1.99,
    },
    {
      domain: "2020-09-28",
      realisedPnl: -223.48,
    },
    {
      domain: "2020-09-29",
      realisedPnl: 0,
    },
    {
      domain: "2020-09-30",
      realisedPnl: 0,
    },
    {
      domain: "2020-10-01",
      realisedPnl: 0,
    },
    {
      domain: "2020-10-02",
      realisedPnl: 0,
    },
    {
      domain: "2020-10-09",
      realisedPnl: 0,
    },
    {
      domain: "2020-10-12",
      realisedPnl: 0,
    },
    {
      domain: "2020-10-13",
      realisedPnl: 1088.94,
    },
    {
      domain: "2020-10-16",
      realisedPnl: 0,
    },
    {
      domain: "2020-10-20",
      realisedPnl: -538.68,
    },
    {
      domain: "2020-10-21",
      realisedPnl: 38.98,
    },
    {
      domain: "2020-10-22",
      realisedPnl: 487.27,
    },
    {
      domain: "2020-10-23",
      realisedPnl: -808.89,
    },
    {
      domain: "2020-10-26",
      realisedPnl: 0,
    },
    {
      domain: "2020-10-27",
      realisedPnl: -1171.89,
    },
    {
      domain: "2020-10-28",
      realisedPnl: -6532.26,
    },
    {
      domain: "2020-10-29",
      realisedPnl: -714.26,
    },
    {
      domain: "2020-10-30",
      realisedPnl: 1703.14,
    },
    {
      domain: "2020-11-02",
      realisedPnl: 0,
    },
    {
      domain: "2020-11-03",
      realisedPnl: 0,
    },
    {
      domain: "2020-11-09",
      realisedPnl: -2646.1,
    },
    {
      domain: "2020-11-10",
      realisedPnl: 0,
    },
    {
      domain: "2020-11-13",
      realisedPnl: 0,
    },
    {
      domain: "2020-11-17",
      realisedPnl: 0,
    },
    {
      domain: "2020-11-19",
      realisedPnl: -301.02,
    },
    {
      domain: "2020-11-23",
      realisedPnl: -578.12,
    },
    {
      domain: "2020-11-24",
      realisedPnl: 5313.12,
    },
    {
      domain: "2020-11-25",
      realisedPnl: 0,
    },
    {
      domain: "2020-11-27",
      realisedPnl: -564.16,
    },
    {
      domain: "2020-11-30",
      realisedPnl: 2.98,
    },
    {
      domain: "2020-12-01",
      realisedPnl: 0,
    },
    {
      domain: "2020-12-02",
      realisedPnl: 0,
    },
    {
      domain: "2020-12-03",
      realisedPnl: 0,
    },
    {
      domain: "2020-12-04",
      realisedPnl: 0,
    },
    {
      domain: "2020-12-07",
      realisedPnl: 1226.93,
    },
    {
      domain: "2020-12-08",
      realisedPnl: 0,
    },
    {
      domain: "2020-12-10",
      realisedPnl: 0,
    },
    {
      domain: "2020-12-16",
      realisedPnl: -5.6,
    },
    {
      domain: "2020-12-18",
      realisedPnl: 328,
    },
    {
      domain: "2020-12-21",
      realisedPnl: -6,
    },
    {
      domain: "2020-12-22",
      realisedPnl: -153.47,
    },
    {
      domain: "2020-12-23",
      realisedPnl: 0,
    },
    {
      domain: "2020-12-28",
      realisedPnl: 1774.82,
    },
    {
      domain: "2020-12-30",
      realisedPnl: 289.89,
    },
    {
      domain: "2020-12-31",
      realisedPnl: 0,
    },
    {
      domain: "2021-01-04",
      realisedPnl: 30.48,
    },
    {
      domain: "2021-01-05",
      realisedPnl: 0,
    },
    {
      domain: "2021-01-06",
      realisedPnl: -769.02,
    },
    {
      domain: "2021-01-07",
      realisedPnl: 240.99,
    },
    {
      domain: "2021-01-08",
      realisedPnl: 0,
    },
    {
      domain: "2021-01-11",
      realisedPnl: 41.96,
    },
    {
      domain: "2021-01-12",
      realisedPnl: -920.39,
    },
    {
      domain: "2021-01-13",
      realisedPnl: 2579,
    },
    {
      domain: "2021-01-14",
      realisedPnl: 0,
    },
    {
      domain: "2021-01-15",
      realisedPnl: 4998.16,
    },
    {
      domain: "2021-01-19",
      realisedPnl: -1333.1,
    },
    {
      domain: "2021-01-20",
      realisedPnl: -911.89,
    },
    {
      domain: "2021-01-21",
      realisedPnl: 0,
    },
    {
      domain: "2021-01-22",
      realisedPnl: 0,
    },
    {
      domain: "2021-01-25",
      realisedPnl: 975.86,
    },
    {
      domain: "2021-01-26",
      realisedPnl: 1098.76,
    },
    {
      domain: "2021-01-27",
      realisedPnl: 0,
    },
    {
      domain: "2021-01-28",
      realisedPnl: 0,
    },
    {
      domain: "2021-01-29",
      realisedPnl: -472.05,
    },
    {
      domain: "2021-02-01",
      realisedPnl: 0,
    },
    {
      domain: "2021-02-02",
      realisedPnl: 0,
    },
    {
      domain: "2021-02-03",
      realisedPnl: 394.99,
    },
    {
      domain: "2021-02-04",
      realisedPnl: 1639.41,
    },
    {
      domain: "2021-02-05",
      realisedPnl: 0,
    },
    {
      domain: "2021-02-08",
      realisedPnl: 495.87,
    },
    {
      domain: "2021-02-09",
      realisedPnl: 269.98,
    },
    {
      domain: "2021-02-10",
      realisedPnl: 0,
    },
    {
      domain: "2021-02-11",
      realisedPnl: 364.89,
    },
    {
      domain: "2021-02-12",
      realisedPnl: 0,
    },
    {
      domain: "2021-02-16",
      realisedPnl: 472.99,
    },
    {
      domain: "2021-02-17",
      realisedPnl: -5.02,
    },
    {
      domain: "2021-02-18",
      realisedPnl: 0,
    },
    {
      domain: "2021-02-19",
      realisedPnl: 1620.89,
    },
    {
      domain: "2021-02-22",
      realisedPnl: 4538.79,
    },
    {
      domain: "2021-02-23",
      realisedPnl: 5317.13,
    },
    {
      domain: "2021-02-24",
      realisedPnl: 0,
    },
    {
      domain: "2021-02-25",
      realisedPnl: -384.02,
    },
    {
      domain: "2021-02-26",
      realisedPnl: -1480.3,
    },
    {
      domain: "2021-03-01",
      realisedPnl: 512.88,
    },
    {
      domain: "2021-03-02",
      realisedPnl: 243.99,
    },
    {
      domain: "2021-03-03",
      realisedPnl: 0,
    },
    {
      domain: "2021-03-04",
      realisedPnl: 1769.61,
    },
    {
      domain: "2021-03-05",
      realisedPnl: 365.95,
    },
    {
      domain: "2021-03-08",
      realisedPnl: 653.98,
    },
    {
      domain: "2021-03-09",
      realisedPnl: 0,
    },
    {
      domain: "2021-03-10",
      realisedPnl: 147.94,
    },
    {
      domain: "2021-03-12",
      realisedPnl: 0,
    },
    {
      domain: "2021-03-15",
      realisedPnl: 224.93,
    },
    {
      domain: "2021-03-16",
      realisedPnl: 1269.9,
    },
    {
      domain: "2021-03-18",
      realisedPnl: 1003.34,
    },
    {
      domain: "2021-03-19",
      realisedPnl: 1083.22,
    },
    {
      domain: "2021-03-22",
      realisedPnl: 1850.22,
    },
    {
      domain: "2021-03-23",
      realisedPnl: 235.85,
    },
    {
      domain: "2021-03-24",
      realisedPnl: 396.97,
    },
    {
      domain: "2021-03-25",
      realisedPnl: -4046.94,
    },
    {
      domain: "2021-03-26",
      realisedPnl: 0,
    },
    {
      domain: "2021-03-29",
      realisedPnl: 0,
    },
    {
      domain: "2021-03-30",
      realisedPnl: -8.64,
    },
    {
      domain: "2021-03-31",
      realisedPnl: 329.98,
    },
    {
      domain: "2021-04-01",
      realisedPnl: 0,
    },
    {
      domain: "2021-04-05",
      realisedPnl: 722.86,
    },
    {
      domain: "2021-04-06",
      realisedPnl: 267.94,
    },
    {
      domain: "2021-04-07",
      realisedPnl: 268,
    },
    {
      domain: "2021-04-08",
      realisedPnl: 902.76,
    },
    {
      domain: "2021-04-09",
      realisedPnl: 167.93,
    },
    {
      domain: "2021-04-12",
      realisedPnl: 206.96,
    },
    {
      domain: "2021-04-14",
      realisedPnl: -1004.14,
    },
    {
      domain: "2021-04-15",
      realisedPnl: -416.12,
    },
    {
      domain: "2021-04-16",
      realisedPnl: 0,
    },
    {
      domain: "2021-04-19",
      realisedPnl: -177,
    },
    {
      domain: "2021-04-21",
      realisedPnl: 1115.86,
    },
    {
      domain: "2021-04-22",
      realisedPnl: 0,
    },
    {
      domain: "2021-04-26",
      realisedPnl: 435.92,
    },
    {
      domain: "2021-04-28",
      realisedPnl: 34,
    },
    {
      domain: "2021-04-29",
      realisedPnl: -236,
    },
    {
      domain: "2021-05-03",
      realisedPnl: 0,
    },
    {
      domain: "2021-05-04",
      realisedPnl: 508.86,
    },
    {
      domain: "2021-05-05",
      realisedPnl: -1120.11,
    },
    {
      domain: "2021-05-06",
      realisedPnl: -197.28,
    },
    {
      domain: "2021-05-07",
      realisedPnl: 785.84,
    },
  ],
  chartProperties: {
    name: "PnL By Date",
    chartType: "bar",
    keys: ["realisedPnl"],
    colors: ["var(--chart-blue)"],
    legend: ["Realized PnL"],
    domainType: "date",
  },
};
