export enum Brokers {
  IBKR = "IBKR",
  TastyWorks = "TastyWorks",
  Schwab = "Schwab",
  Fidelity = "Fidelity",
  Robinhood = "Robinhood",
  Generic = "Generic",
  ETrade = "ETrade",
  Firstrade = "Firstrade",
  Ally = "Ally",
}

export enum InstrumentType {
  Equity = "Equity",
  Option = "Option",
  Future = "Future",
  Forex = "Forex",
  Fund = "Fund", // Mutual Fund, ETF etc
  Cash = "Cash",
  Unknown = "Unknown",
}

export enum BuySell {
  Buy = "Buy",
  Sell = "Sell",
}

export enum PutCall {
  Put = "Put",
  Call = "Call",
}

export enum CashFlowType {
  Deposit = "Deposit",
  Withdrawal = "Withdrawal",
  Fee = "Fee",
  Dividend = "Dividend",
  Interest = "Interest",
  Tax = "Tax",
  Transfer = "Transfer",
  Other = "Other",
}

export enum FormFrameworkComponentType {
  Text = "text",
  TextArea = "textarea",
  CheckBox = "checkbox",
  Date = "date",
  Select = "select",
  Radio = "radio",
  Number = "number",
  ReadOnly = "readonly",
  InstrumentSearch = "instrument-search",
}

export enum PricingPollerStatus {
  POLLING = "POLLING",
  EMPTY = "EMPTY",
  ERROR = "ERROR",
}

export enum PositionStatus {
  ALL = "all",
  ACTIVE = "active",
  OPEN = "open",
  CLOSED = "closed",
}

export enum ToolbarFeatures {
  FILTER_SELECTOR = "filter-selector",
  DASHBOARD_SELECTOR = "dashboard-selector",
  UNDERLYING = "underlying",
  STATUS = "status",
  GROUPING = "grouping",
  TAGS = "tags",
  HELP = "help",
  PRICING_QUEUE = "pricing-queue",
  CLEAR = "clear",
  DATE_RANGE = "date-range",
  TOGGLE_UNBOOKED_AND_NO_POS_GROUP = "toggle-unbooked-and-no-pos-group",
  INSIGHTS = "insights",
  POSITION_GROUPING = "position-grouping",
  BOOKS = "books",
  STRATEGY = "strategy",
  TIMEFRAMES = "timeframes",
  TITLE = "title",
  DESCRIPTION = "description",
  AUTOSYNC = "autosync",
}

export enum PositionGroupingOptions {
  BUP = "B/U/P",
  UBP = "U/B/P",
  BPU = "B/P/U",
  NONE = "None",
  DEFAULT = "None",
}

export enum KnowledgeBaseHelperTopics {
  DASHBOARD = "Dashboard",
  POSITIONS = "Positions",
  TRANSACTIONS = "Transactions",
  CASHFLOWS = "Cashflows",
  IMPORTING = "Importing",
  PRICES = "Prices",
}

export enum InsightSearchPages {
  ALL = "all",
  YOUR_INSIGHTS = "your_insights",
  OFFICIAL = "official",
  COMMUNITY = "community",
}
