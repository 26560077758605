import React from "react";
import {
  Box,
  Center,
  Flex,
  HStack,
  IconButton,
  TabPanel,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { MdDragHandle, MdOutlineClose } from "react-icons/md";
import { getAllChildrenOfGroup } from "../../../components";
import { useAtomValue } from "jotai";
import { TransactionsAtom } from "../../../store";
import { useEffect, useMemo, useRef, useState } from "react";
import { Position, Trade, TradeEx } from "../../../types";
import "./Sash.css";
import "./TradesView.css";
import { KeyStatBar } from "./components/KeyStatBar";

import {
  CustomTab,
  CustomTabList,
  CustomTabPanels,
  CustomTabsContainer,
} from "../../../components/design_library";
import { NotesTimeline } from "../../../components/timeline/NotesTimeline";
import { useInstruments } from "../../../hooks";
import { PositionAuditLog } from "../../../components/audit/PositionAuditLog";
import TradingView from "../../../components/tradingview/TradingView";
import PayoffChart from "../../../components/optionpayoff/PayOffChart";
import TradesAgGrid from "./components/TradesAgGrid";
import { PositionAudit } from "../../audit/PositionAudit";
import SharePositions from "./components/SharePositions";

interface TradesViewProps {
  onClose: () => void;
  tradeIds: string[];
  params: any;
  positions: Position[];
}

export const TradesView = (props: TradesViewProps) => {
  const transactions = useAtomValue(TransactionsAtom);
  const [groupStatsAvailable, setGroupStatsAvailable] =
    useState<boolean>(false);

  const instruments = useInstruments();
  const [underlyingPrice, setUnderlyingPrice] = useState<number>(0);

  const positions: Position[] = useMemo(() => {
    if (props.params?.node?.group !== true && props.params?.node?.data) {
      // leaf selected
      return [props.params.node.data as Position];
    } else if (
      props.params?.node?.group === true &&
      (props.params?.node?.allLeafChildren?.length ?? 0) > 0
    ) {
      // group selected
      return props.params.node.allLeafChildren.map((child: any) => {
        return child.data as Position;
      });
    }
    return [];
  }, [props.params]);

  useEffect(() => {
    if (props?.params?.node?.aggData) {
      setGroupStatsAvailable(true);
    } else {
      setGroupStatsAvailable(false);
    }
  }, [props.params]);

  const trades: TradeEx[] = useMemo(() => {
    const filteredTrades = transactions.trades.filter(
      (transaction: Trade) =>
        transaction.id && props.tradeIds?.includes(transaction.id)
    );

    let price = 0;
    return filteredTrades.map((trade) => {
      price = trade.underlyingPriceData?.price ?? 0;
      setUnderlyingPrice(price);
      return {
        ...trade,
        instrument: trade.instrumentId
          ? instruments.instruments.find((x) => x.id === trade.instrumentId)
          : undefined,
        underlyingInstrument: trade.underlyingInstrumentId
          ? instruments.instruments.find(
              (x) => x.id === trade.underlyingInstrumentId
            )
          : instruments.instruments.find((x) => x.id === trade.instrumentId),
      } as TradeEx;
    });
  }, [transactions.trades, props.tradeIds]);

  const topLeftRef = useRef(null);

  return (
    <CustomTabsContainer height={"100%"}>
      <Center>
        <Text flex="2">
          <MdDragHandle className="grabHandleOnPane" />
        </Text>
      </Center>

      <HStack
        w="full"
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <CustomTabList flex="1" p={0}>
          <HStack justifyContent={"space-between"} w="100%">
            <HStack justifyContent={"space-between"}>
              <HStack>
                <CustomTab>Trades</CustomTab>
                <CustomTab>Historical Chart</CustomTab>
                <CustomTab>Option Payoff</CustomTab>
                <CustomTab>Timeline</CustomTab>

                <CustomTab isDisabled={positions.length === 0}>
                  <Tooltip
                    label={
                      !props.params?.node?.data
                        ? "Audit is only available on single positions"
                        : ""
                    }
                  >
                    <Text>Audit</Text>
                  </Tooltip>
                </CustomTab>
                <CustomTab isDisabled={!props.params?.node?.data}>
                  <Text>Share</Text>
                </CustomTab>
              </HStack>
            </HStack>
          </HStack>
        </CustomTabList>

        <Box maxW="60px">
          <IconButton
            justifyContent={"flex-end"}
            pr="20px"
            flex="1"
            size="lg"
            height="24px"
            variant="none"
            icon={<MdOutlineClose />}
            aria-label="close"
            onClick={props.onClose}
            ref={topLeftRef}
          />
        </Box>
      </HStack>
      <CustomTabPanels p={0} height={"100%"}>
        <TabPanel p="0" height={"100%"}>
          <Flex direction="column" h="full" className="allotment-tutorial">
            {/* {props?.params?.node?.aggData && (
              <KeyStatBar
                isAggData={true}
                data={props?.params?.node?.aggData}
              />
            )}

            {props?.params?.node?.data && (
              <KeyStatBar isAggData={false} data={props?.params?.node?.data} />
            )} */}
            <TradesAgGrid trades={trades} topLeftRef={topLeftRef} />
          </Flex>
        </TabPanel>

        <TabPanel p={0} m={0} height={"100%"}>
          <TradingView trades={trades} />
        </TabPanel>
        <TabPanel p={0} m={0} height={"100%"}>
          <Box p={0} m={0} height={"100%"}>
            <PayoffChart
              trades={trades}
              underlyingPrice={underlyingPrice}
              positions={props.positions}
            />
          </Box>
        </TabPanel>

        <TabPanel p={0} m={0} height={"100%"}>
          <NotesTimeline trades={trades} />
        </TabPanel>
        <TabPanel p={0} m={0} height="full" w="full">
          {/* TODO - REMOVE */}
          {/* <PositionAuditLog trades={trades} data={props.params?.node?.data} /> */}
          <PositionAudit trades={trades} positions={positions} />
        </TabPanel>

        <TabPanel p={0} m={0} height={"100%"}>
          <Box p={0} m={0} height={"100%"}>
            <SharePositions data={props?.params?.node?.data} trades={trades} />
          </Box>
        </TabPanel>
      </CustomTabPanels>
    </CustomTabsContainer>
  );
};
