import { Button } from "@chakra-ui/react";
import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { IoChevronDown } from "react-icons/io5";

export default function DropDownButton(props: any) {
  return (
    <Button
      w={{
        sm: "auto",
        base: "100%",
      }}
      bg="var(--dark-gray)"
      aria-haspopup="listbox"
      rightIcon={<IoChevronDown />}
      justifyContent="space-between"
      mt="2px"
      height="28px"
      px="8px"
      fontWeight={500}
      fontSize={{
        base: "14px",
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
}
