import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  VStack,
  HStack,
  Avatar,
  Input,
  Divider,
  Box,
  FormLabel,
  Tooltip,
  Portal,
  Text,
} from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { BiTrash } from "react-icons/bi";
import { MdAccountTree } from "react-icons/md";
import { AccountAtom } from "../../store";
import { SecondaryButton, Dialog } from "../design_library";
import useAccountController from "../AccountSelector/useAccountController";
import React from "react";
import ModalHeader from "../design_library/ModalHeader";
import ExportAccount from "../ExportAccount/ExportAccount";

export const MangageAccounts = () => {
  const controller = useAccountController();
  const accountState = useAtomValue(AccountAtom);

  return (
    <>
      <SecondaryButton
        variant="ghost"
        justifyContent="space-between"
        onClick={() => {
          controller.setModalOpen(true);
        }}
        width="full"
        justifyContent={"center"}
      >
        Manage portfolios
      </SecondaryButton>

      <Modal
        size={"3xl"}
        isOpen={controller.modalOpen}
        onClose={() => controller.setModalOpen(false)}
      >
        <ModalOverlay backdropFilter="blur(3px)" />

        <ModalContent bg="var(--bg)" maxW="500px">
          <ModalHeader
            icon={<MdAccountTree />}
            title={"Portfolio Management"}
            description={
              "Export your trades, update & delete your portfolios, or create a new one."
            }
          />
          <ModalBody pb="40px" pt="20px">
            <Heading fontSize={"md"} mb="12px">
              Your Accounts
            </Heading>

            <VStack spacing={2} align="stretch" mb="20px">
              {accountState.accounts
                .sort((a, b) => a.id - b.id)
                .map((account) => (
                  <HStack
                    w="full"
                    borderRadius={"6px"}
                    justifyContent={"space-between"}
                  >
                    <Avatar
                      bg={"var(--gray)"}
                      name={account.name}
                      w="40px"
                      h="40px"
                      borderRadius={6}
                      color={"var(--white)"}
                    />
                    <Input
                      defaultValue={account.name}
                      onChange={(e) => {
                        controller.updateAccountName(
                          account.id,
                          e.target.value,
                          account
                        );
                      }}
                      placeholder="Enter account name"
                    />

                    <Dialog
                      buttonSize="md"
                      title={"Delete all transactions"}
                      buttonText={
                        <HStack color="var(--black)" px="18px">
                          <BiTrash />
                          <Text fontSize="12px">Trades</Text>
                        </HStack>
                      }
                      description={`Are you sure you want to delete all transactions for account "${account.name}"?`}
                      confirmText={"Confirm"}
                      onConfirm={() =>
                        controller.deleteAllTheTrades(account.id)
                      }
                    />
                    <Dialog
                      buttonSize="md"
                      title={"Remove Account"}
                      disabled={accountState.accounts.length === 1}
                      buttonText={
                        <HStack color="var(--black)" px="18px">
                          <BiTrash />
                          <Text fontSize="12px">Portfolio</Text>
                        </HStack>
                      }
                      description={`Are you sure you want to delete "${account.name}" and all associated transactions?`}
                      confirmText={"Confirm"}
                      onConfirm={() => controller.handleDelete(account.id)}
                    />
                  </HStack>
                ))}
            </VStack>
            <Divider mb="10px" />
            <Box mb="20px">
              <form onSubmit={controller.handleSubmit}>
                <FormLabel>Add new portfolio</FormLabel>
                <HStack>
                  <Input
                    type="text"
                    name="name"
                    value={controller.accountForm.name}
                    onChange={controller.handleFormChange}
                    placeholder="Enter new portfolio name"
                    required
                  />
                </HStack>
                <SecondaryButton w="full" type="submit" mt="10px">
                  {controller.editingId ? "Update" : "Create portfolio"}
                </SecondaryButton>
              </form>
            </Box>

            <ExportAccount />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
