import React from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Tag,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { BiTrash } from "react-icons/bi";
import { useAPIKeysManagementPanelController } from "./useAPIKeysManagementPanelController";
import NoFeatureFlagAPIKeys from "./NoFeatureFlagAPIKeys";
import { AppStateAtom } from "../../store";
import { useAtomValue } from "jotai";

export default function APIKeysManagementPanel() {
  const toast = useToast();
  const { apiKeysList, loading, handleDeleteKey, handleCreateKey } =
    useAPIKeysManagementPanelController();
  const appState = useAtomValue(AppStateAtom);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  if (!appState?.featureFlags?.includes("api_keys")) {
    return <NoFeatureFlagAPIKeys />;
  }

  return (
    <Box w="full" h="100vh" overflowY="scroll" overflowX="hidden">
      <Flex w="full" rounded="lg" direction="column" bg="var(--bg)">
        <Box shadow="base" p={5} rounded="lg" w="full">
          <VStack spacing={4}>
            {apiKeysList.length === 0 && !loading && (
              <Text color="var(--white)">
                You don't have any API keys yet. Click the button below to
                generate a new one.
              </Text>
            )}

            {loading ? (
              <Spinner />
            ) : (
              apiKeysList.map((key: any) => (
                <HStack
                  key={key.id}
                  width="full"
                  bg="var(--dark-gray)"
                  shadow="sm"
                  rounded="lg"
                  p="2"
                >
                  <Flex justifyContent="space-between" w="100%" pl="10px">
                    <HStack>
                      <Text color="var(--white)" isTruncated maxW={260}>
                        {key.key}
                      </Text>
                      <IconButton
                        onClick={() => copyToClipboard(key.key)}
                        bg="transparent"
                        icon={<CopyIcon />}
                        aria-label={""}
                      />
                    </HStack>
                    <HStack>
                      <VStack mr="10px" spacing={0} w="100px">
                        <Text color="var(--white)" fontSize="sm">
                          {key.count || 0} calls
                        </Text>
                        <Text color="var(--light-gray)" fontSize="xs">
                          Usage
                        </Text>
                      </VStack>
                      <Tag colorScheme="blue">{key.type}</Tag>
                    </HStack>
                  </Flex>
                  <Button
                    onClick={() => handleDeleteKey(key.id)}
                    bg="transparent"
                  >
                    <BiTrash color="var(--red)" fontSize="20px" />
                  </Button>
                </HStack>
              ))
            )}

            <Button w="full" onClick={handleCreateKey}>
              Generate new key
            </Button>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
}
