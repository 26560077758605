import React from "react";
import { Box, Grid, Heading, GridItem } from "@chakra-ui/react";
import { InsightTemplate } from "../../../../../types/Insight";
import InsightCard from "./InsightCard";
import InsightListItem from "./InsightListItem";

interface InsightsGridProps {
  filteredEntries: Record<string, InsightTemplate[]>;
  isInsightEnabled: (id: number) => boolean;
  handleToggleInsight: (insight: InsightTemplate) => void;
  appState: { user?: { id: string } };
  setInsightData: (insight: any) => void;
  setPage: (page: string) => void;
  view: string;
  displayDemoData: boolean;
}

const InsightsGrid: React.FC<InsightsGridProps> = ({
  filteredEntries,
  isInsightEnabled,
  handleToggleInsight,
  setInsightData,
  setPage,
  appState,
  view,
  displayDemoData,
}) => {
  return (
    <Box pb="800px">
      {Object.entries(filteredEntries).map(([category, insights]) => (
        <Box key={category} w="full" mb={8}>
          <Heading size="sm" mb={4} mt="10px">
            {category} ({insights.length})
          </Heading>

          <Grid
            templateColumns={
              view === "list"
                ? {
                    base: "1fr",

                    md: "repeat(auto-fit, minmax(100%, 1fr))",
                  }
                : {
                    base: "1fr",
                    md: "repeat(auto-fit, minmax(360px, 1fr))",
                  }
            }
            gap={view === "list" ? { base: 2, md: 2 } : 8}
            w="full"
            maxW="100%"
            pr={{ base: "0px", md: "0px" }}
            gridAutoRows={view === "list" ? "auto" : "160px"}
            gridAutoFlow={view === "list" ? "row" : "row dense"}
            overflow="hidden"
          >
            {insights.map((insight) => {
              const isChartOrTable =
                insight.type.toLowerCase().includes("chart") ||
                insight.type.toLowerCase().includes("treemap") ||
                insight.type.toLowerCase().includes("table");
              const isComparison = insight.type
                .toLowerCase()
                .includes("comparison");

              return (
                <GridItem
                  key={insight.insightId}
                  gridColumn={
                    isChartOrTable ? { base: "span 1", md: "span 3" } : "span 1"
                  }
                  gridRow={isChartOrTable || isComparison ? "span 5" : "span 2"} // Double height for both chart/table and comparison
                >
                  {view === "list" && (
                    <InsightListItem
                      insight={insight}
                      enabled={isInsightEnabled(insight.insightId as number)}
                      handleToggleInsight={handleToggleInsight}
                      isOwner={
                        parseInt(appState.user?.id) ===
                        parseInt(insight.ownerUserId)
                      }
                      setInsightData={setInsightData}
                      setPage={setPage}
                    />
                  )}
                  {view === "grid" && (
                    <InsightCard
                      insight={insight}
                      enabled={isInsightEnabled(insight.insightId as number)}
                      handleToggleInsight={handleToggleInsight}
                      isOwner={
                        parseInt(appState.user?.id) ===
                        parseInt(insight.ownerUserId)
                      }
                      setInsightData={setInsightData}
                      setPage={setPage}
                      displayDemoData={displayDemoData}
                    />
                  )}
                </GridItem>
              );
            })}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default InsightsGrid;
