import { atom } from "jotai";

export const SidebarStateAtom = atom(
  JSON.parse(localStorage.getItem("collapsed") || "false")
);

// Effect to sync with localStorage on every state change
SidebarStateAtom.onMount = (setAtom) => {
  const savedState = localStorage.getItem("collapsed");
  if (savedState !== null) {
    setAtom(JSON.parse(savedState));
  }
};
