import { Heading, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import NavWrapper from "./NavWrapper";

export default function SimpleVsComplexPaneTutorial() {
  return (
    <NavWrapper
      previousEnabled={false}
      isFinalStep={false}
      nextDisabled={false}
    >
      <VStack w="100%" textAlign={"left"} alignItems={"flex-start"}>
        <Heading
          as="h2"
          fontSize="18px"
          mb={4}
          fontWeight={600}
          textAlign={"left"}
          w="100%"
        >
          Display Type - Simple vs Complex
        </Heading>
        <Text mt="10px">
          By default the positions page shows grouping of different levels. This
          is a complex view.
        </Text>
        <Text mt="10px">
          If you wish to see a simplier view of your positions, you can switch
          to the "No grouping" view.
        </Text>
        <Text mt="10px">
          Click this button to switch between the two views.
        </Text>

        <Image
          src="https://cdn.wealthbee.io/main/app/tutorial/position/simplevscomplex.png"
          alt="Complex view"
        />
      </VStack>
    </NavWrapper>
  );
}
