import React from "react";
import { HStack, Button } from "@chakra-ui/react";
import { FormikProps } from "formik";
import { InsightTemplate } from "../../../../types";

interface SubmitButtonsProps {
  formik: FormikProps<InsightTemplate>;
  mode: "create" | "update";
}

const SubmitButtons: React.FC<SubmitButtonsProps> = ({ formik, mode }) => (
  <HStack spacing={4}>
    {mode == "create" ? (
      <Button type="submit" colorScheme="teal">
        Create Insight
      </Button>
    ) : (
      <Button type="submit" colorScheme="blue">
        Update Insight
      </Button>
    )}
  </HStack>
);

export default SubmitButtons;
