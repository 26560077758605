import { Box } from "@chakra-ui/react";
import React from "react";

export const FastTag = (props: any) => {
  return (
    <Box
      p="8px 16px"
      background="var(--gray)"
      borderRadius={"40px"}
      color={"var(--white)"}
      fontSize={"12px"}
      lineHeight={"10px"}
      textAlign={"center"}
      {...props}
    >
      {props.children}
    </Box>
  );
};
