import React, { useEffect } from "react";
import { useAtom } from "jotai";
import {
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverTrigger,
  Tooltip,
  Text,
  Button,
  VStack,
  Divider,
  PopoverContent,
  PopoverArrow,
  Box,
} from "@chakra-ui/react";
import { TimeFrameAtom } from "../../store";
import { TimeFrameEnum } from "../../types";
import DropDownButton from "../design_library/DropDownButton";
import { resolveNamedTimeFrame } from "../../utils";
import moment from "moment";
import { SecondaryButton } from "../design_library";

export const TimeFrameSelector = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [windows, setWindows] = React.useState<TimeFrameEnum[]>([]);
  const [asAt, setAsAt] = React.useState<TimeFrameEnum[]>([]);
  const [timeFrame, setTimeFrame] = useAtom(TimeFrameAtom);

  const onTimeFrameSelected = (timeFrame: TimeFrameEnum) => {
    setTimeFrame({ timeFrame });
  };

  useEffect(() => {
    if (asAt.length > 0) return;

    setAsAt(() => {
      let result: TimeFrameEnum[] = [];
      Object.entries(TimeFrameEnum).map(([key, value]) => {
        if (key.includes("UP_TO")) {
          result.push(value);
        }
      });
      return result;
    });

    setWindows(() => {
      let result: TimeFrameEnum[] = [];
      Object.entries(TimeFrameEnum).map(([key, value]) => {
        if (!key.includes("UP_TO")) {
          result.push(value);
        }
      });
      return result;
    });
  }, []);

  const { startDate, endDate } = resolveNamedTimeFrame(timeFrame.timeFrame);

  return (
    <Popover
      isOpen={expanded}
      onClose={() => setExpanded(false)}
      closeOnBlur={true}
      placement="bottom-start"
      arrowSize={15}
    >
      <PopoverTrigger>
        <Flex>
          <DropDownButton
            aria-expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <HStack cursor="pointer" minWidth="60px" overflow={"visible"}>
              {timeFrame.timeFrame && <Text>{timeFrame.timeFrame}</Text>}
              {!timeFrame?.timeFrame && (
                <Text color="yellow.200">Not Specified</Text>
              )}

              {timeFrame.timeFrame !== TimeFrameEnum.UP_TO_TODAY && (
                <VStack fontSize="8px" lineHeight={0}>
                  <Text>
                    {moment.utc(startDate).year() === 2000
                      ? "Inception"
                      : moment.utc(startDate).format("DD MMM YYYY")}
                  </Text>
                  <Divider />
                  <Text>{moment.utc(endDate).format("DD MMM YYYY")}</Text>
                </VStack>
              )}
            </HStack>
          </DropDownButton>
        </Flex>
      </PopoverTrigger>
      <PopoverContent p={0} mt="-4px" w="300px" bg="var(--bg)">
        <PopoverArrow bg="var(--bg)" />
        <PopoverBody py={2} m={0} w="full">
          <Text fontSize="sm" fontWeight="bold">
            Timeframe
          </Text>
          <VStack align="stretch" p={0} spacing={2}>
            <Text>Up To</Text>
            <VStack spacing={2} align="stretch">
              {asAt.map((value, index) => (
                <SecondaryButton
                  size="xs"
                  key={index}
                  variant={timeFrame.timeFrame == value ? "solid" : "outline"}
                  bg={
                    timeFrame.timeFrame == value ? "var(--accent)" : "var(--bg)"
                  }
                  border="0"
                  justifyContent="space-between"
                  onClick={(e) => {
                    e.preventDefault();
                    onTimeFrameSelected(value);
                    // setExpanded(false);
                  }}
                >
                  <Flex w="full" justifyContent="space-between" align="center">
                    <Box>{value}</Box>
                    <Box>
                      <Text fontSize="12px" fontWeight={400}>
                        {moment
                          .utc(resolveNamedTimeFrame(value).endDate)
                          .format("Do MMM YY")}
                      </Text>
                    </Box>
                  </Flex>
                </SecondaryButton>
              ))}
            </VStack>

            <Divider />
            <VStack align="stretch" spacing={2}>
              <Text>Window</Text>
              <Text fontSize="10px" color="gray.400">
                (Only contains trades within the dates, so it may not contain
                full positions)
              </Text>
            </VStack>
            <VStack spacing={2} align="stretch">
              {windows.map((value, index) => {
                const { startDate, endDate } = resolveNamedTimeFrame(value);

                return (
                  <SecondaryButton
                    size="xs"
                    key={index}
                    variant={timeFrame.timeFrame == value ? "solid" : "outline"}
                    bg={
                      timeFrame.timeFrame == value
                        ? "var(--accent)"
                        : "var(--bg)"
                    }
                    border="0"
                    justifyContent="space-between"
                    onClick={(e) => {
                      e.preventDefault();
                      onTimeFrameSelected(value);
                      // setExpanded(false);
                    }}
                  >
                    <Flex
                      w="full"
                      justifyContent="space-between"
                      align="center"
                    >
                      <Box>{value}</Box>
                      <Box>
                        <Text fontSize="12px" fontWeight={400}>
                          {moment.utc(startDate).format("Do MMM YY")} -{" "}
                          {moment.utc(endDate).format("Do MMM YY")}
                        </Text>
                      </Box>
                    </Flex>
                  </SecondaryButton>
                );
              })}
            </VStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
