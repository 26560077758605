import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { useFilters } from "../../../hooks/useFilters";
import { DataItem, InsightBarchartProps } from "../../../types/Insight";
import { AccountAtom, TimeFrameAtom } from "../../../store";
import { useQuery } from "@tanstack/react-query";
import { InsightStateEnum } from "../InsightStateEnum";
import { distinctValues, resolveNamedTimeFrame } from "../../../utils";
import { runInsight } from "../../../api";
import { TreeChartItem } from "./TreeChartItem";
import { BaseInsightProps } from "../BaseInsightProps";
import InsightTreeMapDemoDataMap from "./data";
import { useSearchParams } from "react-router-dom";

interface UseInsightTreeMapController {
  insightState: InsightStateEnum;
  supported: boolean;
  data?: TreeChartItem;
}

export const useInsightTreeMapController = (
  props: BaseInsightProps
): UseInsightTreeMapController => {
  const { insight } = props;

  const filters = useFilters();
  const accountState = useAtomValue(AccountAtom);
  const timeFrame = useAtomValue(TimeFrameAtom);

  const [treeData, setTreeData] = useState<TreeChartItem | undefined>(
    undefined
  );

  const [insightState, setInsightState] = useState<InsightStateEnum>(
    InsightStateEnum.Loading
  );

  const [searchParams] = useSearchParams();
  const pid = parseInt(searchParams.get("pid") ?? "");
  const accountId = pid || accountState?.selectedAccount?.id;

  // uses react query to cache result
  const result = useQuery({
    queryKey: [
      props.demo === true ? "insight-treemap" : "insight-treemap-demo",

      insight.insightId,
      insight.query,
      insight.type,
      accountId,
      filters.selectedUnderlying,
      filters.selectedTag,
      filters.selectedStrategy,
      filters.selectedBook,
      filters.selectedPositionGroup,
      timeFrame.timeFrame,
    ],
    queryFn: async () => {
      if (props.demo === true && props?.insight?.query) {
        return InsightTreeMapDemoDataMap[props?.insight?.query];
      } else {
        const timeframe = resolveNamedTimeFrame(timeFrame.timeFrame);

        return await runInsight(
          accountId,
          insight.insightId,
          insight.query!,
          insight.type,
          timeframe,
          filters.selectedUnderlying,
          filters.selectedTag,
          filters.selectedStrategy,
          filters.selectedBook,
          filters.selectedPositionGroup
        );
      }
    },
    enabled:
      accountId !== undefined &&
      insight !== undefined &&
      insight.insightId !== undefined &&
      insight.query !== undefined &&
      insight.type !== undefined,
  });

  const buildTreeData = (
    data: InsightBarchartProps
  ): TreeChartItem | undefined => {
    if (data.data) {
      const filteredData = data.data.filter(
        (d: any) => d.value !== 0 && d.riskpercent > 1
      );

      let groups: string[] = [];
      let label = "";

      switch (data.chartProperties?.domainType) {
        case "SIZE_BY_BOOK":
          label = "Size By Book - (Open Positions >= 1% Relative Size)";
          groups = distinctValues(filteredData.map((d: any) => d.book));
          break;
        case "SIZE_BY_UNDERLYING":
          label = "Size By Underlying - (Open Positions >= 1% Relative Size)";
          groups = distinctValues(filteredData.map((d: any) => d.underlying));
          break;
        case "SIZE_BY_POSITIONGROUP":
          label =
            "Size By Position Groups - (Open Positions >= 1% Relative Size)";
          groups = distinctValues(
            filteredData.map((d: any) => d.positionGroup)
          );
          break;
        case "SIZE_BY_STRATEGY":
          label = "Size By Strategy - (Open Positions >= 1% Relative Size)";
          groups = distinctValues(filteredData.map((d: any) => d.strategy));
          break;
        default:
          break;
      }

      const children = groups.map((group) => {
        let items: DataItem[] = [];
        switch (data.chartProperties?.domainType) {
          case "SIZE_BY_BOOK":
            items = filteredData.filter((d) => d.book === group);
            break;
          case "SIZE_BY_UNDERLYING":
            items = filteredData.filter((d) => d.underlying === group);
            break;
          case "SIZE_BY_POSITIONGROUP":
            items = filteredData.filter((d) => d.positionGroup === group);
            break;
          case "SIZE_BY_STRATEGY":
            items = filteredData.filter((d) => d.strategy === group);
            break;
          default:
            break;
        }

        return {
          level: 1,
          label: group,
          confidence:
            items.filter((x) => x.confidence !== 1).length > 0 ? 0 : 1,
          children: items.map((s) => {
            return {
              level: 2,
              label: s.symbol,
              value: s.value,
              confidence: s.confidence,
              parent: group,
            } as TreeChartItem;
          }),
        };
      }) as TreeChartItem[];

      const reportData: TreeChartItem = {
        level: 0,
        label: label,
        confidence:
          filteredData.filter((x) => x.confidence !== 1).length > 0 ? 0 : 1,
        children,
      };

      return reportData;
    }
    return undefined;
  };

  useEffect(() => {
    if (result.isSuccess && result.data) {
      const data = buildTreeData(result.data);
      setTreeData(data);
    }
    return undefined;
  }, [props.demo, result.isSuccess, result.data]);

  useEffect(() => {
    if (props.demo) {
      setInsightState(InsightStateEnum.Success);
    } else {
      if (!result.isSuccess && !result.isError) {
        setInsightState(InsightStateEnum.Loading);
      } else if (result.isSuccess) {
        if (result.data) {
          setInsightState(InsightStateEnum.Success);
        } else {
          setInsightState(InsightStateEnum.NoData);
        }

        if (result?.data?.data === null) {
          setInsightState(InsightStateEnum.NoData);
        } else {
          setInsightState(InsightStateEnum.Success);
        }
      } else if (result.isError) {
        setInsightState(InsightStateEnum.Error);
      }
    }
  }, [result, props.demo]);

  return {
    insightState,
    supported:
      result.data?.supported === undefined ? true : result.data!.supported,
    data: treeData,
  };
};
