import { StepType } from "@reactour/tour";
import WelcomeToWealthBee from "./components/WelcomeToWealthBee";
import AccountManagerTutorial from "./components/AccountManagerTutorial";
import AddAssetsTutorial from "./components/AddAssetsTutorial";
import PositionsGridExplainer from "./components/PositionsGridExplainer";
import BookExplainerTutorial from "./components/BookExplainerTutorial";
import TransactionsPaneTutorial from "./components/TransactionsPaneTutorial";
import UnderlyingExplainerTutorial from "./components/UnderlyingExplainerTutorial";
import PositionsGroupTutorial from "./components/PositionsGroupTutorial";
import PositionSingleExplainerTutorial from "./components/PositionSingleExplainerTutorial";
import LastPage from "./components/LastPage";
import TutorialVideo from "./components/TutorialVideo";
import SimpleVsComplexPaneTutorial from "./components/SimpleVsComplexView";

export const tourData: StepType[] = [
  {
    selector: ".xxxxxx",
    content: TutorialVideo,
    position: "center",
  },
  {
    selector: ".xxxxxx",
    content: WelcomeToWealthBee,
    position: "center",
  },
  {
    selector: ".tutorial-account-manager",
    content: AccountManagerTutorial,
    position: "top",
  },
  {
    selector: ".tutorial-add-assets",
    content: AddAssetsTutorial,
  },

  {
    selector: ".ag-root-wrapper",
    content: PositionsGridExplainer,
    position: "right",
  },
  {
    selector: ".ag-row-level-0",
    content: BookExplainerTutorial,
    position: [500, 20],
  },
  {
    selector: ".ag-row-level-1",
    content: UnderlyingExplainerTutorial,
    position: [600, 20],
  },
  {
    selector: ".ag-row-level-2",
    content: PositionsGroupTutorial,
    position: [650, 20],
  },
  {
    selector: ".ag-row-level-3",
    content: PositionSingleExplainerTutorial,
    position: [600, 20],
  },
  {
    selector: ".split-view-view:nth-child(2)",
    content: TransactionsPaneTutorial,
    position: [400, 20],
  },
  {
    selector: ".tutorial-grouping",
    content: SimpleVsComplexPaneTutorial,
    position: "right",
  },
  {
    selector: ".xxxxxxx",
    content: LastPage,
    position: "center",
  },
];
