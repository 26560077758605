import { keyframes } from "@emotion/react";

export const pulse = keyframes`
  0% {
    /* Start with no rings */
    box-shadow: 
      0 0 0 0px var(--accent-rgba),
      0 0 0 0px var(--accent-dark-rgba);
  }
  50% {
    /* Expand rings with semi-transparent layers */
    box-shadow: 
        0 0 0 4px var(--accent-rgba),
      0 0 0 8px var(--accent-dark-rgba);
  }
  100% {
    /* Contract back to no rings */
    box-shadow: 
        0 0 0 0px var(--accent-rgba),
      0 0 0 0px var(--accent-dark-rgba);
  }
`;
