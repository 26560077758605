import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Spacer,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { ConfigItem } from "../../../types";
import { FormText } from "../../../components";
import React from "react";

interface EditConfigProps {
  configItem?: ConfigItem;
  updateConfigItem: (configItem: ConfigItem) => void;
}

export const EditConfig = (props: EditConfigProps) => {
  const [configItemToUpdate, setConfigItemToUpdate] = useState<ConfigItem>();

  useEffect(() => {
    setConfigItemToUpdate({ ...props.configItem } as ConfigItem);
  }, [props.configItem]);

  return (
    <VStack spacing={4} bg="gray.900" h="full" my={1} p={2}>
      <Text fontSize="18px">Selected Instrument</Text>

      <FormControl>
        <FormLabel size="sm">Namespace</FormLabel>
        <FormText value={configItemToUpdate?.namespace} />
      </FormControl>

      <FormControl>
        <FormLabel size="sm">Key</FormLabel>
        <FormText value={configItemToUpdate?.key} />
      </FormControl>

      <FormControl isRequired>
        <FormLabel size="sm">Value</FormLabel>
        <Textarea
          size="sm"
          value={configItemToUpdate?.value}
          onChange={(e: any) => {
            if (e.target.value) {
              setConfigItemToUpdate({
                ...configItemToUpdate,
                value: e.target.value,
              } as ConfigItem);
            }
          }}
        />
      </FormControl>

      <HStack w="full">
        <Spacer />
        <Button
          colorScheme="blue"
          disabled={props.configItem === undefined}
          onClick={() =>
            setConfigItemToUpdate({ ...props.configItem } as ConfigItem)
          }
        >
          Reset
        </Button>
        <Button
          colorScheme="green"
          ml={2}
          onClick={() =>
            configItemToUpdate
              ? props.updateConfigItem(configItemToUpdate)
              : undefined
          }
        >
          Save
        </Button>
      </HStack>
    </VStack>
  );
};
