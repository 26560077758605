import { Box } from "@chakra-ui/react";
import React from "react";

interface InsightWrapper2Props {
  children: React.ReactNode;
}
export const InsightWrapper2 = (props: InsightWrapper2Props) => {
  const { children } = props;
  return (
    <Box
      className="insight-wrapper"
      borderRadius="lg"
      boxShadow="lg"
      p={1}
      overflow="hidden"
      w="full"
      minH="130px"
      h="full"
      bg="var(--bg)"
      border="1px solid var(--black)"
      userSelect={"none"}
    >
      {children}
    </Box>
  );
};
