import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

export default function NoFeatureFlagAPIKeys() {
  return (
    <Box width="full" maxW="100vw" shadow="base" p={5} rounded="lg">
      <Heading as="h3" size="lg" color="var(--white)" mb={5}>
        API Keys
      </Heading>
      <Text>
        API Keys are a premium feature that allows you to embed your dashboard
        into 3rd party websites. Please contact support to arrange access.
      </Text>
    </Box>
  );
}
