import moment from "moment";
import { TimeFrame, TimeFrameEnum } from "../types";

export const resolveNamedTimeFrame = (
  namedTimeFrame: TimeFrameEnum
): TimeFrame => {
  // 1-jan-2000
  let start = new Date(2000, 0, 1);
  // today
  let end = moment().utc().endOf("day").toDate();

  switch (namedTimeFrame) {
    case TimeFrameEnum.WTD:
      start = moment()
        .utc()
        .startOf("week")
        .add(1, "day")
        .startOf("day")
        .toDate();
      break;
    case TimeFrameEnum.MTD:
      start = moment().utc().startOf("month").toDate();
      break;
    case TimeFrameEnum.QTD:
      start = moment().utc().startOf("quarter").toDate();
      break;
    case TimeFrameEnum.YTD:
      start = moment().utc().startOf("year").toDate();
      break;
    case TimeFrameEnum.LAST_WEEK:
      start = moment()
        .utc()
        .startOf("week")
        .add(-6, "days")
        .startOf("day")
        .toDate();
      end = moment().utc().startOf("week").endOf("day").toDate();
      break;
    case TimeFrameEnum.LAST_MONTH:
      start = moment()
        .utc()
        .startOf("month")
        .add(-1, "month")
        .startOf("day")
        .toDate();
      end = moment()
        .utc()
        .startOf("month")
        .add(-1, "day")
        .endOf("day")
        .toDate();
      break;
    case TimeFrameEnum.LAST_QUARTER:
      start = moment()
        .utc()
        .startOf("quarter")
        .add(-1, "quarter")
        .startOf("day")
        .toDate();
      end = moment()
        .utc()
        .startOf("quarter")
        .add(-1, "day")
        .endOf("day")
        .toDate();
      break;
    case TimeFrameEnum.LAST_YEAR:
      start = moment()
        .utc()
        .startOf("year")
        .add(-1, "year")
        .startOf("day")
        .toDate();
      end = moment().utc().startOf("year").add(-1, "day").endOf("day").toDate();
      break;

    case TimeFrameEnum.UP_TO_YESTERDAY:
      end = moment().utc().add(-1, "day").endOf("day").toDate();
      break;
    case TimeFrameEnum.UP_TO_LAST_MONTH:
      end = moment()
        .utc()
        .startOf("month")
        .add(-1, "day")
        .endOf("day")
        .toDate();
      break;
    case TimeFrameEnum.UP_TO_LAST_QUARTER:
      end = moment()
        .utc()
        .startOf("quarter")
        .add(-1, "day")
        .endOf("day")
        .toDate();
      break;
    case TimeFrameEnum.UP_TO_LAST_YEAR:
      end = moment().utc().startOf("year").add(-1, "day").endOf("day").toDate();
      break;

    case TimeFrameEnum.UP_TO_TODAY:
      // default
      break;
  }

  return {
    timeFrame: namedTimeFrame,
    startDate: start.toISOString(),
    endDate: end.toISOString(),
  };
};
