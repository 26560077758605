import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

const DateHeader = ({ label, drilldownView, onDrillDown, date }) => {
  if (!drilldownView) {
    return <span>{label}</span>;
  }
  if (date === false) {
    return (
      <span
        style={{
          marginTop: "12px",
        }}
      >
        Weekly P&L
      </span>
    );
  }
  if (moment(date).format("DD MMM YYYY") === moment().format("DD MMM YYYY")) {
    return (
      <button
        type="button"
        className="rbc-button-link today-circle"
        role="cell"
      >
        {label}
      </button>
    );
  } else {
    return (
      <button
        type="button"
        className="rbc-button-link"
        // onClick={onDrillDown}
        role="cell"
      >
        {label}
      </button>
    );
  }
};

DateHeader.propTypes = {
  label: PropTypes.node,
  date: PropTypes.instanceOf(Date),
  drilldownView: PropTypes.string,
  onDrillDown: PropTypes.func,
  isOffRange: PropTypes.bool,
};

export default DateHeader;
