import React from "react";
import { HStack, Box, Heading, DrawerHeader } from "@chakra-ui/react";
import { SecondaryButton } from "../../../../components/design_library";
import { InsightType } from "../../../../types";

interface InsightsManagerHeaderProps {
  isUserAdmin: boolean;
  controller: any;
}

const InsightsManagerHeader: React.FC<InsightsManagerHeaderProps> = ({
  isUserAdmin,
  controller,
}) => {
  const handlePageChange = (page: "insights" | "creator") => {
    controller.setPage(page);
  };

  return (
    <HStack justifyContent="space-between" w="full">
      <DrawerHeader flex="3" pl="4">
        <Heading fontSize="xxl">Financial Insights</Heading>
      </DrawerHeader>

      <Box h="20px" />
      {isUserAdmin && (
        <Box>
          {controller.page === "insights" ? (
            <SecondaryButton
              size="xs"
              onClick={() => {
                controller.setInsightData({
                  title: "",
                  type: InsightType.Number,
                  query: "",
                  category: "",
                  description: "",
                  tags: [],
                  isPublic: false,
                  version: 1,
                });
                handlePageChange("creator");
              }}
              color="var(--red)"
              pos="absolute"
              bottom="10px"
              left="60px"
              opacity="0.8"
              zIndex="999999"
            >
              Manage insights
            </SecondaryButton>
          ) : (
            <SecondaryButton onClick={() => handlePageChange("insights")}>
              Return to insights manager
            </SecondaryButton>
          )}
        </Box>
      )}
    </HStack>
  );
};

export default InsightsManagerHeader;
