import React from "react";
import {
  Flex,
  InputGroup,
  Input,
  InputLeftAddon,
  Text,
  FormErrorMessage,
  FormControl,
  Heading,
  OrderedList,
  ListItem,
  Box,
  Image,
  HStack,
  Spacer,
  Button,
  Link,
} from "@chakra-ui/react";
import {
  IBKRAutoSyncControllerProps,
  useIBKRAutoSyncController,
} from "./useIBKRAutoSyncController";
import { PrimaryButton } from "../../../../components/design_library";
import { Show } from "../../../../components";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export const IBKR_AutoSync = (props: IBKRAutoSyncControllerProps) => {
  const controller = useIBKRAutoSyncController(props);
  return (
    <Flex direction="column" w="full">
      <FormControl isInvalid={!controller.validToken}>
        <InputGroup size="sm" my={2}>
          <InputLeftAddon w="225px">Token</InputLeftAddon>
          <Show if={controller.readOnly === true}>
            <Text size="sm">{controller.config.token}</Text>
          </Show>
          <Show if={!controller.readOnly}>
            <Input
              size="sm"
              type="text"
              value={controller.config.token}
              onChange={(e) =>
                controller.setConfig({
                  ...controller.config,
                  token: e.target.value,
                })
              }
            />
          </Show>
        </InputGroup>
        <FormErrorMessage>Token is required.</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!controller.validQueryId}>
        <InputGroup size="sm" my={2}>
          <InputLeftAddon w="225px">Trades & Cashflows Query Id</InputLeftAddon>
          <Show if={controller.readOnly === true}>
            <Text size="sm">{controller.config.tradesAndCashflowsQueryId}</Text>
          </Show>
          <Show if={!controller.readOnly}>
            <Input
              size="sm"
              type="text"
              value={controller.config.tradesAndCashflowsQueryId}
              onChange={(e) =>
                controller.setConfig({
                  ...controller.config,
                  tradesAndCashflowsQueryId: e.target.value,
                })
              }
            />
          </Show>
        </InputGroup>
        <FormErrorMessage>At least 1 Query Id is required.</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!controller.validQueryId}>
        <InputGroup size="sm" my={2}>
          <InputLeftAddon w="225px">
            Trade Confirmations Query Id
          </InputLeftAddon>
          <Show if={controller.readOnly === true}>
            <Text size="sm">{controller.config.tradeConfirmationsQueryId}</Text>
          </Show>
          <Show if={!controller.readOnly}>
            <Input
              size="sm"
              type="text"
              value={controller.config.tradeConfirmationsQueryId}
              onChange={(e) =>
                controller.setConfig({
                  ...controller.config,
                  tradeConfirmationsQueryId: e.target.value,
                })
              }
            />
          </Show>
        </InputGroup>
        <FormErrorMessage>At least 1 Query Id is required.</FormErrorMessage>
      </FormControl>

      <HStack mb={1}>
        <Spacer />
        <Button
          size="sm"
          colorScheme="green"
          disabled={!controller.validToken || !controller.validQueryId}
          onClick={() => {
            if (controller.validToken && controller.validQueryId) {
              controller.testConfig();
            }
          }}
          ml={2}
        >
          Test
        </Button>

        <Show if={!controller.readOnly}>
          <Button
            size="sm"
            ml={2}
            disabled={!controller.validToken || !controller.validQueryId}
            onClick={() => {
              if (controller.validToken && controller.validQueryId) {
                controller.saveConfig();
              }
            }}
          >
            Save
          </Button>
        </Show>
        <Show if={props.close !== undefined}>
          <Button
            size="sm"
            onClick={() => {
              props.close!();
            }}
            ml={2}
          >
            Close
          </Button>
        </Show>
      </HStack>

      <Flex direction="column">
        <Heading size="md" mt={4}>
          Instructions
        </Heading>
        <Link
          href="https://wealthbee.io/knowledgebase/ibkr-import/"
          isExternal
          display="flex"
        >
          <Text color="var(--accent)">
            Click here to read the instructions for Interactive Brokers
          </Text>
          <ExternalLinkIcon ml="10px" />
        </Link>
      </Flex>
    </Flex>
  );
};
