import React, { useEffect, useRef, useState } from "react";
import { VStack, Text } from "@chakra-ui/react";
import ResizeObserver from "resize-observer-polyfill"; // Only if you need a polyfill for older browsers

import styles from "./InsightNumber2.module.css";
import { useInsightNumberController } from "./useInsightNumberController";
import { InsightStateEnum } from "../InsightStateEnum";
import { Show } from "../../../components";
import { BaseInsightProps } from "../BaseInsightProps";
import ExampleTag from "../shared/ExampleTag";
import InsightErrorRouter from "../components/InsightErrorRouter";

export const InsightNumber2 = (props: BaseInsightProps) => {
  const { insight } = props;
  const controller = useInsightNumberController(props);
  const [flashClass, setFlashClass] = useState("");
  const textRef = useRef<HTMLDivElement>(null);

  // NEW: reference to the container we'll measure
  const containerRef = useRef<HTMLDivElement>(null);

  // NEW: dynamic font size state
  const [fontSize, setFontSize] = useState(40);

  // Flash effect logic (existing code)
  useEffect(() => {
    if (controller.value > 0) {
      setFlashClass(styles.flashGreen);
    } else if (controller.value < 0) {
      setFlashClass(styles.flashRed);
    } else {
      setFlashClass("");
    }
    const timer = setTimeout(() => {
      setFlashClass("");
    }, 1000);

    return () => clearTimeout(timer);
  }, [controller.value]);

  // NEW: measure container width and calculate font size
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        // Example formula: clamp the font size so it doesn't get too large or too small
        const newFontSize = Math.max(Math.min(width / 10, 100), 5);
        setFontSize(newFontSize);
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <VStack
      // Attach the ref here
      ref={containerRef}
      spacing="0"
      h="70%"
      justifyContent="center"
      key={`insight-${insight.insightId}`}
    >
      {props.demo === true && <ExampleTag />}

      <InsightErrorRouter
        insightState={controller.insightState}
        supported={controller.supported}
      />

      <Show
        if={
          controller.supported === true &&
          controller.insightState === InsightStateEnum.Success
        }
      >
        <Text
          ref={textRef}
          className={`${styles.statNumber} ${flashClass}`}
          color={
            controller.value > 0
              ? "var(--green)"
              : controller.value < 0
              ? "var(--red)"
              : "gray.500"
          }
          // Use the dynamic font size
          fontSize={`${fontSize}px`}
        >
          {controller.prefix}
          {controller.formattedValue}
          {controller.suffix}
        </Text>
        <Text textAlign={"center"}>{insight.title}</Text>
      </Show>
    </VStack>
  );
};
