import { useAtom } from "jotai";
import { TransactionsAtom } from "../store";
import {
  autoAssignGroupsToTrades,
  createTrade,
  deleteBulkTrades,
  deleteTrade,
  getTrades,
  updateAccountOfOfManyTradeIDs,
  updateManyTrades,
} from "../api/Trades";
import { getCashFlows } from "../api/Cashflow";
import { Trade } from "../types";
import { CashFlow } from "../types/Cashflow";
import { usePositions } from "./usePositions";
import { useNotifications } from "./useNotifications";
import { Position } from "../types/Position";
import { BuySell } from "../types/Enums";
import moment from "moment";
import { useFilters } from "./useFilters";
import { filter } from "@chakra-ui/react";

export interface UseTransactions {
  getTradesAndCashflows: (account: string) => void;
  setTrades: (trades: Trade[]) => void;
  setCashflows: (cashflows: CashFlow[]) => void;
  updateBook: (trades: Trade[], book: string, accountId: string) => void;
  getBooks: (accountId: string) => void;
  getTags: (accountId: string) => void;
  getGroupNames: (accountId: string) => void;
  deleteATrade: (tradeId: string, accountId: string) => void;
  deleteManyTrades: (trades: Trade[], accountId: string) => void;
  autoAssignGroups: (trades: Trade[], accountId: string, field: string) => void;
  updateGroupNames: (
    trades: Trade[],
    groupName: string,
    accountId: string
  ) => void;
  updateBookAndGroup: (
    trades: Trade[],
    book: string,
    groupName: string,
    accountId: string
  ) => void;
  updateStrategy: (
    trades: Trade[],
    strategy: string,
    accountId: string
  ) => void;
  modifyNote: (note: string, trades: Trade[], accountId: string) => void;
  createClosingTrade: (
    position: Position,
    trade: Trade,
    accountId: string,
    closingPrice: number,
    closingFees: number,
    closingNotes: string,
    closingCommission: number,
    closingDate: Date
  ) => void;
  hideTrades: (trades: Trade[], accountId: string) => void;
  setTags: (tags: string[], trades: Trade[], accountId: string) => void;
  bulkUpdateTradeProperties: (trades: Trade[], accountId: number) => void;
  updateAccountId: (
    trades: Trade[],
    accountId: number,
    selectedAccountId: number
  ) => void;
}

export const useTransactions = (): UseTransactions => {
  const filtersHook = useFilters();
  const [transactionState, setTransactionsState] = useAtom(TransactionsAtom);
  const positions = usePositions();
  const notifications = useNotifications();

  const getTradesAndCashflows = (accountId: number) => {
    notifications.createTradeNotification(
      "Retrieving trades & cashflows...",
      ""
    );

    getTrades(accountId).then((trades) => {
      getCashFlows(accountId).then((cashflows) => {
        setTransactionsState((prevState) => {
          const tags = trades?.metadata?.tags.map((tag) => ({
            value: tag,
            label: tag,
          }));
          tags.unshift({ value: "All", label: "All" });

          filtersHook.setTags(tags);
          filtersHook.setPositionGroups(trades?.metadata?.groupNames);
          filtersHook.setBooks(trades?.metadata?.books);
          filtersHook.setBaseStrategies(trades?.metadata?.baseStrategies);
          filtersHook.setTradedStrategies(trades?.metadata?.tradedStrategies);

          return {
            ...prevState,
            trades: trades?.data,
            cashflows: cashflows?.data,
            books: trades?.metadata?.books,
            groupNames: trades?.metadata?.groupNames,
            tags: trades?.metadata?.tags,
            strategies: [
              ...trades?.metadata?.tradedStrategies,
              ...trades?.metadata?.baseStrategies,
            ],
          };
        });
        notifications.clearNotifications("trade");
      });
    });
  };

  const deleteManyTrades = (trades: Trade[], accountId: number) => {
    notifications.createTradeNotification(
      "Deleting trades...",
      "Please wait while we delete your trades"
    );
    deleteBulkTrades(trades, accountId).then((response) => {
      setTransactionsState((prevState) => {
        return {
          ...prevState,
          trades: prevState.trades.filter(
            (currentTrade) =>
              !trades.some((trade) => trade.id === currentTrade.id)
          ),
        };
      });
      notifications.clearNotifications("trade");
    });
  };

  const deleteATrade = (trade: Trade, accountId: string) => {
    notifications.createTradeNotification(
      "Deleting trade...",
      "Please wait while we delete your trade"
    );
    deleteTrade(trade.id, accountId).then((response) => {
      setTransactionsState((prevState) => {
        return {
          ...prevState,

          trades: prevState.trades.filter(
            (currentTrade) => currentTrade.id !== trade.id
          ),
        };
      });
      notifications.clearNotifications("trade");
    });
  };

  const modifyNote = (notes: string, trades: Trade[], accountId: string) => {
    notifications.createTradeNotification("Updating note...", "");

    const updatedTrades = trades.map((trade) => {
      trade.notes = notes;
      return trade;
    });

    updateManyTrades(updatedTrades, accountId).then((response) => {
      setTransactionsState((prevState) => {
        return {
          ...prevState,

          trades: prevState.trades.map((trade) => {
            if (updatedTrades.some((newTrade) => newTrade.id === trade.id)) {
              return {
                ...trade,
                notes,
              };
            }
            return trade;
          }),
        };
      });
      notifications.clearNotifications("trade");
    });
  };

  const setTags = (tags: string[], trades: Trade[], accountId: string) => {
    notifications.createTradeNotification("Updating tags...", "");

    const updatedTrades = trades.map((trade) => {
      trade.tags = tags;
      return trade;
    });

    updateManyTrades(updatedTrades, accountId).then((response) => {
      setTransactionsState((prevState) => {
        notifications.clearNotifications("trade");
        return {
          ...prevState,

          trades: prevState.trades.map((trade) => {
            if (updatedTrades.some((newTrade) => newTrade.id === trade.id)) {
              return {
                ...trade,
                tags,
              };
            }
            return trade;
          }),
        };
      });
    });
  };

  const getBooks = (accountId: string) => {
    notifications.createTradeNotification("Updating books...", "");
    getTrades(accountId).then((trades) => {
      setTransactionsState((prevState) => {
        notifications.clearNotifications("trade");
        return {
          ...prevState,

          books: trades.metadata.books,
        };
      });
    });
  };

  const getTags = (accountId: string) => {
    notifications.createTradeNotification("Loading tags...", "");

    getTrades(accountId).then((trades) => {
      setTransactionsState((prevState) => {
        notifications.clearNotifications("trade");
        return {
          ...prevState,

          tags: trades.metadata.tags,
        };
      });
    });
  };

  const getGroupNames = (accountId: string) => {
    notifications.createTradeNotification("Updating group names...", "");

    getTrades(accountId).then((trades) => {
      setTransactionsState((prevState) => {
        notifications.clearNotifications("trade");
        return {
          ...prevState,

          groupNames: trades.metadata.groupNames,
        };
      });
    });
  };

  const getStrategies = (accountId: string) => {
    notifications.createTradeNotification("Updating strategies...", "");

    getTrades(accountId).then((trades) => {
      setTransactionsState((prevState) => {
        notifications.clearNotifications("trade");
        return {
          ...prevState,

          baseStrategies: trades.metadata.baseStrategies,
          tradedStrategies: trades.metadata.tradedStrategies,
        };
      });
    });
  };

  const setTrades = (trades: Trade[]) => {
    notifications.createTradeNotification(
      "Updating data...",
      "Please wait while we load your trades"
    );

    setTransactionsState((prevState) => {
      notifications.clearNotifications("trade");

      return {
        ...prevState,

        trades: [...trades],
      };
    });
  };

  const setCashflows = (cashflows: CashFlow[]) => {
    notifications.createTradeNotification(
      "Updating data...",
      "Please wait while we load your cashflows"
    );

    setTransactionsState((prevState) => {
      notifications.clearNotifications("trade");

      return {
        ...prevState,

        cashflows: [...cashflows],
      };
    });
  };

  const updateBookAndGroup = (
    trades: Trade[],
    book: string,
    groupName: string,
    accountId: string
  ) => {
    notifications.createTradeNotification(
      "Updating data...",
      "Please wait while we update your trades"
    );

    const newTrades = trades.map((trade) => {
      return { ...trade, book, positionGroup: groupName };
    });

    updateManyTrades(newTrades, accountId).then((response) => {
      getBooks(accountId);
      getGroupNames(accountId);
      positions.fetchPositions(accountId);

      setTransactionsState((prevState) => {
        notifications.clearNotifications("trade");

        return {
          ...prevState,

          trades: prevState.trades.map((trade) => {
            if (newTrades.some((newTrade) => newTrade.id === trade.id)) {
              return {
                ...trade,
                book,
                positionGroup: groupName,
              };
            }
            return trade;
          }),
        };
      });
    });
  };

  const updateBook = (trades: Trade[], book: string, accountId: string) => {
    notifications.createTradeNotification(
      "Updating data...",
      "Please wait while we update your trades"
    );
    const newTrades = trades.map((trade) => {
      return { ...trade, book };
    });

    updateManyTrades(newTrades, accountId).then((response) => {
      getBooks(accountId);
      positions.fetchPositions(accountId);

      setTransactionsState((prevState) => {
        notifications.clearNotifications("trade");

        return {
          ...prevState,

          trades: prevState.trades.map((trade) => {
            if (newTrades.some((newTrade) => newTrade.id === trade.id)) {
              return {
                ...trade,
                book,
              };
            }
            return trade;
          }),
        };
      });
    });
  };

  const autoAssignGroups = (
    trades: Trade[],
    accountId: string,
    field: string
  ) => {
    notifications.createTradeNotification(
      "Grouping selected positions automatically...",
      ""
    );

    setTimeout(() => {
      notifications.clearNotifications("trade");
    }, 3000);

    const newTrades = trades.map((trade) => {
      return trade.id;
    });

    autoAssignGroupsToTrades(newTrades, accountId, field).then((response) => {
      if (response) {
        positions.fetchPositions(accountId);
        getTradesAndCashflows(accountId);
        setTransactionsState((prevState) => {
          return {
            ...prevState,
            trades: prevState.trades.map((trade) => {
              if (newTrades.some((newTrade) => newTrade.id === trade.id)) {
                return {
                  ...trade,
                };
              }
              return trade;
            }),
          };
        });
      }
    });
  };

  const updateGroupNames = (
    trades: Trade[],
    groupName: string,
    accountId: string
  ) => {
    notifications.createTradeNotification(
      "Updating data...",
      "Please wait while we update your trades"
    );
    const newTrades = trades.map((trade) => {
      return { ...trade, positionGroup: groupName };
    });
    updateManyTrades(newTrades, accountId).then((response) => {
      getGroupNames(accountId);
      positions.fetchPositions(accountId);

      setTransactionsState((prevState) => {
        notifications.clearNotifications("trade");

        return {
          ...prevState,

          trades: prevState.trades.map((trade) => {
            if (newTrades.some((newTrade) => newTrade.id === trade.id)) {
              return {
                ...trade,
                positionGroup: groupName,
              };
            }
            return trade;
          }),
        };
      });
    });
  };

  const updateStrategy = (
    trades: Trade[],
    strategy: string,
    accountId: string
  ) => {
    notifications.createTradeNotification(
      "Updating data...",
      "Please wait while we update your trades"
    );
    const newTrades = trades.map((trade) => {
      return { ...trade, strategy };
    });

    updateManyTrades(newTrades, accountId).then((response) => {
      getStrategies(accountId);
      positions.fetchPositions(accountId);

      setTransactionsState((prevState) => {
        notifications.clearNotifications("trade");

        return {
          ...prevState,

          trades: prevState.trades.map((trade) => {
            if (newTrades.some((newTrade) => newTrade.id === trade.id)) {
              return {
                ...trade,
                strategy,
              };
            }
            return trade;
          }),
        };
      });
    });
  };

  const createClosingTrade = (
    position: Position,
    trade: Trade,
    accountId: string,
    closingPrice: number,
    closingFees: number,
    closingNotes: string,
    closingCommission: number,
    closingDate: Date
  ) => {
    // Create a closing trade for the position

    let closingTrade = {
      ...trade,
      symbol: position.underlyingSymbol,
      tradeType: position.instrument.instrumentType,
      tradeDate: moment.utc(closingDate).toISOString(),
      exchange: position.instrument.exchange,
      commission: closingCommission,
      fees: closingFees,
      price: closingPrice,
      notes: closingNotes,
      importJSON: "",
      brokerTradeId: trade.brokerTradeId + "-manuallyClosed",
      putCall: position.instrument.putCall,
      expiryDate: position.instrument.expiry,
      strikePrice: position.instrument.strike,
      underlyingSymbol: position.underlyingSymbol,
      multiplier: position.instrument.multiplier,
    };

    if (position.quantity > 0) {
      closingTrade.buySell = BuySell.Sell;
      closingTrade.quantity = -position.quantity;
    } else {
      closingTrade.buySell = BuySell.Buy;
      closingTrade.quantity = Math.abs(position.quantity);
    }

    notifications.createTradeNotification(
      "Creating closing trade...",
      "Please wait while we create your closing trade"
    );

    createTrade(closingTrade, accountId).then((response) => {
      notifications.clearNotifications("trade");
      getTradesAndCashflows(accountId);
      positions.fetchPositions(accountId);
    });
  };

  const hideTrades = (trades: Trade[], accountId: string) => {
    notifications.createTradeNotification(
      "Hiding trades",
      "Please wait while we hide your trades and regenerate your positions..."
    );

    const hiddenTrades = trades.map((trade) => {
      return { ...trade, hidden: true };
    });

    updateManyTrades(hiddenTrades, accountId).then((response) => {
      getTradesAndCashflows(accountId);
      positions.fetchPositions(accountId);
    });
  };

  const bulkUpdateTradeProperties = async (
    trades: Trade[],
    accountId: number
  ) => {
    await updateManyTrades(trades, accountId);
    getTradesAndCashflows(accountId);
    positions.fetchPositions(accountId);
  };

  const updateAccountId = async (
    trades: Trade[],
    toAccountId: number,
    selectedAccountId: number
  ) => {
    try {
      const tradeIds = trades.map((trade) => trade.id) as number[];
      console.log(tradeIds);

      await updateAccountOfOfManyTradeIDs(
        tradeIds,
        selectedAccountId,
        toAccountId
      );

      setTransactionsState((prevState) => {
        return {
          ...prevState,
          trades: prevState.trades.filter(
            (trade) => !tradeIds.includes(trade.id)
          ),
        };
      });
    } catch (error) {
      console.error("Error updating account ID:", error);
    }
  };

  return {
    updateAccountId,
    hideTrades,
    getTradesAndCashflows,
    updateBookAndGroup,
    setTrades,
    setCashflows,
    updateBook,
    getBooks,
    getGroupNames,
    updateGroupNames,
    modifyNote,
    deleteATrade,
    getTags,
    setTags,
    createClosingTrade,
    deleteManyTrades,
    updateStrategy,
    bulkUpdateTradeProperties,
    autoAssignGroups,
  };
};
