import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Spinner,
  Text,
  Divider,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { FaSearchMinus } from "react-icons/fa";
import { useInsightSearchListController } from "./useInsightSearchListController";
import CategoryFilter from "./components/CategoryFilter";
import LoadingOrEmptyState from "./components/LoadingOrEmptyState";
import InsightsGrid from "./components/InsightsGrid";
import { InsightTemplate } from "../../../types";
import { BiTrash } from "react-icons/bi";
import { SecondaryButton } from "../../../components/design_library";

interface InsightSearchListProps {
  setPage: (page: string) => void;
  setInsightData: (insight: any) => void;
  onClose: () => void;
}

const InsightSearchList: React.FC<InsightSearchListProps> = ({
  setPage,
  setInsightData,
  onClose,
}) => {
  const {
    isInsightEnabled,
    insightsByCategory,
    categories,
    appState,
    loading,
    insights,
    searchQuery,
    setSearchQuery,
    applyChanges,
    dashboardLayoutState,
  } = useInsightSearchListController();

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [tempEnabledInsights, setTempEnabledInsights] = useState<Set<number>>(
    new Set(dashboardLayoutState.enabledInsights.map(Number))
  );

  const [displayDemoData, setDisplayDemoData] = useState(true);
  const [loadingDemoData, setLoadingDemoData] = useState(false);

  useEffect(() => {
    if (loadingState === true) {
      setTimeout(() => {
        setLoadingState(false);
        onClose();
      }, 1000);
    }
  }, [dashboardLayoutState]);

  const [loadingState, setLoadingState] = useState(false);

  const filteredEntries = selectedCategory
    ? { [selectedCategory]: insightsByCategory[selectedCategory] || [] }
    : insightsByCategory;

  const handleToggleInsight = (insight: InsightTemplate) => {
    setTempEnabledInsights((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(insight.insightId)) {
        newSet.delete(insight.insightId);
      } else {
        newSet.add(insight.insightId);
      }
      return newSet;
    });
  };

  const handleModalClose = () => {
    setLoadingState(true);
    const insightsToAdd = insights.filter(
      (insight) =>
        tempEnabledInsights.has(insight.insightId) &&
        !isInsightEnabled(insight.insightId)
    );
    const insightsToRemove = insights.filter(
      (insight) =>
        !tempEnabledInsights.has(insight.insightId) &&
        isInsightEnabled(insight.insightId)
    );
    applyChanges(insightsToAdd, insightsToRemove);
  };

  function compareSetToArray(set, array) {
    if (set.size !== array.length) {
      return false;
    }
    for (const element of array) {
      if (!set.has(element)) {
        return false;
      }
    }
    return true;
  }

  const handleClearAll = () => {
    setTempEnabledInsights(new Set());
  };

  const [view, setView] = useState("grid");

  useEffect(() => {
    setLoadingDemoData(true);
    setTimeout(() => {
      setLoadingDemoData(false);
    }, 1000);
  }, [displayDemoData]);

  return (
    <Box flex="1" fontSize="14px">
      {tempEnabledInsights !== dashboardLayoutState.enabledInsights && (
        <HStack
          px="1"
          py="1"
          pos={"fixed"}
          bottom={"20px"}
          left={"50%"}
          transform={"translateX(-50%)"}
          zIndex={99999}
          bg="var(--bg)"
          justifyContent={"center"}
          border={"1px solid var(--accent)"}
          borderRadius={"8px"}
        >
          <Button
            minW={"280px"}
            cursor={"pointer"}
            textAlign={"center"}
            bg={"var(--chart-blue)"}
            borderBottomWidth={1}
            boxShadow="md"
            p={4}
            isLoading={loadingState}
            onClick={() => {
              handleModalClose();
            }}
            isDisabled={compareSetToArray(
              tempEnabledInsights,
              dashboardLayoutState.enabledInsights
            )}
            zIndex={99999}
          >
            Save updates
          </Button>

          <Tooltip label="Clear all insights" hasArrow>
            <Button
              textAlign={"center"}
              borderBottomWidth={1}
              boxShadow="md"
              minW={"30px"}
              bg="var(--red)"
              onClick={handleClearAll}
              isDisabled={tempEnabledInsights.size === 0}
            >
              <Text mr="10px">Clear all insights</Text>
              <BiTrash />
            </Button>
          </Tooltip>
        </HStack>
      )}

      <CategoryFilter
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        setView={setView}
        view={view}
        setDisplayDemoData={setDisplayDemoData}
        displayDemoData={displayDemoData}
      />

      <Divider mt="12px" />

      <HStack px="3" alignItems="flex-start">
        <VStack flex="4" overflow="auto" w="100%" alignItems="stretch">
          <LoadingOrEmptyState loading={loading} insights={insights} />
          {loadingDemoData ? (
            <Box textAlign="center" w="100%" mt="20px">
              <Spinner size="md" />
            </Box>
          ) : (
            <InsightsGrid
              filteredEntries={filteredEntries}
              isInsightEnabled={(id) => tempEnabledInsights.has(id)}
              handleToggleInsight={handleToggleInsight}
              appState={appState}
              setInsightData={setInsightData}
              setPage={setPage}
              view={view}
              displayDemoData={displayDemoData}
            />
          )}
        </VStack>
      </HStack>
    </Box>
  );
};

export default InsightSearchList;
