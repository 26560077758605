import React from "react";
import { FormControl, FormLabel, Textarea, Text } from "@chakra-ui/react";
import { FormikProps } from "formik";
import { InsightTemplate } from "../../../../../types/Insight";

interface DescriptionFieldProps {
  formik: FormikProps<InsightTemplate>;
}

const DescriptionField: React.FC<DescriptionFieldProps> = ({ formik }) => (
  <FormControl
    isInvalid={formik.touched.description && !!formik.errors.description}
  >
    <FormLabel>Description</FormLabel>
    <Textarea
      name="description"
      value={formik.values.description}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      rows={2}
    />
    {formik.touched.description && formik.errors.description ? (
      <Text color="red.500">{formik.errors.description}</Text>
    ) : null}
  </FormControl>
);

export default DescriptionField;
