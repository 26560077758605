import React, { useEffect, useMemo } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "./GridLayout.css";
import { Spinner } from "../../../components";
import { useAtomValue } from "jotai";
import {
  DashboardLayoutStateStore,
  SelectedDashboardIdAtom,
} from "../../../store/DashboardLayout";
import { InsightContainer } from "../../../components/insights/InsightContainer";
import { useGridLayoutController } from "./useGridLayoutController";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { AccountAtom, PositionStateAtom } from "../../../store";
import { useFilters } from "../../../hooks/useFilters";
import RenderNoInsights from "../../../components/dashboard/RenderNoInsights";
import RenderLoading from "../../../components/dashboard/RenderLoading";
import { InsightItem } from "../../../types/Insight";

// const ResponsiveGridLayout = WidthProvider(Responsive);

interface GridLayoutProps {
  dashboardId?: string;
  portfolioId?: string;
  theme?: string;
  showToolbar?: boolean;
  apiKey?: string;
}

const GridLayout: React.FC<GridLayoutProps> = () => {
  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);

  const dashboardLayoutState = useAtomValue(DashboardLayoutStateStore);
  const selectedDashboardId = useAtomValue(SelectedDashboardIdAtom);
  const controller = useGridLayoutController();
  const filters = useFilters();
  const accountState = useAtomValue(AccountAtom);
  const positionsState = useAtomValue(PositionStateAtom);

  // Fetch and handle layout updates
  useEffect(() => {
    controller.fetchLayouts();
    controller.handleResize();
    window.addEventListener("resize", controller.handleResize);

    setInterval(() => {
      window.addEventListener("resize", controller.handleResize);
    }, 1000);

    setTimeout(() => {
      window.addEventListener("resize", controller.handleResize);
      setTimeout(() => {
        window.addEventListener("resize", controller.handleResize);
        setTimeout(() => {
          window.addEventListener("resize", controller.handleResize);
          setTimeout(() => {
            window.addEventListener("resize", controller.handleResize);
          }, 1000);
        }, 1000);
      }, 1000);
    }, 1000);

    return () => {
      window.removeEventListener("resize", controller.handleResize);
    };
  }, [
    accountState?.selectedAccount?.id,
    filters.selectedUnderlying,
    filters.selectedTag,
    filters.selectedStrategy,
    filters.selectedPositionGroup,
    filters.selectedBook,
    positionsState.positions,
    selectedDashboardId,
  ]);

  // Render grid layout
  const renderGridLayout = () => (
    <Box minH="100%">
      {controller.loading && (
        <Flex
          pos="fixed"
          w="100vw"
          h="calc(100vh - 120px)"
          justifyContent={"center"}
          alignItems={"center"}
          zIndex={1}
        >
          <VStack
            borderRadius={"md"}
            justifyContent={"center"}
            alignItems={"center"}
            bg="var(--bg)"
            border={"1px solid var(--accent)"}
            flexDir={"row"}
            p="4"
          >
            <Spinner size="lg" />
            <Text>Updating dashboard...</Text>
          </VStack>
        </Flex>
      )}
      <ResponsiveGridLayout
        useCSSTransforms={false}
        className="layout"
        style={{ width: "100%", padding: "5px", margin: "5px" }}
        layouts={dashboardLayoutState.layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        autoSize
        margin={[10, 10]}
        containerPadding={[0, 0]}
        allowOverlap={false}
        onBreakpointChange={controller.setBreakpoint}
        onDragStop={controller.updateLayoutComp}
        onResizeStop={controller.updateLayoutComp}
        compactType="vertical"
        draggableHandle=".drag-handle"
        transformScale={1.1}
      >
        {dashboardLayoutState?.layouts &&
          dashboardLayoutState?.layouts[
            dashboardLayoutState?.currentBreakpoint || "md"
          ].map((item: InsightItem) =>
            item.data ? (
              <div key={item.i}>
                <InsightContainer insight={item.data} />
              </div>
            ) : null
          )}
      </ResponsiveGridLayout>
    </Box>
  );

  // Main render logic based on conditions
  if (controller.loading) return RenderLoading();
  // if (positionsState.positions.length === 0) return RenderNoPositions();
  if (dashboardLayoutState?.enabledInsights?.length === 0)
    return RenderNoInsights();
  return renderGridLayout();
};

export default GridLayout;
