// SidebarContent.js
import React from "react";
import { Flex, Box, Stack, Tooltip } from "@chakra-ui/react";
import { NavGroup } from "./NavGroup";
import { NavItem } from "./NavItem";
import AccountSelector from "../../../components/AccountSelector/AccountSelector";
import { ToggleColorMode } from "./ToggleColorMode";

export const SidebarContent = ({
  isCollapsed,
  toggleSidebar,
  navigate,
  navigationGroups,
}: {
  isCollapsed: boolean;
  toggleSidebar: () => void;
  navigate: (path: string) => void;
  navigationGroups: any;
}) => {
  return (
    <Flex
      h="full"
      direction="column"
      px={isCollapsed ? "2" : "4"}
      justifyContent="center"
      bg="var(--bg)"
      backdropFilter="blur(7.5px)"
      borderRight="1px solid rgba(255, 255, 255, 0.18)"
    >
      <Stack
        spacing="4"
        flex="1"
        overflow="hidden"
        pt="2"
        alignItems={isCollapsed ? "center" : "flex-start"}
        w="full"
        h="full"
      >
        <Box
          display={{ base: "flex", md: "flex" }}
          alignSelf="flex-start"
          maxW={isCollapsed ? "62px" : "100%"}
          overflow="visible"
          transform={isCollapsed ? "translateX(-4px)" : "0"}
        >
          <AccountSelector />
        </Box>

        {navigationGroups.map((group, groupIndex) => (
          <NavGroup key={groupIndex} label={isCollapsed ? "" : group.label}>
            {group.items.map((item, itemIndex) => {
              const active = window.location.pathname === item.path;
              const handleClick = () => {
                if (item.external && item.url) {
                  window.open(item.url, "_blank");
                } else if (item.path) {
                  navigate(item.path);
                }
              };
              return (
                <NavItem
                  key={itemIndex}
                  active={active}
                  icon={<item.icon />}
                  isExternal={item.external}
                  label={isCollapsed ? "" : item.label}
                  onClick={handleClick}
                />
              );
            })}
          </NavGroup>
        ))}

        <NavGroup label={isCollapsed ? "" : ""}>
          <ToggleColorMode />
        </NavGroup>
      </Stack>

      <Tooltip
        label={isCollapsed ? "Expand" : "Collapse"}
        placement="right"
        hasArrow
      >
        <Flex
          h="full"
          position="absolute"
          right="0px"
          w="4px"
          display="flex"
          alignItems="center"
          onClick={toggleSidebar}
          zIndex={99999}
          cursor="pointer"
          borderRight="1px solid var(--gray)"
          borderLeft="1px solid var(--gray)"
        />
      </Tooltip>
    </Flex>
  );
};
