import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { LicenseManager } from "ag-grid-enterprise";
import { GridContainer, columnTypes, distinctValue } from "../../components";
import { AgGridReact } from "ag-grid-react";
import { useCashflowController } from "./useCashflowController";
import React, { useRef } from "react";
import { columnDefinitions } from "./grid/columnDefinitions";
import { useGridState } from "../../components/grid/useGridState";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { KnowledgeBaseHelperTopics, ToolbarFeatures } from "../../types";
import { FaRegLightbulb } from "react-icons/fa";
import Uploads from "../uploads/Uploads";
import { RiCashFill } from "react-icons/ri";
import SidebarToggle from "../../components/toolbar/SidebarToggle";

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-042988 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Option Tracker Ltd )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Option Tracker )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Option Tracker )_need_to_be_licensed___( Option Tracker )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 26 June 2024 )____[v2]_MTcxOTM1NjQwMDAwMA==da26b5a51856f363e61b37e5775314c6"
);

const Cashflows = () => {
  const controller = useCashflowController();
  const scrollHoldRef = useRef(null);
  const gridState = useGridState({ gridName: "Cashflows" });

  return (
    <Flex h="full" direction="column" w="full">
      <Toolbar
        enabledOptions={[ToolbarFeatures.TITLE, ToolbarFeatures.DESCRIPTION]}
        title={"Cashflows"}
        icon={<RiCashFill />}
        description="Cashflows are dividends, fees, and other cash transactions that affect your portfolio."
        helpKey={KnowledgeBaseHelperTopics.CASHFLOWS}
      />
      <Button ref={scrollHoldRef} h="0"></Button>

      <Flex flex={1} px={2}>
        {controller.cashflows.length === 0 ? (
          <Center h="70%" w="100%">
            <VStack>
              <FaRegLightbulb size="50" mb="10px" />
              <Heading size="md">No Cashflows Found</Heading>
              <Text maxW="300px" textAlign={"center"} mb="20px">
                Please add your trades to see cashflows.
              </Text>
              <Uploads />
            </VStack>
          </Center>
        ) : (
          <GridContainer>
            <AgGridReact
              ref={controller.gridApiRef}
              suppressAggFuncInHeader={true}
              columnDefs={columnDefinitions} // Column Defs for Columns
              rowData={controller.cashflows} // Row Data for Rows
              columnTypes={columnTypes}
              rowHeight={31}
              animateRows={false}
              groupDefaultExpanded={-1}
              getRowId={(data: any) => {
                return data.data.id;
              }}
              onRowGroupOpened={(params) => {
                gridState.performGroupChanged(params);
              }}
              onGridReady={gridState.onGridReady}
              onFirstDataRendered={gridState.onFirstDataRendered}
              onColumnEverythingChanged={gridState.onColumnChanged}
              onColumnResized={gridState.onColumnResized}
              onSortChanged={gridState.onColumnChanged}
              showOpenedGroup={true}
              suppressAggFilteredOnly={false}
              suppressChangeDetection={false}
              onColumnMoved={gridState.onColumnMoved}
              reactiveCustomComponents={true}
            />
          </GridContainer>
        )}
      </Flex>
    </Flex>
  );
};

export default Cashflows;
