import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  IconButton,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { SidebarStateAtom } from "../../../store";
import React from "react";
export const ToggleColorMode = (props: any) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const isCollapsed = useAtomValue(SidebarStateAtom);

  return (
    <HStack spacing={4}>
      <Button
        w="full"
        onClick={toggleColorMode}
        bg="transparent"
        justifyContent={"flex-start"}
        p={0}
        pl={isCollapsed ? "18px" : "16px"}
      >
        {colorMode === "light" ? (
          <SunIcon color={"var(--non-focus-text)"} />
        ) : (
          <MoonIcon color={"var(--non-focus-text)"} />
        )}

        {isCollapsed ? null : (
          <Text
            pl="2"
            fontSize="sm"
            color="var(--non-focus-text)"
            fontWeight={400}
          >
            {colorMode === "light" ? "Light mode" : "Dark mode"}
          </Text>
        )}
      </Button>
    </HStack>
  );
};
