import React, { useEffect } from "react";
import { Position, TradeEx } from "../../types";
import { Show } from "../../components";
import { Center, Flex } from "@chakra-ui/react";
import { usePositionAuditController } from "./usePositionAuditController";
import { columnTypes, distinctValue, GridContainer } from "../../components";
import { AgGridReact } from "ag-grid-react";
import { columnDefinitions } from "./ColumnDefinitions";

interface PositionAuditProps {
  positions: Position[];
  trades?: TradeEx[];
}

export const PositionAudit = (props: PositionAuditProps) => {
  const { positions, trades } = props;

  const controller = usePositionAuditController(positions, trades);

  useEffect(() => {
    console.log(controller.data);
  }, [controller.data]);
  return (
    <Flex w="full" h="full">
      <Show
        if={
          controller.underlyingName === undefined ||
          controller.positionGroup === undefined
        }
      >
        <Flex w="full" justifyContent={"center"}>
          <Center w="full" mt="-50px" maxW="400px" textAlign={"center"}>
            Audit is unavailable for the selection, You can select multiple
            positions but to show the Audit they need to be from the same
            Underlying Symbol and Position Group
          </Center>
        </Flex>
      </Show>
      <Show
        if={
          controller.underlyingName !== undefined &&
          controller.positionGroup !== undefined
        }
      >
        <GridContainer
          customClass={"gridContainerTradesViewHook"}
          style={{ height: "calc(100% - 45px)" }}
        >
          <AgGridReact
            autoGroupColumnDef={{
              cellRendererParams: {
                suppressCount: true,
              },
              width: 350,
              sortable: true,
              resizable: true,
              headerName: "Position",
            }}
            rowHeight={31}
            rowGroupPanelShow="never"
            groupDefaultExpanded={-1}
            reactiveCustomComponents={true}
            rowData={controller.data} // Row Data for Rows
            columnDefs={columnDefinitions} // Column Defs for Columns
            columnTypes={columnTypes} // Optional - custom column types
            suppressAggFilteredOnly={false}
            rowDragManaged={false}
            suppressAggFuncInHeader={true}
            suppressChangeDetection={false}
            aggFuncs={{
              distinct: distinctValue,
            }}
            sideBar={{
              toolPanels: [
                {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                  toolPanelParams: {
                    suppressPivotMode: true,
                    suppressValues: true,
                    suppressRowGroups: true,
                  },
                },
              ],
              position: "left",
              defaultToolPanel: "filters",
            }}
          />
        </GridContainer>
      </Show>
    </Flex>
  );
};
