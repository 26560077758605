export const CUMMULATIVE_PNL = {
  data: [
    {
      domain: "2017-08-09",
      realisedPnl: 0,
    },
    {
      domain: "2017-08-10",
      realisedPnl: 0,
    },
    {
      domain: "2017-08-14",
      realisedPnl: 113.66,
    },
    {
      domain: "2017-08-16",
      realisedPnl: 113.66,
    },
    {
      domain: "2017-08-18",
      realisedPnl: 113.66,
    },
    {
      domain: "2017-08-22",
      realisedPnl: 113.66,
    },
    {
      domain: "2017-08-23",
      realisedPnl: 113.66,
    },
    {
      domain: "2017-08-24",
      realisedPnl: -142.33,
    },
    {
      domain: "2017-08-25",
      realisedPnl: -142.33,
    },
    {
      domain: "2017-08-28",
      realisedPnl: -142.33,
    },
    {
      domain: "2017-08-30",
      realisedPnl: -142.33,
    },
    {
      domain: "2017-08-31",
      realisedPnl: -1162.31,
    },
    {
      domain: "2017-09-06",
      realisedPnl: -1162.31,
    },
    {
      domain: "2017-09-11",
      realisedPnl: -1162.31,
    },
    {
      domain: "2017-09-12",
      realisedPnl: -1262.33,
    },
    {
      domain: "2017-09-13",
      realisedPnl: -1493.01,
    },
    {
      domain: "2017-09-18",
      realisedPnl: -2340.07,
    },
    {
      domain: "2017-09-19",
      realisedPnl: -2340.07,
    },
    {
      domain: "2017-09-20",
      realisedPnl: -2971.1,
    },
    {
      domain: "2017-09-21",
      realisedPnl: -2971.1,
    },
    {
      domain: "2017-09-22",
      realisedPnl: -2971.1,
    },
    {
      domain: "2017-09-25",
      realisedPnl: -2971.1,
    },
    {
      domain: "2017-09-29",
      realisedPnl: -2971.1,
    },
    {
      domain: "2017-10-03",
      realisedPnl: -2971.1,
    },
    {
      domain: "2017-10-05",
      realisedPnl: -2982.88,
    },
    {
      domain: "2017-10-06",
      realisedPnl: -2982.88,
    },
    {
      domain: "2017-10-09",
      realisedPnl: -2982.88,
    },
    {
      domain: "2017-10-13",
      realisedPnl: -2984.41,
    },
    {
      domain: "2017-10-16",
      realisedPnl: -2839.33,
    },
    {
      domain: "2017-10-17",
      realisedPnl: -3077.25,
    },
    {
      domain: "2017-10-18",
      realisedPnl: -3077.25,
    },
    {
      domain: "2017-10-23",
      realisedPnl: -3077.25,
    },
    {
      domain: "2017-10-24",
      realisedPnl: -3077.25,
    },
    {
      domain: "2017-10-25",
      realisedPnl: -3077.25,
    },
    {
      domain: "2017-10-26",
      realisedPnl: -3077.25,
    },
    {
      domain: "2017-10-27",
      realisedPnl: -3680.35,
    },
    {
      domain: "2017-10-30",
      realisedPnl: -3680.35,
    },
    {
      domain: "2017-10-31",
      realisedPnl: -3300.06,
    },
    {
      domain: "2017-11-01",
      realisedPnl: -3300.06,
    },
    {
      domain: "2017-11-02",
      realisedPnl: -3994.13,
    },
    {
      domain: "2017-11-03",
      realisedPnl: -3994.13,
    },
    {
      domain: "2017-11-06",
      realisedPnl: -4592.43,
    },
    {
      domain: "2017-11-08",
      realisedPnl: -4152.94,
    },
    {
      domain: "2017-11-15",
      realisedPnl: -4013.05,
    },
    {
      domain: "2017-11-16",
      realisedPnl: -4013.05,
    },
    {
      domain: "2017-11-17",
      realisedPnl: -4013.05,
    },
    {
      domain: "2017-11-20",
      realisedPnl: -4020.86,
    },
    {
      domain: "2017-11-21",
      realisedPnl: 2691.41,
    },
    {
      domain: "2017-11-22",
      realisedPnl: 2691.41,
    },
    {
      domain: "2017-11-27",
      realisedPnl: 2691.41,
    },
    {
      domain: "2017-11-28",
      realisedPnl: 5717.23,
    },
    {
      domain: "2017-11-29",
      realisedPnl: 8448.13,
    },
    {
      domain: "2017-12-01",
      realisedPnl: 10210.18,
    },
    {
      domain: "2017-12-04",
      realisedPnl: 9507.2,
    },
    {
      domain: "2017-12-07",
      realisedPnl: 9518.12,
    },
    {
      domain: "2017-12-08",
      realisedPnl: 9518.12,
    },
    {
      domain: "2017-12-11",
      realisedPnl: 8330.11,
    },
    {
      domain: "2017-12-12",
      realisedPnl: 7089.19,
    },
    {
      domain: "2017-12-13",
      realisedPnl: 7089.19,
    },
    {
      domain: "2017-12-18",
      realisedPnl: 7089.19,
    },
    {
      domain: "2017-12-21",
      realisedPnl: 9793.27,
    },
    {
      domain: "2017-12-22",
      realisedPnl: 9780.29,
    },
    {
      domain: "2017-12-26",
      realisedPnl: 9780.29,
    },
    {
      domain: "2017-12-27",
      realisedPnl: 10709.37,
    },
    {
      domain: "2017-12-28",
      realisedPnl: 10709.37,
    },
    {
      domain: "2018-01-02",
      realisedPnl: 10709.37,
    },
    {
      domain: "2018-01-03",
      realisedPnl: 10709.37,
    },
    {
      domain: "2018-01-04",
      realisedPnl: 11762.48,
    },
    {
      domain: "2018-01-05",
      realisedPnl: 10290.08,
    },
    {
      domain: "2018-01-08",
      realisedPnl: 10290.08,
    },
    {
      domain: "2018-01-09",
      realisedPnl: 10290.08,
    },
    {
      domain: "2018-01-11",
      realisedPnl: 9050.84,
    },
    {
      domain: "2018-01-18",
      realisedPnl: 9050.84,
    },
    {
      domain: "2018-01-23",
      realisedPnl: 16250.97,
    },
    {
      domain: "2018-01-29",
      realisedPnl: 18997.36,
    },
    {
      domain: "2018-01-30",
      realisedPnl: 31043.4,
    },
    {
      domain: "2018-01-31",
      realisedPnl: 31043.55,
    },
    {
      domain: "2018-02-01",
      realisedPnl: 31491.6,
    },
    {
      domain: "2018-02-02",
      realisedPnl: 30635.63,
    },
    {
      domain: "2018-02-05",
      realisedPnl: 30635.63,
    },
    {
      domain: "2018-02-06",
      realisedPnl: 32513.45,
    },
    {
      domain: "2018-02-08",
      realisedPnl: 32573.31,
    },
    {
      domain: "2018-02-09",
      realisedPnl: 32573.31,
    },
    {
      domain: "2018-02-13",
      realisedPnl: 32573.31,
    },
    {
      domain: "2018-02-15",
      realisedPnl: 32573.31,
    },
    {
      domain: "2018-02-16",
      realisedPnl: 32573.31,
    },
    {
      domain: "2018-02-20",
      realisedPnl: 32573.31,
    },
    {
      domain: "2018-02-22",
      realisedPnl: 32003.63,
    },
    {
      domain: "2018-02-23",
      realisedPnl: 30838.71,
    },
    {
      domain: "2018-03-01",
      realisedPnl: 30838.71,
    },
    {
      domain: "2018-03-05",
      realisedPnl: 30838.71,
    },
    {
      domain: "2018-03-08",
      realisedPnl: 30838.71,
    },
    {
      domain: "2018-03-12",
      realisedPnl: 30838.71,
    },
    {
      domain: "2018-03-13",
      realisedPnl: 30838.71,
    },
    {
      domain: "2018-03-14",
      realisedPnl: 30838.71,
    },
    {
      domain: "2018-03-16",
      realisedPnl: 29597.79,
    },
    {
      domain: "2018-03-23",
      realisedPnl: 29597.79,
    },
    {
      domain: "2018-03-26",
      realisedPnl: 29605.14,
    },
    {
      domain: "2018-03-28",
      realisedPnl: 30105.85,
    },
    {
      domain: "2018-04-03",
      realisedPnl: 30105.85,
    },
    {
      domain: "2018-04-09",
      realisedPnl: 30105.85,
    },
    {
      domain: "2018-04-13",
      realisedPnl: 30105.85,
    },
    {
      domain: "2018-04-17",
      realisedPnl: 30105.85,
    },
    {
      domain: "2018-04-18",
      realisedPnl: 29530.71,
    },
    {
      domain: "2018-04-20",
      realisedPnl: 29530.71,
    },
    {
      domain: "2018-04-26",
      realisedPnl: 28508.73,
    },
    {
      domain: "2018-04-27",
      realisedPnl: 28508.73,
    },
    {
      domain: "2018-04-30",
      realisedPnl: 23388.82,
    },
    {
      domain: "2018-05-01",
      realisedPnl: 23441.74,
    },
    {
      domain: "2018-05-07",
      realisedPnl: 26058.31,
    },
    {
      domain: "2018-05-09",
      realisedPnl: 27543.18,
    },
    {
      domain: "2018-05-11",
      realisedPnl: 27017.2,
    },
    {
      domain: "2018-05-14",
      realisedPnl: 26359.23,
    },
    {
      domain: "2018-05-16",
      realisedPnl: 25375.71,
    },
    {
      domain: "2018-05-21",
      realisedPnl: 22918.12,
    },
    {
      domain: "2018-05-23",
      realisedPnl: 21715.94,
    },
    {
      domain: "2018-05-24",
      realisedPnl: 22180.49,
    },
    {
      domain: "2018-05-30",
      realisedPnl: 22180.49,
    },
    {
      domain: "2018-06-01",
      realisedPnl: 22228.51,
    },
    {
      domain: "2018-06-11",
      realisedPnl: 21905.16,
    },
    {
      domain: "2018-06-15",
      realisedPnl: 22594.17,
    },
    {
      domain: "2018-06-18",
      realisedPnl: 27028.18,
    },
    {
      domain: "2018-06-20",
      realisedPnl: 26462.27,
    },
    {
      domain: "2018-06-22",
      realisedPnl: 26105.46,
    },
    {
      domain: "2018-06-25",
      realisedPnl: 24147.43,
    },
    {
      domain: "2018-06-27",
      realisedPnl: 22768.82,
    },
    {
      domain: "2018-07-02",
      realisedPnl: 22768.82,
    },
    {
      domain: "2018-07-03",
      realisedPnl: 22768.82,
    },
    {
      domain: "2018-07-06",
      realisedPnl: 22768.82,
    },
    {
      domain: "2018-07-09",
      realisedPnl: 22768.82,
    },
    {
      domain: "2018-07-13",
      realisedPnl: 22768.82,
    },
    {
      domain: "2018-07-16",
      realisedPnl: 24818.89,
    },
    {
      domain: "2018-07-17",
      realisedPnl: 24818.89,
    },
    {
      domain: "2018-07-18",
      realisedPnl: 24818.89,
    },
    {
      domain: "2018-07-19",
      realisedPnl: 24818.89,
    },
    {
      domain: "2018-07-20",
      realisedPnl: 21132.07,
    },
    {
      domain: "2018-07-23",
      realisedPnl: 21132.07,
    },
    {
      domain: "2018-07-27",
      realisedPnl: 21550.02,
    },
    {
      domain: "2018-07-30",
      realisedPnl: 21246.29,
    },
    {
      domain: "2018-08-01",
      realisedPnl: 19153.28,
    },
    {
      domain: "2018-08-02",
      realisedPnl: 19153.28,
    },
    {
      domain: "2018-08-03",
      realisedPnl: 19153.28,
    },
    {
      domain: "2018-08-06",
      realisedPnl: 19341.87,
    },
    {
      domain: "2018-08-07",
      realisedPnl: 19341.87,
    },
    {
      domain: "2018-08-08",
      realisedPnl: 19341.87,
    },
    {
      domain: "2018-08-09",
      realisedPnl: 18807.84,
    },
    {
      domain: "2018-08-13",
      realisedPnl: 18800.14,
    },
    {
      domain: "2018-08-14",
      realisedPnl: 17688.17,
    },
    {
      domain: "2018-08-17",
      realisedPnl: 17688.17,
    },
    {
      domain: "2018-08-20",
      realisedPnl: 17688.17,
    },
    {
      domain: "2018-08-27",
      realisedPnl: 22334.09,
    },
    {
      domain: "2018-08-30",
      realisedPnl: 22028.17,
    },
    {
      domain: "2018-09-05",
      realisedPnl: 24550.2,
    },
    {
      domain: "2018-09-11",
      realisedPnl: 24550.2,
    },
    {
      domain: "2018-09-12",
      realisedPnl: 24550.2,
    },
    {
      domain: "2018-09-14",
      realisedPnl: 24875.27,
    },
    {
      domain: "2018-09-17",
      realisedPnl: 24875.27,
    },
    {
      domain: "2018-09-24",
      realisedPnl: 24717.3,
    },
    {
      domain: "2018-09-25",
      realisedPnl: 28176.43,
    },
    {
      domain: "2018-09-26",
      realisedPnl: 28176.43,
    },
    {
      domain: "2018-09-28",
      realisedPnl: 28176.43,
    },
    {
      domain: "2018-10-01",
      realisedPnl: 28176.43,
    },
    {
      domain: "2018-10-02",
      realisedPnl: 27790.46,
    },
    {
      domain: "2018-10-03",
      realisedPnl: 26985.11,
    },
    {
      domain: "2018-10-05",
      realisedPnl: 25696.41,
    },
    {
      domain: "2018-10-08",
      realisedPnl: 21093.08,
    },
    {
      domain: "2018-10-17",
      realisedPnl: 21093.08,
    },
    {
      domain: "2018-10-18",
      realisedPnl: 20705.25,
    },
    {
      domain: "2018-10-22",
      realisedPnl: 20705.25,
    },
    {
      domain: "2018-10-24",
      realisedPnl: 20705.25,
    },
    {
      domain: "2018-11-01",
      realisedPnl: 20705.25,
    },
    {
      domain: "2018-11-02",
      realisedPnl: 20293.09,
    },
    {
      domain: "2018-11-05",
      realisedPnl: 19724.18,
    },
    {
      domain: "2018-11-09",
      realisedPnl: 19724.18,
    },
    {
      domain: "2018-11-13",
      realisedPnl: 21743.3,
    },
    {
      domain: "2018-11-16",
      realisedPnl: 21743.3,
    },
    {
      domain: "2018-11-19",
      realisedPnl: 20724.33,
    },
    {
      domain: "2018-11-20",
      realisedPnl: 20524.33,
    },
    {
      domain: "2018-11-23",
      realisedPnl: 19777.35,
    },
    {
      domain: "2018-11-26",
      realisedPnl: 18233.69,
    },
    {
      domain: "2018-11-29",
      realisedPnl: 18321.73,
    },
    {
      domain: "2018-11-30",
      realisedPnl: 19343.74,
    },
    {
      domain: "2018-12-10",
      realisedPnl: 19343.74,
    },
    {
      domain: "2018-12-14",
      realisedPnl: 18921.69,
    },
    {
      domain: "2018-12-17",
      realisedPnl: 18921.69,
    },
    {
      domain: "2018-12-18",
      realisedPnl: 18921.69,
    },
    {
      domain: "2018-12-19",
      realisedPnl: 18921.69,
    },
    {
      domain: "2018-12-24",
      realisedPnl: 14339.54,
    },
    {
      domain: "2018-12-26",
      realisedPnl: 14339.54,
    },
    {
      domain: "2019-01-02",
      realisedPnl: 13230.24,
    },
    {
      domain: "2019-01-07",
      realisedPnl: 13230.24,
    },
    {
      domain: "2019-01-08",
      realisedPnl: 12348.27,
    },
    {
      domain: "2019-01-09",
      realisedPnl: 11074.87,
    },
    {
      domain: "2019-01-11",
      realisedPnl: 11243.96,
    },
    {
      domain: "2019-01-17",
      realisedPnl: 11243.96,
    },
    {
      domain: "2019-01-18",
      realisedPnl: 11243.96,
    },
    {
      domain: "2019-01-22",
      realisedPnl: 10756.41,
    },
    {
      domain: "2019-01-23",
      realisedPnl: 10217.59,
    },
    {
      domain: "2019-01-25",
      realisedPnl: 10217.59,
    },
    {
      domain: "2019-01-30",
      realisedPnl: 10765.61,
    },
    {
      domain: "2019-02-01",
      realisedPnl: 10765.61,
    },
    {
      domain: "2019-02-04",
      realisedPnl: 10765.61,
    },
    {
      domain: "2019-02-05",
      realisedPnl: 12392.67,
    },
    {
      domain: "2019-02-07",
      realisedPnl: 12484.68,
    },
    {
      domain: "2019-02-08",
      realisedPnl: 12484.68,
    },
    {
      domain: "2019-02-11",
      realisedPnl: 12484.68,
    },
    {
      domain: "2019-02-13",
      realisedPnl: 12048.69,
    },
    {
      domain: "2019-02-14",
      realisedPnl: 11470.7,
    },
    {
      domain: "2019-02-15",
      realisedPnl: 12225.7,
    },
    {
      domain: "2019-02-21",
      realisedPnl: 11372.44,
    },
    {
      domain: "2019-02-22",
      realisedPnl: 11372.44,
    },
    {
      domain: "2019-02-25",
      realisedPnl: 12306.43,
    },
    {
      domain: "2019-02-26",
      realisedPnl: 13467.5,
    },
    {
      domain: "2019-02-27",
      realisedPnl: 14073.69,
    },
    {
      domain: "2019-02-28",
      realisedPnl: 13583.55,
    },
    {
      domain: "2019-03-01",
      realisedPnl: 14991.53,
    },
    {
      domain: "2019-03-05",
      realisedPnl: 14991.53,
    },
    {
      domain: "2019-03-07",
      realisedPnl: 14991.53,
    },
    {
      domain: "2019-03-08",
      realisedPnl: 14523.3,
    },
    {
      domain: "2019-03-11",
      realisedPnl: 14137.3,
    },
    {
      domain: "2019-03-12",
      realisedPnl: 14129.04,
    },
    {
      domain: "2019-03-13",
      realisedPnl: 13350.01,
    },
    {
      domain: "2019-03-14",
      realisedPnl: 12866.1,
    },
    {
      domain: "2019-03-15",
      realisedPnl: 12866.1,
    },
    {
      domain: "2019-03-20",
      realisedPnl: 12380.19,
    },
    {
      domain: "2019-03-25",
      realisedPnl: 12380.19,
    },
    {
      domain: "2019-03-26",
      realisedPnl: 12380.19,
    },
    {
      domain: "2019-03-27",
      realisedPnl: 12380.19,
    },
    {
      domain: "2019-03-28",
      realisedPnl: 11920.08,
    },
    {
      domain: "2019-03-29",
      realisedPnl: 12399.17,
    },
    {
      domain: "2019-04-01",
      realisedPnl: 12942.08,
    },
    {
      domain: "2019-04-02",
      realisedPnl: 12942.08,
    },
    {
      domain: "2019-04-03",
      realisedPnl: 12750.08,
    },
    {
      domain: "2019-04-04",
      realisedPnl: 12953.08,
    },
    {
      domain: "2019-04-08",
      realisedPnl: 13312.67,
    },
    {
      domain: "2019-04-10",
      realisedPnl: 13312.67,
    },
    {
      domain: "2019-04-12",
      realisedPnl: 13308.68,
    },
    {
      domain: "2019-04-15",
      realisedPnl: 13308.68,
    },
    {
      domain: "2019-04-16",
      realisedPnl: 14471.86,
    },
    {
      domain: "2019-04-17",
      realisedPnl: 14640.94,
    },
    {
      domain: "2019-04-18",
      realisedPnl: 15455.02,
    },
    {
      domain: "2019-04-22",
      realisedPnl: 15455.02,
    },
    {
      domain: "2019-04-23",
      realisedPnl: 15390.12,
    },
    {
      domain: "2019-04-26",
      realisedPnl: 15390.12,
    },
    {
      domain: "2019-04-29",
      realisedPnl: 14942.01,
    },
    {
      domain: "2019-05-01",
      realisedPnl: 14942.01,
    },
    {
      domain: "2019-05-10",
      realisedPnl: 14073.02,
    },
    {
      domain: "2019-05-15",
      realisedPnl: 14073.02,
    },
    {
      domain: "2019-05-17",
      realisedPnl: 14073.02,
    },
    {
      domain: "2019-05-20",
      realisedPnl: 13838.09,
    },
    {
      domain: "2019-05-28",
      realisedPnl: 14045.04,
    },
    {
      domain: "2019-05-29",
      realisedPnl: 14045.04,
    },
    {
      domain: "2019-05-31",
      realisedPnl: 14045.04,
    },
    {
      domain: "2019-06-03",
      realisedPnl: 12390.14,
    },
    {
      domain: "2019-06-07",
      realisedPnl: 12390.14,
    },
    {
      domain: "2019-06-12",
      realisedPnl: 12639.21,
    },
    {
      domain: "2019-06-14",
      realisedPnl: 12161.73,
    },
    {
      domain: "2019-06-17",
      realisedPnl: 12667.6,
    },
    {
      domain: "2019-06-18",
      realisedPnl: 12584.7,
    },
    {
      domain: "2019-06-19",
      realisedPnl: 12427.94,
    },
    {
      domain: "2019-06-20",
      realisedPnl: 12427.94,
    },
    {
      domain: "2019-06-24",
      realisedPnl: 12626.62,
    },
    {
      domain: "2019-06-26",
      realisedPnl: 12848.47,
    },
    {
      domain: "2019-06-27",
      realisedPnl: 13756.45,
    },
    {
      domain: "2019-06-28",
      realisedPnl: 13756.45,
    },
    {
      domain: "2019-07-02",
      realisedPnl: 13756.45,
    },
    {
      domain: "2019-07-03",
      realisedPnl: 13756.45,
    },
    {
      domain: "2019-07-08",
      realisedPnl: 14159.52,
    },
    {
      domain: "2019-07-10",
      realisedPnl: 14159.52,
    },
    {
      domain: "2019-07-12",
      realisedPnl: 14562.57,
    },
    {
      domain: "2019-07-15",
      realisedPnl: 14562.57,
    },
    {
      domain: "2019-07-17",
      realisedPnl: 14510.56,
    },
    {
      domain: "2019-07-18",
      realisedPnl: 14767.6,
    },
    {
      domain: "2019-07-19",
      realisedPnl: 14743.54,
    },
    {
      domain: "2019-07-22",
      realisedPnl: 14345.54,
    },
    {
      domain: "2019-07-25",
      realisedPnl: 14345.54,
    },
    {
      domain: "2019-07-26",
      realisedPnl: 14515.5,
    },
    {
      domain: "2019-07-29",
      realisedPnl: 13343.51,
    },
    {
      domain: "2019-07-31",
      realisedPnl: 13564.66,
    },
    {
      domain: "2019-08-01",
      realisedPnl: 13564.66,
    },
    {
      domain: "2019-08-02",
      realisedPnl: 13669.44,
    },
    {
      domain: "2019-08-07",
      realisedPnl: 13669.44,
    },
    {
      domain: "2019-08-08",
      realisedPnl: 13669.44,
    },
    {
      domain: "2019-08-09",
      realisedPnl: 12678.53,
    },
    {
      domain: "2019-08-19",
      realisedPnl: 12378.89,
    },
    {
      domain: "2019-08-20",
      realisedPnl: 10046.11,
    },
    {
      domain: "2019-08-21",
      realisedPnl: 10228.15,
    },
    {
      domain: "2019-08-22",
      realisedPnl: 12356.26,
    },
    {
      domain: "2019-08-26",
      realisedPnl: 12646.13,
    },
    {
      domain: "2019-08-30",
      realisedPnl: 12646.13,
    },
    {
      domain: "2019-09-06",
      realisedPnl: 12646.13,
    },
    {
      domain: "2019-09-09",
      realisedPnl: 9381.17,
    },
    {
      domain: "2019-09-13",
      realisedPnl: 9955.05,
    },
    {
      domain: "2019-09-16",
      realisedPnl: 8114.95,
    },
    {
      domain: "2019-09-18",
      realisedPnl: 8114.95,
    },
    {
      domain: "2019-09-19",
      realisedPnl: 7106.91,
    },
    {
      domain: "2019-09-20",
      realisedPnl: 9042.51,
    },
    {
      domain: "2019-09-26",
      realisedPnl: 7360.34,
    },
    {
      domain: "2019-09-27",
      realisedPnl: 7360.34,
    },
    {
      domain: "2019-10-01",
      realisedPnl: 7499.44,
    },
    {
      domain: "2019-10-02",
      realisedPnl: 7499.44,
    },
    {
      domain: "2019-10-04",
      realisedPnl: 7499.44,
    },
    {
      domain: "2019-10-14",
      realisedPnl: 6791.43,
    },
    {
      domain: "2019-10-18",
      realisedPnl: 5684.41,
    },
    {
      domain: "2019-10-21",
      realisedPnl: 5684.41,
    },
    {
      domain: "2019-10-25",
      realisedPnl: 5192.82,
    },
    {
      domain: "2019-10-31",
      realisedPnl: 5475.4,
    },
    {
      domain: "2019-11-01",
      realisedPnl: 5475.4,
    },
    {
      domain: "2019-11-06",
      realisedPnl: 5587.4,
    },
    {
      domain: "2019-11-08",
      realisedPnl: 5133.17,
    },
    {
      domain: "2019-11-14",
      realisedPnl: 5133.17,
    },
    {
      domain: "2019-11-15",
      realisedPnl: 4425.11,
    },
    {
      domain: "2019-11-18",
      realisedPnl: 3543.24,
    },
    {
      domain: "2019-11-19",
      realisedPnl: 3543.24,
    },
    {
      domain: "2019-11-21",
      realisedPnl: 4549.65,
    },
    {
      domain: "2019-11-26",
      realisedPnl: 4549.65,
    },
    {
      domain: "2019-11-27",
      realisedPnl: 268.07,
    },
    {
      domain: "2019-12-02",
      realisedPnl: 268.07,
    },
    {
      domain: "2019-12-03",
      realisedPnl: 268.07,
    },
    {
      domain: "2019-12-06",
      realisedPnl: 638.95,
    },
    {
      domain: "2019-12-09",
      realisedPnl: 629.86,
    },
    {
      domain: "2019-12-10",
      realisedPnl: -90.46,
    },
    {
      domain: "2019-12-12",
      realisedPnl: -90.46,
    },
    {
      domain: "2019-12-13",
      realisedPnl: -1001.48,
    },
    {
      domain: "2019-12-17",
      realisedPnl: -1001.48,
    },
    {
      domain: "2019-12-18",
      realisedPnl: -1001.48,
    },
    {
      domain: "2019-12-20",
      realisedPnl: -521.21,
    },
    {
      domain: "2019-12-23",
      realisedPnl: -521.21,
    },
    {
      domain: "2019-12-27",
      realisedPnl: -521.21,
    },
    {
      domain: "2020-01-02",
      realisedPnl: -1032.44,
    },
    {
      domain: "2020-01-03",
      realisedPnl: -1032.44,
    },
    {
      domain: "2020-01-06",
      realisedPnl: -1032.44,
    },
    {
      domain: "2020-01-07",
      realisedPnl: -1032.44,
    },
    {
      domain: "2020-01-09",
      realisedPnl: -928.46,
    },
    {
      domain: "2020-01-17",
      realisedPnl: -699.49,
    },
    {
      domain: "2020-01-30",
      realisedPnl: -699.49,
    },
    {
      domain: "2020-02-03",
      realisedPnl: -629.51,
    },
    {
      domain: "2020-02-04",
      realisedPnl: -629.51,
    },
    {
      domain: "2020-02-05",
      realisedPnl: 532.84,
    },
    {
      domain: "2020-02-06",
      realisedPnl: 532.84,
    },
    {
      domain: "2020-02-07",
      realisedPnl: 3154.95,
    },
    {
      domain: "2020-02-10",
      realisedPnl: 5692.77,
    },
    {
      domain: "2020-02-12",
      realisedPnl: 5087.52,
    },
    {
      domain: "2020-02-14",
      realisedPnl: 5087.52,
    },
    {
      domain: "2020-02-21",
      realisedPnl: 6101.44,
    },
    {
      domain: "2020-02-25",
      realisedPnl: 6246.21,
    },
    {
      domain: "2020-03-02",
      realisedPnl: 6246.21,
    },
    {
      domain: "2020-03-03",
      realisedPnl: 6246.21,
    },
    {
      domain: "2020-03-04",
      realisedPnl: 6246.21,
    },
    {
      domain: "2020-03-06",
      realisedPnl: 3922.2,
    },
    {
      domain: "2020-03-13",
      realisedPnl: 618.38,
    },
    {
      domain: "2020-03-16",
      realisedPnl: -1681.54,
    },
    {
      domain: "2020-03-17",
      realisedPnl: -18312.64,
    },
    {
      domain: "2020-03-18",
      realisedPnl: -23191.42,
    },
    {
      domain: "2020-03-25",
      realisedPnl: -23191.42,
    },
    {
      domain: "2020-03-31",
      realisedPnl: -23192.2,
    },
    {
      domain: "2020-04-01",
      realisedPnl: -23192.2,
    },
    {
      domain: "2020-04-02",
      realisedPnl: -23192.2,
    },
    {
      domain: "2020-04-08",
      realisedPnl: -23349.03,
    },
    {
      domain: "2020-04-15",
      realisedPnl: -23245.06,
    },
    {
      domain: "2020-04-24",
      realisedPnl: -23245.06,
    },
    {
      domain: "2020-04-29",
      realisedPnl: -23245.06,
    },
    {
      domain: "2020-05-01",
      realisedPnl: -23245.06,
    },
    {
      domain: "2020-05-04",
      realisedPnl: -23245.06,
    },
    {
      domain: "2020-05-07",
      realisedPnl: -23965.13,
    },
    {
      domain: "2020-05-19",
      realisedPnl: -23773.24,
    },
    {
      domain: "2020-05-28",
      realisedPnl: -23773.24,
    },
    {
      domain: "2020-06-01",
      realisedPnl: -23773.24,
    },
    {
      domain: "2020-06-02",
      realisedPnl: -24868.9,
    },
    {
      domain: "2020-06-03",
      realisedPnl: -23597.68,
    },
    {
      domain: "2020-06-08",
      realisedPnl: -24733.72,
    },
    {
      domain: "2020-06-16",
      realisedPnl: -25816.23,
    },
    {
      domain: "2020-06-18",
      realisedPnl: -25816.23,
    },
    {
      domain: "2020-06-22",
      realisedPnl: -25816.23,
    },
    {
      domain: "2020-06-26",
      realisedPnl: -25816.23,
    },
    {
      domain: "2020-06-30",
      realisedPnl: -25521.48,
    },
    {
      domain: "2020-07-01",
      realisedPnl: -25521.48,
    },
    {
      domain: "2020-07-02",
      realisedPnl: -26868.03,
    },
    {
      domain: "2020-07-09",
      realisedPnl: -26868.03,
    },
    {
      domain: "2020-07-20",
      realisedPnl: -26868.03,
    },
    {
      domain: "2020-07-24",
      realisedPnl: -26868.43,
    },
    {
      domain: "2020-07-27",
      realisedPnl: -26868.43,
    },
    {
      domain: "2020-07-29",
      realisedPnl: -26868.43,
    },
    {
      domain: "2020-08-03",
      realisedPnl: -26868.43,
    },
    {
      domain: "2020-08-04",
      realisedPnl: -26868.43,
    },
    {
      domain: "2020-08-12",
      realisedPnl: -27349.45,
    },
    {
      domain: "2020-08-13",
      realisedPnl: -27349.45,
    },
    {
      domain: "2020-08-14",
      realisedPnl: -26005.51,
    },
    {
      domain: "2020-08-17",
      realisedPnl: -26005.51,
    },
    {
      domain: "2020-08-18",
      realisedPnl: -26056.52,
    },
    {
      domain: "2020-08-21",
      realisedPnl: -26056.52,
    },
    {
      domain: "2020-08-25",
      realisedPnl: -26058.42,
    },
    {
      domain: "2020-08-26",
      realisedPnl: -26058.42,
    },
    {
      domain: "2020-08-28",
      realisedPnl: -26058.42,
    },
    {
      domain: "2020-08-31",
      realisedPnl: -26058.42,
    },
    {
      domain: "2020-09-01",
      realisedPnl: -26058.42,
    },
    {
      domain: "2020-09-02",
      realisedPnl: -26403.57,
    },
    {
      domain: "2020-09-11",
      realisedPnl: -27472.72,
    },
    {
      domain: "2020-09-14",
      realisedPnl: -27717.81,
    },
    {
      domain: "2020-09-16",
      realisedPnl: -27717.81,
    },
    {
      domain: "2020-09-17",
      realisedPnl: -27717.81,
    },
    {
      domain: "2020-09-21",
      realisedPnl: -26116.1,
    },
    {
      domain: "2020-09-23",
      realisedPnl: -25360.26,
    },
    {
      domain: "2020-09-24",
      realisedPnl: -25362.25,
    },
    {
      domain: "2020-09-28",
      realisedPnl: -25585.73,
    },
    {
      domain: "2020-09-29",
      realisedPnl: -25585.73,
    },
    {
      domain: "2020-09-30",
      realisedPnl: -25585.73,
    },
    {
      domain: "2020-10-01",
      realisedPnl: -25585.73,
    },
    {
      domain: "2020-10-02",
      realisedPnl: -25585.73,
    },
    {
      domain: "2020-10-09",
      realisedPnl: -25585.73,
    },
    {
      domain: "2020-10-12",
      realisedPnl: -25585.73,
    },
    {
      domain: "2020-10-13",
      realisedPnl: -24496.79,
    },
    {
      domain: "2020-10-16",
      realisedPnl: -24496.79,
    },
    {
      domain: "2020-10-20",
      realisedPnl: -25035.47,
    },
    {
      domain: "2020-10-21",
      realisedPnl: -24996.49,
    },
    {
      domain: "2020-10-22",
      realisedPnl: -24509.22,
    },
    {
      domain: "2020-10-23",
      realisedPnl: -25318.11,
    },
    {
      domain: "2020-10-26",
      realisedPnl: -25318.11,
    },
    {
      domain: "2020-10-27",
      realisedPnl: -26490,
    },
    {
      domain: "2020-10-28",
      realisedPnl: -33022.26,
    },
    {
      domain: "2020-10-29",
      realisedPnl: -33736.52,
    },
    {
      domain: "2020-10-30",
      realisedPnl: -32033.38,
    },
    {
      domain: "2020-11-02",
      realisedPnl: -32033.38,
    },
    {
      domain: "2020-11-03",
      realisedPnl: -32033.38,
    },
    {
      domain: "2020-11-09",
      realisedPnl: -34679.48,
    },
    {
      domain: "2020-11-10",
      realisedPnl: -34679.48,
    },
    {
      domain: "2020-11-13",
      realisedPnl: -34679.48,
    },
    {
      domain: "2020-11-17",
      realisedPnl: -34679.48,
    },
    {
      domain: "2020-11-19",
      realisedPnl: -34980.5,
    },
    {
      domain: "2020-11-23",
      realisedPnl: -35558.62,
    },
    {
      domain: "2020-11-24",
      realisedPnl: -30245.5,
    },
    {
      domain: "2020-11-25",
      realisedPnl: -30245.5,
    },
    {
      domain: "2020-11-27",
      realisedPnl: -30809.66,
    },
    {
      domain: "2020-11-30",
      realisedPnl: -30806.68,
    },
    {
      domain: "2020-12-01",
      realisedPnl: -30806.68,
    },
    {
      domain: "2020-12-02",
      realisedPnl: -30806.68,
    },
    {
      domain: "2020-12-03",
      realisedPnl: -30806.68,
    },
    {
      domain: "2020-12-04",
      realisedPnl: -30806.68,
    },
    {
      domain: "2020-12-07",
      realisedPnl: -29579.75,
    },
    {
      domain: "2020-12-08",
      realisedPnl: -29579.75,
    },
    {
      domain: "2020-12-10",
      realisedPnl: -29579.75,
    },
    {
      domain: "2020-12-16",
      realisedPnl: -29585.35,
    },
    {
      domain: "2020-12-18",
      realisedPnl: -29257.35,
    },
    {
      domain: "2020-12-21",
      realisedPnl: -29263.35,
    },
    {
      domain: "2020-12-22",
      realisedPnl: -29416.82,
    },
    {
      domain: "2020-12-23",
      realisedPnl: -29416.82,
    },
    {
      domain: "2020-12-28",
      realisedPnl: -27642,
    },
    {
      domain: "2020-12-30",
      realisedPnl: -27352.11,
    },
    {
      domain: "2020-12-31",
      realisedPnl: -27352.11,
    },
    {
      domain: "2021-01-04",
      realisedPnl: -27321.63,
    },
    {
      domain: "2021-01-05",
      realisedPnl: -27321.63,
    },
    {
      domain: "2021-01-06",
      realisedPnl: -28090.65,
    },
    {
      domain: "2021-01-07",
      realisedPnl: -27849.66,
    },
    {
      domain: "2021-01-08",
      realisedPnl: -27849.66,
    },
    {
      domain: "2021-01-11",
      realisedPnl: -27807.7,
    },
    {
      domain: "2021-01-12",
      realisedPnl: -28728.09,
    },
    {
      domain: "2021-01-13",
      realisedPnl: -26149.09,
    },
    {
      domain: "2021-01-14",
      realisedPnl: -26149.09,
    },
    {
      domain: "2021-01-15",
      realisedPnl: -21150.93,
    },
    {
      domain: "2021-01-19",
      realisedPnl: -22484.03,
    },
    {
      domain: "2021-01-20",
      realisedPnl: -23395.92,
    },
    {
      domain: "2021-01-21",
      realisedPnl: -23395.92,
    },
    {
      domain: "2021-01-22",
      realisedPnl: -23395.92,
    },
    {
      domain: "2021-01-25",
      realisedPnl: -22420.06,
    },
    {
      domain: "2021-01-26",
      realisedPnl: -21321.3,
    },
    {
      domain: "2021-01-27",
      realisedPnl: -21321.3,
    },
    {
      domain: "2021-01-28",
      realisedPnl: -21321.3,
    },
    {
      domain: "2021-01-29",
      realisedPnl: -21793.35,
    },
    {
      domain: "2021-02-01",
      realisedPnl: -21793.35,
    },
    {
      domain: "2021-02-02",
      realisedPnl: -21793.35,
    },
    {
      domain: "2021-02-03",
      realisedPnl: -21398.36,
    },
    {
      domain: "2021-02-04",
      realisedPnl: -19758.95,
    },
    {
      domain: "2021-02-05",
      realisedPnl: -19758.95,
    },
    {
      domain: "2021-02-08",
      realisedPnl: -19263.08,
    },
    {
      domain: "2021-02-09",
      realisedPnl: -18993.1,
    },
    {
      domain: "2021-02-10",
      realisedPnl: -18993.1,
    },
    {
      domain: "2021-02-11",
      realisedPnl: -18628.21,
    },
    {
      domain: "2021-02-12",
      realisedPnl: -18628.21,
    },
    {
      domain: "2021-02-16",
      realisedPnl: -18155.22,
    },
    {
      domain: "2021-02-17",
      realisedPnl: -18160.24,
    },
    {
      domain: "2021-02-18",
      realisedPnl: -18160.24,
    },
    {
      domain: "2021-02-19",
      realisedPnl: -16539.35,
    },
    {
      domain: "2021-02-22",
      realisedPnl: -12000.56,
    },
    {
      domain: "2021-02-23",
      realisedPnl: -6683.43,
    },
    {
      domain: "2021-02-24",
      realisedPnl: -6683.43,
    },
    {
      domain: "2021-02-25",
      realisedPnl: -7067.45,
    },
    {
      domain: "2021-02-26",
      realisedPnl: -8547.75,
    },
    {
      domain: "2021-03-01",
      realisedPnl: -8034.87,
    },
    {
      domain: "2021-03-02",
      realisedPnl: -7790.88,
    },
    {
      domain: "2021-03-03",
      realisedPnl: -7790.88,
    },
    {
      domain: "2021-03-04",
      realisedPnl: -6021.27,
    },
    {
      domain: "2021-03-05",
      realisedPnl: -5655.32,
    },
    {
      domain: "2021-03-08",
      realisedPnl: -5001.34,
    },
    {
      domain: "2021-03-09",
      realisedPnl: -5001.34,
    },
    {
      domain: "2021-03-10",
      realisedPnl: -4853.4,
    },
    {
      domain: "2021-03-12",
      realisedPnl: -4853.4,
    },
    {
      domain: "2021-03-15",
      realisedPnl: -4628.47,
    },
    {
      domain: "2021-03-16",
      realisedPnl: -3358.57,
    },
    {
      domain: "2021-03-18",
      realisedPnl: -2355.23,
    },
    {
      domain: "2021-03-19",
      realisedPnl: -1272.01,
    },
    {
      domain: "2021-03-22",
      realisedPnl: 578.21,
    },
    {
      domain: "2021-03-23",
      realisedPnl: 814.06,
    },
    {
      domain: "2021-03-24",
      realisedPnl: 1211.03,
    },
    {
      domain: "2021-03-25",
      realisedPnl: -2835.91,
    },
    {
      domain: "2021-03-26",
      realisedPnl: -2835.91,
    },
    {
      domain: "2021-03-29",
      realisedPnl: -2835.91,
    },
    {
      domain: "2021-03-30",
      realisedPnl: -2844.55,
    },
    {
      domain: "2021-03-31",
      realisedPnl: -2514.57,
    },
    {
      domain: "2021-04-01",
      realisedPnl: -2514.57,
    },
    {
      domain: "2021-04-05",
      realisedPnl: -1791.71,
    },
    {
      domain: "2021-04-06",
      realisedPnl: -1523.77,
    },
    {
      domain: "2021-04-07",
      realisedPnl: -1255.77,
    },
    {
      domain: "2021-04-08",
      realisedPnl: -353.01,
    },
    {
      domain: "2021-04-09",
      realisedPnl: -185.08,
    },
    {
      domain: "2021-04-12",
      realisedPnl: 21.88,
    },
    {
      domain: "2021-04-14",
      realisedPnl: -982.26,
    },
    {
      domain: "2021-04-15",
      realisedPnl: -1398.38,
    },
    {
      domain: "2021-04-16",
      realisedPnl: -1398.38,
    },
    {
      domain: "2021-04-19",
      realisedPnl: -1575.38,
    },
    {
      domain: "2021-04-21",
      realisedPnl: -459.52,
    },
    {
      domain: "2021-04-22",
      realisedPnl: -459.52,
    },
    {
      domain: "2021-04-26",
      realisedPnl: -23.6,
    },
    {
      domain: "2021-04-28",
      realisedPnl: 10.4,
    },
    {
      domain: "2021-04-29",
      realisedPnl: -225.6,
    },
    {
      domain: "2021-05-03",
      realisedPnl: -225.6,
    },
    {
      domain: "2021-05-04",
      realisedPnl: 283.26,
    },
    {
      domain: "2021-05-05",
      realisedPnl: -836.85,
    },
    {
      domain: "2021-05-06",
      realisedPnl: -1034.13,
    },
    {
      domain: "2021-05-07",
      realisedPnl: -248.29,
    },
    {
      domain: "2021-05-10",
      realisedPnl: -248.29,
    },
    {
      domain: "2021-05-11",
      realisedPnl: 617.54,
    },
    {
      domain: "2021-05-12",
      realisedPnl: 897.28,
    },
    {
      domain: "2021-05-13",
      realisedPnl: 995.22,
    },
    {
      domain: "2021-05-14",
      realisedPnl: 1365.56,
    },
    {
      domain: "2021-05-17",
      realisedPnl: 1365.56,
    },
    {
      domain: "2021-05-18",
      realisedPnl: 1803.38,
    },
    {
      domain: "2021-05-19",
      realisedPnl: 1803.38,
    },
    {
      domain: "2021-05-24",
      realisedPnl: 2378.85,
    },
    {
      domain: "2021-05-25",
      realisedPnl: 1938.59,
    },
    {
      domain: "2021-06-01",
      realisedPnl: 2320.53,
    },
    {
      domain: "2021-06-02",
      realisedPnl: 2707.47,
    },
    {
      domain: "2021-06-03",
      realisedPnl: 2987.21,
    },
    {
      domain: "2021-06-07",
      realisedPnl: 4098.02,
    },
    {
      domain: "2021-06-08",
      realisedPnl: 4220.95,
    },
    {
      domain: "2021-06-10",
      realisedPnl: 4220.95,
    },
    {
      domain: "2021-06-11",
      realisedPnl: 4508.89,
    },
    {
      domain: "2021-06-16",
      realisedPnl: 4508.89,
    },
    {
      domain: "2021-06-17",
      realisedPnl: 4852.9,
    },
    {
      domain: "2021-06-18",
      realisedPnl: 5015.84,
    },
    {
      domain: "2021-06-21",
      realisedPnl: 5326.24,
    },
    {
      domain: "2021-06-22",
      realisedPnl: 5327.24,
    },
    {
      domain: "2021-06-23",
      realisedPnl: 5327.24,
    },
    {
      domain: "2021-06-24",
      realisedPnl: 5327.24,
    },
    {
      domain: "2021-06-25",
      realisedPnl: 5515.14,
    },
    {
      domain: "2021-06-28",
      realisedPnl: 4151.13,
    },
    {
      domain: "2021-06-29",
      realisedPnl: 4140.95,
    },
    {
      domain: "2021-06-30",
      realisedPnl: 4140.95,
    },
    {
      domain: "2021-07-01",
      realisedPnl: 4140.95,
    },
    {
      domain: "2021-07-02",
      realisedPnl: 4140.95,
    },
    {
      domain: "2021-07-06",
      realisedPnl: 4730.86,
    },
    {
      domain: "2021-07-08",
      realisedPnl: 7145.74,
    },
    {
      domain: "2021-07-09",
      realisedPnl: 7145.74,
    },
    {
      domain: "2021-07-12",
      realisedPnl: 7145.74,
    },
    {
      domain: "2021-07-13",
      realisedPnl: 7261.64,
    },
    {
      domain: "2021-07-14",
      realisedPnl: 7335.64,
    },
    {
      domain: "2021-07-15",
      realisedPnl: 7881.64,
    },
    {
      domain: "2021-07-16",
      realisedPnl: 6151.38,
    },
    {
      domain: "2021-07-19",
      realisedPnl: 8532.79,
    },
    {
      domain: "2021-07-22",
      realisedPnl: 8532.79,
    },
    {
      domain: "2021-07-23",
      realisedPnl: 7866.79,
    },
    {
      domain: "2021-07-26",
      realisedPnl: 7868.26,
    },
    {
      domain: "2021-07-27",
      realisedPnl: 7868.26,
    },
    {
      domain: "2021-07-28",
      realisedPnl: 7868.26,
    },
    {
      domain: "2021-07-29",
      realisedPnl: 8168.19,
    },
    {
      domain: "2021-07-30",
      realisedPnl: 8168.19,
    },
    {
      domain: "2021-08-02",
      realisedPnl: 8168.19,
    },
    {
      domain: "2021-08-03",
      realisedPnl: 8168.19,
    },
    {
      domain: "2021-08-04",
      realisedPnl: 8507.18,
    },
    {
      domain: "2021-08-05",
      realisedPnl: 8359.83,
    },
    {
      domain: "2021-08-06",
      realisedPnl: 8553.83,
    },
    {
      domain: "2021-08-09",
      realisedPnl: 8553.83,
    },
  ],
  chartProperties: {
    name: "Cummulative PnL",
    chartType: "area",
    keys: ["realisedPnl"],
    colors: ["var(--chart-blue)", "var(--chart-orange)"],
    legend: ["Realised PnL"],
    domainType: "date",
  },
};
