import React, { useEffect } from "react";
import {
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Link,
  VStack,
  HStack,
  Text,
  Flex,
  Tooltip,
  Heading,
  Box,
  keyframes, // <-- import keyframes from Chakra
} from "@chakra-ui/react";
import { getKnowledgeBaseArticles } from "../../api";
import { BiLinkExternal, BiLogoYoutube } from "react-icons/bi";
import { MdHelp } from "react-icons/md";
import DiscordInvite from "../../components/misc/DiscordInvite";
import { SecondaryButton } from "../../components/design_library";
import { Spinner } from "../../components";
import { KnowledgeBaseHelperTopics } from "../../types";
import { pulse } from "../../components/animation/Pulise";

const pulseAnimation = `${pulse} 2s infinite`;

export default function KnowledgeBaseHelper({ topic }: { topic: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = React.useState(true);
  const [articles, setArticles] = React.useState([]);
  // Track if user is first time visitor
  const [isFirstTimeUser, setIsFirstTimeUser] = React.useState(false);

  const getArticles = async () => {
    const as = await getKnowledgeBaseArticles(true, topic);
    setArticles(as.articles);
    setLoading(false);
  };

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {
    if (!loading && articles.length > 0) {
      const visitedKey = `hasVisited_${topic}`;
      const hasVisited = localStorage.getItem(visitedKey);

      // If there's no record in localStorage, user hasn't visited yet
      if (!hasVisited) {
        setIsFirstTimeUser(true); // <-- highlight (pulse) the button
        localStorage.setItem(visitedKey, "true");
      }
    }
  }, [loading, articles, topic, onOpen]);

  // Bail-out if no articles
  if (articles.length === 0) return null;

  return (
    <>
      <SecondaryButton
        // 3. Conditionally apply the pulse animation
        animation={isFirstTimeUser ? pulseAnimation : undefined}
        bg="var(--dark-gray)"
        border="0"
        onClick={() => {
          onOpen();
          setIsFirstTimeUser(false); // stop pulsing once opened manually
        }}
        h="100%"
        maxH="38px"
      >
        <MdHelp color="var(--accent)" />
        <Text pl="4px" fontSize="14px">
          Help
        </Text>
      </SecondaryButton>

      <Drawer onClose={onClose} isOpen={isOpen} size={"xs"}>
        <DrawerOverlay />
        <DrawerContent bg="var(--bg)" overflowY={"scroll"}>
          <DrawerCloseButton
            onClick={() => {
              onClose();
              setIsFirstTimeUser(false); // also stop pulsing on close
            }}
          />
          <Flex bg="var(--dark-gray)">
            <DrawerHeader flex={1} fontSize="14px">
              User guide: {topic}
            </DrawerHeader>
          </Flex>
          <VStack
            mt="20px"
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            pl="24px"
          >
            <Heading fontSize="14px">Articles</Heading>
            {articles
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((article) => (
                <Tooltip key={article.id} label={article.excerpt}>
                  <Link
                    color="var(--link)"
                    target="_blank"
                    display="flex"
                    alignItems="center"
                    justifyContent={"space-between"}
                    href={`https://wealthbee.io/knowledgebase/${article.slug}`}
                  >
                    <Text
                      w={"260px"}
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      fontSize={"13px"}
                    >
                      {article.title}
                    </Text>
                    <BiLinkExternal />
                  </Link>
                </Tooltip>
              ))}

            {(topic === KnowledgeBaseHelperTopics.DASHBOARD ||
              topic === KnowledgeBaseHelperTopics.TRANSACTIONS ||
              topic === KnowledgeBaseHelperTopics.POSITIONS) && (
              <Box
                w="full"
                mt="10px"
                bg="var(--bg)"
                borderTop="1px solid var(--border-color)"
              >
                <Text fontSize="14px" mb="10px" fontWeight={600}>
                  Audio guide:
                </Text>

                <audio
                  controls
                  style={{
                    width: "260px",
                    minWidth: "260px",
                    display: "flex",
                  }}
                  controlsList="nodownload"
                >
                  <source
                    src={
                      topic === KnowledgeBaseHelperTopics.DASHBOARD
                        ? "https://cdn.wealthbee.io/main/audio/dashboard.mp3"
                        : topic === KnowledgeBaseHelperTopics.POSITIONS
                        ? "https://cdn.wealthbee.io/main/audio/positions.mp3"
                        : topic === KnowledgeBaseHelperTopics.TRANSACTIONS
                        ? "https://cdn.wealthbee.io/main/audio/transactions.mp3"
                        : ""
                    }
                    type="audio/mpeg"
                  />
                  Your browser does not support the audio element.
                </audio>
              </Box>
            )}

            <VStack mt="20px" spacing={2} direction="column" align="start">
              <Heading fontSize="14px">More Help Needed?</Heading>
              <Link
                key="youtube"
                target="_blank"
                href="https://www.youtube.com/@WealthBeeIo"
              >
                <HStack>
                  <BiLogoYoutube size="40px" color="red" />
                  <Text textDecoration={{ underline: "none" }}>YouTube</Text>
                </HStack>
              </Link>

              <DiscordInvite />

              <Button
                size="md"
                mt="10px"
                fontSize="14px"
                onClick={() => {
                  $crisp.push(["do", "chat:open"]);
                }}
              >
                Open support chat
              </Button>
            </VStack>
          </VStack>
        </DrawerContent>
      </Drawer>
    </>
  );
}
