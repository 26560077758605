import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  HStack,
  IconButton,
  InputGroup,
  InputLeftElement,
  Tooltip,
} from "@chakra-ui/react";
import SearchInput from "../../../../components/forms/components/SearchInput";
import { BiGrid, BiListCheck } from "react-icons/bi";
import { MdGrid3X3, MdList } from "react-icons/md";
import { SecondaryButton } from "../../../../components/design_library";
import { RiEye2Line, RiEyeCloseFill, RiEyeFill } from "react-icons/ri";
import { pulse } from "../../../../components/animation/Pulise";

interface CategoryFilterProps {
  categories: string[];
  selectedCategory: string | null;
  setSelectedCategory: (category: string | null) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  setView: (view: string) => void;
  view: string;
  setDisplayDemoData: (display: boolean) => void;
  displayDemoData: boolean;
}

const CategoryFilter: React.FC<CategoryFilterProps> = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  searchQuery,
  setSearchQuery,
  setView,
  view,
  setDisplayDemoData,
  displayDemoData,
}) => {
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);

  useEffect(() => {
    const visitedKey = `hasVisited_InsightCategoryComponent`;
    const hasVisited = localStorage.getItem(visitedKey);

    if (!hasVisited) {
      setIsFirstTimeUser(true);
      localStorage.setItem(visitedKey, "true");
    }
  }, []);

  const pulseAnimation = `${pulse} 2s infinite`;

  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);
  useEffect(() => {
    setLocalSearchQuery(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchQuery(localSearchQuery);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [localSearchQuery, setSearchQuery]);

  return (
    <HStack
      justifyContent={"space-between"}
      w="full"
      wrap={"wrap"}
      h="full"
      mt={"8px"}
    >
      <HStack px="3" spacing={2} wrap={"wrap"}>
        <Box
          px={4}
          py={"8px"}
          cursor="pointer"
          borderRadius="md"
          bg={
            !selectedCategory ? "rgba(0, 100, 0, 0.5)" : "rgba(84, 84, 84, 0.5)"
          }
          color="white"
          whiteSpace="nowrap"
          onClick={() => setSelectedCategory(null)}
        >
          All
        </Box>
        {categories.map((category) => (
          <Box
            key={category}
            px={3}
            py={"8px"}
            cursor="pointer"
            borderRadius="md"
            bg={
              selectedCategory === category
                ? "rgba(0, 100, 0, 0.5)"
                : "rgba(84, 84, 84, 0.5)"
            }
            color="white"
            whiteSpace="nowrap"
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </Box>
        ))}
      </HStack>

      {/* Toggle view */}

      <HStack>
        <Tooltip
          label={displayDemoData ? "Display your data" : "Show demo data"}
          hasArrow
        >
          <Button onClick={() => setDisplayDemoData(!displayDemoData)}>
            {displayDemoData ? <RiEyeCloseFill /> : <RiEyeFill />}
          </Button>
        </Tooltip>

        <HStack
          bg="var(--dark-gray)"
          spacing={0}
          animation={isFirstTimeUser ? pulseAnimation : undefined}
          borderRadius={4}
        >
          <Tooltip label="Grid view" hasArrow>
            <Button
              p="0"
              m="0"
              minH="100%"
              onClick={() => {
                setView("grid");
                setIsFirstTimeUser(false);
              }}
              variant={view === "grid" ? "solid" : "outline"}
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
            >
              <MdGrid3X3 fontSize="20px" />
            </Button>
          </Tooltip>
          <Tooltip label="List view" hasArrow>
            <Button
              p="0"
              borderTopLeftRadius={0}
              borderBottomLeftRadius={0}
              m="0"
              minH="100%"
              onClick={() => {
                setView("list");
                setIsFirstTimeUser(false);
              }}
              variant={view === "list" ? "solid" : "outline"}
            >
              <MdList fontSize="20px" />
            </Button>
          </Tooltip>
        </HStack>
        <HStack>
          <SearchInput
            value={localSearchQuery}
            onChange={(val) => setLocalSearchQuery(val)}
            placeholder="Type to search..."
          />
        </HStack>
      </HStack>
    </HStack>
  );
};

export default CategoryFilter;
