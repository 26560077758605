import React from "react";
import { Box, VStack, HStack, Text } from "@chakra-ui/react";
import { useInsightsCreatorController } from "./useInsightsCreatorController";
import { InsightTemplate } from "../../../../types/Insight";

// Smaller, reusable field components
import TitleField from "./components/TitleField";
import TypeQueryField from "./components/TypeQueryField";
import DescriptionField from "./components/DescriptionField";
import TagsField from "./components/TagsField";
import VisibilityFields from "./components/VisibilityFields";
import DefaultPositions from "./components/DefaultPositions";
import DimensionsFields from "./components/DimensionsFields";
import SubmitButtons from "./components/SubmitButtons";
import CategoryField from "./components/CategoryField";

interface InsightsCreatorProps {
  insightsData: InsightTemplate;
  setInsightData: (insight: InsightTemplate) => void;
  setPage: (page: string) => void;
}

const InsightsCreator: React.FC<InsightsCreatorProps> = ({
  insightsData,
  setInsightData,
  setPage,
}) => {
  const { formik, loading, mode, handleTagChange, addTag, removeTag } =
    useInsightsCreatorController({ insightsData, setInsightData, setPage });

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box p={5}>
      <HStack
        spacing={4}
        mb={4}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Box flex={2}>
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4} align="stretch">
              <TitleField formik={formik} />
              <CategoryField formik={formik} />
              <TypeQueryField formik={formik} />
              <DescriptionField formik={formik} />
              <TagsField
                formik={formik}
                removeTag={removeTag}
                addTag={addTag}
                handleTagChange={handleTagChange}
              />
              <VisibilityFields formik={formik} />
              <DefaultPositions formik={formik} />
              <DimensionsFields formik={formik} />
              <SubmitButtons formik={formik} mode={mode} />
            </VStack>
          </form>
        </Box>
      </HStack>
    </Box>
  );
};

export default InsightsCreator;
