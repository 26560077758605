import React from "react";
import { SidebarStateAtom } from "../../store";
import { useAtom } from "jotai";
import { Tooltip, IconButton, Box, Divider, HStack } from "@chakra-ui/react";
import { RiSideBarFill } from "react-icons/ri";

export default function SidebarToggle() {
  const [isCollapsed, setIsCollapsed] = useAtom(SidebarStateAtom);

  return (
    <HStack p={0} m={0}>
      <Box maxH={"40px"} overflow={"hidden"}>
        {
          <Tooltip
            label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
            aria-label="Expand sidebar"
          >
            <IconButton
              aria-label="Expand"
              icon={<RiSideBarFill fontSize="20px" />}
              onClick={() => setIsCollapsed(!isCollapsed)}
              size="sm"
              variant="ghost"
              colorScheme="gray"
              borderRadius="0"
              w="full"
              h="10"
            />
          </Tooltip>
        }
      </Box>

      <Divider
        orientation="vertical"
        display={{
          base: "none",
          md: "block",
        }}
        mr="14px"
      />
    </HStack>
  );
}
