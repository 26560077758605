import React from "react";
import { FastTag } from "../../../components/design_library";

export default function ExampleTag() {
  return (
    <FastTag
      position="absolute"
      top="20px"
      right="10px"
      bg={"var(--light-gray)"}
      color="var(--black)"
      boxShadow="0 8px 32px 0 rgba(0, 0, 0, 0.37)"
      backdropFilter="blur(7.5px)"
    >
      Example
    </FastTag>
  );
}
