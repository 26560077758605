import { ColDef } from "ag-grid-community";
import {
  createGroupColumn,
  ColumnTypeEnum,
  distinctValue,
} from "../../../../components";

export const uncoveredPositionsColDefs: ColDef[] = [
  createGroupColumn("Underlying Symbol", "symbol", ColumnTypeEnum.Logo, {
    suppressColumnsToolPanel: true,
    rowDrag: true,
  }),

  createGroupColumn("Total Shares", "totalShares", ColumnTypeEnum.Numeric),

  createGroupColumn("Covered Shares", "coveredShares", ColumnTypeEnum.Numeric),

  createGroupColumn(
    "Uncovered Shares",
    "uncoveredShares",
    ColumnTypeEnum.Numeric
  ),
];
