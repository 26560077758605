import styles from "../index.module.css";
import { ChevronDown, ChevronUp } from "../Icons";
import Select, { components } from "react-select";
import React from "react";
import { min } from "moment";

const customStylesDarker = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    color: "var(--white)",
    border: "none",
    minWidth: "300px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    border: "none",
    color: "var(--white)",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    border: "none",

    color: "var(--light-gray)",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    fontSize: "14px",
    color: "var(--white)",
    border: "1px solid var(--gray)",
    borderRadius: "6px",
    height: "30px",
    minHeight: "30px",
    maxHeight: "30px",
    display: "flex",
    alignContent: "center",
    marginTop: "2px",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    // padding: "0 6px",
    color: "var(--white)",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    border: "none",
    color: "var(--white)",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
  }),

  singleValue: (provided, state) => ({
    ...provided,
    border: "none",
    color: "var(--white)",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const ToolbarDropDown = (props: any) => {
  return (
    <Select
      isLoading={props.loading}
      id={props.id}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      styles={customStylesDarker}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        ...props.components,
      }}
      {...props}
      minW={"20px"}
    />
  );
};

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <ChevronUp fontSize={"24px"} />
        ) : (
          <ChevronDown fontSize={"24px"} />
        )}
      </components.DropdownIndicator>
    )
  );
};
