import { useEffect, useState, useCallback } from "react";

import moment from "moment";
import { useAtomValue } from "jotai";
import { deleteInsight, getInsights, getInsightsDatasets } from "../../../api";
import { useFilters } from "../../../hooks/useFilters";
import { useNotifications } from "../../../hooks/useNotifications";
import {
  SelectedDashboardIdAtom,
  AccountAtom,
  DashboardLayoutStateStore,
} from "../../../store";
import { InsightSearchPages, InsightTemplate } from "../../../types";

interface Insight {
  insightId?: number;
  [key: string]: any;
}

interface UseInsightsController {
  handleToggle: () => void;
  handleDelete: (insightId: number) => void;
  fetchInsights: (page: InsightSearchPages, search: string) => void;
  isOpen: boolean;
  insights: Insight[];
  insightsDataset: Insight[];
  insightData: Insight | null;
  insightId: number | null;
  setInsightId: (id: number | null) => void;
  loading: boolean;
  displaysPage: InsightSearchPages;
  setDisplayedPage: (page: InsightSearchPages) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  selectedInsight: Insight | null;
  setSelectedInsight: (insight: Insight | null) => void;
  page: string;
  setPage: (page: "insights" | "creator") => void;
  setInsightData: (insight: InsightTemplate) => void;
}

export const useInsightsController = (): UseInsightsController => {
  const [page, setPage] = useState("insights");
  const [insightData, setInsightData] = useState<Insight | null>(null);
  const [insights, setInsights] = useState<Insight[]>([]);
  const [insightsDataset, setInsightsDataset] = useState<Insight[]>([]);
  const [insightId, setInsightId] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState<Insight | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [displaysPage, setDisplayedPage] = useState(InsightSearchPages.ALL);

  const selectedDashboardId = useAtomValue(SelectedDashboardIdAtom);
  const notifications = useNotifications();
  const accountState = useAtomValue(AccountAtom);
  const filters = useFilters();
  const dashboardLayoutStore = useAtomValue(DashboardLayoutStateStore);

  const handleToggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const handleDelete = useCallback(
    async (insightId: number) => {
      if (!window.confirm("Are you sure you want to delete this insight?"))
        return;

      setLoading(true);
      try {
        await deleteInsight(insightId);
        notifications.createNotification(
          "Insight deleted",
          "Your insight has been deleted successfully"
        );
        fetchInsights(displaysPage, searchQuery);
      } catch (error) {
        notifications.createNotification(
          "Error deleting insight",
          "There was an error deleting your insight"
        );
        console.error("Error deleting insight:", error);
      } finally {
        setLoading(false);
      }
    },
    [displaysPage, searchQuery, notifications]
  );

  const fetchInsights = useCallback(
    async (page?: InsightSearchPages, search?: string) => {
      setLoading(true);
      try {
        const response = await getInsights(page, search);

        setTimeout(() => {
          setInsights(response.data);
          setLoading(false);
        }, 600);
      } catch (error) {
        notifications.createNotification(
          "Error fetching insights",
          "There was an error fetching insights"
        );
        console.error("Error fetching insights:", error);
      }
    },
    [notifications]
  );

  return {
    handleToggle,
    handleDelete,
    fetchInsights,
    isOpen,
    insights,
    insightsDataset,
    insightData,
    setInsightData,
    insightId,
    setInsightId,
    loading,
    displaysPage,
    setDisplayedPage,
    searchQuery,
    setSearchQuery,
    selectedInsight,
    setSelectedInsight,
    page,
    setPage,
  };
};
