import React from "react";
import { FormControl, FormLabel, Input, HStack } from "@chakra-ui/react";
import { FormikProps } from "formik";
import { InsightTemplate } from "../../../../../types/Insight";

interface DefaultPositionsProps {
  formik: FormikProps<InsightTemplate>;
}

const DefaultPositions: React.FC<DefaultPositionsProps> = ({ formik }) => (
  <HStack>
    <FormControl>
      <FormLabel>Default X position</FormLabel>
      <Input
        type="number"
        name="defaultX"
        value={formik.values.defaultX || 0}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </FormControl>
    <FormControl>
      <FormLabel>Default Y position</FormLabel>
      <Input
        type="number"
        name="defaultY"
        value={formik.values.defaultY || 0}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </FormControl>
  </HStack>
);

export default DefaultPositions;
