import React from "react";
import {
  Box,
  Heading,
  VStack,
  Text,
  HStack,
  Progress,
  Center,
} from "@chakra-ui/react";
import { useInsightComparisonController } from "./useInsightComparisonController";
import { Show } from "../../../components";
import { BaseInsightProps } from "../BaseInsightProps";
import { FastTag } from "../../../components/design_library";
import ExampleTag from "../shared/ExampleTag";
import NoTimeFrameCompability from "../components/NoTimeframeCompatibility";
import { InsightStateEnum } from "../InsightStateEnum";
import NoDataAvailable from "../components/NoDataAvailable";
import InsightErrorRouter from "../components/InsightErrorRouter";

export const InsightComparison = (props: BaseInsightProps) => {
  const { insight }: BaseInsightProps = props;
  const controller = useInsightComparisonController(props);

  return (
    <>
      <InsightErrorRouter
        insightState={controller.insightState}
        supported={controller.supported}
      />
      <Show
        if={
          controller.supported === true &&
          controller.insightState === InsightStateEnum.Success
        }
      >
        <Box w="full" p={4} h="80%" key={`insight-${insight.insightId}`}>
          {props.demo === true && <ExampleTag />}

          <VStack
            align="stretch"
            spacing={4}
            m={"auto"}
            h="full"
            justifyContent={"center"}
          >
            <Show if={controller.showProgress}>
              <VStack spacing={4} w="full">
                <VStack spacing={1} align="center" w="full">
                  <HStack justifyContent={"space-between"} w="full">
                    <Text
                      fontSize="4xl"
                      fontWeight="bold"
                      color={
                        parseFloat(controller.formattedValue1) >
                        parseFloat(controller.formattedValue2)
                          ? "var(--green)"
                          : "var(--red)"
                      }
                    >
                      {controller.prefix}
                      {controller.formattedValue1}
                      {controller.suffix}
                    </Text>
                    <Text fontSize="lg" color="var(--white)">
                      {controller.label1}
                    </Text>
                  </HStack>
                  <Progress
                    borderRadius={4}
                    value={parseFloat(controller.formattedValue1)}
                    size="lg"
                    w="full"
                    colorScheme={
                      parseFloat(controller.formattedValue1) >
                      parseFloat(controller.formattedValue2)
                        ? "green"
                        : "red"
                    }
                  />
                </VStack>
                <VStack spacing={1} align="center" w="full">
                  <HStack justifyContent={"space-between"} w="full">
                    <Text
                      fontSize="4xl"
                      fontWeight="bold"
                      color={
                        parseFloat(controller.formattedValue2) >
                        parseFloat(controller.formattedValue1)
                          ? "var(--green)"
                          : "var(--red)"
                      }
                    >
                      {controller.prefix}
                      {controller.formattedValue2}
                      {controller.suffix}
                    </Text>
                    <Text fontSize="lg" color="var(--white)">
                      {controller.label2}
                    </Text>
                  </HStack>
                  <Progress
                    borderRadius={4}
                    value={parseFloat(controller.formattedValue2)}
                    size="lg"
                    w="full"
                    colorScheme={
                      parseFloat(controller.formattedValue2) >
                      parseFloat(controller.formattedValue1)
                        ? "green"
                        : "red"
                    }
                  />
                </VStack>
              </VStack>
            </Show>

            <Show if={controller.showProgress === false}>
              <VStack spacing={4} py="30px">
                <HStack spacing={1} align="center" w="full">
                  <VStack justifyContent={"space-between"} w="full">
                    <Text
                      fontSize="4xl"
                      fontWeight="bold"
                      color={
                        parseFloat(controller.value1) >
                        parseFloat(controller.value2)
                          ? "var(--green)"
                          : "var(--red)"
                      }
                    >
                      {controller.prefix}
                      {controller.formattedValue1}
                      {controller.suffix}
                    </Text>
                    <Text fontSize="lg" color="var(--white)">
                      {controller.label1}
                    </Text>
                  </VStack>
                  <Text fontSize="lg" fontWeight={600}>
                    vs
                  </Text>
                  <VStack justifyContent={"space-between"} w="full">
                    <Text
                      fontSize="4xl"
                      fontWeight="bold"
                      color={
                        parseFloat(controller.value2) >
                        parseFloat(controller.value1)
                          ? "var(--green)"
                          : "var(--red)"
                      }
                    >
                      {controller.prefix}
                      {controller.formattedValue2}
                      {controller.suffix}
                    </Text>
                    <Text fontSize="lg" color="var(--white)">
                      {controller.label2}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </Show>
          </VStack>
        </Box>
      </Show>
    </>
  );
};
