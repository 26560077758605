import { ColDef } from "ag-grid-community";
import {
  createGroupColumn,
  ColumnTypeEnum,
} from "../../../../../../../components";
import convertSymbolToReadable from "../../../../../../../components/grid/utils/convertSymbolToReadable";
import { calculateReturnOnPremium } from "../../../../../../../utils";

export const openPositionsColDefs: ColDef[] = [
  createGroupColumn(
    "Underlying Symbol",
    "underlyingSymbol",
    ColumnTypeEnum.Logo,
    {
      suppressColumnsToolPanel: true,
      hide: true,
      rowDrag: true,
    }
  ),

  createGroupColumn("Instrument", "instrument.symbol", ColumnTypeEnum.Text, {
    valueGetter: (params) => {
      return convertSymbolToReadable(params.data.instrument.symbol);
    },
    aggFunc: "distinct",
    minWidth: 200,
    hide: true,
  }),

  createGroupColumn("Book", "book", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    aggFunc: "distinct",
    rowDrag: true,
    sort: "asc",
    suppressColumnsToolPanel: true,
  }),

  createGroupColumn("Underlying", "underlyingName", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    suppressColumnsToolPanel: true,
    valueGetter: (params) => {
      if (params.data.underlyingType === "Future") {
        return params.data.underlyingName;
      } else {
        return params.data.underlyingSymbol;
      }
    },
  }),

  createGroupColumn("Position Group", "positionName", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    suppressColumnsToolPanel: true,
  }),

  createGroupColumn("Strategy", "strategy", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
  }),

  createGroupColumn(
    "Underlying Symbol",
    "underlyingSymbol",
    ColumnTypeEnum.Logo,
    {
      suppressColumnsToolPanel: true,
      hide: true,
      rowDrag: true,
    }
  ),

  // tags
  createGroupColumn("Tags", "tags", ColumnTypeEnum.Tags, {
    hide: true,
    aggFunc: "none",
  }),

  createGroupColumn("Market Price", "price", ColumnTypeEnum.Currency, {
    cellRendererParams: { noFormatting: true },
    hide: false,
    aggFunc: "none",
  }),

  createGroupColumn(
    "Unrealized P&L$",
    "unrealisedPnl",
    ColumnTypeEnum.CurrencyWithPercentage,
    {
      hide: false,
      cellRendererParams: {
        showDirection: true,
        percentageField: "unRealisedPnLPercentage",
      },
      aggFunc: "sum",
      valueGetter: (params) => {
        if (params.data.priceDate === null) {
          return null;
        } else {
          return params.data.unrealisedPnl;
        }
      },
    }
  ),

  // createGroupColumn("Total P&L$", "totalPnl", ColumnTypeEnum.Currency, {
  //   hide: false,
  //   aggFunc: "sum",
  //   valueGetter: (params) => {
  //     return params.data.unrealisedPnl + params.data.realisedPnl;
  //   },
  // }),

  createGroupColumn(
    "Gross proceeds",
    "grossProceeds",
    ColumnTypeEnum.Currency,
    { cellRendererParams: { showDirection: true }, aggFunc: "sum" }
  ),

  createGroupColumn("Net proceeds", "netProceeds", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn("Total Credits", "totalCredits", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn("Total Debits", "totalDebits", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn("Costs", "costs", ColumnTypeEnum.Currency, {
    hide: false,
    aggFunc: "sum",
    valueGetter: (params) => {
      return params.data.costs * -1;
    },
  }),

  createGroupColumn("Type", "instrument.instrumentType", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
  }),

  createGroupColumn("Put/Call", "instrument.putCall", ColumnTypeEnum.Pill, {
    cellRendererParams: {
      exactMatch: true,

      colourMap: {
        Put: "var(--trade-call)",
        Call: "var(--trade-put)",
      },
      defaultColour: "var(--accent)",
    },
    aggFunc: "distinct",
  }),

  createGroupColumn("Strike", "instrument.strike", ColumnTypeEnum.Numeric, {
    aggFunc: "distinct",
  }),
  createGroupColumn("Expiry Date", "instrument.expiry", ColumnTypeEnum.Date, {
    aggFunc: "distinct",
  }),

  createGroupColumn(
    "Multiplier",
    "instrument.multiplier",
    ColumnTypeEnum.Numeric,
    {
      aggFunc: "distinct",
    }
  ),

  createGroupColumn(
    "Days in Position",
    "daysInPosition",
    ColumnTypeEnum.Numeric,
    {
      aggFunc: "avg",
      hide: false,
      valueFormatter: (params) => {
        if (typeof params.value === "object") {
          return params?.value?.value?.toFixed(1);
        } else {
          return params?.value?.toFixed(1);
        }
      },
    }
  ),

  createGroupColumn("Quantity", "quantity", ColumnTypeEnum.Numeric, {
    aggFunc: "none",
    hide: true,
    valueFormatter: (params) => {
      return params.value.toLocaleString();
    },
  }),

  createGroupColumn(
    "Underlying Price",
    "underlyingPrice",
    ColumnTypeEnum.Currency,
    { aggFunc: "none" }
  ),

  createGroupColumn("Premium", "premium", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn("Cost Basis", "costBasis", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn(
    "Return On Premium",
    "returnOnPremium",
    ColumnTypeEnum.Percentage,
    {
      cellRendererParams: { showDirection: true },
      aggFunc: "returnOnPremium",
      valueGetter: (params) => {
        return calculateReturnOnPremium(
          params.data.premium,
          params.data.realisedPnl,
          params.data.unrealisedPnl
        );
      },
    }
  ),
];
