import { ColDef } from "ag-grid-community";
import {
  createGroupColumn,
  ColumnTypeEnum,
  calculateDaysUntilExpiry,
} from "../../../../components";
import convertSymbolToReadable from "../../../../components/grid/utils/convertSymbolToReadable";

export const upcomingExpiriesColumnDefs: ColDef[] = [
  createGroupColumn("Underlying", "underlyingName", ColumnTypeEnum.Logo, {
    valueGetter: (params) => {
      if (params.data.underlyingType === "Future") {
        return params.data.underlyingName;
      } else {
        return params.data.underlyingSymbol;
      }
    },
  }),
  createGroupColumn("Symbol", "symbol", ColumnTypeEnum.Text, {
    valueGetter: (params) => {
      return convertSymbolToReadable(params.data.symbol);
    },
  }),

  createGroupColumn("Expiry", "expiry", ColumnTypeEnum.Date, {
    aggFunc: "distinct",
  }),

  createGroupColumn(
    "Days until expiry",
    "daysUntilExpiry",
    ColumnTypeEnum.Numeric,
    {
      sort: "asc",

      aggFunc: "avg",
      hide: false,
      valueGetter: (params) => {
        let daysUntilExpiry = calculateDaysUntilExpiry(
          params?.data?.instrument
        );
        if (isNaN(daysUntilExpiry) || daysUntilExpiry < 0) {
          return "";
        }
        return daysUntilExpiry.toFixed(2) || "";
      },
    }
  ),
];
