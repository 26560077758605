import React from "react";
import { InsightStateEnum } from "../InsightStateEnum";
import { Box, Flex, Text } from "@chakra-ui/react";
import InsightError from "./InsightError";
import InsightLoading from "./InsightLoading";
import NoDataAvailable from "./NoDataAvailable";
import NoTimeFrameCompability from "./NoTimeframeCompatibility";
import { Show } from "../../../components";

export default function InsightErrorRouter({
  insightState,
  supported,
}: {
  insightState: InsightStateEnum;
  supported: boolean;
}) {
  if (insightState === InsightStateEnum.Success && supported === true) {
    return null;
  }
  return (
    <Box w={"100%"} h={"100%"} justify={"center"} align={"center"}>
      <Show if={insightState === InsightStateEnum.NoData}>
        <NoDataAvailable />
      </Show>
      <Show if={insightState === InsightStateEnum.Error}>
        <InsightError />
      </Show>
      <Show if={insightState === InsightStateEnum.Loading}>
        <InsightLoading />
      </Show>

      {insightState === InsightStateEnum.Success && (
        <Show if={supported === false}>
          <NoTimeFrameCompability />
        </Show>
      )}
    </Box>
  );
}
