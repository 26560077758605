import { useEffect, useMemo, useState } from "react";
import { useAtomValue } from "jotai";
import { useFilters } from "../../../hooks/useFilters";
import {
  ChartProperties,
  DataItem,
  InsightBarchartProps,
} from "../../../types/Insight";
import { AccountAtom, TimeFrameAtom } from "../../../store";
import { useQuery } from "@tanstack/react-query";
import { InsightStateEnum } from "../InsightStateEnum";
import { resolveNamedTimeFrame } from "../../../utils";
import { runInsight } from "../../../api";
import { BaseInsightProps } from "../BaseInsightProps";
import InsightChartDemoDataMap from "./data";
import { useSearchParams } from "react-router-dom";

interface UseInsightChartController {
  insightState: InsightStateEnum;
  supported: boolean;
  name: string;
  data?: DataItem[];
  chartProperties?: ChartProperties;
  domainValues: string[];
}

export const useInsightChartController = (
  props: BaseInsightProps
): UseInsightChartController => {
  const { insight } = props;

  const filters = useFilters();
  const accountState = useAtomValue(AccountAtom);
  const timeFrame = useAtomValue(TimeFrameAtom);

  const [insightState, setInsightState] = useState<InsightStateEnum>(
    InsightStateEnum.Loading
  );

  const [searchParams] = useSearchParams();
  const pid = parseInt(searchParams.get("pid") ?? "");
  const accountId = pid || accountState?.selectedAccount?.id;

  // uses react query to cache result
  const result = useQuery<InsightBarchartProps>({
    queryKey: [
      props.demo === true ? "insight-chart" : "insight-chart-demo",
      insight.insightId,
      insight.query,
      insight.type,
      accountId,
      filters.selectedUnderlying,
      filters.selectedTag,
      filters.selectedStrategy,
      filters.selectedBook,
      filters.selectedPositionGroup,
      timeFrame.timeFrame,
    ],
    queryFn: async () => {
      if (props.demo === true && props?.insight?.query) {
        return InsightChartDemoDataMap[props?.insight?.query];
      } else {
        const timeframe = resolveNamedTimeFrame(timeFrame.timeFrame);

        return (await runInsight(
          accountId,
          insight.insightId,
          insight.query!,
          insight.type,
          timeframe,
          filters.selectedUnderlying,
          filters.selectedTag,
          filters.selectedStrategy,
          filters.selectedBook,
          filters.selectedPositionGroup
        )) as InsightBarchartProps;
      }
    },
    enabled:
      accountId !== undefined &&
      insight !== undefined &&
      insight.insightId !== undefined &&
      insight.query !== undefined &&
      insight.type !== undefined,
  });

  const domainValues = useMemo(
    () => result?.data?.data?.map((d) => d.domain as string),
    [result?.data?.data]
  );

  useEffect(() => {
    if (props.demo) {
      setInsightState(InsightStateEnum.Success);
    } else {
      if (!result.isSuccess && !result.isError) {
        setInsightState(InsightStateEnum.Loading);
      } else if (result.isSuccess) {
        if (result.data?.data) {
          setInsightState(InsightStateEnum.Success);
        } else {
          setInsightState(InsightStateEnum.NoData);
        }
      } else if (result.isError) {
        setInsightState(InsightStateEnum.Error);
      }
    }
  }, [result, props.demo]);

  return {
    insightState,
    supported:
      result.data?.supported === undefined ? true : result.data!.supported,
    name: insight.title,
    data: result.data?.data ?? [],
    chartProperties: result?.data?.chartProperties,
    domainValues: domainValues ?? [],
  };
};
