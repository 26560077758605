import React from "react";
import {
  Box,
  HStack,
  VStack,
  Heading,
  Text,
  Tag,
  Divider,
  Switch,
} from "@chakra-ui/react";
import { InsightTemplate } from "../../../../types/Insight";
import { InsightTypeIcon } from "../../../../components/insights/InsightTypeIcon";
import InsightErrorBoundary from "../../../dashboard/canvas/insight/InsightRenderers/InsightErrorBoundary";
import { InsightRenderer2 } from "../../container";
import { SecondaryButton } from "../../../../components/design_library";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useInsightsController } from "../../InsightsManager/useInsightsController";

interface InsightCardProps {
  insight: InsightTemplate;
  enabled: boolean;
  handleToggleInsight: (insight: InsightTemplate) => void;
  isOwner: boolean;
  setPage: (page: string) => void;
  setInsightData: (insight: InsightTemplate) => void;
  displayDemoData: boolean;
}

const InsightCard: React.FC<InsightCardProps> = ({
  insight,
  enabled,
  handleToggleInsight,
  isOwner,
  setPage,
  setInsightData,
  displayDemoData,
}) => {
  const [toggled, setToggled] = React.useState(false);
  const controller = useInsightsController();

  return (
    <Box
      px={2}
      py={1}
      overflow={"hidden"}
      borderRadius={8}
      // _hover={{ bg: "var(--bg)" }}
      transition={"all 0.2s"}
      h="100%"
      cursor={"pointer"}
      justifyContent={"center"}
      maxW={
        insight.type.toLowerCase().includes("chart") ||
        insight.type.toLowerCase().includes("table") ||
        insight.type.toLowerCase().includes("comparison") ||
        insight.type.toLowerCase().includes("map")
          ? "95vw"
          : "500px"
      }
      p="4"
      onClick={() => {
        setToggled(!toggled);
        handleToggleInsight(insight);
      }}
      boxShadow="0 8px 32px 0 rgba(0, 0, 0, 0.37)"
      backdropFilter="blur(7.5px)"
      border={
        enabled ? "5px solid var(--green)" : "1px solid var(--light-gray)"
      }
      // Tint
      _hover={{
        bg: "var(--dark-gray)",
        border: enabled
          ? "5px solid var(--green)"
          : "1px solid var(--light-gray)",
      }}
    >
      <VStack justifyContent={"space-between"} h={"100%"}>
        <HStack w="full">
          <Box
            w="30px"
            h="30px"
            borderRadius="6px"
            bg={enabled ? "var(--green)" : "var(--gray)"}
            color="var(--white)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            px={2}
            py={1}
            overflow={"hidden"}
            cursor={"pointer"}
            fontSize="40px"
          >
            <InsightTypeIcon type={insight.type} />
          </Box>

          <Heading
            fontSize={"16px"}
            pt="2"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            w="100%"
            pb="10px"
          >
            {insight.title}
          </Heading>
        </HStack>

        <InsightErrorBoundary key={insight.insightId}>
          <Box h={"100%"} overflow={"hidden"} w="100%" m="6">
            <InsightRenderer2 insight={insight} demo={displayDemoData} />
          </Box>
        </InsightErrorBoundary>

        <HStack justifyContent="space-between" w="100%">
          <VStack
            textAlign={"left"}
            w={"100%"}
            justifyContent={"flex-start"}
            alignContent={"flex-start"}
          >
            <Text
              size="xs"
              w="100%"
              textAlign={"left"}
              color="var(--light-gray)"
              pb="6px"
              pt="6px"
            >
              {insight.description}
            </Text>
            <Divider />
            <HStack justifyContent="space-between" w="100%">
              <HStack justifyContent="flex-start" w="100%">
                {insight.official && <Tag colorScheme="green">Official</Tag>}
                {insight.isDefaultInsight && (
                  <Tag colorScheme="blue">Recommended</Tag>
                )}
              </HStack>

              <HStack>
                <Text minW="100px" textAlign={"right"}>
                  {enabled ? "Enabled" : "Not Enabled"}
                </Text>
                {/* <Switch
                  colorScheme="green"
                  size={"lg"}
                  isChecked={enabled || (toggled && !enabled) ? true : false}
                  onChange={() => {
                    setToggled(!toggled);
                    handleToggleInsight(insight);
                  }}
                /> */}
              </HStack>

              <HStack
                justifyContent="space-between"
                alignItems="center"
                zIndex={9999}
                bg="red"
                opacity={0.4}
              >
                {isOwner && (
                  <>
                    <SecondaryButton
                      bg="transparent"
                      onClick={() => {
                        setInsightData(insight);
                        setPage("creator");
                      }}
                      px="2px"
                    >
                      <EditIcon fontSize="20px" />
                    </SecondaryButton>
                    <SecondaryButton
                      bg="transparent"
                      onClick={() => {
                        controller.handleDelete(insight.insightId as number);
                      }}
                      px="2px"
                    >
                      <DeleteIcon fontSize="20px" />
                    </SecondaryButton>
                  </>
                )}
              </HStack>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  );
};

export default InsightCard;
