export const PNL_BY_STRATEGY = {
  data: [
    {
      domain: "Unspecified",
      realisedPnl: 8553.83,
      unrealisedPnl: 0,
    },
    {
      domain: "Long Futures",
      realisedPnl: 12500.25,
      unrealisedPnl: 340.75,
    },
    {
      domain: "Short Options",
      realisedPnl: -2300.5,
      unrealisedPnl: 120.4,
    },
    {
      domain: "Pairs Trade",
      realisedPnl: 4500.1,
      unrealisedPnl: 50.6,
    },
    {
      domain: "Dividend Capture",
      realisedPnl: 2200,
      unrealisedPnl: -100,
    },
    {
      domain: "Volatility Arbitrage",
      realisedPnl: -750,
      unrealisedPnl: 200,
    },
  ],
  chartProperties: {
    name: "PnL By Strategy",
    chartType: "bar",
    keys: ["realisedPnl", "unrealisedPnl"],
    colors: ["var(--chart-blue)", "var(--chart-orange)"],
    legend: ["Realized PnL", "Unrealized PnL"],
    domainType: "string",
    showEveryDomainLabel: true,
    flipAxis: true,
  },
};
