import { useCallback, useEffect, useState } from "react";
import { deleteAPIKey, createAPIKey, getAPIKeys } from "../../api/APIKeys";
import { useAtomValue } from "jotai";
import { AppStateAtom } from "../../store";

export const useAPIKeysManagementPanelController = () => {
  const [apiKeysList, setApiKeysList] = useState([]);
  const [loading, setLoading] = useState(true);

  const appState = useAtomValue(AppStateAtom);

  const fetchKeys = useCallback(async () => {
    setLoading(true);
    const keys = await getAPIKeys();
    setApiKeysList(keys || []);
    setLoading(false);
  }, [appState.user.id]);

  useEffect(() => {
    fetchKeys();
  }, [fetchKeys]);

  const handleDeleteKey = async (id) => {
    const confirmation = window.prompt(
      "Are you sure you want to delete this key? Type 'DELETE' to confirm."
    );
    if (confirmation === "DELETE") {
      await deleteAPIKey(appState.user.id, id);
      fetchKeys();
    }
  };

  const handleCreateKey = async () => {
    await createAPIKey(appState.user.id);
    fetchKeys();
  };

  return { apiKeysList, loading, fetchKeys, handleDeleteKey, handleCreateKey };
};
