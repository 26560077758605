import { Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { TbDatabaseExclamation } from "react-icons/tb";

export default function NoDataAvailable() {
  return (
    <VStack
      h="100%"
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
      px="20px"
      w={"100%"}
    >
      <TbDatabaseExclamation fontSize="40px" />
      <Heading fontSize="md">No data available</Heading>
    </VStack>
  );
}
