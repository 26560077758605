import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Flex, Spinner, VStack, Text, Spacer, Box } from "@chakra-ui/react";
import "./Dashboard.css";
import { KnowledgeBaseHelperTopics, ToolbarFeatures } from "../../types";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { useDashboardController } from "./useDashboardController";
import { Show } from "../../components";
import { InsightContainer2 } from "../insights/container";
import { useAtomValue } from "jotai";
import { PositionStateAtom } from "../../store";
import { RiDashboardFill } from "react-icons/ri";

export const Dashboard2 = () => {
  const controller = useDashboardController();
  const positionsState = useAtomValue(PositionStateAtom);
  const ResponsiveGridLayout = useMemo(
    () => WidthProvider(Responsive),
    [
      controller.selectedDashboardId,
      positionsState.positions,
      controller.insightDefinitions,
    ]
  );

  useLayoutEffect(() => {
    try {
      (window as any).$crisp?.push([
        "set",
        "session:event",
        ["user:visitDashboardPage"],
      ]);
    } catch (error) {
      console.error("Crisp event error:", error);
    }
  }, []);

  return (
    <Flex direction={"column"} h="full" w="full">
      <Toolbar
        enabledOptions={[
          ToolbarFeatures.FILTER_SELECTOR,
          ToolbarFeatures.UNDERLYING,
          ToolbarFeatures.HELP,
          ToolbarFeatures.PRICING_QUEUE,
          ToolbarFeatures.TAGS,
          ToolbarFeatures.INSIGHTS,
          ToolbarFeatures.POSITION_GROUPING,
          ToolbarFeatures.BOOKS,
          ToolbarFeatures.DATE_RANGE,
          ToolbarFeatures.STRATEGY,
          ToolbarFeatures.CLEAR,
          ToolbarFeatures.DASHBOARD_SELECTOR,
          ToolbarFeatures.HELP,
          ToolbarFeatures.TIMEFRAMES,
          ToolbarFeatures.AUTOSYNC,
          ToolbarFeatures.TITLE,
          ToolbarFeatures.DESCRIPTION,
        ]}
        title={"Dashboard"}
        icon={<RiDashboardFill />}
        description="
          This is your dashboard. You can customize it by adding, removing, and resizing insights from the insights button."
        helpKey={KnowledgeBaseHelperTopics.DASHBOARD}
      />
      <Flex h="full" w="full" direction="column" className="dashboard">
        <Flex flex={1} className="dashboard-layout">
          <Show if={controller.loading}>
            <Flex
              pos="fixed"
              w="100vw"
              h="calc(100vh - 120px)"
              justifyContent={"center"}
              alignItems={"center"}
              zIndex={1}
            >
              <VStack
                borderRadius={"md"}
                justifyContent={"center"}
                alignItems={"center"}
                bg="var(--bg)"
                border={"1px solid var(--accent)"}
                flexDir={"row"}
                p="4"
              >
                <Spinner size="lg" />
                <Text>Updating dashboard...</Text>
              </VStack>
            </Flex>
          </Show>

          <ResponsiveGridLayout
            useCSSTransforms={false}
            measureBeforeMount={false}
            className="layout dashboard-layout"
            layouts={controller.layouts}
            breakpoints={{
              lg: 1200,
              md: 996,
              sm: 768,
              xs: 480,
              xxs: 0,
            }}
            cols={{
              lg: 12,
              md: 10,
              sm: 6,
              xs: 4,
              xxs: 2,
            }}
            allowOverlap={false}
            onBreakpointChange={controller.setBreakpoint}
            onDragStop={controller.updateLayoutComp}
            compactType="vertical"
            draggableHandle=".drag-handle"
            transformScale={1}
            onResizeStart={(e) => {
              controller.setInsightBeingResized(true);
            }}
            onResizeStop={(e) => {
              controller.setInsightBeingResized(false);
              controller.updateLayoutComp(e);
            }}
          >
            {controller.insightDefinitions.map((item) => {
              return (
                <div key={item.i}>
                  <InsightContainer2
                    insight={item}
                    resizing={controller.insightBeingResized}
                  />
                </div>
              );
            })}
          </ResponsiveGridLayout>
        </Flex>
      </Flex>
    </Flex>
  );
};
