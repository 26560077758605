import { useRef, useState } from "react";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Box,
  Text,
  VStack,
  Divider,
  HStack,
  Portal,
  PopoverArrow,
  Avatar,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import React from "react";
import { useAtomValue } from "jotai";
import { AccountAtom, SidebarStateAtom } from "../../store";
import useAccountController from "./useAccountController";
import { IoRadioButtonOff, IoRadioButtonOn } from "react-icons/io5";
import { MangageAccounts } from "../ManageAccounts/ManageAccounts";
import { Show } from "../misc";

export default function AccountSelector({}: any) {
  const controller = useAccountController();
  const isCollapsed = useAtomValue(SidebarStateAtom);

  const accountState = useAtomValue(AccountAtom);
  const [open, setOpen] = useState(false);

  const handleAccountSelection = (account) => {
    controller.setSelectedAccount(account);
  };

  const initialFocusRef = useRef();

  return (
    <Popover
      isOpen={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      initialFocusRef={initialFocusRef}
      placement="right"
      closeOnEsc={true}
      closeOnBlur={true}
      arrowSize={15}
      arrowPadding={10}
    >
      <PopoverTrigger>
        <Button
          mt={2}
          className="tutorial-account-manager"
          bg={"var(--dark-gray)"}
          aria-haspopup="listbox"
          aria-expanded={open}
          rightIcon={
            <VStack>
              {isCollapsed ? null : (
                <>
                  <ChevronUpIcon />
                  <ChevronDownIcon />
                </>
              )}
            </VStack>
          }
          width="240px"
          justifyContent="space-between"
          mt="2px"
          height="60px"
          px="8px"
        >
          <HStack>
            <Box
              bg="var(--gray)"
              borderRadius={"6px"}
              minWidth="46px"
              height="46px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontSize="2xl">
                {accountState?.selectedAccount?.name[0].toUpperCase()}
              </Text>
            </Box>
            {isCollapsed ? null : (
              <VStack w="full" alignItems={"flex-start"} pl="4px">
                <Text fontSize="xs" fontWeight={400}>
                  Selected Portfolio
                </Text>
                <Text
                  maxW="140px"
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                >
                  {accountState?.selectedAccount?.name}
                </Text>
              </VStack>
            )}
          </HStack>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent bg="var(--bg)" width="300px">
          <PopoverArrow bg="var(--bg)" />
          <VStack align="stretch" p={4} spacing={0}>
            <Box>
              {accountState.accounts.map((account, key) => (
                <>
                  <Button
                    p="0"
                    m="0"
                    pl="2"
                    pr="2"
                    key={key}
                    variant="ghost"
                    justifyContent="space-between"
                    onClick={() => handleAccountSelection(account)}
                    width="full"
                    mb="2"
                    bg={"var(--bg)"}
                    overflow={"hidden"}
                  >
                    <HStack>
                      <Avatar
                        bg={"var(--gray)"}
                        name={account.name}
                        size={"sm"}
                        borderRadius={6}
                        color={"var(--white)"}
                      />
                      <Text
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        maxW="180px"
                      >
                        {account.name}
                      </Text>
                    </HStack>

                    <Show if={account.id === accountState.selectedAccount.id}>
                      <IoRadioButtonOn color="var(--white)" />
                    </Show>
                    <Show if={account.id !== accountState.selectedAccount.id}>
                      <IoRadioButtonOff color="var(--white)" />
                    </Show>
                  </Button>
                  <Divider mb="2" />
                </>
              ))}
            </Box>

            <MangageAccounts />
          </VStack>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
