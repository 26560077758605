import {
  Box,
  Heading,
  ListItem,
  OrderedList,
  Image,
  Stack,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { Panel } from "../../../../../components/design_library";
import { useAtomValue } from "jotai";
import {
  AccountAtom,
  AppStateAtom,
  TransactionsAtom,
} from "../../../../../store";

export const SchwabInstructions = () => {
  const transactionsState = useAtomValue(TransactionsAtom);
  return (
    <Panel w="full">
      <Heading fontSize={"16px"}>Upload Instructions</Heading>

      <RegularUploads />
    </Panel>
  );
};

const RegularUploads = () => {
  return (
    <Stack spacing="5">
      <OrderedList>
        <ListItem>
          Log into the Schwab website, go to the 'Accounts' page and 'History'
          menu item
        </ListItem>
        <ListItem>Click the Export button on the right</ListItem>
        <Box my={4} boxSize="full">
          <Image
            src="https://cdn.wealthbee.io/main/brokers/instructions/schwab-import.png"
            style={{ maxWidth: "600px" }}
            alt="Schwab Upload page"
          />
        </Box>
        <ListItem>Click OK</ListItem>
        <ListItem>Upload the file!</ListItem>
        <Image
          my={4}
          src="https://cdn.wealthbee.io/main/brokers/instructions/schwab.png"
          style={{ maxWidth: "600px" }}
          alt="Schwab CSV"
        />
        <ListItem>
          Once you've done this, come back here to set up automatic updates by
          clicking on "Automatic Updates" above.
        </ListItem>
      </OrderedList>
    </Stack>
  );
};

export const SchwabEmailForTradeConfirmations = () => {
  const accountState = useAtomValue(AccountAtom);
  const appState = useAtomValue(AppStateAtom);
  const accountId = accountState?.selectedAccount?.id;
  const userId = appState.user.id;
  const broker = "SCHWAB";
  const email = `ccf73397122224a7221c+${userId}+${accountId}+${broker}@cloudmailin.net`;

  return (
    <Box>
      <Heading size="md">Trade Confirmation Emails</Heading>
      <Text mt="2">
        It's possible to update your account automatically using Schwabs trade
        confirmations emails (called eConfirms). Please note that these take up
        to 24 hours to arrive and are only delivered on trading days.
      </Text>

      <OrderedList mt="4">
        <ListItem>Copy this email address</ListItem>

        <InputGroup size="md" mt="4">
          <Input pr="4.5rem" type={"text"} value={email} />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(email);
              }}
            >
              Copy
            </Button>
          </InputRightElement>
        </InputGroup>

        <ListItem mt="2">
          Forward your trade confirmations to this address to automatically
          import new trades. (You can test it now, it takes about 5 minutes to
          process)
        </ListItem>

        <ListItem mt="2">
          You can automate this by setting up rules in your email client. Here
          is a guide{" "}
          <a
            style={{ color: "var(--accent)" }}
            href="https://clean.email/blog/email-management/automatic-email-forwarding"
          >
            to set up forwarding.
          </a>
        </ListItem>
      </OrderedList>
    </Box>
  );
};
