import { Box, HStack, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaBookOpen } from "react-icons/fa";
import NavWrapper from "./NavWrapper";
import { useTour } from "@reactour/tour";
import React from "react";

export default function BookExplainerTutorial() {
  return (
    <NavWrapper previousEnabled={false} isFinalStep={false} nextDisabled={true}>
      <HStack>
        <FaBookOpen style={{ marginLeft: "10px" }} />

        <Heading
          as="h2"
          fontSize="18px"
          mb={4}
          fontWeight={600}
          textAlign={"left"}
        >
          Books
        </Heading>
      </HStack>
      <Text mt="10px">
        We group your trades into a heirarchy of accounts, books, and positions.
        You can expand the book to see the positions that make up the account.{" "}
      </Text>{" "}
      <Text mt="10px">
        You can also see the total P&L and other aggregated metrics for the book
        to the right.
      </Text>
      <Text mt="10px">
        After the tutorial if you want to rename your book or add a new book,
        you can do so by right-clicking on any group and selecting "Allocate to
        new book".
      </Text>
      <Text mt="10px">
        Examples of how this can be used it to group your trades by account, by
        individual, or by strategy. For example "Schwab Account", "Family Member
        1", "Long Term Investments".
      </Text>
      <Text mt="10px" fontWeight={600}>
        To expand a group
      </Text>
      <VStack alignItems={"flex-start"}>
        <HStack>
          <Text>Mobile:</Text>
          <span className="rainbow">
            Tap the 'right arrow' (Chevron) next to the group{" "}
          </span>
        </HStack>
        <HStack>
          <Text>Desktop:</Text>
          <span className="rainbow">
            Double-click the book name ('Unbooked'){" "}
          </span>
        </HStack>
      </VStack>
      <Box w="100%" maxH="200px" overflow={"hidden"}>
        <Image
          minW={"100%"}
          mt="10px"
          src="https://cdn.wealthbee.io/main/app/tutorial/books/books.gif"
          alt="Account Manager"
        />
      </Box>
    </NavWrapper>
  );
}
