// navConfig.js
import {
  RiAdminLine,
  RiDashboardFill,
  RiRoadMapFill,
  RiExchangeBoxFill,
  RiCashFill,
  RiCalendar2Fill,
  RiPriceTag2Fill,
  RiSettings2Fill,
  RiBook2Fill,
  RiDiscordFill,
} from "react-icons/ri";

export const navigationGroups = (isUserAdmin, isCollapsed) => [
  {
    label: "",
    items: [
      {
        path: "/app/dashboard",
        icon: RiDashboardFill,
        label: "Dashboard",
      },
      {
        path: "/app/positions",
        icon: RiRoadMapFill,
        label: "Positions",
      },
      {
        path: "/app/transactions",
        icon: RiExchangeBoxFill,
        label: "Transactions",
      },
      {
        path: "/app/cashflows",
        icon: RiCashFill,
        label: "Cashflows",
      },
      {
        path: "/app/calendar",
        icon: RiCalendar2Fill,
        label: "Calendar",
      },
      {
        path: "/app/prices",
        icon: RiPriceTag2Fill,
        label: "Prices",
      },
    ],
  },
  {
    label: "Configuration",
    items: [
      {
        path: "/app/settings",
        icon: RiSettings2Fill,
        label: "Settings",
      },
      ...(isUserAdmin
        ? [
            {
              path: "/app/admin",
              icon: RiAdminLine,
              label: "Admin",
            },
          ]
        : []),
    ],
  },
  {
    label: "Help & Support",
    items: [
      {
        external: true,
        url: "https://wealthbee.io/knowledgebase",
        icon: RiBook2Fill,
        label: "User Guide",
        isExternal: true,
      },
      {
        external: true,
        url: "https://discord.gg/5N9rJHhAbS",
        icon: RiDiscordFill,
        label: "Discord",
        isExternal: true,
      },
    ],
  },
];
