import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { findDOMNode } from "react-dom";

import {
  Box,
  HStack,
  Text,
  Tooltip,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { CalendarIcon, NoteIcon } from "../../components/design_library/Icons";
import Selection, { getBoundsForNode, isEvent } from "./Selection";
import { notify } from "./utils/helpers";
import { dateCellSelection, getSlotAtX, pointInBox } from "./utils/selection";
import currencyFormatter from "currency-formatter";
import {
  IoAlertCircleOutline,
  IoCalendar,
  IoCash,
  IoDocumentText,
} from "react-icons/io5";
import { shortenNumber } from "../../utils/NumberUtils";
import convertSymbolToReadable from "../../components/grid/utils/convertSymbolToReadable";

export const BackgroundCells = (props) => {
  const { colorMode } = useColorMode();
  return <Child color={colorMode} {...props} />;
};

class Child extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selecting: false,
    };
  }

  componentDidMount() {
    this.props.selectable && this._selectable();
  }

  componentWillUnmount() {
    this._teardownSelectable();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectable && !this.props.selectable) this._selectable();

    if (!nextProps.selectable && this.props.selectable)
      this._teardownSelectable();
  }

  render() {
    let {
      range,
      getNow,
      getters,
      date: currentDate,
      components: { dateCellWrapper: Wrapper },
      localizer,
    } = this.props;
    let { selecting, startIdx, endIdx } = this.state;
    let current = getNow();

    return (
      <>
        <div className="rbc-row-bg">
          {[...range, ""].map((date, index) => {
            let selected = selecting && index >= startIdx && index <= endIdx;
            const { className, style } = getters.dayProp(date);

            let pandl = undefined;
            let found = false;

            let matchDate = moment(date).format("DD MMM YYYY");
            Object.keys(this.props.dailyPandL || []).map((key) => {
              if (key === "Invalid date") {
                return;
              }
              if (key === matchDate) {
                pandl =
                  this.props.dailyPandL[key].profit.total -
                  this.props.dailyPandL[key].profit.unrealised;
                found = true;
              }
            });

            let notes = 0;
            let trades = 0;
            let openTrades = 0;
            let expiry = 0;
            let closedTrades = 0;
            let notesToReview = 0;
            let overdue = 0;
            let actualNotes = [];

            const noteForThisDate =
              this?.props?.notes?.filter((note) => {
                return (
                  moment(note.calendarDateReference).format("DD MMM YYYY") ===
                    matchDate && note.content.length > 0
                );
              }) || [];

            const earningsForThisDate =
              (this?.props?.earnings || []).filter((value) => {
                return (
                  moment.utc(value.date).format("DD MMM YYYY") ===
                  moment.utc(matchDate).format("DD MMM YYYY")
                );
              }) || [];

            const uniqueEarningsForThisDate = earningsForThisDate.filter(
              (value, index, self) =>
                index ===
                self.findIndex((t) => t.instrumentId === value.instrumentId)
            );

            const expiryForThisDate =
              (this?.props?.expiries || []).filter((value) => {
                return moment(value.expiry).format("DD MMM YYYY") === matchDate;
              }) || [];

            const pnlForThisDate =
              (this?.props?.pnlByDate || []).filter((value) => {
                return (
                  moment(value.domain, "YYYY-MM-DD").format("DD MMM YYYY") ===
                  matchDate
                );
              })[0]?.realisedPnl || 0;

            const costBasisForThisDate =
              (this?.props?.pnlByDate || []).filter((value) => {
                return (
                  moment(value.domain, "YYYY-MM-DD").format("DD MMM YYYY") ===
                  matchDate
                );
              })[0]?.costBasis || 0;

            let returnForThisDate =
              (this?.props?.pnlByDate || []).filter((value) => {
                return (
                  moment(value.domain, "YYYY-MM-DD").format("DD MMM YYYY") ===
                  matchDate
                );
              })[0]?.returnAsPerc || 0;
            if (pnlForThisDate < 0) {
              returnForThisDate = returnForThisDate * -1;
            }

            const numberOfTradesOnThisDay = (this.props.trades || []).filter(
              (trade) => {
                return (
                  moment(trade.tradeDate).format("DD MMM YYYY") === matchDate
                );
              }
            );

            const numberOfTradesThisWeek = (this.props.trades || []).filter(
              (trade) => {
                const d = moment(trade.tradeDate, "YYYY-MM-DD");
                return d.isSame(range[index - 1], "week");
              }
            );

            const numberOfTradingDaysThisWeek = (this.props.pnlByDate || [])

              .filter((value) => {
                const d = moment(value.domain, "YYYY-MM-DD");
                return d.isSame(range[index - 1], "week");
              })
              .map((value) => {
                return value.domain;
              });

            const totalForThisWeek = (this.props.pnlByDate || [])
              .filter((value) => {
                const d = moment(value.domain, "YYYY-MM-DD");
                return d.isSame(range[index - 1], "week");
              })
              .reduce((acc, value) => {
                return acc + parseFloat(value.realisedPnl);
              }, 0);

            const tradesForThisDay = (this.props.trades || []).filter(
              (trade) => {
                return (
                  moment(trade.tradeDate).format("DD MMM YYYY") === matchDate
                );
              }
            );

            const isWeekend =
              moment(date).isoWeekday() === 6 ||
              moment(date).isoWeekday() === 7;

            return (
              <Wrapper key={index} value={date} range={range}>
                <Tooltip
                  isDisabled={
                    expiryForThisDate.length === 0 &&
                    noteForThisDate.length === 0 &&
                    earningsForThisDate.length === 0
                  }
                  label={
                    <>
                      {expiryForThisDate.length > 0 && (
                        <>
                          <Text fontSize="12px">Expiries:</Text>
                          <Box maxHeight={"260px"} overflow={"auto"}>
                            {expiryForThisDate.map((expiry) => {
                              return (
                                <HStack>
                                  <IoCalendar pr="2px" color="var(--black)" />
                                  <Text className="">
                                    {expiry?.symbol &&
                                      convertSymbolToReadable(expiry?.symbol)}
                                  </Text>
                                </HStack>
                              );
                            })}
                          </Box>
                        </>
                      )}
                      {noteForThisDate.length > 0 ? (
                        <>
                          <Text fontSize="12px">Notes:</Text>
                          <Box
                            maxHeight={"260px"}
                            overflow={"hidden"}
                            dangerouslySetInnerHTML={{
                              __html: noteForThisDate[0]?.content,
                            }}
                          />
                        </>
                      ) : undefined}{" "}
                      {earningsForThisDate.length > 0 && (
                        <>
                          <Text fontSize="12px">Earnings:</Text>
                          <Box maxHeight={"260px"} overflow={"auto"}>
                            {uniqueEarningsForThisDate.map((earning) => {
                              if (!earning?.instrument) return null;
                              if (!earning?.instrument?.symbol) return null;
                              return (
                                <HStack>
                                  <IoCash pr="2px" color="var(--black)" />
                                  <Text className="">
                                    {earning?.instrument?.symbol &&
                                      convertSymbolToReadable(
                                        earning?.instrument?.symbol
                                      )}{" "}
                                    -{" "}
                                    {earning.beforeAfterMarket ===
                                    "BeforeMarket"
                                      ? "Before Market"
                                      : "After Market"}
                                  </Text>
                                </HStack>
                              );
                            })}
                          </Box>
                        </>
                      )}
                    </>
                  }
                  hasArrow
                >
                  <div
                    style={{
                      ...style,
                      backgroundColor: isWeekend
                        ? "var(--dark-gray)"
                        : pnlForThisDate === undefined
                          ? ""
                          : pnlForThisDate > 0.1
                            ? "var(--dark-green)"
                            : pnlForThisDate < -0.1 && "var(--dark-red)",

                      border:
                        pnlForThisDate === undefined
                          ? ""
                          : pnlForThisDate > 0.1
                            ? "1px solid var(--dark-green)"
                            : pnlForThisDate < -0.1 &&
                              "1px solid var(--dark-red)",
                    }}
                    onClick={() => {
                      if (date === "") return;
                      this.props.setDay(date, pandl);
                    }}
                    className={clsx(
                      date === "" && "rbc-weekly-pnl",
                      "rbc-day-bg",
                      "cursor-pointer",
                      this.props.color === "light" && "rbc-day-light",
                      "rbc-day-dark",
                      className,
                      selected && "rbc-selected-cell",
                      localizer.isSameDate(date, current) && "rbc-today",
                      localizer.isSameDate(date, current) &&
                        this.props.color === "dark" &&
                        "rbc-today-dark", // WHY
                      localizer.isSameDate(date, current) &&
                        this.props.color === "light" &&
                        "rbc-today-light", // WHY
                      currentDate &&
                        localizer.neq(currentDate, date, "month") &&
                        this.props.color === "light" &&
                        "rbc-off-range-bg-light",
                      localizer.neq(currentDate, date, "month") &&
                        this.props.color === "dark" &&
                        "rbc-off-range-bg-dark",
                      pandl !== undefined &&
                        found === true &&
                        this.props.color === "dark" &&
                        "rbc-pandl-zero-dark",
                      pandl !== undefined &&
                        found === true &&
                        this.props.color === "light" &&
                        "rbc-pandl-zero-light",
                      pandl > 0 && found === true && "rbc-pandl-profit-dark",
                      pandl < 0 && found === true && "rbc-pandl-loss-dark",

                      "center-me"
                    )}
                    pandl={pandl}
                  >
                    <VStack
                      className="vstackjournal"
                      fontSize="xs"
                      spacing={0}
                      lineHeight={1.2}
                      position={"absolute"}
                      mt="5"
                    >
                      {pnlForThisDate && (
                        <Text
                          className={"rbc-text-dark"}
                          alignItems="center"
                          fontWeight={600}
                          style={{
                            color:
                              pnlForThisDate > 0
                                ? "var(--green)"
                                : "var(--red)",
                            fontSize: "1.1rem",
                          }}
                        >
                          {shortenNumber(pnlForThisDate, 2, "$")}
                        </Text>
                      )}
                      {date === "" && (
                        <VStack>
                          <Text
                            className={"rbc-text-dark"}
                            alignItems="center"
                            fontWeight={600}
                            style={{
                              color:
                                totalForThisWeek > 0
                                  ? "var(--green)"
                                  : "var(--red)",
                              fontSize: "1.1rem",
                            }}
                          >
                            {shortenNumber(totalForThisWeek, 2, "$")}
                            {numberOfTradesThisWeek.length > 0 && (
                              <Text
                                className=""
                                fontSize="12px"
                                color="var(--white)"
                                fontWeight={400}
                              >
                                {numberOfTradesThisWeek.length}{" "}
                                {numberOfTradesThisWeek.length === 1
                                  ? "TRADE"
                                  : "TRADES"}
                              </Text>
                            )}

                            {numberOfTradingDaysThisWeek.length > 0 && (
                              <Text
                                className=""
                                fontSize="12px"
                                color="var(--white)"
                                fontWeight={400}
                              >
                                {numberOfTradingDaysThisWeek.length}{" "}
                                {numberOfTradingDaysThisWeek.length === 1
                                  ? "DAY"
                                  : "DAYS"}
                              </Text>
                            )}
                            {numberOfTradingDaysThisWeek.length == 0 && (
                              <Text
                                className=""
                                fontSize="10px"
                                color="var(--white)"
                                fontWeight={400}
                              >
                                NO TRADES
                              </Text>
                            )}
                          </Text>
                        </VStack>
                      )}
                      {numberOfTradesOnThisDay.length > 0 && (
                        <HStack>
                          <Text className="">{`${
                            numberOfTradesOnThisDay.length
                          } ${
                            numberOfTradesOnThisDay.length === 1
                              ? "TRADE"
                              : "TRADES"
                          }`}</Text>
                        </HStack>
                      )}
                      {returnForThisDate !== 0 && (
                        <Text
                          className={"rbc-text-dark"}
                          alignItems="center"
                          style={{
                            color:
                              returnForThisDate > 0
                                ? "var(--green)"
                                : "var(--red)",
                            fontSize: ".8rem",
                          }}
                        >
                          {returnForThisDate.toFixed(2)}%
                        </Text>
                      )}

                      {earningsForThisDate.length > 0 && (
                        <HStack>
                          <IoAlertCircleOutline pr="2px" color="var(--red)" />
                          <Text className="">{`${uniqueEarningsForThisDate.length} ${
                            uniqueEarningsForThisDate.length === 1
                              ? "EARNINGS"
                              : "EARNINGS"
                          }`}</Text>
                        </HStack>
                      )}

                      {/* {costBasisForThisDate !== 0 && (
                    <Text
                      className={"rbc-text-dark"}
                      alignItems="center"
                      style={{
                        color:
                          costBasisForThisDate > 0
                            ? "var(--green)"
                            : "var(--red)",
                        fontSize: ".8rem",
                      }}
                    >
                      {currencyFormatter.format(costBasisForThisDate, {
                        code: "USD",
                      })}
                    </Text>
                  )} */}
                      {noteForThisDate.length > 0 && (
                        <HStack>
                          <IoDocumentText pr="2px" color="white" />
                          <Text className="">{`${noteForThisDate.length} NOTE`}</Text>
                        </HStack>
                      )}
                      {expiryForThisDate.length > 0 && (
                        <HStack>
                          <IoCalendar pr="2px" color="white" />
                          <Text className="">{`${expiryForThisDate.length} ${
                            expiryForThisDate.length === 1
                              ? "EXPIRY"
                              : "EXPIRIES"
                          }`}</Text>
                        </HStack>
                      )}
                    </VStack>
                    {notes !== 0 && actualNotes[0].length > 0 && (
                      <div className="noteIconContainer">
                        <NoteIcon className="noteIconJournal" />
                      </div>
                    )}
                  </div>
                </Tooltip>
              </Wrapper>
            );
          })}
        </div>
      </>
    );
  }

  _selectable() {
    let node = findDOMNode(this);
    let selector = (this._selector = new Selection(this.props.container, {
      longPressThreshold: this.props.longPressThreshold,
    }));

    let selectorClicksHandler = (point, actionType) => {
      if (!isEvent(findDOMNode(this), point)) {
        let rowBox = getBoundsForNode(node);
        let { range, rtl } = this.props;

        if (pointInBox(rowBox, point)) {
          let currentCell = getSlotAtX(rowBox, point.x, rtl, range.length);

          this._selectSlot({
            startIdx: currentCell,
            endIdx: currentCell,
            action: actionType,
            box: point,
          });
        }
      }

      this._initial = {};
      this.setState({ selecting: false });
    };

    selector.on("selecting", (box) => {
      let { range, rtl } = this.props;

      let startIdx = -1;
      let endIdx = -1;

      if (!this.state.selecting) {
        notify(this.props.onSelectStart, [box]);
        this._initial = { x: box.x, y: box.y };
      }
      if (selector.isSelected(node)) {
        let nodeBox = getBoundsForNode(node);
        ({ startIdx, endIdx } = dateCellSelection(
          this._initial,
          nodeBox,
          box,
          range.length,
          rtl
        ));
      }

      this.setState({
        selecting: true,
        startIdx,
        endIdx,
      });
    });

    selector.on("beforeSelect", (box) => {
      if (this.props.selectable !== "ignoreEvents") return;

      return !isEvent(findDOMNode(this), box);
    });

    selector.on("click", (point) => selectorClicksHandler(point, "click"));

    selector.on("doubleClick", (point) =>
      selectorClicksHandler(point, "doubleClick")
    );

    selector.on("select", (bounds) => {
      this._selectSlot({ ...this.state, action: "select", bounds });
      this._initial = {};
      this.setState({ selecting: false });
      notify(this.props.onSelectEnd, [this.state]);
    });
  }

  _teardownSelectable() {
    if (!this._selector) return;
    this._selector.teardown();
    this._selector = null;
  }

  _selectSlot({ endIdx, startIdx, action, bounds, box }) {
    if (endIdx !== -1 && startIdx !== -1)
      this.props.onSelectSlot &&
        this.props.onSelectSlot({
          start: startIdx,
          end: endIdx,
          action,
          bounds,
          box,
          resourceId: this.props.resourceId,
        });
  }
}

BackgroundCells.propTypes = {
  date: PropTypes.instanceOf(Date),
  getNow: PropTypes.func.isRequired,

  getters: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,

  container: PropTypes.func,
  dayPropGetter: PropTypes.func,
  selectable: PropTypes.oneOf([true, false, "ignoreEvents"]),
  longPressThreshold: PropTypes.number,

  onSelectSlot: PropTypes.func.isRequired,
  onSelectEnd: PropTypes.func,
  onSelectStart: PropTypes.func,

  range: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  rtl: PropTypes.bool,
  type: PropTypes.string,
  resourceId: PropTypes.any,

  localizer: PropTypes.any,
};

export default BackgroundCells;
