import { useState } from "react";
import { useAtomValue } from "jotai";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  CustomInputGroup,
  PrimaryButton,
  TextInput,
} from "../../../components/design_library";
import {
  HeaderContainer,
  InnerContainer,
  Meta,
  OuterContainer,
} from "../../../components";
import { useResetPasswordController } from "./useResetPasswordController";
import { useNavigate } from "react-router";
import { AppStateAtom } from "../../../store";
import { AuthenticationFrame } from "../components/AuthenticationFrame";

/* eslint-disable @typescript-eslint/no-unused-vars */
export const ResetPassword = (props: any) => {
  const appState = useAtomValue(AppStateAtom);
  const navigate = useNavigate();
  const controller = useResetPasswordController();

  return (
    <AuthenticationFrame>
      <Meta
        title={"Reset password"}
        description={"Reset your password for WealthBee"}
        canonical={"https://platform.wealthbee.io/app/reset"}
      />
      <HeaderContainer>
        <Heading mb="4">Reset password</Heading>
      </HeaderContainer>
      <InnerContainer>
        <FormControl>
          <Stack spacing={5}>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <CustomInputGroup>
                <TextInput
                  bg={"var(--bg)"}
                  type={"text"}
                  placeholder="Enter email"
                  disabled={controller.loading}
                  onChange={(e: any) => {
                    controller.setEmail(e.target.value);
                  }}
                  autoComplete="email"
                />
              </CustomInputGroup>
            </FormControl>

            <PrimaryButton
              isLoading={controller.loading}
              colorScheme="blue"
              variant="solid"
              onClick={() => controller.reset()}
              disabled={controller.loading}
              isFullWidth
              loadingText="Resetting..."
              color="var(--white)"
            >
              Reset password
            </PrimaryButton>

            <Text mt="4" align="center" maxW="md" fontWeight="medium">
              <Box
                as="a"
                href="#"
                onClick={() => {
                  navigate("/login");
                }}
                color={"var(--accent)"}
                textDecor={"underline"}
              >
                Go back
              </Box>
            </Text>
          </Stack>
        </FormControl>
      </InnerContainer>
    </AuthenticationFrame>
  );
};
