import { useAtom, useAtomValue } from "jotai";
import { AccountAtom } from "../../../store";
import { useNotifications } from "../../../hooks/useNotifications";
import { InsightTemplate } from "../../../types/Insight";
import {
  DashboardLayoutStateStore,
  SelectedDashboardIdAtom,
} from "../../../store/DashboardLayout";
import { useGridLayoutController } from "../canvas/useGridLayoutController";
import { updateLayout } from "../../../api";
import { useAccount } from "../../../hooks/useAccount";

export interface UseLayout {
  removeInsightFromLayouts: (insight: InsightTemplate) => void;
  addInsightToLayouts: (insight: InsightTemplate) => void;
  setSelectedConfiguration: (configurationId: number) => void;
  addAndRemoveMultipleInsights: (
    insightsToAdd: InsightTemplate[],
    insightsToRemove: InsightTemplate[]
  ) => void;
}

interface LayoutItem {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW?: number;
  maxW?: number;
  minH?: number;
  maxH?: number;
  data?: InsightTemplate;
}

export const useLayout = (): UseLayout => {
  const [dashboardLayoutState, setDashboardLayoutState] = useAtom(
    DashboardLayoutStateStore
  );
  const selectedDashboardId = useAtomValue(SelectedDashboardIdAtom);

  const notifications = useNotifications();
  const [accountState, setAccountState] = useAtom(AccountAtom);
  const gridLayout = useGridLayoutController();
  const account = useAccount();

  const setSelectedConfiguration = async (configurationId: number) => {
    setDashboardLayoutState({
      ...dashboardLayoutState,
      selectedConfiguration: configurationId as any,
    });
    account.setDefaultDashboardConfiguration(configurationId);
  };

  const updateLayouts = async (newLayoutData: any, message: string) => {
    const update = await updateLayout(
      selectedDashboardId ??
        accountState.selectedAccount.defaultDashboardConfigurationId,
      {
        layout: newLayoutData,
      }
    );

    setDashboardLayoutState({
      ...dashboardLayoutState,
      layouts: newLayoutData,
      enabledInsights: update.enabledInsights,
    });
  };

  const removeInsightFromLayouts = async (insight: InsightTemplate) => {
    setDashboardLayoutState((prevStat) => {
      return {
        ...prevStat,
        gridLoading: true,
      };
    });

    const newLayoutData: { [key: string]: LayoutItem[] } = {
      ...dashboardLayoutState.layouts,
    };

    dashboardLayoutState.breakpoints.forEach((breakpoint) => {
      newLayoutData[breakpoint] = newLayoutData[breakpoint].filter(
        (item: LayoutItem) =>
          parseInt(item.i) !== parseInt(insight.insightId.toString())
      );
    });

    await updateLayouts(newLayoutData, "Removed");
  };

  const addInsightToLayouts = async (insight: InsightTemplate) => {
    setDashboardLayoutState((prevStat) => {
      return {
        ...prevStat,
        gridLoading: true,
      };
    });
    const newLayoutData: { [key: string]: LayoutItem[] } = {
      ...dashboardLayoutState.layouts,
    };

    dashboardLayoutState.breakpoints.forEach((breakpoint) => {
      //Need to add check here for type...
      const layoutItem: LayoutItem = {
        i: insight.insightId.toString(),
        x: 0,
        y: 0,
        w: insight.width || 1,
        h: insight.height || 1,
        data: insight,
      };

      newLayoutData[breakpoint].push(layoutItem);
    });

    await updateLayouts(newLayoutData, "Added");

    gridLayout.fetchLayouts();
  };

  const addAndRemoveMultipleInsights = async (
    insightsToAdd: InsightTemplate[],
    insightsToRemove: InsightTemplate[]
  ) => {
    setDashboardLayoutState((prevStat) => {
      return {
        ...prevStat,
        gridLoading: true,
      };
    });

    const newLayoutData: { [key: string]: LayoutItem[] } = {
      ...dashboardLayoutState.layouts,
    };

    insightsToRemove.forEach((insight) => {
      dashboardLayoutState.breakpoints.forEach((breakpoint) => {
        newLayoutData[breakpoint] = newLayoutData[breakpoint].filter(
          (item: LayoutItem) =>
            parseInt(item.i) !== parseInt(insight.insightId.toString())
        );
      });
    });

    insightsToAdd.forEach((insight) => {
      dashboardLayoutState.breakpoints.forEach((breakpoint) => {
        const layoutItem: LayoutItem = {
          i: insight.insightId.toString(),
          x: 0,
          y: 0,
          w: insight.width || 1,
          h: insight.height || 1,
          data: insight,
        };

        newLayoutData[breakpoint].push(layoutItem);
      });
    });

    await updateLayouts(newLayoutData, "Added");
  };

  return {
    removeInsightFromLayouts,
    addInsightToLayouts,
    setSelectedConfiguration,
    addAndRemoveMultipleInsights,
  };
};
