import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ColumnHeader,
  ColumnHeading,
} from "../knowledgebase/components/Column";
import { Main } from "../knowledgebase/components/Main";
import { Sidebar } from "../knowledgebase/components/Sidebar";
import { SecondaryButton } from "../../../components/design_library";
import { NegativeButton } from "../../../components/design_library/NegativeButton";
import moment from "moment";
import { CreatableSelect, Select } from "chakra-react-select";
import "../../../components/design_library/WYSIWIGEditorStyling.css";
import { useBlogAdminController } from "./useBlogAdminController";
import { KnowledgeBaseArticleStatus } from "../../../types/KnowledgeBaseArticle";
import { Spinner } from "../../../components";
import { BlogArticleStatus } from "../../../types/BlogArticle";
import { FileUploader } from "react-drag-drop-files";
import {
  generateArticleForBlog,
  generateImageForBlogArticle,
  uploadImage,
} from "../../../api";

export default function BlogAdmin() {
  const controller = useBlogAdminController();

  const [file, setFile] = useState(null);
  const [imageGenLoading, setImageGenLoading] = useState(false);
  const [rewriteLoading, setRewriteLoading] = useState(false);
  const [url, setUrl] = useState("");
  const handleChange = async (file: any) => {
    setFile(file);
    const result = await uploadImage(file);
    setUrl(result.directory);

    controller.setArticle({
      ...controller.selectedArticle,
      image: result.directory,
    });
  };

  return (
    <Flex height="85vh" bg="var(--bg)">
      <Box
        borderRightWidth="1px"
        display={{ base: "none", md: "initial" }}
        overflowY="auto"
        onScroll={(x) =>
          controller.setSidebarIsScrolled(x.currentTarget.scrollTop > 32)
        }
      >
        <ColumnHeader shadow={controller.sidebarIsScrolled ? "base" : "none"}>
          <HStack spacing="3" justifyContent={"space-between"} w="full">
            <ColumnHeading>Blogs</ColumnHeading>
            <SecondaryButton
              onClick={() => {
                controller.createArticle();
              }}
              size="xs"
            >
              Add article
            </SecondaryButton>
          </HStack>
        </ColumnHeader>
        <Sidebar
          articles={controller.articles}
          setSelectedArticle={controller.setSelectedArticle}
          categories={controller.categories}
          selectedArticle={controller.selectedArticle}
        />
      </Box>
      <Box
        flex="1"
        overflowY="auto"
        onScroll={(x) =>
          controller.setmMainIsScrolled(x.currentTarget.scrollTop > 32)
        }
      >
        <ColumnHeader
          shadow={controller.mainIsScrolled ? "base" : "none"}
          display="flex"
          justifyContent={"space-between"}
        >
          {controller.selectedArticle && (
            <>
              <HStack>
                <Box>
                  <CreatableSelect
                    tagVariant="solid"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={{
                      value: controller.selectedArticle?.category,
                      label: controller.selectedArticle?.category,
                    }}
                    placeholder="Category"
                    isMulti={false}
                    options={controller.categories.map((c) => ({
                      value: c,
                      label: c,
                    }))}
                    onChange={(e) => {
                      controller.setArticle({
                        ...controller.selectedArticle,
                        category: e.value,
                      });
                    }}
                  />
                </Box>

                {controller.selectedArticle &&
                  controller.selectedArticle.status ===
                    KnowledgeBaseArticleStatus.Published &&
                  controller.selectedArticle?.slug && (
                    <Link
                      px="4"
                      target="_blank"
                      href={`../../blog/${controller.selectedArticle?.slug}`}
                      fontSize="14px"
                    >
                      URL:{" "}
                      <span style={{ color: "var(--chart-blue)" }}>
                        {controller.selectedArticle?.slug}
                      </span>
                    </Link>
                  )}

                {controller.selectedArticle &&
                  controller.selectedArticle?.title && (
                    <Text px="4" fontSize="14px">
                      Last updated:{" "}
                      {moment(
                        controller.selectedArticle?.dateUpdated
                      ).calendar()}
                    </Text>
                  )}
                {controller.selectedArticle &&
                  controller.selectedArticle?.title && (
                    <Button
                      isLoading={rewriteLoading}
                      onClick={async () => {
                        setRewriteLoading(true);

                        const result = await generateArticleForBlog(
                          controller.selectedArticle?.content
                        );

                        controller.setArticle({
                          ...controller.selectedArticle,
                          content: result.content,
                          title: result.title,
                          excerpt: result.excerpt,
                        });

                        setRewriteLoading(false);
                      }}
                    >
                      Re-write article using AI
                    </Button>
                  )}
              </HStack>
              <HStack>
                <Box>
                  <Select
                    tagVariant="solid"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={{
                      value: controller.selectedArticle?.status,
                      label: controller.selectedArticle?.status,
                    }}
                    placeholder="Status"
                    isMulti={false}
                    options={Object.values(BlogArticleStatus).map((c) => ({
                      value: c,
                      label: c,
                    }))}
                    onChange={(e) => {
                      controller.setArticle({
                        ...controller.selectedArticle,
                        status: e.value,
                      });
                    }}
                  />
                </Box>

                <SecondaryButton
                  onClick={() => {
                    controller.saveArticle(controller.selectedArticle);
                  }}
                  size="sm"
                  mr="4"
                  px="4"
                  bg={"var(--chart-blue)"}
                  isDisabled={controller.saveLoading}
                >
                  {controller.saveLoading ? <Spinner size="xs" /> : "Save"}
                </SecondaryButton>

                <NegativeButton
                  size="sm"
                  px="4"
                  bg={"var(--red)"}
                  onClick={() => {
                    controller.deleteArticle(
                      controller.selectedArticle.articleId
                    );
                  }}
                >
                  Delete
                </NegativeButton>
              </HStack>
            </>
          )}
        </ColumnHeader>

        {controller.selectedArticle && (
          <VStack pt="20px">
            <HStack>
              <Button
                isLoading={imageGenLoading}
                onClick={async () => {
                  setImageGenLoading(true);
                  const image = await generateImageForBlogArticle(
                    controller.selectedArticle.content
                  );

                  controller.setArticle({
                    ...controller.selectedArticle,
                    image: image,
                  });
                  setImageGenLoading(false);
                }}
              >
                Generate Image in WB style
              </Button>
              <FileUploader handleChange={handleChange} name="file" />
            </HStack>
            <Link
              href={controller.selectedArticle.image}
              target="_blank"
              rel="noreferrer"
              mt="20px"
            >
              {controller.selectedArticle.image}
            </Link>
            <Image src={controller.selectedArticle.image} maxW="400px" />
          </VStack>
        )}

        {!controller.loading && !rewriteLoading && (
          <Main
            maxW="3xl"
            mx="auto"
            py="8"
            px={{ base: "4", md: "8" }}
            selectedArticle={controller.selectedArticle}
            setArticle={controller.setArticle}
          />
        )}
      </Box>
    </Flex>
  );
}
