import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Text,
  VStack,
  Select,
  Input,
  useToast,
  Tag,
  Flex,
  Center,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { useAtomValue } from "jotai";
import { BiTrash, BiCopy } from "react-icons/bi";
import { IoEye } from "react-icons/io5";
import { useColorMode, useColorModeValue } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { CopyIcon } from "@chakra-ui/icons";
import { FaThermometerEmpty } from "react-icons/fa";
import { useEmbeddingConfigurationController } from "./useEmbeddingConfigurationController";
import { AccountAtom, AppStateAtom } from "../../../store";
import NoFeatureFlagAPIKeys from "../../apikeys/NoFeatureFlagAPIKeys";

export default function EmbeddingConfigurationPanel() {
  const controller = useEmbeddingConfigurationController();
  const appState = useAtomValue(AppStateAtom);
  const accountState = useAtomValue(AccountAtom);

  if (!appState?.featureFlags?.includes("api_keys")) {
    return <NoFeatureFlagAPIKeys />;
  }

  const IframeExample = () => {
    return (
      <Box
        w="full"
        h="1900px"
        overflow={"hidden"}
        bg={controller.backgroundColorSetting}
        border="var(--red) 1px solid"
      >
        <iframe
          id="contentFrame"
          src={controller.embedLink}
          width="100%"
          frameBorder={0}
          scrolling="no"
          allowFullScreen
          height="99%"
        ></iframe>
      </Box>
    );
  };

  if (controller.apiKeysList.length === 0) {
    return (
      <Box w="full" h="100vh" overflowY={"scroll"} overflowX={"hidden"}>
        <Flex
          m={4}
          w="full"
          width="full"
          maxW="100vw"
          shadow="base"
          p={5}
          rounded="lg"
          direction="column"
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          alignContent={"flex-start"}
        >
          <Text>
            You need to create an API key before you can embed your dashboard.
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Box m={4} w="full" h="100vh" overflowY={"scroll"} overflowX={"hidden"}>
      <Flex
        bg={"var(--bg)"}
        w="full"
        width="full"
        maxW="100vw"
        shadow="base"
        rounded="lg"
        direction="column"
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        alignContent={"flex-start"}
        pb="200px"
      >
        <HStack width="full" alignItems={"flex-start"}>
          <Flex flex={1} px={5} mt={"10px"}>
            <VStack alignItems={"flex-start"} w="full">
              <Heading as="h3" size="sm" color="var(--white)">
                Select API Key
              </Heading>
              <Select
                placeholder="Select API key"
                value={controller.selectedAPIKey}
                defaultValue={controller.selectedAPIKey}
                onChange={(e) => controller.setSelectedAPIKey(e.target.value)}
              >
                {controller.apiKeysList.map((key) => (
                  <option key={key.id} value={key.key}>
                    {key.key}
                  </option>
                ))}
              </Select>

              <Heading as="h3" size="sm" color="var(--white)">
                Select Account
              </Heading>
              <Select
                placeholder="Select account"
                value={controller.selectedAccountId}
                defaultValue={accountState.selectedAccount.id}
                onChange={(e) =>
                  controller.setSelectedAccountId(e.target.value)
                }
              >
                {accountState.accounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name}
                  </option>
                ))}
              </Select>

              <Heading as="h3" size="sm" color="var(--white)">
                Embedding Type
              </Heading>
              <RadioGroup>
                <Radio
                  value={"SINGLE_INSIGHT"}
                  onChange={() =>
                    controller.setToggleInsightDisplay("SINGLE_INSIGHT")
                  }
                >
                  Single Insight
                </Radio>
                <Radio
                  ml="4"
                  value={"DASHBOARD"}
                  onChange={() =>
                    controller.setToggleInsightDisplay("DASHBOARD")
                  }
                >
                  Dashboard
                </Radio>
              </RadioGroup>

              {controller.toggleInsightDisplay === "DASHBOARD" && (
                <>
                  <Heading as="h3" size="sm" color="var(--white)">
                    Select Dashboard
                  </Heading>
                  <Select
                    placeholder="Select dashboard"
                    value={controller.selectedDashboardId}
                    onChange={(e) =>
                      controller.setSelectedDashboardId(e.target.value)
                    }
                  >
                    {controller.layouts.map((configuration) => (
                      <option
                        key={configuration.id}
                        value={configuration.configurationId}
                      >
                        {configuration.name}
                      </option>
                    ))}
                  </Select>
                </>
              )}

              {controller.toggleInsightDisplay === "SINGLE_INSIGHT" && (
                <>
                  <Heading as="h3" size="sm" color="var(--white)">
                    Select insight
                  </Heading>
                  <Select
                    placeholder="Select insight"
                    value={controller.selectedInsightId}
                    onChange={(e) =>
                      controller.setSelectedInsightId(e.target.value)
                    }
                  >
                    {controller.insights.map((insight) => {
                      return (
                        <option key={insight.id} value={insight.id}>
                          {insight.title}
                        </option>
                      );
                    })}
                  </Select>

                  {/* Width */}

                  <Text color="var(--white)">Width (px)</Text>
                  <Input
                    value={controller.width}
                    onChange={(e) => controller.setWidth(e.target.value)}
                  />

                  {/* Height */}
                  <Text color="var(--white)">Height (px)</Text>
                  <Input
                    value={controller.height}
                    onChange={(e) => controller.setHeight(e.target.value)}
                  />
                </>
              )}

              <Text color="var(--white)">Theme</Text>
              <Select
                value={controller.themeSetting}
                onChange={(e) => controller.setThemeSetting(e.target.value)}
              >
                <option value="light">Light</option>
                <option value="dark">Dark</option>
              </Select>
              <Text color="var(--white)">Referral ID</Text>
              <Input
                value={controller.referralId}
                onChange={(e) => controller.setReferralId(e.target.value)}
              />
              <Text fontSize="14px">https://wealthbee.io/affiliates</Text>

              <Text color="var(--white)">Background Color</Text>
              <Input
                p={0}
                type="color"
                value={controller.backgroundColorSetting}
                onChange={(e) =>
                  controller.setBackgroundColorSetting(e.target.value)
                }
              />
              <Heading as="h3" size="sm" color="var(--white)" mt="40px">
                Code
              </Heading>

              <Input
                isDisabled={controller.embedLink === ""}
                value={`<iframe id="contentFrame" src="${controller.embedLink}" 
                     
                    width="${controller.width}"
          height=:
          allowfullscreen
          frameborder="0"
          scrolling="no"

                ></iframe>`}
                isReadOnly
                size="md"
              />
              <Button
                isDisabled={controller.embedLink === ""}
                w="full"
                size="xs"
                leftIcon={<BiCopy />}
                onClick={() =>
                  controller.copyToClipboard(
                    `<iframe id="contentFrame" src="${controller.embedLink}" 
                    
                    width="${controller.width}"
          height="${controller.height}"
          allowfullscreen
          frameborder="0"
          scrolling="no"

                    ></iframe>`
                  )
                }
              >
                Copy iFrame Embed Code
              </Button>

              {/* <Text textAlign={"center"} w="full">
                OR
              </Text>
              <Input
                isDisabled={controller.embedLink === ""}
                value={controller.embedLink}
              /> */}

              {/* <Button
                isDisabled={controller.embedLink === ""}
                w="full"
                size="xs"
                leftIcon={<BiCopy />}
                onClick={() =>
                  controller.copyToClipboard(
                    `<embed
      src=${controller.embedLink}
      width="${controller.width}"
      height="${controller.height}"
    />`
                  )
                }
              >
                Copy Embed Object
              </Button> */}

              <Input
                isDisabled={controller.embedLink === ""}
                value={controller.footerScript}
                isReadOnly
                size="md"
              />

              <Button
                isDisabled={controller.embedLink === ""}
                w="full"
                leftIcon={<BiCopy />}
                onClick={() =>
                  controller.copyToClipboard(controller.footerScript)
                }
                size="xs"
              >
                Copy Footer Script
              </Button>

              <Button
                isDisabled={
                  controller.embedLink === "" ||
                  !controller.selectedAccountId ||
                  !controller.selectedAPIKey
                }
                onClick={() => {
                  window.open(controller.embedLink, "_blank");
                }}
                w="full"
                size="xs"
              >
                <IoEye />
                <Text pl="10px">View preview</Text>
              </Button>

              {appState.role === "admin" ||
                (appState?.featureFlags?.includes("embedding_demo") && (
                  <Box border="red 1px solid" p={4} w="full">
                    <Text fontSize="xs" color="red">
                      Demo Generator (Admin Only)
                    </Text>

                    {/* Toggle endabled
                     */}

                    <RadioGroup>
                      <Radio
                        value={"true"}
                        onChange={() => controller.setDemoEnabled(true)}
                      >
                        Enabled
                      </Radio>
                      <Radio
                        ml="4"
                        value={"false"}
                        onChange={() => controller.setDemoEnabled(false)}
                      >
                        Disabled
                      </Radio>
                    </RadioGroup>

                    {controller.demoEnabled === true && (
                      <>
                        <Text color="var(--white)">Brand Logo URL</Text>
                        <Input
                          value={controller.brandLogoUrl}
                          onChange={(e) =>
                            controller.setbrandLogoUrl(e.target.value)
                          }
                        />

                        <Text color="var(--white)">Brand Colour Primary</Text>
                        <Input
                          p={0}
                          type="color"
                          value={controller.brandColorPrimary}
                          onChange={(e) =>
                            controller.setBrandColorPrimary(e.target.value)
                          }
                        />
                        <Text color="var(--white)">Brand Web Font</Text>

                        <Select
                          value={controller.brandWebFont}
                          onChange={(e) =>
                            controller.setbrandWebFont(e.target.value)
                          }
                        >
                          <option value="cursive">Cursive</option>
                          <option value="Times New Roman">
                            Times New Roman
                          </option>
                          <option value="Courier New">Courier New</option>
                          <option value="Verdana">Verdana</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Palatino">Palatino</option>
                        </Select>
                        <Text color="var(--white)">Brand Name</Text>
                        <Input
                          value={controller.brandName}
                          onChange={(e) =>
                            controller.setbrandName(e.target.value)
                          }
                        />

                        <Button
                          isDisabled={
                            controller.embedLink === "" ||
                            !controller.selectedAccountId ||
                            !controller.selectedAPIKey
                          }
                          onClick={() => {
                            window.open(controller.embedLink, "_blank");
                          }}
                          w="full"
                          size="xs"
                          mt="10px"
                        >
                          <IoEye />
                          <Text pl="10px">View preview</Text>
                        </Button>
                      </>
                    )}
                  </Box>
                ))}
            </VStack>
          </Flex>
          <Flex flex={4} h="100%" alignContent={"flex-start"}>
            {controller.selectedAPIKey === "" && (
              <Center
                w="full"
                minW="100%"
                h="500px"
                flexDir={"column"}
                textAlign={"center"}
              >
                <Text>
                  Select an account, dashboard and API key to preview.
                </Text>
              </Center>
            )}

            {!controller.loading &&
              controller.selectedAPIKey &&
              controller.selectedAccountId && <IframeExample />}
            {controller.loading && (
              <Center w="100%" h="500px" flexDir={"column"}>
                <Spinner size="xl" />
                <Text mt="10px">Loading preview...</Text>
              </Center>
            )}
          </Flex>
        </HStack>
      </Flex>
    </Box>
  );
}
