import React, { useEffect, useMemo, useState } from "react";
import { VStack, Text, Box } from "@chakra-ui/react";
import { InsightTableProps } from "../../../../../../types/Insight";
import { DynamicTable } from "../../../../../../components/table/DynamicTable";
import {
  columnTypes,
  distinctValue,
  GridContainer,
} from "../../../../../../components";
import { AgGridReact } from "ag-grid-react";
import { PositionsInnerRender } from "../../../../../positions/grid/PositionsInnerRender";
import { usePositionController } from "../../../../../positions/usePositionController";
import { useGridContextMenuController } from "../../../../../../components/grid/utils/useGridContextMenuController";
import { useGridState } from "../../../../../../components/grid/useGridState";
import { openPositionsColDefs } from "./coldefs/OpenPositionsColumnDefs";
import { closedPositionsColDefs } from "./coldefs/ClosedPositionsColumnDefs";
import { recentlyTradedPositionsColumnDefs } from "./coldefs/RecentlyTradedPositionsColumnDefs";
import {
  overlayLoadingTemplate,
  overlayNoRowsTemplate,
} from "../../../../../../components/grid/NoRowsToShow";
import { upcomingExpiriesColumnDefs } from "./coldefs/UpcomingExpiriesColumnDefs";
import { useAtomValue } from "jotai";
import { FilterStateAtom } from "../../../../../../store/FilterStateAtom";
import { PositionStatus } from "../../../../../../types";
import { PositionStateAtom } from "../../../../../../store";

const InsightTable = ({
  data,
  demo,
  title,
  query,
}: {
  data: InsightTableProps;
  demo?: boolean;
  title: string;
  query?: string;
}) => {
  const controller = usePositionController();
  const gridState = useGridState({ gridName: `Insight_${query}` });
  const [switchingColDef, setSwitchingColDef] = useState<boolean>(false);
  const filters = useAtomValue(FilterStateAtom);
  const positionState = useAtomValue(PositionStateAtom);

  const isOpenPositions = query === "TABLE_OPEN_POSITIONS";
  const isClosedPositions = query === "TABLE_CLOSED_POSITIONS";
  const recentTrades = query === "TABLE_RECENTLY_TRADED_POSITIONS";
  const recentlyClosedPositions = query === "TABLE_RECENTLY_CLOSED_POSITIONS";
  const upcomingExpiries = query === "TABLE_UPCOMING_EXPIRIES";

  useEffect(() => {
    setSwitchingColDef(true);
  }, []);

  const getRowData = () => {
    let result = data.rows.map((x: any) => ({
      ...x,
      id: x.positionId,
      positionName: x.positionGroup,
      instrument: {
        symbol: x.symbol,
        exchange: x.exchange,
        name: x.name,
        instrumentType: x.instrumentType,
        putCall: x.putCall,
        strike: x.strike,
        expiry: x.expiry,
        multiplier: x.multiplier,
      },
    }));

    // Apply status-based filters
    switch (filters.status) {
      case PositionStatus.ACTIVE:
        result = result.filter((position) =>
          positionState.openPositions.includes(position.id)
        );
        break;
      case PositionStatus.OPEN:
        result = result.filter((position) => position.quantity !== 0);
        break;
      case PositionStatus.CLOSED:
        result = result.filter((position) =>
          positionState.closedPositions.includes(position.id)
        );
        break;
    }

    // Apply additional filters if filterApplied is true
    if (filters.filterApplied) {
      result = result.filter((position) => {
        let underlyingMatch = true;

        if (
          filters.selectedUnderlying &&
          filters.selectedUnderlying !== "All"
        ) {
          underlyingMatch =
            position.underlyingSymbol === filters.selectedUnderlying ||
            position.underlyingName === filters.selectedUnderlying;
        }

        let tagsMatch = true;
        if (filters.selectedTag && filters.selectedTag !== "All") {
          tagsMatch = position.tags.includes(filters.selectedTag);
        }

        let positionGroupMatch = true;
        if (
          filters.selectedPositionGroup &&
          filters.selectedPositionGroup !== "All"
        ) {
          positionGroupMatch =
            position.positionGroup === filters.selectedPositionGroup;
        }

        let booksMatch = true;
        if (filters.selectedBook && filters.selectedBook !== "All") {
          booksMatch = position.book === filters.selectedBook;
        }

        let strategiesMatch = true;
        if (filters.selectedStrategy && filters.selectedStrategy !== "All") {
          strategiesMatch = position.strategy === filters.selectedStrategy;
        }

        return (
          underlyingMatch &&
          tagsMatch &&
          positionGroupMatch &&
          booksMatch &&
          strategiesMatch
        );
      });
    }

    return result;
  };

  const loadingOverlayComponent = useMemo(() => {
    return overlayLoadingTemplate;
  }, []);

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Retrieving positions...",
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return overlayNoRowsTemplate;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => "No positions found! ",
    };
  }, []);

  const renderTable = () => {
    if (
      isOpenPositions ||
      isClosedPositions ||
      recentTrades ||
      upcomingExpiries ||
      recentlyClosedPositions
    ) {
      return (
        <GridContainer>
          {controller.switchingColDef === false ? (
            <AgGridReact
              onColumnMoved={gridState.onColumnMoved}
              onRowGroupOpened={(params) => {
                gridState.performGroupChanged(params);
              }}
              sideBar={{
                toolPanels: [
                  {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                      suppressPivotMode: true,
                      suppressValues: true,
                      suppressRowGroups: true,
                    },
                  },
                ],
                position: "left",
                defaultToolPanel: "filters",
              }}
              autoGroupColumnDef={
                upcomingExpiries === true
                  ? {
                      cellRendererParams: {
                        suppressCount: true,
                        innerRenderer: PositionsInnerRender,
                      },
                      sortable: true,
                      width: 520,
                      resizable: true,
                      headerName: "Expiry date",
                    }
                  : recentTrades === true
                  ? {
                      cellRendererParams: {
                        suppressCount: true,
                        innerRenderer: PositionsInnerRender,
                      },
                      sortable: true,
                      width: 520,
                      resizable: true,
                      headerName: "Last trade date",
                    }
                  : {
                      cellRendererParams: {
                        suppressCount: true,
                        innerRenderer: PositionsInnerRender,
                      },
                      sortable: true,
                      width: 620,
                      resizable: true,
                      headerName: "Book",
                    }
              }
              rowData={getRowData()}
              columnDefs={
                isOpenPositions === true
                  ? openPositionsColDefs
                  : isClosedPositions === true ||
                    recentlyClosedPositions === true
                  ? closedPositionsColDefs
                  : recentTrades === true
                  ? recentlyTradedPositionsColumnDefs
                  : upcomingExpiries === true
                  ? upcomingExpiriesColumnDefs
                  : []
              }
              columnTypes={columnTypes}
              rowHeight={31}
              groupDefaultExpanded={3}
              showOpenedGroup={true}
              suppressAggFilteredOnly={false}
              suppressAggFuncInHeader={true}
              ref={controller.gridApiRef}
              suppressChangeDetection={false}
              reactiveCustomComponents={true}
              aggFuncs={{
                distinct: distinctValue,
                none: () => "",
              }}
              rowDragManaged={false}
              rowDragMultiRow={true}
              getRowId={controller.getRowId}
              animateRows={true}
              onGridReady={gridState.onGridReady}
              onFirstDataRendered={gridState.onFirstDataRendered}
              onColumnEverythingChanged={gridState.onColumnChanged}
              onColumnResized={gridState.onColumnResized}
              onSortChanged={gridState.onColumnChanged}
              loadingOverlayComponent={loadingOverlayComponent}
              loadingOverlayComponentParams={loadingOverlayComponentParams}
              noRowsOverlayComponent={noRowsOverlayComponent}
              noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            />
          ) : (
            <></>
          )}
        </GridContainer>
      );
    } else {
      return <DynamicTable data={[]} />;
    }
  };

  if (!data) return null;

  return (
    <Box h="92%">
      <Text
        zIndex={-11111}
        textAlign={"center"}
        size="sm"
        color="var(--text-primary)"
        maxW={"90%"}
        margin={"auto"}
        transform={"translateY(-20px)"}
      >
        {title}
      </Text>
      {renderTable()}
      <Box pb="100px"></Box>
    </Box>
  );
};

export default InsightTable;
