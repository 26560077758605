import { WarningIcon } from "@chakra-ui/icons";
import { Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function InsightError() {
  return (
    <Flex w="full" p={4} justifyContent="center" alignItems="center" h="100%">
      <VStack>
        <WarningIcon fontSize="40px" />
        <Text fontSize="sm">Failed to load insight!</Text>
      </VStack>
    </Flex>
  );
}
