import { useAtomValue } from "jotai";
import moment from "moment";
import { useState, useEffect } from "react";
import { GetCalendarNotes, CreateCalendarNote } from "../../api/CalendarNotes";
import convertSymbolToReadable from "../../components/grid/utils/convertSymbolToReadable";
import { useInstruments, usePositions } from "../../hooks";
import { AccountAtom, PositionStateAtom } from "../../store";
import { getEarnings } from "../../api/Earnings";
import { InstrumentType } from "../../types";
import { distinctValues } from "../../utils";

interface useCalendarControllerProps {
  events: any[];
  month: string;
  day: string;
  loading: boolean;
  notes: any[];
  selectedNote: any;
  expiries: any[];
  setDay: (day: string) => void;
  setMonth: (month: string) => void;
  isOpen: boolean;
  accountState: any;
  expiriesForThisDate: any[];
  pnlByDate: any[];
  trades: any[];
  setIsOpen: (isOpen: boolean) => void;
  tradesForThisDate: any[];
  pnlThisMonth: any[];
  numberOfTradedDays: number;
  getNotes: (month: string) => void;
  createNote: (content: string, date: string) => void;
  earnings: any[];
  earningsForThisDate: any[];
}

export const useCalendarController = (): useCalendarControllerProps => {
  const [events, setEvents] = useState<any[]>([]);
  const [month, setMonth] = useState(moment.utc().format("MMM YYYY"));
  const [day, setDay] = useState(moment.utc().format("DD MMM YYYY"));
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<any[]>([]);
  const [selectedNote, setSelectedNote] = useState<any>(null);
  const [expiries, setExpiries] = useState<any[]>([]);
  const instruments = useInstruments();

  const [isOpen, setIsOpen] = useState(false);

  const accountState = useAtomValue(AccountAtom);
  const [expiriesForThisDate, setExpiriesForThisDate] = useState<any[]>([]);
  const [pnlByDate, setPnlByDate] = useState<any[]>([]);
  const [trades, setTrades] = useState<any[]>([]);
  const [tradesForThisDate, setTradesForThisDate] = useState<any[]>([]);
  const [pnlThisMonth, setPnlThisMonth] = useState<any[]>([]);
  const [numberOfTradedDays, setNumberOfTradedDays] = useState<number>(0);
  const [earnings, setEarnings] = useState<any[]>([]);
  const [earningsForThisDate, setEarningsForThisDate] = useState<any[]>([]);
  const positions = useAtomValue(PositionStateAtom);
  useEffect(() => {
    getNotes(month);
  }, [accountState.selectedAccount.id, isOpen, instruments.instruments]);

  const getNotes = async (month: string) => {
    if (!accountState.selectedAccount) return;
    if (!accountState.selectedAccount.id) return;
    if (!month) return;
    setLoading(true);

    const notes = await GetCalendarNotes(
      month,
      accountState.selectedAccount.id
    );

    const instrumentIds = positions.positions
      .filter((position) => {
        if (positions.openPositions.includes(position.positionId)) {
          return position;
        }
      })
      .map((position) => {
        if (position.instrumentType === InstrumentType.Equity) {
          return position.instrumentId;
        } else {
          return position.underlyingInstrumentId;
        }
      });

    const uniqueInstrumentIds = distinctValues(instrumentIds);

    const earnings = await getEarnings(uniqueInstrumentIds);
    const mapInstrumentDataToEarnings = earnings.map((earning) => {
      return {
        ...earning,
        instrument: instruments.instruments.find(
          (x) => x.id === earning.instrumentId
        ),
      };
    });
    setEarnings(mapInstrumentDataToEarnings);

    const expiriesEventArray = notes.expiries.map(
      (expiry: any, index: number) => {
        return {
          id: expiry.symbol + "-" + index,
          title: "Expiry: " + convertSymbolToReadable(expiry.symbol),
          start: moment
            .utc(expiry.expiry)
            .endOf("day")
            .subtract(6, "hour")
            .toDate(),
          end: moment.utc(expiry.expiry).endOf("day").toDate(),
          allDay: false,
          resourceId: expiry.symbol + "-" + index,
        };
      }
    );

    let tradesEventArray = notes.trades.map((trade: any, index: number) => {
      const ins = instruments.instruments.find(
        (x) => x.id === trade.instrumentId
      );
      if (!ins) return;
      return {
        id: ins.symbol,
        title: "Trade: " + convertSymbolToReadable(ins.symbol),
        start: moment.utc(trade.tradeDate).toDate(),
        end: moment.utc(trade.tradeDate).add(3, "hour").toDate(),
        allDay: false,
        resourceId: ins.symbol + "-" + index,
      };
    });
    //merge tradesEvents where they have same ID;
    tradesEventArray = tradesEventArray.reduce((acc, current) => {
      try {
        const x = acc.find((item) => item.id === current.id);
        if (x) {
          return acc;
        } else {
          return acc.concat([current]);
        }
      } catch (e) {
        console.log(e);
        return acc;
      }
    }, []);

    setEvents([...expiriesEventArray, ...tradesEventArray]);
    setNotes(notes.notes || []);
    setExpiries(notes.expiries || []);
    setPnlByDate(notes.pnl || []);
    setNumberOfTradedDays(notes.numberOfTradedDays || 0);
    setPnlThisMonth(notes.totalPNL || []);
    setTrades(notes.trades || []);
    setLoading(false);
  };

  const createNote = async (content: string, date: string) => {
    setLoading(true);

    setExpiriesForThisDate(
      expiries.filter((expiry) => {
        return (
          moment.utc(expiry.expiry, "YYYY-MM-DD").format("YYYY-MM-DD") === date
        );
      })
    );

    setTradesForThisDate(
      trades.filter((trade) => {
        return moment.utc(trade.tradeDate).format("YYYY-MM-DD") === date;
      })
    );

    setEarningsForThisDate(
      earnings.filter((earning) => {
        if (
          moment.utc(earning.date).format("YYYY-MM-DD") ===
          moment.utc(date).format("YYYY-MM-DD")
        ) {
          return earning;
        }
      })
    );

    const existingNote = notes.find((note) => {
      return (
        moment(note.calendarDateReference).format("YYYY-MM-DD") ===
        moment(date).format("YYYY-MM-DD")
      );
    });
    if (!existingNote) {
      const note = await CreateCalendarNote(
        date,
        content,
        accountState.selectedAccount.id
      );

      setSelectedNote(note.note);
      setIsOpen(true);

      getNotes(month);
    } else {
      setSelectedNote(existingNote);
      setIsOpen(true);
    }
    setLoading(false);
  };

  return {
    events,
    earnings,
    month,
    day,
    loading,
    notes,
    selectedNote,
    expiries,
    isOpen,
    accountState,
    expiriesForThisDate,
    pnlByDate,
    trades,
    tradesForThisDate,
    pnlThisMonth,
    numberOfTradedDays,
    getNotes,
    createNote,
    setIsOpen,
    setDay,
    setMonth,
    earningsForThisDate,
  };
};
