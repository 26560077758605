import { HStack, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { FaBookOpen } from "react-icons/fa";

const BookRender = (params: any) => {
  return (
    <Tooltip
      label={
        params.value === "" &&
        "Unbooked: Books are used to track trades, example of a book is 'Schwab Account', 'Covered Call' or 'IRA'. This collection of trades are not booked to any specific grouping. It optional but allows easier tracking of performance when using filters. Right click trades to assign a book or drag and drop between groups."
      }
      hasArrow
      fontSize="sm"
      arrowSize={10}
    >
      <HStack justifyContent={"space-between"} w="100%">
        <FaBookOpen
          color={params.value === "" ? "var(--red)" : "var(--white)"}
        />
        <Text
          color={params.value === "" ? "var(--red)" : "var(--white)"}
          fontWeight={600}
        >
          {params.value === ""
            ? `Unbooked (${params.node.allChildrenCount})`
            : `${params.value} (${params.node.allChildrenCount})`}
        </Text>
      </HStack>
    </Tooltip>
  );
};

export default BookRender;
