import { Box, HStack } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import React from "react";
import { ReactElement, ReactNode } from "react";
import { BsCaretRightFill } from "react-icons/bs";
import { RiExternalLinkFill } from "react-icons/ri";
import { SidebarStateAtom } from "../../../store";

interface NavItemProps {
  label: string;
  subtle?: boolean;
  active?: boolean;
  icon: ReactElement;
  endElement?: ReactElement;
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
  isExternal?: boolean;
}

export const NavItem = (props: NavItemProps) => {
  const {
    active,
    subtle,
    icon,
    children,
    label,
    endElement,
    onClick,
    className,
    isExternal,
  } = props;

  const isCollaposed = useAtomValue(SidebarStateAtom);
  return (
    <HStack
      as="a"
      w="full"
      px="3"
      className={className}
      py="2"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.2s"
      justifyContent={"center"}
      _hover={{ bg: "var(--dark-gray)" }}
      color={active ? "var(--active-link)" : "var(--non-focus-text)"}
      onClick={onClick}
    >
      <Box fontSize="lg" color={active ? "currentcolor" : "var(--grey)"}>
        {icon}
      </Box>
      <Box
        display={label.length > 0 ? "flex" : "none"}
        flex="1"
        fontWeight="inherit"
        color={subtle ? "var(--grey)" : undefined}
      >
        {label}
      </Box>
      {endElement && !children && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
      {isExternal && isCollaposed === false && (
        <Box fontSize="xs" flexShrink={0} as={RiExternalLinkFill} />
      )}
    </HStack>
  );
};
