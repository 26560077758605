import { Box, Center, Spinner, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useSchwabAutoSyncController } from "./useSCHWABAutoSyncController";
import { AccountAtom } from "../../../../store";
import { useAtomValue } from "jotai";
import { useSearchParams } from "react-router-dom";
import { MdCheckCircle, MdError } from "react-icons/md";

export default function SchwabHandleCallBack(props: any) {
  const controller = useSchwabAutoSyncController(props);
  const accountState = useAtomValue(AccountAtom);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const code = searchParams.get("code");
    controller.step2SwapCodeForToken(
      code as string,
      accountState.selectedAccount.id
    );
  }, []);

  return (
    <Box>
      <Center height="100vh">
        {controller.loading && !controller.error && !controller.connected && (
          <VStack>
            <Spinner size="xl" />
            <h1>Connecting to Schwab...</h1>
          </VStack>
        )}

        {controller.error && !controller.loading && !controller.connected && (
          <VStack>
            <MdError color="red" fontSize="60px" />
            <Text>{controller.error}</Text>
          </VStack>
        )}

        {controller.connected && !controller.loading && !controller.error && (
          <VStack>
            <MdCheckCircle color="green" fontSize="60px" />
            <Text>Connected to Schwab!</Text>
            <Text fontSize="sm">Redirecting in a few seconds...</Text>
          </VStack>
        )}
      </Center>
    </Box>
  );
}
