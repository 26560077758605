export const TABLE_UNCOVERED_POSITIONS = {
  rows: [
    {
      symbol: "ABNB",
      totalShares: "200",
      coveredShares: "100",
      uncoveredShares: "100",
    },
    {
      symbol: "MSFT",
      totalShares: "150",
      coveredShares: "0",
      uncoveredShares: "150",
    },
    {
      symbol: "NVDA",
      totalShares: "100",
      coveredShares: "0",
      uncoveredShares: "100",
    },
  ],
  timeFrameType: "all",
  supported: true,
};
