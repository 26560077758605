import { SIZE_BY_BOOK } from "./SIZE_BY_BOOK";
import { SIZE_BY_POSITIONGROUP } from "./SIZE_BY_POSITIONGROUP";
import { SIZE_BY_STRATEGY } from "./SIZE_BY_STRATEGY";
import { SIZE_BY_UNDERLYING } from "./SIZE_BY_UNDERLYING";

const InsightTreeMapDemoDataMap = {
  SIZE_BY_BOOK: SIZE_BY_BOOK,
  SIZE_BY_POSITIONGROUP: SIZE_BY_POSITIONGROUP,
  SIZE_BY_STRATEGY: SIZE_BY_STRATEGY,
  SIZE_BY_UNDERLYING: SIZE_BY_UNDERLYING,
};

export default InsightTreeMapDemoDataMap;
