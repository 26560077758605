import React from "react";
import InsightSearchList from "../../InsightSearchList/InsightSearchList";
import InsightsCreator from "../../InsightsCreator/InsightsCreator";
import { InsightTemplate } from "../../../../types";

interface InsightsManagerContentProps {
  controller: any;
  onClose: () => void;
}

const InsightsManagerContent: React.FC<InsightsManagerContentProps> = ({
  controller,
  onClose,
}) => {
  return controller.page === "insights" ? (
    <InsightSearchList
      setPage={controller.setPage}
      setInsightData={controller.setInsightData}
      onClose={onClose}
    />
  ) : (
    <InsightsCreator
      setPage={controller.setPage}
      insightsData={controller.insightData as InsightTemplate}
      setInsightData={controller.setInsightData}
    />
  );
};

export default InsightsManagerContent;
