import React from "react";
import NavWrapper from "./NavWrapper";
import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { FaCog } from "react-icons/fa";
import { SettingsIcon } from "@chakra-ui/icons";

export default function AccountManagerTutorial() {
  return (
    <NavWrapper
      previousEnabled={false}
      isFinalStep={false}
      nextDisabled={false}
    >
      <Box fontSize="14px">
        <Heading
          as="h2"
          fontSize="18px"
          mb={4}
          fontWeight={600}
          textAlign={"left"}
        >
          Multiple Accounts
        </Heading>
        <Text mt="10px">Accounts segregate your investments.</Text>
        <Text mt="10px">
          You can have multiple accounts to manage different investment
          strategies or you can use books to manage different portfolios (we
          will cover books later in the tutorial).
        </Text>
        <Text mt="10px" display="inline-block">
          Within this menu its also possible to delete your transactions, create
          new, rename & delete portfolios.
        </Text>
      </Box>
    </NavWrapper>
  );
}
