export const TABLE_UPCOMING_EARNINGS = {
  rows: [
    {
      instrumentId: 9649,
      symbol: "AAPL",
      earningsDate: "2025-01-30T00:00:00.000Z",
      beforeAfterMarket: "AfterMarket",
    },
    {
      instrumentId: 10607,
      symbol: "ABNB",
      earningsDate: "2025-02-11T00:00:00.000Z",
      beforeAfterMarket: "AfterMarket",
    },
    {
      instrumentId: 9649,
      symbol: "AAPL",
      earningsDate: "2025-04-29T23:00:00.000Z",
      beforeAfterMarket: "BeforeMarket",
    },
    {
      instrumentId: 10607,
      symbol: "ABNB",
      earningsDate: "2025-05-05T23:00:00.000Z",
      beforeAfterMarket: "AfterMarket",
    },
  ],
};
