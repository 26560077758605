export const PNL_BY_MONTH = {
  data: [
    {
      domain: "2023-11-30",
      realisedPnl: 382.16,
    },
    {
      domain: "2023-12-31",
      realisedPnl: 594.88,
    },
    {
      domain: "2024-01-31",
      realisedPnl: 1296.67,
    },
    {
      domain: "2024-02-29",
      realisedPnl: 702.82,
    },
    {
      domain: "2024-03-31",
      realisedPnl: 816.9,
    },
    {
      domain: "2024-04-30",
      realisedPnl: -7087.2,
    },
    {
      domain: "2024-05-31",
      realisedPnl: 2765.06,
    },
    {
      domain: "2024-06-30",
      realisedPnl: 7595.45,
    },
    {
      domain: "2024-07-31",
      realisedPnl: 6765.63,
    },
    {
      domain: "2024-08-31",
      realisedPnl: 121.84,
    },
    {
      domain: "2024-09-30",
      realisedPnl: 363.94,
    },
    {
      domain: "2024-10-31",
      realisedPnl: -144.17,
    },
  ],
  chartProperties: {
    name: "PnL By Month",
    chartType: "bar",
    keys: ["realisedPnl"],
    colors: ["var(--chart-blue)"],
    legend: ["Realized PnL"],
    domainType: "date",
  },
};
