import { APIEndpoint } from ".";
import { DashboardConfiguration } from "../types/DashboardConfiguration";
import { InsightTemplate } from "../types/Insight";

export const getDashboardConfiguration = async (
  layoutId: number
): Promise<DashboardConfiguration | undefined> => {
  const token = localStorage.getItem("token");

  const result = await fetch(`${APIEndpoint}/dashboard2/${layoutId}`, {
    method: "GET",
    headers: {
      Authorization: `${token || ""}`,
    },
  });

  if (result.ok) {
    const data = await result.json();

    if (data.config) {
      return data.config as DashboardConfiguration;
    }
  }

  return undefined;
};

export const getDashboardInsights = async (
  layoutId: number
): Promise<InsightTemplate[] | undefined> => {
  const token = localStorage.getItem("token");

  const result = await fetch(`${APIEndpoint}/dashboard2/insights/${layoutId}`, {
    method: "GET",
    headers: {
      Authorization: `${token || ""}`,
    },
  });

  if (result.ok) {
    const data = await result.json();

    if (data.insights) {
      return data.insights as InsightTemplate[];
    }
  }

  return undefined;
};
