import { Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { TbClock, TbDatabaseExclamation } from "react-icons/tb";

export default function NoTimeFrameCompability() {
  return (
    <VStack
      h="80%"
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
      px="20px"
    >
      <TbClock fontSize="40px" />
      <Heading fontSize="md">
        Not compatible with currently selected timeframe
      </Heading>
    </VStack>
  );
}
