import React from "react";
import { Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import { Panel } from "../../../../../components/design_library";

export const SchwabAutoSyncInstructions = () => {
  return (
    <Panel w="full">
      <Text mt={2}>
        Connect your Schwab account to seamlessly import your trades on a
        regular basis. Before you get started, please review the following
        details:
      </Text>

      <UnorderedList mt={4} spacing={3}>
        <ListItem>
          We only request read-only access to your account. We’ll never make
          trades on your behalf.
        </ListItem>

        <ListItem>
          Schwab requires API keys to be renewed every 7 days. This means you’ll
          need to re-authenticate periodically using this page. We will send you
          an email when its time to reconnect.
        </ListItem>

        <ListItem>
          We’ll retrieve your transaction history— including journal and cash
          entries—every weekday to keep your data current.
        </ListItem>

        <ListItem>
          Only trades from the current year will be imported, with a maximum of
          3,000 transactions (including dividends and journals). For older
          trades, please upload a CSV with your historical data.
        </ListItem>

        <ListItem>
          Schwab’s API currently supports only equities, options, and futures.
          Foreign exchange (FX) trades will not be imported.
        </ListItem>

        <ListItem>
          All reported trade times are in UTC rather than your local time zone.
        </ListItem>

        <ListItem>
          Not all account types are supported by the Schwab Trader API. This
          includes (but is not limited to) HSA, PCRA, Joint Tenant, Designated
          Beneficiary Joint Tenant, and Corporate accounts. We’re actively
          working to expand support for these account types in the future.
        </ListItem>

        <ListItem>
          Once you’re ready, simply link your Schwab account and we’ll take care
          of the rest.
        </ListItem>
      </UnorderedList>
    </Panel>
  );
};
