import { Flex, Spinner, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function InsightLoading() {
  return (
    <Flex w="full" p={4} justifyContent="center" alignItems="center" h="100%">
      <VStack>
        <Spinner size="xl" />
        <Text fontSize="sm">Loading data...</Text>
      </VStack>
    </Flex>
  );
}
