export enum TimeFrameEnum {
  // windows
  YTD = "YTD",
  LAST_YEAR = "Last Year",
  QTD = "QTD",
  LAST_QUARTER = "Last Quarter",
  MTD = "MTD",
  LAST_MONTH = "Last Month",
  WTD = "WTD",
  LAST_WEEK = "Last Week",

  // as at
  UP_TO_TODAY = "Today",
  UP_TO_YESTERDAY = "Yesterday",
  UP_TO_LAST_MONTH = "Up To Last Month",
  UP_TO_LAST_QUARTER = "Up To Last Quarter",
  UP_TO_LAST_YEAR = "Up To Last Year",

  // CUSTOM = "Custom", TODO: Implement this
}

export interface TimeFrame {
  timeFrame: TimeFrameEnum;
  startDate?: string;
  endDate?: string;
}
