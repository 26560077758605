import { ColDef } from "ag-grid-community";
import { createGroupColumn, ColumnTypeEnum } from "../../../../components";
import React from "react";
import { Indicator } from "../../../../components/grid/renderers/PriceIndicator";
import { HStack, Text } from "@chakra-ui/react";
import currencyFormatter from "currency-formatter";
import convertSymbolToReadable from "../../../../components/grid/utils/convertSymbolToReadable";

export const PricesColumnDefs: ColDef[] = [
  createGroupColumn("Symbol", "instrument.symbol", ColumnTypeEnum.Text, {
    valueGetter: (params) => {
      return convertSymbolToReadable(params?.data?.symbol);
    },
    aggFunc: "distinct",
  }),

  createGroupColumn("Latest price", "price", ColumnTypeEnum.ComponentColumn, {
    cellRenderer: (params: any) => {
      return (
        <HStack>
          <Text>
            {" "}
            {currencyFormatter.format(params.data.price, {
              code: "USD",
              precision: 2,
            })}
          </Text>
          <Indicator priceConfidence={params.data.priceConfidence} />;
        </HStack>
      );
    },
  }),

  //Price cahgne
  createGroupColumn("% Change (T-1)", "priceChange", ColumnTypeEnum.Percentage),
];
