import { useState, useEffect, ChangeEvent } from "react";
import { FormikHelpers, useFormik } from "formik";
import { createInsight, updateInsight } from "../../../api";
import { useNotifications } from "../../../hooks/useNotifications";
import { InsightTemplate, InsightType } from "../../../types";

interface UseInsightsCreatorControllerProps {
  insightsData: InsightTemplate;
  setInsightData: (insight: InsightTemplate) => void;
  setPage: (page: string) => void;
}

export const useInsightsCreatorController = ({
  insightsData,
  setInsightData,
  setPage,
}: UseInsightsCreatorControllerProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<"create" | "update">("create");
  const notifications = useNotifications();

  useEffect(() => {
    // Determine mode based on initial insight data
    setMode(insightsData.title === "" ? "create" : "update");

    // If no data, set a default template
    if (JSON.stringify(insightsData) === "{}") {
      setInsightData({
        title: "XX INSIGHT",
        type: InsightType.Number,
        query: "SELECT * FROM example_table",
        category: "Example",
        description: "This is an example insight.",
        tags: ["example", "insight"],
        isPublic: true,
        official: false,
        version: 1,
        newTag: "",
        insightId: 0,
        userActivations: 0,
        createdDate: new Date(),
        updatedDate: new Date(),
        ownerUserId: 0,
        isDeleted: false,
        minw: 1,
        maxw: 4,
        minh: 1,
        maxh: 4,
        width: 1,
        height: 1,
        isDefaultInsight: false,
        defaultX: 0,
        defaultY: 0,
      });
    }
  }, [insightsData, setInsightData]);

  const formik = useFormik({
    initialValues: insightsData,
    onSubmit: async (
      values: InsightTemplate,
      { setFieldError }: FormikHelpers<InsightTemplate>
    ) => {
      setLoading(true);
      try {
        let result;
        if (mode === "create") {
          result = await createInsight(values);
        } else {
          result = await updateInsight(values);
        }

        if (result.success) {
          notifications.createNotification(
            `Insight ${mode === "create" ? "created" : "updated"}`,
            `Your insight has been ${
              mode === "create" ? "created" : "updated"
            } successfully`
          );
          setPage("insights");
        } else {
          notifications.createNotification(
            "Validation error",
            "There was an error with your input"
          );
          if (result.details) {
            result.details.forEach(
              (error: { path: string; message: string }) => {
                setFieldError(error.path, error.message);
              }
            );
          }
        }
      } catch (error) {
        notifications.createNotification(
          `Error ${mode === "create" ? "creating" : "updating"} insight`,
          `There was an error ${
            mode === "create" ? "creating" : "updating"
          } your insight`
        );
        console.error(
          `Error ${mode === "create" ? "creating" : "updating"} insight:`,
          error
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const handleTagChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("newTag", e.target.value);
  };

  const addTag = () => {
    if (formik.values && formik.values.tags) {
      const newTag = (formik.values.newTag || "").trim();
      if (newTag && !formik.values.tags.includes(newTag)) {
        formik.setFieldValue("tags", [...formik.values.tags, newTag]);
        formik.setFieldValue("newTag", "");
      }
    }
  };

  const removeTag = (tag: string) => {
    if (
      tag &&
      formik.values &&
      formik.values.tags &&
      formik.values.tags.includes(tag)
    ) {
      formik.setFieldValue(
        "tags",
        formik.values.tags.filter((t: string) => t !== tag)
      );
    }
  };

  return {
    formik,
    loading,
    mode,
    handleTagChange,
    addTag,
    removeTag,
  };
};
