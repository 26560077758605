import React, { useMemo } from "react";
import {
  Flex,
  InputGroup,
  InputLeftAddon,
  Input,
  Select,
} from "@chakra-ui/react";
import { SecondaryButton } from "../../../components/design_library";
import { Brokers } from "../../../types";
import {
  AddOrEditConfigurationProps,
  useAddOrEditConfigurationController,
} from "./useAddOrEditAutoSyncConfigurationController";
import styles from "../AutoSync.module.css";
import { Show } from "../../../components";
import { IBKR_AutoSync } from "../Brokers/IBKR/IBKR_AutoSync";
import { ETRADE_AutoSync } from "../Brokers/ETRADE/ETRADE_AutoSync";
import SCHWAB_AutoSync from "../Brokers/SCHWAB/SCHWAB_AutoSync";

const supportedBrokers = [Brokers.IBKR, Brokers.ETrade, Brokers.Schwab];

export const AddOrEditAutoSyncConfiguration = (
  props: AddOrEditConfigurationProps
) => {
  const controller = useAddOrEditConfigurationController(props);

  const brokerOptions = useMemo(() => {
    return Object.values(supportedBrokers).map((item) => {
      return { value: item, label: item };
    });
  }, []);

  return (
    <Flex direction="column" w="full">
      <Show if={controller.new}>
        <InputGroup>
          <InputLeftAddon>Broker</InputLeftAddon>
          <Select
            className={styles.autoSyncBrokerSelect}
            placeholder="Select a broker to configure"
            size="sm"
            value={
              controller.selectedBroker ? controller.selectedBroker : undefined
            }
            onChange={(e) => {
              controller.setSelectedBroker(e.target.value as Brokers);
            }}
          >
            {brokerOptions.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </Select>
        </InputGroup>
      </Show>

      <Show if={!controller.new}>
        <InputGroup size="sm">
          <InputLeftAddon>Broker</InputLeftAddon>
          <Input disabled={true} value={controller.selectedBroker} />
        </InputGroup>
      </Show>

      <Flex flex={1} w="full" my={2}>
        <Show if={controller.selectedBroker === Brokers.IBKR}>
          <IBKR_AutoSync settings={controller.settings} close={props.close} />
        </Show>
        <Show if={controller.selectedBroker === Brokers.ETrade}>
          <ETRADE_AutoSync settings={controller.settings} close={props.close} />
        </Show>
        <Show if={controller.selectedBroker === Brokers.Schwab}>
          <SCHWAB_AutoSync settings={controller.settings} close={props.close} />
        </Show>
      </Flex>

      <Show if={controller.selectedBroker === undefined}>
        <Flex mb={1} direction="row-reverse">
          <SecondaryButton
            size="sm"
            onClick={() => {
              props.close();
            }}
          >
            Close
          </SecondaryButton>
        </Flex>
      </Show>
    </Flex>
  );
};
