import { TABLE_CLOSED_POSITIONS } from "./TABLE_CLOSED_POSITIONS";
import { TABLE_OPEN_POSITIONS } from "./TABLE_OPEN_POSITIONS";
import { TABLE_RECENTLY_CLOSED_POSITIONS } from "./TABLE_RECENTLY_CLOSED_POSITIONS";
import { TABLE_RECENTLY_TRADED_POSITIONS } from "./TABLE_RECENTLY_TRADED_POSITIONS";
import { TABLE_UPCOMING_EXPIRIES } from "./TABLE_UPCOMING_EXPIRIES";
import { TABLE_UNCOVERED_POSITIONS } from "./TABLE_UNCOVERED_POSITIONS";
import { TABLE_UNDERLYING_PRICES } from "./TABLE_UNDERLYING_PRICES";
import { TABLE_UPCOMING_EARNINGS } from "./TABLE_UPCOMING_EARNINGS";

const InsightTableDemoDataMap = {
  TABLE_RECENTLY_TRADED_POSITIONS: TABLE_RECENTLY_TRADED_POSITIONS,
  TABLE_OPEN_POSITIONS: TABLE_OPEN_POSITIONS,
  TABLE_CLOSED_POSITIONS: TABLE_CLOSED_POSITIONS,
  TABLE_UPCOMING_EXPIRIES: TABLE_UPCOMING_EXPIRIES,
  TABLE_UNCOVERED_POSITIONS: TABLE_UNCOVERED_POSITIONS,
  TABLE_UNDERLYING_PRICES: TABLE_UNDERLYING_PRICES,
  TABLE_UPCOMING_EARNINGS: TABLE_UPCOMING_EARNINGS,
  TABLE_RECENTLY_CLOSED_POSITIONS: TABLE_RECENTLY_CLOSED_POSITIONS,
};

export default InsightTableDemoDataMap;
