import React, { useMemo } from "react";
import { VStack, Text, Spinner, Flex, Center, HStack } from "@chakra-ui/react";
import { useInsightTableController } from "./useInsightTableController";
import { InsightStateEnum } from "../InsightStateEnum";
import {
  columnTypes,
  distinctValue,
  GridContainer,
  Show,
} from "../../../components";
import { BaseInsightProps } from "../BaseInsightProps";
import {
  closedPositionsColDefs,
  openPositionsColDefs,
  PricesColumnDefs,
  recentlyTradedPositionsColumnDefs,
  uncoveredPositionsColDefs,
  upcomingExpiriesColumnDefs,
} from "./ColumnDefinitions";
import {
  overlayLoadingTemplate,
  overlayNoRowsTemplate,
} from "../../../components/grid/NoRowsToShow";
import { PositionsInnerRender } from "../../positions/grid/PositionsInnerRender";
import { AgGridReact } from "ag-grid-react";
import ExampleTag from "../shared/ExampleTag";
import NoTimeFrameCompability from "../components/NoTimeframeCompatibility";
import InsightError from "../components/InsightError";
import InsightLoading from "../components/InsightLoading";
import NoDataAvailable from "../components/NoDataAvailable";
import InsightErrorRouter from "../components/InsightErrorRouter";

export const InsightTable = (props: BaseInsightProps) => {
  const { insight }: BaseInsightProps = props;
  const controller = useInsightTableController(props);

  const columnDefs = useMemo(() => {
    switch (insight.query) {
      case "TABLE_OPEN_POSITIONS":
        return openPositionsColDefs;
      case "TABLE_UNCOVERED_POSITIONS":
        return uncoveredPositionsColDefs;
      case "TABLE_UNDERLYING_PRICES":
        return PricesColumnDefs;
      case "TABLE_CLOSED_POSITIONS":
        return closedPositionsColDefs;
      case "TABLE_RECENTLY_TRADED_POSITIONS":
        return recentlyTradedPositionsColumnDefs;
      case "TABLE_RECENTLY_CLOSED_POSITIONS":
        return closedPositionsColDefs;
      case "TABLE_UPCOMING_EXPIRIES":
        return upcomingExpiriesColumnDefs;
      default:
        return [];
    }
  }, [insight.query]);

  const loadingOverlayComponent = useMemo(() => {
    return overlayLoadingTemplate;
  }, []);

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Retrieving positions...",
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return overlayNoRowsTemplate;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => "No positions found! ",
    };
  }, []);

  const data = useMemo(() => {
    if (controller.rows) {
      // Map data to row format
      const result = controller.rows.map((x: any) => ({
        ...x,
        id: x.positionId,
        positionName: x.positionGroup,
        instrument: {
          symbol: x.symbol,
          exchange: x.exchange,
          name: x.name,
          instrumentType: x.instrumentType,
          putCall: x.putCall,
          strike: x.strike,
          expiry: x.expiry,
          multiplier: x.multiplier,
        },
      }));
      return result;
    }
    return [];
  }, [controller.rows]);

  return (
    <Flex
      h="full"
      w="full"
      align="start"
      key={`insight-${insight.insightId}`}
      pb="20px"
    >
      {props.demo === true && <ExampleTag />}

      <InsightErrorRouter
        insightState={controller.insightState}
        supported={controller.supported}
      />

      <Show
        if={
          controller.supported === true &&
          controller.insightState === InsightStateEnum.Success
        }
      >
        <Flex p={4} direction="column" h="full" w="full">
          <Flex flex={1} maxH="100%">
            <GridContainer>
              <AgGridReact
                sideBar={{
                  toolPanels: [
                    {
                      id: "columns",
                      labelDefault: "Columns",
                      labelKey: "columns",
                      iconKey: "columns",
                      toolPanel: "agColumnsToolPanel",
                      toolPanelParams: {
                        suppressPivotMode: true,
                        suppressValues: true,
                        suppressRowGroups: true,
                      },
                    },
                  ],
                  position: "left",
                  defaultToolPanel: "filters",
                }}
                autoGroupColumnDef={{
                  cellRendererParams: {
                    suppressCount: true,
                    innerRenderer: PositionsInnerRender,
                  },
                  sortable: true,
                  width: 620,
                  resizable: true,
                  headerName: "Group",
                }}
                rowData={data}
                columnDefs={columnDefs}
                columnTypes={columnTypes}
                rowHeight={31}
                groupDefaultExpanded={3}
                showOpenedGroup={true}
                suppressAggFilteredOnly={false}
                suppressAggFuncInHeader={true}
                reactiveCustomComponents={true}
                aggFuncs={{
                  distinct: distinctValue,
                  none: () => "",
                }}
                suppressRowDrag={true}
                rowDragManaged={false}
                rowDragMultiRow={false}
                getRowId={(params: any) => {
                  return params.data.id;
                }}
                animateRows={true}
                loadingOverlayComponent={loadingOverlayComponent}
                loadingOverlayComponentParams={loadingOverlayComponentParams}
                noRowsOverlayComponent={noRowsOverlayComponent}
                noRowsOverlayComponentParams={noRowsOverlayComponentParams}
              />
            </GridContainer>
          </Flex>
        </Flex>
      </Show>
    </Flex>
  );
};
