import React from "react";
import { VStack, Spinner, Text } from "@chakra-ui/react";
import { FaSearchMinus } from "react-icons/fa";
import { InsightTemplate } from "../../../../../types/Insight";

interface LoadingOrEmptyStateProps {
  loading: boolean;
  insights: InsightTemplate[];
}

const LoadingOrEmptyState: React.FC<LoadingOrEmptyStateProps> = ({
  loading,
  insights,
}) => {
  if (loading && insights.length === 0) {
    return (
      <VStack h="100%" minH="300px" justifyContent="center" alignItems="center">
        <Spinner />
        <Text fontSize="lg">Loading insights...</Text>
      </VStack>
    );
  }

  if (!loading && insights.length === 0) {
    return (
      <VStack h="160px" justifyContent="center" mt="40px">
        <FaSearchMinus fontSize="40px" />
        <Text fontSize="lg" maxW="200px" textAlign="center">
          No insights found, try a different search term...
        </Text>
      </VStack>
    );
  }

  return null;
};

export default LoadingOrEmptyState;
