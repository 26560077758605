import React from "react";
import { HStack, FormControl, FormLabel, Select, Text } from "@chakra-ui/react";
import { FormikProps } from "formik";
import { InsightTemplate, InsightType } from "../../../../types";

interface TypeQueryFieldProps {
  formik: FormikProps<InsightTemplate>;
}

const TypeQueryField: React.FC<TypeQueryFieldProps> = ({ formik }) => {
  const { values, handleChange, handleBlur, touched, errors } = formik;

  const renderQueryOptions = () => {
    switch (values.type) {
      case "Table":
        return (
          <Select
            name="query"
            value={values.query}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value=""></option>
            <option value="TABLE_OPEN_POSITIONS">Open Positions</option>
            <option value="TABLE_CLOSED_POSITIONS">Closed Positions</option>
            <option value="TABLE_RECENTLY_TRADED_POSITIONS">
              Recently traded positions
            </option>
            <option value="TABLE_RECENTLY_CLOSED_POSITIONS">
              Recently closed positions
            </option>
            <option value="TABLE_UPCOMING_EXPIRIES">Upcoming expiries</option>
            <option value="TABLE_UNCOVERED_POSITIONS">Uncovered equity</option>
            <option value="TABLE_UNDERLYING_PRICES">Underlying Prices</option>
          </Select>
        );
      case "Treemap":
        return (
          <Select
            name="query"
            value={values.query || "SIZE_BY_POSITIONGROUP"}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value=""></option>
            <option value="SIZE_BY_POSITIONGROUP">
              Size by position group
            </option>
            <option value="SIZE_BY_BOOK">Size by book</option>
            <option value="SIZE_BY_STRATEGY">Size by strategy</option>
            <option value="SIZE_BY_UNDERLYING">Size by underlying</option>
          </Select>
        );
      case "Number":
        return (
          <Select
            name="query"
            value={values.query || ""}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value=""></option>
            <option value="REALIZED_PNL">Realized Profit & Loss</option>
            <option value="UNREALIZED_PNL">Unrealized Profit & Loss</option>
            <option value="TOTAL_PNL">Total Profit & Loss</option>
            <option value="TOTAL_GROSS_PROCEEDS">Total Gross Proceeds</option>
            <option value="TOTAL_NET_PROCEEDS">Total Net Proceeds</option>
            <option value="TOTAL_COSTS">Total Costs</option>
            <option value="AVG_DAYS_IN_POSITION">
              Average Days in Position
            </option>
            <option value="TOTAL_DEBITS">Total Debits</option>
            <option value="TOTAL_CREDITS">Total Credits</option>
            <option value="AVERAGE_PROFIT_PER_TRADING_DAY">
              Average Profit Per Trading Day
            </option>
            <option value="BIGGEST_WINNER">Biggest Winner</option>
            <option value="BIGGEST_LOSER">Biggest Loser</option>
          </Select>
        );
      case "Comparison":
        return (
          <Select
            name="query"
            value={values.query || "WIN_LOSS_PERCENTAGE"}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value=""></option>
            <option value="WIN_LOSS_PERCENTAGE">Win Loss Ratio (%)</option>
            <option value="AVG_WIN_LOSS_CURRENCY">
              Average Win Loss Ratio ($)
            </option>
          </Select>
        );
      case "Barchart":
        return (
          <Select
            name="query"
            value={values.query || "PNL_BY_DATE"}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value=""></option>
            <option value="PNL_BY_DATE">Profit & Loss By Date</option>
            <option value="PNL_BY_UNDERLYING">
              Profit & Loss By Underlying
            </option>
            <option value="CUMMULATIVE_PNL">Cumulative Profit & Loss</option>
            <option value="GET_WEEKDAY_PNL">P&L by Weekday</option>
            <option value="GET_PNL_BY_HOLDING_TIME">P&L by holding time</option>
            <option value="GET_PNL_BY_SEASON">P&L by season</option>
            <option value="GET_PNL_BY_TRADE_COUNT">P&L by trade count</option>
          </Select>
        );
      case "Linechart":
        return (
          <Select
            name="query"
            value={values.query || "CUMMULATIVE_PNL"}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value=""></option>
            <option value="CUMMULATIVE_PNL">Cumulative Profit & Loss</option>
          </Select>
        );
      default:
        return null;
    }
  };

  return (
    <HStack>
      <FormControl isInvalid={touched.type && !!errors.type}>
        <FormLabel>Type</FormLabel>
        <Select
          name="type"
          value={values.type}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {Object.values(InsightType).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
        {touched.type && errors.type ? (
          <Text color="red.500">{errors.type}</Text>
        ) : null}
      </FormControl>

      <FormControl isInvalid={touched.query && !!errors.query}>
        <FormLabel>Query</FormLabel>
        {renderQueryOptions()}
        {touched.query && errors.query ? (
          <Text color="red.500">{errors.query}</Text>
        ) : null}
      </FormControl>
    </HStack>
  );
};

export default TypeQueryField;
