import React from "react";
import { FormControl, FormLabel, Input, Text, HStack } from "@chakra-ui/react";
import { FormikProps } from "formik";
import { InsightTemplate } from "../../../../../types/Insight";

interface DimensionsFieldsProps {
  formik: FormikProps<InsightTemplate>;
}

const DimensionsFields: React.FC<DimensionsFieldsProps> = ({ formik }) => (
  <HStack>
    <FormControl isInvalid={formik.touched.width && !!formik.errors.width}>
      <FormLabel>Width</FormLabel>
      <Input
        name="width"
        type="number"
        value={formik.values.width || 1}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {formik.touched.width && formik.errors.width ? (
        <Text color="red.500">{formik.errors.width}</Text>
      ) : null}
    </FormControl>
    <FormControl isInvalid={formik.touched.height && !!formik.errors.height}>
      <FormLabel>Height</FormLabel>
      <Input
        name="height"
        type="number"
        value={formik.values.height || 2}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {formik.touched.height && formik.errors.height ? (
        <Text color="red.500">{formik.errors.height}</Text>
      ) : null}
    </FormControl>
  </HStack>
);

export default DimensionsFields;
