export const TABLE_CLOSED_POSITIONS = {
  rows: [
    {
      positionId: 16230,
      book: "",
      underlyingInstrumentId: 10474,
      underlyingName: "AAL",
      underlyingType: "Equity",
      underlyingSymbol: "AAL",
      underlyingExchange: "US",
      positionDate: "2021-03-15",
      positionGroup: "",
      strategy: "",
      tradeIds: [210659, 210599, 210601],
      instrumentId: 10514,
      name: "AAL_20210416_1800_Put",
      symbol: "AAL_20210416_1800_Put",
      exchange: "US",
      instrumentType: "Option",
      currency: "USD",
      multiplier: 100,
      putCall: "Put",
      strike: 18,
      expiry: "2021-04-16T00:00:00.000Z",
      quantity: 0,
      averagePrice: 0,
      grossProceeds: 228,
      netProceeds: 224.93,
      realisedPnl: 224.93,
      unrealisedPnl: 0,
      costs: 3.07,
      premiumReceived: 323.99,
      winLoss: 1,
      daysInPosition: 17,
      totalDebits: -49.53,
      totalCredits: 274.46,
      tags: "",
      price: 0,
      priceDate: null,
      priceConfidence: null,
      underlyingPrice: 0,
      underlyingPriceDate: null,
      underlyingPriceConfidence: null,
      positionKey: "default-10514-default",
      costBasis: 274.46,
    },
    {
      positionId: 16126,
      book: "",
      underlyingInstrumentId: 10474,
      underlyingName: "AAL",
      underlyingType: "Equity",
      underlyingSymbol: "AAL",
      underlyingExchange: "US",
      positionDate: "2021-04-09",
      positionGroup: "",
      strategy: "",
      tradeIds: [210598, 210600, 210492],
      instrumentId: 10475,
      name: "AAL_20210416_2200_Put",
      symbol: "AAL_20210416_2200_Put",
      exchange: "US",
      instrumentType: "Option",
      currency: "USD",
      multiplier: 100,
      putCall: "Put",
      strike: 22,
      expiry: "2021-04-16T00:00:00.000Z",
      quantity: 0,
      averagePrice: 0,
      grossProceeds: 171,
      netProceeds: 167.93,
      realisedPnl: 167.93,
      unrealisedPnl: 0,
      costs: 3.07,
      premiumReceived: 279.01,
      winLoss: 1,
      daysInPosition: 25,
      totalDebits: -55.54,
      totalCredits: 223.47,
      tags: "",
      price: 0,
      priceDate: null,
      priceConfidence: null,
      underlyingPrice: 0,
      underlyingPriceDate: null,
      underlyingPriceConfidence: null,
      positionKey: "default-10475-default",
      costBasis: 223.47,
    },
    {
      positionId: 16849,
      book: "",
      underlyingInstrumentId: 10841,
      underlyingName: "AAP",
      underlyingType: "Equity",
      underlyingSymbol: "AAP",
      underlyingExchange: "US",
      positionDate: "2019-02-21",
      positionGroup: "",
      strategy: "",
      tradeIds: [211561, 211549],
      instrumentId: 10842,
      name: "AAP_20190621_17000_Call",
      symbol: "AAP_20190621_17000_Call",
      exchange: "US",
      instrumentType: "Option",
      currency: "USD",
      multiplier: 100,
      putCall: "Call",
      strike: 170,
      expiry: "2019-06-21T00:00:00.000Z",
      quantity: 0,
      averagePrice: 0,
      grossProceeds: -357,
      netProceeds: -367.91,
      realisedPnl: -367.91,
      unrealisedPnl: 0,
      costs: 10.91,
      premiumReceived: 2082.99,
      winLoss: -1,
      daysInPosition: 10,
      totalDebits: -1225.45,
      totalCredits: 857.54,
      tags: "",
      price: 0,
      priceDate: null,
      priceConfidence: null,
      underlyingPrice: 0,
      underlyingPriceDate: null,
      underlyingPriceConfidence: null,
      positionKey: "default-10842-default",
      costBasis: -1225.45,
    },
    {
      positionId: 16757,
      book: "",
      underlyingInstrumentId: 9649,
      underlyingName: "AAPL",
      underlyingType: "Equity",
      underlyingSymbol: "AAPL",
      underlyingExchange: "US",
      positionDate: "2019-07-08",
      positionGroup: "",
      strategy: "",
      tradeIds: [211440, 211410],
      instrumentId: 10786,
      name: "AAPL_20191018_19000_Call",
      symbol: "AAPL_20191018_19000_Call",
      exchange: "US",
      instrumentType: "Option",
      currency: "USD",
      multiplier: 100,
      putCall: "Call",
      strike: 190,
      expiry: "2019-10-18T00:00:00.000Z",
      quantity: 0,
      averagePrice: 0,
      grossProceeds: 414,
      netProceeds: 403.07,
      realisedPnl: 403.07,
      unrealisedPnl: 0,
      costs: 10.93,
      premiumReceived: 2895.97,
      winLoss: 1,
      daysInPosition: 31,
      totalDebits: -1246.45,
      totalCredits: 1649.52,
      tags: "",
      price: 0,
      priceDate: null,
      priceConfidence: null,
      underlyingPrice: 223.2,
      underlyingPriceDate: "2024-11-11",
      underlyingPriceConfidence: "Delayed",
      positionKey: "default-10786-default",
      costBasis: -1246.45,
    },
    {
      positionId: 16423,
      book: "",
      underlyingInstrumentId: 10607,
      underlyingName: "ABNB",
      underlyingType: "Equity",
      underlyingSymbol: "ABNB",
      underlyingExchange: "US",
      positionDate: "2021-01-04",
      positionGroup: "",
      strategy: "",
      tradeIds: [210865, 210835],
      instrumentId: 10607,
      name: "ABNB",
      symbol: "ABNB",
      exchange: "US",
      instrumentType: "Equity",
      currency: "USD",
      multiplier: 1,
      putCall: null,
      expiry: null,
      quantity: 0,
      averagePrice: 0,
      grossProceeds: 0.6,
      netProceeds: 0.54,
      realisedPnl: 0.54,
      unrealisedPnl: 0,
      costs: 0.06,
      premiumReceived: 0,
      winLoss: 1,
      daysInPosition: 19,
      totalDebits: -2799.4,
      totalCredits: 2799.94,
      tags: "",
      price: 0,
      priceDate: null,
      priceConfidence: null,
      underlyingPrice: 0,
      underlyingPriceDate: null,
      underlyingPriceConfidence: null,
      positionKey: "default-10607-default",
      costBasis: -2799.4,
    },
    {
      positionId: 16861,
      book: "",
      underlyingInstrumentId: 10850,
      underlyingName: "ABT",
      underlyingType: "Equity",
      underlyingSymbol: "ABT",
      underlyingExchange: "US",
      positionDate: "2019-02-07",
      positionGroup: "",
      strategy: "",
      tradeIds: [211575, 211568],
      instrumentId: 10851,
      name: "ABT_20190517_7000_Call",
      symbol: "ABT_20190517_7000_Call",
      exchange: "US",
      instrumentType: "Option",
      currency: "USD",
      multiplier: 100,
      putCall: "Call",
      strike: 70,
      expiry: "2019-05-17T00:00:00.000Z",
      quantity: 0,
      averagePrice: 0,
      grossProceeds: 105,
      netProceeds: 92.01,
      realisedPnl: 92.01,
      unrealisedPnl: 0,
      costs: 12.99,
      premiumReceived: 2744.97,
      winLoss: 1,
      daysInPosition: 13,
      totalDebits: -1326.48,
      totalCredits: 1418.49,
      tags: "",
      price: 0,
      priceDate: null,
      priceConfidence: null,
      underlyingPrice: 0,
      underlyingPriceDate: null,
      underlyingPriceConfidence: null,
      positionKey: "default-10851-default",
      costBasis: -1326.48,
    },
    {
      positionId: 17124,
      book: "",
      underlyingInstrumentId: 10977,
      underlyingName: "ACN",
      underlyingType: "Equity",
      underlyingSymbol: "ACN",
      underlyingExchange: "US",
      positionDate: "2018-01-30",
      positionGroup: "",
      strategy: "",
      tradeIds: [211966, 211967, 211906, 211907],
      instrumentId: 11013,
      name: "ACN_20180518_15000_Call",
      symbol: "ACN_20180518_15000_Call",
      exchange: "US",
      instrumentType: "Option",
      currency: "USD",
      multiplier: 100,
      putCall: "Call",
      strike: 150,
      expiry: "2018-05-18T00:00:00.000Z",
      quantity: 0,
      averagePrice: 0,
      grossProceeds: 1181,
      netProceeds: 1169.06,
      realisedPnl: 1169.06,
      unrealisedPnl: 0,
      costs: 11.94,
      premiumReceived: 4143,
      winLoss: 1,
      daysInPosition: 53,
      totalDebits: -1486.97,
      totalCredits: 2656.03,
      tags: "",
      price: 0,
      priceDate: null,
      priceConfidence: null,
      underlyingPrice: 0,
      underlyingPriceDate: null,
      underlyingPriceConfidence: null,
      positionKey: "default-11013-default",
      costBasis: -1486.97,
    },
    {
      positionId: 17068,
      book: "",
      underlyingInstrumentId: 10977,
      underlyingName: "ACN",
      underlyingType: "Equity",
      underlyingSymbol: "ACN",
      underlyingExchange: "US",
      positionDate: "2018-04-30",
      positionGroup: "",
      strategy: "",
      tradeIds: [211881, 211844],
      instrumentId: 10978,
      name: "ACN_20180817_16500_Call",
      symbol: "ACN_20180817_16500_Call",
      exchange: "US",
      instrumentType: "Option",
      currency: "USD",
      multiplier: 100,
      putCall: "Call",
      strike: 165,
      expiry: "2018-08-17T00:00:00.000Z",
      quantity: 0,
      averagePrice: 0,
      grossProceeds: -1180,
      netProceeds: -1191.97,
      realisedPnl: -1191.97,
      unrealisedPnl: 0,
      costs: 11.97,
      premiumReceived: 1859.99,
      winLoss: -1,
      daysInPosition: 69,
      totalDebits: -1525.98,
      totalCredits: 334.01,
      tags: "",
      price: 0,
      priceDate: null,
      priceConfidence: null,
      underlyingPrice: 0,
      underlyingPriceDate: null,
      underlyingPriceConfidence: null,
      positionKey: "default-10978-default",
      costBasis: -1525.98,
    },
    {
      positionId: 17066,
      book: "",
      underlyingInstrumentId: 9310,
      underlyingName: "ADBE",
      underlyingType: "Equity",
      underlyingSymbol: "ADBE",
      underlyingExchange: "US",
      positionDate: "2018-04-30",
      positionGroup: "",
      strategy: "",
      tradeIds: [211874, 211838],
      instrumentId: 10976,
      name: "ADBE_20180720_22000_Call",
      symbol: "ADBE_20180720_22000_Call",
      exchange: "US",
      instrumentType: "Option",
      currency: "USD",
      multiplier: 100,
      putCall: "Call",
      strike: 220,
      expiry: "2018-07-20T00:00:00.000Z",
      quantity: 0,
      averagePrice: 0,
      grossProceeds: -40,
      netProceeds: -52.02,
      realisedPnl: -52.02,
      unrealisedPnl: 0,
      costs: 12.02,
      premiumReceived: 5579.94,
      winLoss: -1,
      daysInPosition: 53,
      totalDebits: -2815.98,
      totalCredits: 2763.96,
      tags: "",
      price: 0,
      priceDate: null,
      priceConfidence: null,
      underlyingPrice: 0,
      underlyingPriceDate: null,
      underlyingPriceConfidence: null,
      positionKey: "default-10976-default",
      costBasis: -2815.98,
    },
  ],
};
