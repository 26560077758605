import { useAtomValue } from "jotai";
import { AppStateAtom } from "../../store";
import React, { lazy } from "react";
import "./Switcher/styles/fonts.css";
import "./Switcher/styles/ag-grid-no-native-widgets.css";
import { useSearchParams } from "react-router-dom";
import { Text, useColorMode } from "@chakra-ui/react";
const Retro = lazy(() => import("./Switcher/Retro"));
const Lime = lazy(() => import("./Switcher/Lime"));
const Alpine = lazy(() => import("./Switcher/Alpine"));
const Balham = lazy(() => import("./Switcher/Balham"));
const Material = lazy(() => import("./Switcher/Material"));
const Quartz = lazy(() => import("./Switcher/Quartz"));
const TastyWorks = lazy(() => import("./Switcher/TastyWorks"));

export default function ThemeSwitcher() {
  const appState = useAtomValue(AppStateAtom);

  const gridTheme =
    new URLSearchParams(window.location.search).get("theme") ||
    appState.gridTheme;

  const { colorMode, toggleColorMode } = useColorMode();
  if (colorMode === "dark") {
    return (
      <React.Suspense fallback={<></>}>
        {/* {gridTheme === "ag-theme-alpine" && <Alpine />}
        {gridTheme === "ag-theme-tasty" && <TastyWorks />}
        {gridTheme === "ag-theme-lime" && <Lime />}
        {gridTheme === "ag-theme-retro" && <Retro />}
        {gridTheme === "ag-theme-balham" && <Balham />}
        {gridTheme === "ag-theme-balham-dark" && <Balham />}
        {gridTheme === "ag-theme-material" && <Material />}
        {gridTheme === "ag-theme-material-dark" && <Material />}
        {gridTheme === "ag-theme-quartz" && <Quartz />}
        {gridTheme === "ag-theme-quartz-dark" && <Quartz />} */}
        <Quartz />
      </React.Suspense>
    );
  } else {
    return (
      <React.Suspense fallback={<></>}>
        {/* {gridTheme === "ag-theme-alpine" && <Alpine />}
        {gridTheme === "ag-theme-tasty" && <TastyWorks />}
        {gridTheme === "ag-theme-lime" && <Lime />}
        {gridTheme === "ag-theme-retro" && <Retro />}
        {gridTheme === "ag-theme-balham" && <Balham />}
        {gridTheme === "ag-theme-balham-dark" && <Balham />}
        {gridTheme === "ag-theme-material" && <Material />}
        {gridTheme === "ag-theme-material-dark" && <Material />}
        {gridTheme === "ag-theme-quartz" && <Quartz />}
        {gridTheme === "ag-theme-quartz-dark" && <Quartz />} */}
        <Alpine />
      </React.Suspense>
    );
  }
}
