export enum ColumnTypeEnum {
  Boolean,
  Complex,
  Currency,
  Date,
  Numeric,
  Percentage,
  Pill,
  Symbol,
  Text,
  Indicator,
  Tags,
  Logo,
  TradeDetail,
  PriceDetail,
  CurrencyWithPercentage,
  CurrencyWithPercentageOfStock,
  ComponentColumn,
}

export const columnTypes = {
  currency: {},
  component: {},
  percentage: {},
  pill: {},
  symbol: {},
  complex: {},
  indicator: {},
  tags: {},
  text: {},
  date: {},
  logo: {},
  tradeDetail: {},
  priceDetail: {},
  price: {},
  currencyWithPercentage: {},
  currencyWithPercentageOfStock: {},
  numeric: {},
  boolean: {},
};
