import React, { useCallback, useMemo, useRef } from "react";
import {
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  Text,
  Box,
  Button,
  Input,
  Spacer,
  VStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogBody,
  PopoverArrow,
} from "@chakra-ui/react";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import { useDashboardSelectorController } from "./useDashboardSelectorController";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { Show } from "../../components";
import { _deleteLayout } from "../../api";
import { DashboardConfiguration } from "../../types/DashboardConfiguration";
import PopoverContentTransparent from "../../components/design_library/PopoverContentTransparent";
import DropDownButton from "../../components/design_library/DropDownButton";
import {
  IoCheckmark,
  IoRadioButtonOff,
  IoRadioButtonOn,
} from "react-icons/io5";
import ActionButton from "../../components/design_library/ActionButton";
import { SecondaryButton } from "../../components/design_library";

export const DashboardSelector = () => {
  const renameDashboardInput = useRef<HTMLInputElement>(null);
  const newDashboardInput = useRef<HTMLInputElement>(null);
  const [showAlert, setShowAlert] = React.useState(false);
  const cancelRef = React.useRef<any>();

  const [editRow, setEditRow] = React.useState<number | undefined>(undefined);
  const [deleteDashboard, setDeleteDashboardRow] = React.useState<
    DashboardConfiguration | undefined
  >(undefined);

  const controller = useDashboardSelectorController();

  const dashboards = useMemo(() => {
    return controller.configurations.map((item) => {
      return { value: item, label: item.name };
    });
  }, [controller.configurations]);

  const doDelete = async () => {
    if (deleteDashboard?.configurationId) {
      await controller.deleteDashboard(deleteDashboard.configurationId);
    }
    setDeleteDashboardRow(undefined);
    setShowAlert(false);
  };

  const confirmDelete = useCallback(() => {
    return (
      <AlertDialog
        isOpen={showAlert}
        leastDestructiveRef={cancelRef}
        onClose={() => setShowAlert(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="var(--bg)">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Dashboard ({deleteDashboard?.name})
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setShowAlert(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => doDelete()} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }, [deleteDashboard, showAlert]);

  const [expanded, setExpanded] = React.useState(false);
  return (
    <>
      {confirmDelete()}
      <Popover
        isOpen={expanded}
        onClose={() => setExpanded(false)}
        closeOnBlur={true}
        placement="bottom-start"
        arrowSize={15}
      >
        <PopoverTrigger>
          <Flex>
            <DropDownButton
              aria-expanded={expanded}
              onClick={() => setExpanded(!expanded)}
            >
              <HStack cursor="pointer">
                {controller.selectedDashboard && (
                  <Text
                    maxW="200px"
                    minWidth="100px"
                    overflow={"hidden"}
                    textAlign={"left"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                  >
                    {controller.selectedDashboard}
                  </Text>
                )}
                {!controller.selectedDashboard && (
                  <Text color="yellow.200">Not Specified</Text>
                )}
              </HStack>
            </DropDownButton>
          </Flex>
        </PopoverTrigger>
        <PopoverContent bg="var(--bg)" p={0} mt="-4px" maxW="250px">
          <PopoverArrow bg="var(--bg)" />
          <PopoverBody py={2} m={0} w="full">
            <Text fontSize="sm" fontWeight="bold">
              Dashboard
            </Text>
            <VStack align="stretch" p={0} spacing={0}>
              <Box maxHeight="200px" overflowY="auto">
                {dashboards.map((dashboard, index) => (
                  <Button
                    key={index}
                    h="28px"
                    justifyContent="space-between"
                    bg="transparent"
                    onClick={(e) => {
                      e.preventDefault();
                      controller.setSelectedConfiguration(
                        dashboard.value.configurationId!
                      );
                      // setExpanded(false);
                    }}
                    width="full"
                    py={0}
                    pr={2}
                    pl={0}
                  >
                    <HStack w="full">
                      <Show if={editRow !== dashboard.value.configurationId}>
                        {controller.selectedDashboard !== dashboard.label ? (
                          <IoRadioButtonOff color="var(--white)" />
                        ) : (
                          <IoRadioButtonOn color="var(--white)" />
                        )}

                        <Text
                          maxW="200px"
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          fontSize="14px"
                        >
                          {dashboard.label}
                        </Text>
                      </Show>
                      <Show if={editRow === dashboard.value.configurationId}>
                        <Input
                          h="28px"
                          w="full"
                          m="0"
                          onClick={(e) => e.stopPropagation()}
                          ref={renameDashboardInput}
                          defaultValue={dashboard.label}
                          onKeyUp={(e) => {
                            e.preventDefault();

                            if (e.code === "Enter") {
                              controller.renameDashboard(
                                dashboard.value.configurationId!,
                                renameDashboardInput.current?.value ?? ""
                              );
                              setExpanded(false);
                              setEditRow(undefined);
                            }
                          }}
                        />
                      </Show>
                      <Spacer />
                      <Tooltip label="Rename Dashboard">
                        <EditIcon
                          fontSize="12px"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditRow(dashboard.value.configurationId);
                          }}
                          color="var(--white)"
                        />
                      </Tooltip>
                      <Tooltip
                        label={
                          dashboards.length === 1
                            ? "You can't delete your only dashboard."
                            : "Delete dashboard"
                        }
                      >
                        <DeleteIcon
                          fontSize="12px"
                          color={
                            dashboards.length === 1
                              ? "var(--dark-grey)"
                              : "var(--red)"
                          }
                          cursor={dashboards.length !== 1 ? "pointer" : "auto"}
                          onClick={(e) => {
                            if (dashboards.length === 1) {
                              return;
                            }
                            e.stopPropagation();
                            setDeleteDashboardRow(dashboard.value);
                            setShowAlert(true);
                          }}
                        />
                      </Tooltip>
                    </HStack>
                  </Button>
                ))}
              </Box>

              <Divider mt="2" />
              <Flex direction="column" py={2}>
                <Input
                  h="38px"
                  fontSize="14px"
                  ref={newDashboardInput}
                  placeholder="Add new dashboard"
                  onKeyUp={(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault();
                      controller.createDashboard(
                        newDashboardInput.current?.value ?? ""
                      );
                      setExpanded(false);
                    }
                  }}
                />
                <HStack mt={2} w="full">
                  <SecondaryButton
                    w="full"
                    onClick={(e) => {
                      e.preventDefault();
                      controller.createDashboard(
                        newDashboardInput.current?.value ?? ""
                      );
                      setExpanded(false);
                    }}
                  >
                    Create Dashboard
                  </SecondaryButton>
                </HStack>
              </Flex>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
