import { Button } from "@chakra-ui/react";
import styles from "./index.module.css";
import "./style.css";

export const SecondaryButton = (props: any) => {
  let overrideStyleString = `${styles.button} ${styles.secondaryButton}`;

  if (props.fat === true) {
    overrideStyleString = overrideStyleString + ` ${styles.customSecondary}`;
  }

  return (
    <Button {...props} className={overrideStyleString} maxHeight="28px">
      {props.children}
    </Button>
  );
};
