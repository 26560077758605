export const PNL_BY_YEAR = {
  data: [
    {
      domain: "2021-12-31",
      realisedPnl: 19734.34,
    },
    {
      domain: "2022-12-31",
      realisedPnl: -2977.12,
    },
    {
      domain: "2023-12-31",
      realisedPnl: 9817.54,
    },
    {
      domain: "2024-12-31",
      realisedPnl: 13196.91,
    },
  ],
  chartProperties: {
    name: "PnL By Year (Realised)",
    chartType: "bar",
    keys: ["realisedPnl"],
    colors: ["var(--chart-blue)"],
    legend: ["Realized PnL"],
    domainType: "date",
  },
};
