export const TABLE_OPEN_POSITIONS = {
  rows: [
    {
      positionId: 17226,
      book: "",
      underlyingInstrumentId: 10474,
      underlyingName: "AAL",
      underlyingType: "Equity",
      underlyingSymbol: "AAL",
      underlyingExchange: "US",
      positionDate: "2017-08-23",
      positionGroup: "",
      strategy: "",
      tradeIds: [212090, 212091],
      instrumentId: 10474,
      name: "AAL",
      symbol: "AAL",
      exchange: "US",
      instrumentType: "Equity",
      currency: "USD",
      multiplier: 1,
      putCall: null,
      expiry: null,
      quantity: -100.3621,
      averagePrice: 44.2642,
      grossProceeds: 4447.5,
      netProceeds: 4442.45,
      realisedPnl: 0,
      unrealisedPnl: 0,
      costs: 5.05,
      premiumReceived: 0,
      winLoss: 0,
      daysInPosition: 2674.84,
      totalDebits: 0,
      totalCredits: 4442.45,
      tags: "",
      price: 0,
      priceDate: null,
      priceConfidence: null,
      underlyingPrice: 0,
      underlyingPriceDate: null,
      underlyingPriceConfidence: null,
      positionKey: "default-10474-default",
      costBasis: 4442.45,
    },
    {
      positionId: 16327,
      book: "",
      underlyingInstrumentId: 9649,
      underlyingName: "AAPL",
      underlyingType: "Equity",
      underlyingSymbol: "AAPL",
      underlyingExchange: "US",
      positionDate: "2021-02-22",
      positionGroup: "",
      strategy: "",
      tradeIds: [
        211800, 211714, 211649, 211650, 211089, 211073, 211062, 210964, 210965,
        210936, 210710, 210687, 210688, 210689,
      ],
      instrumentId: 9649,
      name: "AAPL",
      symbol: "AAPL",
      exchange: "US",
      instrumentType: "Equity",
      currency: "USD",
      multiplier: 1,
      putCall: null,
      expiry: null,
      quantity: -0.1149,
      averagePrice: 55585.9878,
      grossProceeds: -6376.27,
      netProceeds: -6386.83,
      realisedPnl: -6401.4,
      unrealisedPnl: 6361.18,
      costs: 10.56,
      premiumReceived: 0,
      winLoss: -1,
      daysInPosition: 2403.84,
      totalDebits: -40683.03,
      totalCredits: 34296.21,
      tags: "",
      price: 223.2,
      priceDate: "2024-11-11",
      priceConfidence: "Delayed",
      underlyingPrice: 223.2,
      underlyingPriceDate: "2024-11-11",
      underlyingPriceConfidence: "Delayed",
      positionKey: "default-9649-default",
      costBasis: -28569.03,
    },
  ],
};
