export const GET_WEEKDAY_PNL = {
  data: [
    {
      domain: "Monday",
      totalPnl: -303.73,
    },
    {
      domain: "Monday",
      totalPnl: -708.01,
    },
    {
      domain: "Monday",
      totalPnl: 4594.41,
    },
    {
      domain: "Monday",
      totalPnl: -386,
    },
    {
      domain: "Monday",
      totalPnl: 141.99,
    },
    {
      domain: "Monday",
      totalPnl: 641.51,
    },
    {
      domain: "Monday",
      totalPnl: 59.28,
    },
    {
      domain: "Monday",
      totalPnl: 310.4,
    },
    {
      domain: "Monday",
      totalPnl: 495.87,
    },
    {
      domain: "Monday",
      totalPnl: -245.09,
    },
    {
      domain: "Monday",
      totalPnl: 1226.93,
    },
    {
      domain: "Monday",
      totalPnl: -3591.58,
    },
    {
      domain: "Monday",
      totalPnl: -3264.96,
    },
    {
      domain: "Monday",
      totalPnl: 435.92,
    },
    {
      domain: "Monday",
      totalPnl: 2537.82,
    },
    {
      domain: "Monday",
      totalPnl: 4645.92,
    },
    {
      domain: "Monday",
      totalPnl: 224.93,
    },
    {
      domain: "Monday",
      totalPnl: -1171.99,
    },
    {
      domain: "Monday",
      totalPnl: -1136.04,
    },
    {
      domain: "Monday",
      totalPnl: -1930.95,
    },
    {
      domain: "Monday",
      totalPnl: 913.65,
    },
    {
      domain: "Monday",
      totalPnl: 3713.38,
    },
    {
      domain: "Monday",
      totalPnl: -1364.01,
    },
    {
      domain: "Monday",
      totalPnl: 1774.82,
    },
    {
      domain: "Monday",
      totalPnl: -398,
    },
    {
      domain: "Monday",
      totalPnl: 933.99,
    },
    {
      domain: "Monday",
      totalPnl: -1654.9,
    },
    {
      domain: "Monday",
      totalPnl: -157.97,
    },
    {
      domain: "Monday",
      totalPnl: -1873.39,
    },
    {
      domain: "Monday",
      totalPnl: 41.96,
    },
    {
      domain: "Monday",
      totalPnl: 1110.81,
    },
    {
      domain: "Monday",
      totalPnl: -234.93,
    },
    {
      domain: "Monday",
      totalPnl: 953.82,
    },
    {
      domain: "Monday",
      totalPnl: 145.08,
    },
    {
      domain: "Monday",
      totalPnl: 722.86,
    },
    {
      domain: "Monday",
      totalPnl: -1958.03,
    },
    {
      domain: "Monday",
      totalPnl: -7.81,
    },
    {
      domain: "Monday",
      totalPnl: -777.12,
    },
    {
      domain: "Monday",
      totalPnl: 4434.01,
    },
    {
      domain: "Monday",
      totalPnl: 138,
    },
    {
      domain: "Monday",
      totalPnl: -657.97,
    },
    {
      domain: "Monday",
      totalPnl: 69.98,
    },
    {
      domain: "Monday",
      totalPnl: 2653.95,
    },
    {
      domain: "Monday",
      totalPnl: -6,
    },
    {
      domain: "Monday",
      totalPnl: -881.87,
    },
    {
      domain: "Monday",
      totalPnl: 198.68,
    },
    {
      domain: "Monday",
      totalPnl: 505.87,
    },
    {
      domain: "Monday",
      totalPnl: 2.98,
    },
    {
      domain: "Monday",
      totalPnl: 793.85,
    },
    {
      domain: "Monday",
      totalPnl: -702.98,
    },
    {
      domain: "Monday",
      totalPnl: 2250.05,
    },
    {
      domain: "Monday",
      totalPnl: 575.47,
    },
    {
      domain: "Monday",
      totalPnl: 69.09,
    },
    {
      domain: "Monday",
      totalPnl: -4603.33,
    },
    {
      domain: "Monday",
      totalPnl: 289.87,
    },
    {
      domain: "Monday",
      totalPnl: 403.07,
    },
    {
      domain: "Monday",
      totalPnl: -1018.97,
    },
    {
      domain: "Monday",
      totalPnl: 653.98,
    },
    {
      domain: "Monday",
      totalPnl: -847.06,
    },
    {
      domain: "Monday",
      totalPnl: -177,
    },
    {
      domain: "Monday",
      totalPnl: -9.09,
    },
    {
      domain: "Monday",
      totalPnl: -390.5,
    },
    {
      domain: "Monday",
      totalPnl: 7.35,
    },
    {
      domain: "Monday",
      totalPnl: -568.91,
    },
    {
      domain: "Monday",
      totalPnl: 188.59,
    },
    {
      domain: "Monday",
      totalPnl: 2886.99,
    },
    {
      domain: "Monday",
      totalPnl: -1188.01,
    },
    {
      domain: "Monday",
      totalPnl: -1885.02,
    },
    {
      domain: "Monday",
      totalPnl: 30.48,
    },
    {
      domain: "Monday",
      totalPnl: -448.11,
    },
    {
      domain: "Monday",
      totalPnl: -1382.83,
    },
    {
      domain: "Monday",
      totalPnl: 2050.07,
    },
    {
      domain: "Monday",
      totalPnl: -1840.1,
    },
    {
      domain: "Tuesday",
      totalPnl: 191.89,
    },
    {
      domain: "Tuesday",
      totalPnl: -131.53,
    },
    {
      domain: "Tuesday",
      totalPnl: 1161.07,
    },
    {
      domain: "Tuesday",
      totalPnl: 12046.04,
    },
    {
      domain: "Tuesday",
      totalPnl: 472.99,
    },
    {
      domain: "Tuesday",
      totalPnl: 122.93,
    },
    {
      domain: "Tuesday",
      totalPnl: -1240.92,
    },
    {
      domain: "Tuesday",
      totalPnl: -1333.1,
    },
    {
      domain: "Tuesday",
      totalPnl: 269.98,
    },
    {
      domain: "Tuesday",
      totalPnl: 7200.13,
    },
    {
      domain: "Tuesday",
      totalPnl: -385.97,
    },
    {
      domain: "Tuesday",
      totalPnl: -538.68,
    },
    {
      domain: "Tuesday",
      totalPnl: -440.26,
    },
    {
      domain: "Tuesday",
      totalPnl: -2332.78,
    },
    {
      domain: "Tuesday",
      totalPnl: -4737.25,
    },
    {
      domain: "Tuesday",
      totalPnl: 381.94,
    },
    {
      domain: "Tuesday",
      totalPnl: 1,
    },
    {
      domain: "Tuesday",
      totalPnl: 2019.12,
    },
    {
      domain: "Tuesday",
      totalPnl: 1098.76,
    },
    {
      domain: "Tuesday",
      totalPnl: -200,
    },
    {
      domain: "Tuesday",
      totalPnl: 7744.3,
    },
    {
      domain: "Tuesday",
      totalPnl: -720.32,
    },
    {
      domain: "Tuesday",
      totalPnl: 865.83,
    },
    {
      domain: "Tuesday",
      totalPnl: 243.99,
    },
    {
      domain: "Tuesday",
      totalPnl: 6712.27,
    },
    {
      domain: "Tuesday",
      totalPnl: 267.94,
    },
    {
      domain: "Tuesday",
      totalPnl: 235.85,
    },
    {
      domain: "Tuesday",
      totalPnl: 206.95,
    },
    {
      domain: "Tuesday",
      totalPnl: -881.97,
    },
    {
      domain: "Tuesday",
      totalPnl: -51.01,
    },
    {
      domain: "Tuesday",
      totalPnl: -237.92,
    },
    {
      domain: "Tuesday",
      totalPnl: -13044.82,
    },
    {
      domain: "Tuesday",
      totalPnl: 3009.03,
    },
    {
      domain: "Tuesday",
      totalPnl: 1163.18,
    },
    {
      domain: "Tuesday",
      totalPnl: -601.2,
    },
    {
      domain: "Tuesday",
      totalPnl: 437.82,
    },
    {
      domain: "Tuesday",
      totalPnl: 52.71,
    },
    {
      domain: "Tuesday",
      totalPnl: 3025.82,
    },
    {
      domain: "Tuesday",
      totalPnl: 139.1,
    },
    {
      domain: "Tuesday",
      totalPnl: -9357.88,
    },
    {
      domain: "Tuesday",
      totalPnl: 1877.82,
    },
    {
      domain: "Tuesday",
      totalPnl: -153.47,
    },
    {
      domain: "Tuesday",
      totalPnl: 1627.06,
    },
    {
      domain: "Tuesday",
      totalPnl: -1171.89,
    },
    {
      domain: "Tuesday",
      totalPnl: 508.86,
    },
    {
      domain: "Tuesday",
      totalPnl: -64.9,
    },
    {
      domain: "Tuesday",
      totalPnl: 380.29,
    },
    {
      domain: "Tuesday",
      totalPnl: 589.91,
    },
    {
      domain: "Tuesday",
      totalPnl: -1111.97,
    },
    {
      domain: "Tuesday",
      totalPnl: -487.55,
    },
    {
      domain: "Tuesday",
      totalPnl: 1269.9,
    },
    {
      domain: "Tuesday",
      totalPnl: -82.9,
    },
    {
      domain: "Tuesday",
      totalPnl: -100.02,
    },
    {
      domain: "Tuesday",
      totalPnl: 144.77,
    },
    {
      domain: "Wednesday",
      totalPnl: -435.99,
    },
    {
      domain: "Wednesday",
      totalPnl: -432.06,
    },
    {
      domain: "Wednesday",
      totalPnl: 3282.92,
    },
    {
      domain: "Wednesday",
      totalPnl: -1004.14,
    },
    {
      domain: "Wednesday",
      totalPnl: -1120.11,
    },
    {
      domain: "Wednesday",
      totalPnl: -769.02,
    },
    {
      domain: "Wednesday",
      totalPnl: -230.68,
    },
    {
      domain: "Wednesday",
      totalPnl: -983.52,
    },
    {
      domain: "Wednesday",
      totalPnl: 439.49,
    },
    {
      domain: "Wednesday",
      totalPnl: 548.02,
    },
    {
      domain: "Wednesday",
      totalPnl: -631.03,
    },
    {
      domain: "Wednesday",
      totalPnl: -52.01,
    },
    {
      domain: "Wednesday",
      totalPnl: 221.85,
    },
    {
      domain: "Wednesday",
      totalPnl: -481.02,
    },
    {
      domain: "Wednesday",
      totalPnl: -2219.1,
    },
    {
      domain: "Wednesday",
      totalPnl: 74,
    },
    {
      domain: "Wednesday",
      totalPnl: 221.15,
    },
    {
      domain: "Wednesday",
      totalPnl: 2522.03,
    },
    {
      domain: "Wednesday",
      totalPnl: 249.07,
    },
    {
      domain: "Wednesday",
      totalPnl: 268,
    },
    {
      domain: "Wednesday",
      totalPnl: -939.94,
    },
    {
      domain: "Wednesday",
      totalPnl: -779.03,
    },
    {
      domain: "Wednesday",
      totalPnl: -575.14,
    },
    {
      domain: "Wednesday",
      totalPnl: 112,
    },
    {
      domain: "Wednesday",
      totalPnl: -1109.3,
    },
    {
      domain: "Wednesday",
      totalPnl: 937.93,
    },
    {
      domain: "Wednesday",
      totalPnl: 338.99,
    },
    {
      domain: "Wednesday",
      totalPnl: 1115.86,
    },
    {
      domain: "Wednesday",
      totalPnl: 34,
    },
    {
      domain: "Wednesday",
      totalPnl: -345.15,
    },
    {
      domain: "Wednesday",
      totalPnl: 1539.08,
    },
    {
      domain: "Wednesday",
      totalPnl: 84,
    },
    {
      domain: "Wednesday",
      totalPnl: 500.71,
    },
    {
      domain: "Wednesday",
      totalPnl: 182.04,
    },
    {
      domain: "Wednesday",
      totalPnl: 1484.87,
    },
    {
      domain: "Wednesday",
      totalPnl: -1378.61,
    },
    {
      domain: "Wednesday",
      totalPnl: 38.98,
    },
    {
      domain: "Wednesday",
      totalPnl: -1670.83,
    },
    {
      domain: "Wednesday",
      totalPnl: -911.89,
    },
    {
      domain: "Wednesday",
      totalPnl: -538.82,
    },
    {
      domain: "Wednesday",
      totalPnl: -2317.68,
    },
    {
      domain: "Wednesday",
      totalPnl: 103.97,
    },
    {
      domain: "Wednesday",
      totalPnl: 386.94,
    },
    {
      domain: "Wednesday",
      totalPnl: -192,
    },
    {
      domain: "Wednesday",
      totalPnl: 289.89,
    },
    {
      domain: "Wednesday",
      totalPnl: -241.83,
    },
    {
      domain: "Wednesday",
      totalPnl: 394.99,
    },
    {
      domain: "Wednesday",
      totalPnl: 513,
    },
    {
      domain: "Wednesday",
      totalPnl: 147.94,
    },
    {
      domain: "Wednesday",
      totalPnl: 139.89,
    },
    {
      domain: "Wednesday",
      totalPnl: 1085.62,
    },
    {
      domain: "Wednesday",
      totalPnl: -5.02,
    },
    {
      domain: "Wednesday",
      totalPnl: -565.91,
    },
    {
      domain: "Wednesday",
      totalPnl: 2730.9,
    },
    {
      domain: "Wednesday",
      totalPnl: -485.91,
    },
    {
      domain: "Wednesday",
      totalPnl: 279.74,
    },
    {
      domain: "Wednesday",
      totalPnl: -2093.01,
    },
    {
      domain: "Wednesday",
      totalPnl: 1162.35,
    },
    {
      domain: "Wednesday",
      totalPnl: 329.98,
    },
    {
      domain: "Thursday",
      totalPnl: -1019.98,
    },
    {
      domain: "Thursday",
      totalPnl: -714.26,
    },
    {
      domain: "Thursday",
      totalPnl: -416.12,
    },
    {
      domain: "Thursday",
      totalPnl: -10006.3,
    },
    {
      domain: "Thursday",
      totalPnl: 1006.41,
    },
    {
      domain: "Thursday",
      totalPnl: 59.86,
    },
    {
      domain: "Thursday",
      totalPnl: -384.02,
    },
    {
      domain: "Thursday",
      totalPnl: -147.35,
    },
    {
      domain: "Thursday",
      totalPnl: 487.27,
    },
    {
      domain: "Thursday",
      totalPnl: 464.55,
    },
    {
      domain: "Thursday",
      totalPnl: 240.99,
    },
    {
      domain: "Thursday",
      totalPnl: 2128.11,
    },
    {
      domain: "Thursday",
      totalPnl: 92.01,
    },
    {
      domain: "Thursday",
      totalPnl: -387.83,
    },
    {
      domain: "Thursday",
      totalPnl: -347.64,
    },
    {
      domain: "Thursday",
      totalPnl: 902.76,
    },
    {
      domain: "Thursday",
      totalPnl: -490.14,
    },
    {
      domain: "Thursday",
      totalPnl: 448.05,
    },
    {
      domain: "Thursday",
      totalPnl: -720.07,
    },
    {
      domain: "Thursday",
      totalPnl: 88.04,
    },
    {
      domain: "Thursday",
      totalPnl: 282.58,
    },
    {
      domain: "Thursday",
      totalPnl: 1987.52,
    },
    {
      domain: "Thursday",
      totalPnl: -770.34,
    },
    {
      domain: "Thursday",
      totalPnl: 623.84,
    },
    {
      domain: "Thursday",
      totalPnl: 961.27,
    },
    {
      domain: "Thursday",
      totalPnl: -2574.87,
    },
    {
      domain: "Thursday",
      totalPnl: 1089.96,
    },
    {
      domain: "Thursday",
      totalPnl: 364.89,
    },
    {
      domain: "Thursday",
      totalPnl: -1682.17,
    },
    {
      domain: "Thursday",
      totalPnl: 97.94,
    },
    {
      domain: "Thursday",
      totalPnl: 257.04,
    },
    {
      domain: "Thursday",
      totalPnl: -255.99,
    },
    {
      domain: "Thursday",
      totalPnl: 3203.77,
    },
    {
      domain: "Thursday",
      totalPnl: 546,
    },
    {
      domain: "Thursday",
      totalPnl: -236,
    },
    {
      domain: "Thursday",
      totalPnl: -367.91,
    },
    {
      domain: "Thursday",
      totalPnl: -483.91,
    },
    {
      domain: "Thursday",
      totalPnl: -1008.04,
    },
    {
      domain: "Thursday",
      totalPnl: 103.98,
    },
    {
      domain: "Thursday",
      totalPnl: -511.23,
    },
    {
      domain: "Thursday",
      totalPnl: -1021.98,
    },
    {
      domain: "Thursday",
      totalPnl: -305.92,
    },
    {
      domain: "Thursday",
      totalPnl: 279.74,
    },
    {
      domain: "Thursday",
      totalPnl: -152.98,
    },
    {
      domain: "Thursday",
      totalPnl: -569.68,
    },
    {
      domain: "Thursday",
      totalPnl: 1064.02,
    },
    {
      domain: "Thursday",
      totalPnl: -301.02,
    },
    {
      domain: "Thursday",
      totalPnl: -694.07,
    },
    {
      domain: "Thursday",
      totalPnl: -577.99,
    },
    {
      domain: "Thursday",
      totalPnl: 176,
    },
    {
      domain: "Thursday",
      totalPnl: 203,
    },
    {
      domain: "Thursday",
      totalPnl: 2704.08,
    },
    {
      domain: "Thursday",
      totalPnl: -197.28,
    },
    {
      domain: "Thursday",
      totalPnl: 591.44,
    },
    {
      domain: "Thursday",
      totalPnl: -534.03,
    },
    {
      domain: "Friday",
      totalPnl: 370.88,
    },
    {
      domain: "Friday",
      totalPnl: 1022.01,
    },
    {
      domain: "Friday",
      totalPnl: 309,
    },
    {
      domain: "Friday",
      totalPnl: 162.94,
    },
    {
      domain: "Friday",
      totalPnl: -320.01,
    },
    {
      domain: "Friday",
      totalPnl: -1069.15,
    },
    {
      domain: "Friday",
      totalPnl: -1730.26,
    },
    {
      domain: "Friday",
      totalPnl: -1103.14,
    },
    {
      domain: "Friday",
      totalPnl: 48.02,
    },
    {
      domain: "Friday",
      totalPnl: 328,
    },
    {
      domain: "Friday",
      totalPnl: 1343.94,
    },
    {
      domain: "Friday",
      totalPnl: -422.05,
    },
    {
      domain: "Friday",
      totalPnl: -1.53,
    },
    {
      domain: "Friday",
      totalPnl: -1164.92,
    },
    {
      domain: "Friday",
      totalPnl: -12.98,
    },
    {
      domain: "Friday",
      totalPnl: 479.09,
    },
    {
      domain: "Friday",
      totalPnl: -2180.85,
    },
    {
      domain: "Friday",
      totalPnl: 689.01,
    },
    {
      domain: "Friday",
      totalPnl: 1935.6,
    },
    {
      domain: "Friday",
      totalPnl: -24.06,
    },
    {
      domain: "Friday",
      totalPnl: 785.84,
    },
    {
      domain: "Friday",
      totalPnl: -868.99,
    },
    {
      domain: "Friday",
      totalPnl: 403.05,
    },
    {
      domain: "Friday",
      totalPnl: -1240.92,
    },
    {
      domain: "Friday",
      totalPnl: 169.09,
    },
    {
      domain: "Friday",
      totalPnl: -666,
    },
    {
      domain: "Friday",
      totalPnl: 3083.35,
    },
    {
      domain: "Friday",
      totalPnl: 370.34,
    },
    {
      domain: "Friday",
      totalPnl: -3.99,
    },
    {
      domain: "Friday",
      totalPnl: -1374.55,
    },
    {
      domain: "Friday",
      totalPnl: -454.23,
    },
    {
      domain: "Friday",
      totalPnl: -1107.02,
    },
    {
      domain: "Friday",
      totalPnl: -525.98,
    },
    {
      domain: "Friday",
      totalPnl: 287.94,
    },
    {
      domain: "Friday",
      totalPnl: -855.97,
    },
    {
      domain: "Friday",
      totalPnl: 169.96,
    },
    {
      domain: "Friday",
      totalPnl: 228.97,
    },
    {
      domain: "Friday",
      totalPnl: 104.78,
    },
    {
      domain: "Friday",
      totalPnl: -158,
    },
    {
      domain: "Friday",
      totalPnl: -2823.55,
    },
    {
      domain: "Friday",
      totalPnl: 325.07,
    },
    {
      domain: "Friday",
      totalPnl: -746.98,
    },
    {
      domain: "Friday",
      totalPnl: 1013.92,
    },
    {
      domain: "Friday",
      totalPnl: -356.81,
    },
    {
      domain: "Friday",
      totalPnl: 408.02,
    },
    {
      domain: "Friday",
      totalPnl: -911.02,
    },
    {
      domain: "Friday",
      totalPnl: -708.06,
    },
    {
      domain: "Friday",
      totalPnl: -84.39,
    },
    {
      domain: "Friday",
      totalPnl: 573.88,
    },
    {
      domain: "Friday",
      totalPnl: 40.76,
    },
    {
      domain: "Friday",
      totalPnl: 1017.89,
    },
    {
      domain: "Friday",
      totalPnl: -3686.82,
    },
    {
      domain: "Friday",
      totalPnl: 194,
    },
    {
      domain: "Friday",
      totalPnl: -491.59,
    },
    {
      domain: "Friday",
      totalPnl: -990.91,
    },
    {
      domain: "Friday",
      totalPnl: -990.98,
    },
    {
      domain: "Friday",
      totalPnl: 1762.05,
    },
    {
      domain: "Friday",
      totalPnl: -19.02,
    },
    {
      domain: "Friday",
      totalPnl: -1480.3,
    },
    {
      domain: "Friday",
      totalPnl: 417.95,
    },
    {
      domain: "Friday",
      totalPnl: 755,
    },
    {
      domain: "Friday",
      totalPnl: 187.9,
    },
    {
      domain: "Friday",
      totalPnl: 1620.89,
    },
    {
      domain: "Friday",
      totalPnl: 1407.98,
    },
    {
      domain: "Friday",
      totalPnl: 167.93,
    },
    {
      domain: "Saturday",
      totalPnl: 0,
    },
    {
      domain: "Sunday",
      totalPnl: 0,
    },
  ],
  chartProperties: {
    name: "PnL By Day of Week",
    chartType: "bar",
    keys: ["totalPnl"],
    colors: ["var(--chart-blue)"],
    legend: ["Total PnL"],
    domainType: "dayOfWeek",
  },
};
