export const GET_PNL_BY_SEASON = {
  data: [
    {
      domain: "Fall",
      realisedPnl: -6960.02,
    },
    {
      domain: "Spring",
      realisedPnl: 4842.77,
    },
    {
      domain: "Summer",
      realisedPnl: -21614.6,
    },
    {
      domain: "Winter",
      realisedPnl: 32323.32,
    },
  ],
  chartProperties: {
    name: "PnL By Season",
    chartType: "bar",
    keys: ["realisedPnl"],
    colors: ["var(--chart-blue)"],
    legend: ["Realized PnL"],
    domainType: "text",
  },
};
