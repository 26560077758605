import React, { useEffect, useRef } from "react";
import { ResponsiveTreeMap } from "@nivo/treemap";
import { Box, Center, Flex, HStack, Spinner, VStack } from "@chakra-ui/react";
import Big from "big.js";
import { useInsightTreeMapController } from "./useInsightTreeMapController";
import { BaseInsightProps } from "../BaseInsightProps";
import { TreeChartItem } from "./TreeChartItem";
import { InsightStateEnum } from "../InsightStateEnum";
import { Show } from "../../../components";
import NoDataAvailable from "../components/NoDataAvailable";
import { FastTag } from "../../../components/design_library";
import ExampleTag from "../shared/ExampleTag";
import NoTimeFrameCompability from "../components/NoTimeframeCompatibility";
import InsightErrorRouter from "../components/InsightErrorRouter";

export const InsightTreeMap = (props: BaseInsightProps) => {
  const { insight, demo } = props;

  const controller = useInsightTreeMapController(props);

  const rootNode = useRef<TreeChartItem | undefined>(undefined);

  const [selectedNode, setSelectedNode] = React.useState<
    TreeChartItem | undefined
  >(controller.data);

  //const containerRef = useRef<HTMLDivElement>(null);

  const getFormattedLabel = (e: TreeChartItem) => {
    const value = e.value
      ? e.value >= 1000000
        ? `${new Big(e.value).div(1000000).round(0).toString()}m`
        : e.value >= 1000
        ? `${new Big(e.value).div(1000).round(0).toString()}k`
        : `${e.value}`
      : "";

    let label = "";
    if (selectedNode?.level !== 0) {
      label = `${e.label}`;
    }

    return label
      ? `${label}: ${value}${!e.confidence ? " (Traded Value)" : ""}`
      : `${value}`;
  };

  useEffect(() => {
    if (controller.data) {
      rootNode.current = controller.data;
      setSelectedNode(controller.data);
    }
  }, [controller.data]);

  return (
    <VStack
      margin={2}
      h="95%"
      align="start"
      // ref={containerRef}
      key={`insight-${insight.insightId}`}
    >
      {props.demo === true && <ExampleTag />}

      <Box overflow={"visible"} h="full" w="full">
        <Flex h="full" w="full" pb={1} justifyContent={"center"}>
          <InsightErrorRouter
            insightState={controller.insightState}
            supported={controller.supported}
          />

          <Show
            if={
              controller.supported === true &&
              controller.insightState === InsightStateEnum.Success &&
              controller.data !== undefined
            }
          >
            <ResponsiveTreeMap
              key={`treemap-${insight.insightId}`}
              data={selectedNode as any}
              identity="label"
              value="value"
              innerPadding={4}
              margin={{ top: 4, right: 4, bottom: 4, left: 4 }}
              parentLabelPosition="top"
              parentLabelSize={24}
              parentLabelPadding={4}
              colors={{ scheme: "nivo" }}
              theme={{
                tooltip: {
                  container: {
                    background: "rgba(0, 0, 0, 0.92)",
                    color: "white",
                    fontSize: "14px",
                    borderRadius: "4px",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                    padding: "8px",
                  },
                },
              }}
              nodeOpacity={0.35}
              outerPadding={8}
              label={(e) => {
                return getFormattedLabel(e.data as TreeChartItem);
              }}
              labelSkipSize={(selectedNode?.level ?? 0) !== 0 ? 0 : 30}
              labelTextColor={{
                from: "color",
                modifiers: [["brighter", 10]],
              }}
              parentLabelTextColor={{
                from: "color",
                modifiers: [["brighter", 10]],
              }}
              borderColor={{
                from: "color",
                modifiers: [["brighter", 0.4]],
              }}
              animate={true}
              motionConfig="stiff"
              onClick={(e) => {
                if (e.data.level === 0) {
                  return;
                }
                if (selectedNode?.level === 0) {
                  const node = rootNode.current?.children?.find(
                    (x) =>
                      x.level === 1 &&
                      (x.label === e.data.label || x.label === e.data.parent)
                  );
                  setSelectedNode(node);
                } else if (selectedNode?.level !== 0) {
                  setSelectedNode(rootNode.current);
                }
              }}
            />
          </Show>
        </Flex>
      </Box>
    </VStack>
  );
};
