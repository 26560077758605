/**
 * Decodes a base64 string into a File object that mimics a user-selected file.
 * @param {string} base64String - The base64 encoded string
 * @param {string} fileName - The name you want the uploaded file to have
 * @param {string} mimeType - The MIME type of the file (e.g., 'image/png', 'text/csv', etc.)
 * @returns {File} - A File object ready for FormData
 */
export const base64ToFile = (
  base64String,
  fileName,
  mimeType = "application/octet-stream"
) => {
  // atob() decodes a base64-encoded string
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  // Convert each character’s char code into a byte
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Turn byte array into a typed array, which File accepts
  const byteArray = new Uint8Array(byteNumbers);

  // Create a File object (lastModified is optional, set it if you need it)
  const file = new File([byteArray], fileName, {
    type: mimeType,
    lastModified: new Date().getTime(), // e.g., set current time
  });

  return file;
};
