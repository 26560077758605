import { useAtomValue } from "jotai";
import { useEffect, useCallback } from "react";
import { useInsightsController } from "../InsightsManager/useInsightsController";
import { DashboardLayoutStateStore, AppStateAtom } from "../../../store";
import { InsightTemplate } from "../../../types";
import { useLayout } from "../../dashboard/helpers/Layout";

export interface InsightSearchListControllerProps {
  isInsightEnabled: (insightId: number) => boolean;
  insightsByCategory: Record<string, InsightTemplate[]>;
  categories: string[];
  appState: { user?: { id: string } };
  loading: boolean;
  searchQuery: string;
  setSearchQuery: (q: string) => void;
  insights: InsightTemplate[];
  applyChanges: (
    insightsToAdd: InsightTemplate[],
    insightsToRemove: InsightTemplate[]
  ) => void;
  dashboardLayoutState: any;
}

export const useInsightSearchListController =
  (): InsightSearchListControllerProps => {
    const controller = useInsightsController();
    const {
      addInsightToLayouts,
      removeInsightFromLayouts,
      addAndRemoveMultipleInsights,
    } = useLayout();
    const dashboardLayoutState = useAtomValue(DashboardLayoutStateStore);
    const appState = useAtomValue(AppStateAtom);

    useEffect(() => {
      controller.fetchInsights(controller.displaysPage, controller.searchQuery);
    }, [controller.searchQuery]);

    const isInsightEnabled = useCallback(
      (insightId: number) =>
        dashboardLayoutState.enabledInsights?.some(
          (id) => parseInt(id) === insightId
        ),
      [dashboardLayoutState.enabledInsights]
    );

    const insightsByCategory = controller.insights.reduce((acc, insight) => {
      const category = insight.category || "Uncategorized";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(insight);
      return acc;
    }, {} as Record<string, InsightTemplate[]>);

    const categories = Object.keys(insightsByCategory).sort();

    const applyChanges = useCallback(
      (
        insightsToAdd: InsightTemplate[],
        insightsToRemove: InsightTemplate[]
      ) => {
        addAndRemoveMultipleInsights(insightsToAdd, insightsToRemove);
      },
      [
        addInsightToLayouts,
        removeInsightFromLayouts,
        addAndRemoveMultipleInsights,
      ]
    );

    return {
      applyChanges,
      isInsightEnabled,
      insightsByCategory,
      categories,
      appState,
      loading: controller.loading,
      searchQuery: controller.searchQuery,
      setSearchQuery: controller.setSearchQuery,
      dashboardLayoutState,
      insights: controller.insights,
    };
  };
