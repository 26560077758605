import { APIEndpoint } from ".";

export const getEarnings = async (ids: number[]) => {
  const token = localStorage.getItem("token");
  return fetch(`${APIEndpoint}/earnings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify(ids),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting earnings:", error));
};
