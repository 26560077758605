import { useAtom } from "jotai";
import { AppStateAtom } from "../../store";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { CustomDrawer } from "../../components/design_library/CustomDrawer";
import { useEffect, useRef, useState } from "react";
import { NegativeButton } from "../../components/design_library/NegativeButton";
import { useSettingsController } from "./useSettingsController";
import Themes from "../../components/themes/Themes";
import mixpanel from "mixpanel-browser";
import { AutoSync } from "../AutoSync/AutoSync";
import React from "react";
import CancelSubscription from "./CancelSubscription";
import PageHeading from "../../components/design_library/PageHeading";
import { RiSettings2Fill } from "react-icons/ri";
import EmbeddingConfigurationPanel from "../embedding/EmbeddingConfigurator/EmbeddingConfigurationPanel";
import APIKeysManagementPanel from "../apikeys/APIKeysManagementPanel";
import SidebarToggle from "../../components/toolbar/SidebarToggle";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { ToolbarFeatures } from "../../types";

const Settings = () => {
  const controller = useSettingsController();
  const [appState] = useAtom(AppStateAtom);
  const [showPassword, setShowPassword] = useState(false);
  const [logOutModalOpen, setLogOutModalOpen] = useState(false);
  const cancelRef = useRef<any>();

  return (
    <Flex h="100vh" direction="column" w="100%">
      {/* Page heading */}

      <Toolbar
        enabledOptions={[ToolbarFeatures.TITLE, ToolbarFeatures.DESCRIPTION]}
        title={"Settings"}
        icon={<RiSettings2Fill />}
        description="Manage your account settings"
      />

      {/* Tabs container */}
      <Tabs w="full" overflow="hidden">
        <TabList>
          <Tab>Auto Sync</Tab>
          <Tab>Account</Tab>
          <Tab>Tutorial</Tab>
          <Tab>API Keys</Tab>
          <Tab>Embedding</Tab>
          <Tab>Billing</Tab>
          {/* <Tab>Cancel</Tab> */}
        </TabList>

        <TabPanels>
          {/* AUTO SYNC TAB */}
          <TabPanel>
            <Box bg="var(--dark-gray)" rounded="md" shadow="base" p="5">
              <AutoSync />
            </Box>
          </TabPanel>

          {/* ACCOUNT TAB */}
          <TabPanel p="0" mt={2}>
            <Box bg="var(--dark-gray)" rounded="md" shadow="base" p="5" m={4}>
              <Text fontSize="sm">Your email is {appState.user.email}</Text>

              <HStack mt="5" alignItems={"flex-start"} wrap={"wrap"}>
                <Button
                  onClick={() => controller.setDrawerOpen(true)}
                  size={"sm"}
                >
                  Change password
                </Button>
                <Button
                  size={"sm"}
                  onClick={() => {
                    mixpanel.track("Logout Attempt");
                    setLogOutModalOpen(true);
                  }}
                >
                  Logout
                </Button>
              </HStack>
            </Box>

            {/* Drawer for changing password */}
            <CustomDrawer
              size="sm"
              isOpen={controller.drawerOpen}
              onClose={() => {
                controller.setDrawerOpen(false);
                controller.setPassword("");
                setShowPassword(false);
              }}
              body={
                <Stack>
                  <Box>
                    <InputGroup mt="20px" size="md">
                      <Input
                        pr="4.5rem"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter password"
                        autoComplete="password"
                        size="md"
                        disabled={controller.loading}
                        onChange={(e) => {
                          controller.setPassword(e.target.value);
                        }}
                        isRequired={true}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>

                    <HStack mt="20px" justifyContent={"flex-end"}>
                      <Button
                        colorScheme="blue"
                        mr="5"
                        isLoading={controller.loading}
                        onClick={() => {
                          controller.changePassword();
                        }}
                      >
                        Save
                      </Button>

                      <NegativeButton
                        onClick={() => {
                          controller.setPassword("");
                          setShowPassword(false);
                          controller.setDrawerOpen(false);
                        }}
                      >
                        Close
                      </NegativeButton>
                    </HStack>
                  </Box>
                </Stack>
              }
            />
          </TabPanel>

          {/* TUTORIAL TAB */}
          <TabPanel p="0" m={4}>
            <Box bg="var(--dark-gray)" rounded="md" shadow="base" p="5">
              <Button
                size="sm"
                onClick={() => {
                  window.location.href = "../app/positions?tutorial=true";
                }}
              >
                Replay tutorial again
              </Button>
            </Box>
          </TabPanel>

          <TabPanel p="0" m={4}>
            <APIKeysManagementPanel />
          </TabPanel>
          <TabPanel p="0" mt={2}>
            <EmbeddingConfigurationPanel />
          </TabPanel>

          {/* BILLING TAB */}
          <TabPanel p="0" m={4}>
            <Box
              width="full"
              bg="var(--dark-gray)"
              rounded="md"
              shadow="base"
              p="5"
              mb="20px"
            >
              <Text mb="4">View invoices and manage your subscription</Text>

              <Link
                color="var(--accent)"
                onClick={() => controller.requestStripePortal()}
              >
                Manage subscription
              </Link>
            </Box>
          </TabPanel>

          {/* CANCEL SUBSCRIPTION TAB */}
          {/* <TabPanel p="0" m={4}>
            <Box
              width="xl"
              bg="var(--dark-gray)"
              rounded="md"
              shadow="base"
              p="5"
              mb="20px"
            >
              <CancelSubscription />
            </Box>
          </TabPanel> */}
        </TabPanels>
      </Tabs>

      {/* Logout confirmation modal */}
      <AlertDialog
        isOpen={logOutModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setLogOutModalOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="var(--bg)">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Logout
            </AlertDialogHeader>
            <AlertDialogBody>Are you sure you want to logout?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setLogOutModalOpen(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  window.localStorage.removeItem("token");
                  window.location.href = "../app/login";
                }}
                ml={3}
              >
                Logout
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default Settings;
