import { ColDef } from "ag-grid-community";
import { createGroupColumn, ColumnTypeEnum } from "../../../../components";
import convertSymbolToReadable from "../../../../components/grid/utils/convertSymbolToReadable";
import moment from "moment";

export const upcomingEarningsColumnDefs: ColDef[] = [
  createGroupColumn("Symbol", "symbol", ColumnTypeEnum.Text, {
    valueGetter: (params) => {
      return convertSymbolToReadable(params.data.symbol);
    },
  }),

  createGroupColumn("Date", "earningsDate", ColumnTypeEnum.Date, {
    aggFunc: "distinct",
  }),

  createGroupColumn(
    "Days Until Earnings",
    "daysUntilEarnings",
    ColumnTypeEnum.Numeric,
    {
      sortable: true,
      sort: "asc",
      valueGetter: (params) => {
        return moment.utc(params.data.earningsDate).diff(moment.utc(), "days");
      },
    }
  ),

  createGroupColumn(
    "Before/After Market",
    "beforeAfterMarket",
    ColumnTypeEnum.Text,
    {
      valueGetter: (params) => {
        if (params.data.beforeAfterMarket === "BeforeMarket") {
          return "Before Market";
        } else {
          return "After Market";
        }
      },
    }
  ),
];
