import React from "react";
import NavWrapper from "./NavWrapper";
import { Box, Heading, Image, OrderedList, Text } from "@chakra-ui/react";
import { PrimaryButton, SecondaryButton } from "../../design_library";
import { uploadFile } from "../../../api";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../../store";
import { base64ToFile } from "../../../utils/File";
import { usePositions } from "../../../hooks";
import { useTransactions } from "../../../hooks/useTransactions";

export default function AddAssetsTutorial() {
  const accountState = useAtomValue(AccountAtom);
  const positions = usePositions();
  const transactions = useTransactions();
  return (
    <NavWrapper
      previousEnabled={false}
      isFinalStep={false}
      nextDisabled={false}
      overrideNextText={"Click to upload demo trades"}
      customFunction={async () => {
        const exampleFileBase64 =
          "VHlwZSxUcmFkZSBEYXRlLEluc3RydW1lbnQsRXhwaXJ5LFN0cmlrZSxQdXQgLyBDYWxsLEJ1eSAvIFNlbGwsUXVhbnRpdHksUHJpY2UsRmVlcyxNdWx0aXBsaWVyLEJvb2ssUG9zaXRpb24gR3JvdXAsU3RyYXRlZ3ksTm90ZXMsQ3VycmVuY3ksRXhjaGFuZ2UNCk9wdGlvbiwwMS8zMS8yMDI0LEFNRCwwMS8zMS8yMDI1LDE1MCxDYWxsLFNlbGwsMSwxLjEyMyw1LDEwMCxJbmNvbWUgYWNjb3VudCxDb3ZlcmVkIGNhbGwsQ2FsbHMsSGVyZSBhcmUgbXkgbm90ZXPJLFVTRCxVUw0KRXF1aXR5LDAxLzMxLzIwMjUsQU1ELCwsLEJ1eSwxMDAsOTgsMi40NSwxLEluY29tZSBhY2NvdW50LENvdmVyZWQgY2FsbCxFcXVpdHksIiMgRXhhbXBsZSBub3RlcyB1c2luZyBtYXJrZG93bi4gCiIsVVNELFVT";
        const file = base64ToFile(exampleFileBase64, "demo.csv", "text/csv");
        const formData = new FormData();
        formData.append("file", file, file.name);

        const uploadFileExample = await uploadFile(
          formData,
          "GENERIC",
          accountState.selectedAccount.id,
          "Tutorial Book",
          "NONE"
        );
        transactions.getTradesAndCashflows(accountState.selectedAccount.id);
        positions.fetchPositions(accountState.selectedAccount.id);

        setTimeout(() => {
          return uploadFileExample;
        }, 400);
      }}
    >
      <Box fontSize="14px" maxW="300px">
        <Heading
          as="h2"
          fontSize="18px"
          mb={4}
          fontWeight={600}
          textAlign={"left"}
        >
          Adding assets
        </Heading>
        <Text mt="10px">
          You can add your investments by clicking this button at the top right.{" "}
        </Text>
        <Text mt="10px">Supported Asset Classes:</Text>
        <OrderedList mt="10px">
          <li>Equities</li>
          <li>ETFs</li>
          <li>Options</li>
          <li>Futures</li>
        </OrderedList>
        <Text mt="10px">
          During the tutorial we will upload a demo file for you.
        </Text>
      </Box>
    </NavWrapper>
  );
}
