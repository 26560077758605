import { Heading, HStack, Tooltip } from "@chakra-ui/react";
import React from "react";
import { IoHelp, IoHelpCircle } from "react-icons/io5";

export default function PageHeading({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon?: any;
}) {
  return (
    <HStack pr="20px">
      {icon}
      <Heading size="md" alignItems={"center"} display="flex">
        {title}
      </Heading>
      <Tooltip label={description} aria-label="Help">
        <span>
          {" "}
          <IoHelpCircle />
        </span>
      </Tooltip>
    </HStack>
  );
}
