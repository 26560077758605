import React from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  VStack,
  HStack,
  Image,
  Tooltip,
  Spinner,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { TbPlayerPlayFilled } from "react-icons/tb";
import { useAutoSyncController } from "./useAutoSyncController";
import { AddOrEditAutoSyncConfiguration } from "./components/AddOrEditAutoSyncConfiguration";
import { Brokers } from "../../types";
import { useNavigate } from "react-router";
import { FaCog } from "react-icons/fa";
import { WarningTwoIcon } from "@chakra-ui/icons";

export const AutoSync = ({ minimalGui }: { minimalGui?: boolean }) => {
  const controller = useAutoSyncController();
  const navigate = useNavigate();

  const getPanelContent = () => {
    if (controller.settings.length === 0) {
      return (
        <Box pt={2}>
          <Text fontSize="sm">No settings defined for this account</Text>
        </Box>
      );
    } else {
      return (
        <Flex my={4}>
          <VStack>
            {controller.settings.map((setting) => {
              return (
                <HStack
                  justifyContent={"flex-start"}
                  w="full"
                  key={setting.syncSettingId}
                >
                  <Button
                    w="100px"
                    isDisabled={!setting.active}
                    leftIcon={
                      setting.active ? (
                        <TbPlayerPlayFilled />
                      ) : (
                        <WarningTwoIcon />
                      )
                    }
                    size="sm"
                    mr={2}
                    colorScheme={setting.active ? "green" : "red"}
                    onClick={() => {
                      controller.run(setting);
                    }}
                  >
                    {setting.active ? "Sync" : "Inactive"}
                  </Button>

                  <Button
                    size="sm"
                    mr={2}
                    onClick={() => {
                      if (minimalGui) {
                        navigate("/app/settings");
                      } else {
                        controller.setSelectedSettings(setting);
                        controller.setShowConfigure(true);
                      }
                    }}
                  >
                    {minimalGui ? <FaCog /> : "Edit"}
                  </Button>
                  {/* )} */}
                  <Text size="sm" ml={2} pt="5px">
                    {setting.broker}
                  </Text>
                </HStack>
              );
            })}
          </VStack>
        </Flex>
      );
    }
  };

  if (minimalGui) {
    return (
      <Flex p={1}>
        {controller.loading && (
          <Flex
            bg="var(--bg)"
            h="18px"
            w="40px"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size="sm" />
          </Flex>
        )}

        <Popover>
          <PopoverTrigger>
            <Button p="0" m={0} bg="transparent" maxH="16px">
              {!controller.loading &&
                controller.settings.map((setting) => {
                  return (
                    <HStack justifyContent={"space-between"} w="full">
                      <Box pr="1">
                        {setting.active === false && (
                          <Tooltip
                            label={`${setting.broker} is not connected. Please activate it in the settings page`}
                          >
                            <Box
                              h="24px"
                              w="24px"
                              bg="red"
                              pos={"absolute"}
                              opacity={1}
                            >
                              <WarningTwoIcon />
                            </Box>
                          </Tooltip>
                        )}
                        <Image
                          height="24px"
                          width="24px"
                          src={
                            setting.broker === Brokers.Schwab
                              ? "https://cdn.wealthbee.io/main/brokers/broker_icons/schwab.png"
                              : setting.broker === Brokers.ETrade
                              ? "https://cdn.wealthbee.io/main/brokers/broker_icons/etrade.png"
                              : setting.broker === Brokers.IBKR &&
                                "https://cdn.wealthbee.io/main/brokers/broker_icons/interactive-brokers.png"
                          }
                        />
                      </Box>
                    </HStack>
                  );
                })}
            </Button>
          </PopoverTrigger>
          <PopoverContent w="260px" bg="var(--bg)">
            <PopoverArrow bg="var(--bg)" />
            <PopoverBody>
              <Box>{getPanelContent()}</Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <Heading as="h3" size="lg">
        Auto Sync
      </Heading>
      {getPanelContent()}
      <Flex mt="10px">
        <Button
          w="full"
          onClick={() => {
            controller.setSelectedSettings(undefined);
            controller.setShowConfigure(true);
          }}
        >
          Add Configuration
        </Button>
      </Flex>
      <Modal
        closeOnOverlayClick={false}
        isOpen={controller.showConfigure}
        onClose={() => controller.setShowConfigure(false)}
        scrollBehavior="inside"
        isCentered
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent bg="var(--bg)">
          <ModalHeader>Configure AutoSync</ModalHeader>

          <ModalCloseButton size="sm" />
          <ModalBody>
            <AddOrEditAutoSyncConfiguration
              settings={controller.selectedSettings}
              close={() => controller.setShowConfigure(false)}
            />
          </ModalBody>
          <Box h="10px" />
        </ModalContent>
      </Modal>
    </Flex>
  );
};
