import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { FaFlask } from "react-icons/fa";
import { useInsightsController } from "./useInsightsController";

import InsightsManagerTriggerButton from "./components/InsightsManagerTriggerButton";
import InsightsManagerDrawer from "./components/InsightsManagerDrawer";
import { AppStateAtom } from "../../../store";

const InsightsManager: React.FC = () => {
  const controller = useInsightsController();
  const appState = useAtomValue(AppStateAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isUserAdmin = appState.user?.role === "admin";

  return (
    <Flex>
      <InsightsManagerTriggerButton onOpen={onOpen} />
      <InsightsManagerDrawer
        isOpen={isOpen}
        onClose={onClose}
        isUserAdmin={isUserAdmin}
        controller={controller}
      />
    </Flex>
  );
};

export default InsightsManager;
