export const SIZE_BY_STRATEGY = {
  data: [
    {
      strategy: "Unspecified",
      symbol: "BPYU",
      value: 7981.32,
      confidence: 0,
      riskpercent: 2.1,
    },
    {
      strategy: "Unspecified",
      symbol: "BABA",
      value: 5131.07,
      confidence: 0,
      riskpercent: 1.3,
    },
    {
      strategy: "Unspecified",
      symbol: "CELH 20Aug21 65 Put",
      value: 205,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "RNP",
      value: 8007.94,
      confidence: 0,
      riskpercent: 2.1,
    },
    {
      strategy: "Unspecified",
      symbol: "SNR",
      value: 1884.12,
      confidence: 0,
      riskpercent: 0.5,
    },
    {
      strategy: "Unspecified",
      symbol: "TALK",
      value: 0.3,
      confidence: 0,
      riskpercent: 0,
    },
    {
      strategy: "Unspecified",
      symbol: "GOGL",
      value: 5170,
      confidence: 0,
      riskpercent: 1.3,
    },
    {
      strategy: "Unspecified",
      symbol: "BLMN 15Oct21 30 Call",
      value: 1520,
      confidence: 0,
      riskpercent: 0.4,
    },
    {
      strategy: "Unspecified",
      symbol: "WB 20Oct17 60 Call",
      value: 2318,
      confidence: 0,
      riskpercent: 0.6,
    },
    {
      strategy: "Unspecified",
      symbol: "TTWO 15Dec17 65 Call",
      value: 2480,
      confidence: 0,
      riskpercent: 0.6,
    },
    {
      strategy: "Unspecified",
      symbol: "OPI",
      value: 1511.55,
      confidence: 0,
      riskpercent: 0.4,
    },
    {
      strategy: "Unspecified",
      symbol: "ABBV",
      value: 9574.58,
      confidence: 0,
      riskpercent: 2.5,
    },
    {
      strategy: "Unspecified",
      symbol: "VHT",
      value: 4128.22,
      confidence: 0,
      riskpercent: 1.1,
    },
    {
      strategy: "Unspecified",
      symbol: "OXLC",
      value: 7440.8,
      confidence: 0,
      riskpercent: 1.9,
    },
    {
      strategy: "Unspecified",
      symbol: "CIM",
      value: 17118.93,
      confidence: 0,
      riskpercent: 4.5,
    },
    {
      strategy: "Unspecified",
      symbol: "MTCH 18Jan19 55 Call",
      value: 1418,
      confidence: 0,
      riskpercent: 0.4,
    },
    {
      strategy: "Unspecified",
      symbol: "M 20Aug21 18 Put",
      value: 270,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "EOS",
      value: 5877.04,
      confidence: 0,
      riskpercent: 1.5,
    },
    {
      strategy: "Unspecified",
      symbol: "AAPL",
      value: 25.64568,
      confidence: 1,
      riskpercent: 0,
    },
    {
      strategy: "Unspecified",
      symbol: "KRBN",
      value: 5312.25,
      confidence: 0,
      riskpercent: 1.4,
    },
    {
      strategy: "Unspecified",
      symbol: "CRSR",
      value: 7000,
      confidence: 0,
      riskpercent: 1.8,
    },
    {
      strategy: "Unspecified",
      symbol: "IWM",
      value: 176.12000000000003,
      confidence: 0,
      riskpercent: 0,
    },
    {
      strategy: "Unspecified",
      symbol: "ARKG",
      value: 2861.04,
      confidence: 0,
      riskpercent: 0.7,
    },
    {
      strategy: "Unspecified",
      symbol: "FCX",
      value: 5979.56,
      confidence: 0,
      riskpercent: 1.6,
    },
    {
      strategy: "Unspecified",
      symbol: "BCE",
      value: 8657,
      confidence: 0,
      riskpercent: 2.3,
    },
    {
      strategy: "Unspecified",
      symbol: "PROSY",
      value: 1175.5,
      confidence: 0,
      riskpercent: 0.3,
    },
    {
      strategy: "Unspecified",
      symbol: "AAL",
      value: 4447.5,
      confidence: 0,
      riskpercent: 1.2,
    },
    {
      strategy: "Unspecified",
      symbol: "DHI 20Aug21 100 Call",
      value: 1100,
      confidence: 0,
      riskpercent: 0.3,
    },
    {
      strategy: "Unspecified",
      symbol: "APPS 20Aug21 59 Put",
      value: 226,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "SCHD",
      value: 9319.17,
      confidence: 0,
      riskpercent: 2.4,
    },
    {
      strategy: "Unspecified",
      symbol: "CYH 20Aug21 14 Put",
      value: 300,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "NLY",
      value: 7639.2,
      confidence: 0,
      riskpercent: 2,
    },
    {
      strategy: "Unspecified",
      symbol: "CELH 20Aug21 60 Put",
      value: 470,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "BST",
      value: 2569.92,
      confidence: 0,
      riskpercent: 0.7,
    },
    {
      strategy: "Unspecified",
      symbol: "DFS 15Oct21 120 Call",
      value: 1324,
      confidence: 0,
      riskpercent: 0.3,
    },
    {
      strategy: "Unspecified",
      symbol: "LAD",
      value: 1171.42,
      confidence: 0,
      riskpercent: 0.3,
    },
    {
      strategy: "Unspecified",
      symbol: "BAX",
      value: 11162.509999999998,
      confidence: 0,
      riskpercent: 2.9,
    },
    {
      strategy: "Unspecified",
      symbol: "DWDP",
      value: 16811.4,
      confidence: 0,
      riskpercent: 4.4,
    },
    {
      strategy: "Unspecified",
      symbol: "LAC",
      value: 5250,
      confidence: 0,
      riskpercent: 1.4,
    },
    {
      strategy: "Unspecified",
      symbol: "NVDA 17Nov17 150 Call",
      value: 3200,
      confidence: 0,
      riskpercent: 0.8,
    },
    {
      strategy: "Unspecified",
      symbol: "ACRE",
      value: 6182.24,
      confidence: 0,
      riskpercent: 1.6,
    },
    {
      strategy: "Unspecified",
      symbol: "NOC 17Nov17 255 Call",
      value: 3809,
      confidence: 0,
      riskpercent: 1,
    },
    {
      strategy: "Unspecified",
      symbol: "ARGO",
      value: 4925.9,
      confidence: 0,
      riskpercent: 1.3,
    },
    {
      strategy: "Unspecified",
      symbol: "MP 20Aug21 30 Put",
      value: 326,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "WGO 15Oct21 65 Call",
      value: 1030,
      confidence: 0,
      riskpercent: 0.3,
    },
    {
      strategy: "Unspecified",
      symbol: "SPY 29Sep17 230 Call",
      value: 1662,
      confidence: 0,
      riskpercent: 0.4,
    },
    {
      strategy: "Unspecified",
      symbol: "XIV",
      value: 2037.6800000000003,
      confidence: 0,
      riskpercent: 0.5,
    },
    {
      strategy: "Unspecified",
      symbol: "JD 15Sep17 38 Call",
      value: 2700,
      confidence: 0,
      riskpercent: 0.7,
    },
    {
      strategy: "Unspecified",
      symbol: "NLS 20Aug21 22.5 Call",
      value: 150,
      confidence: 0,
      riskpercent: 0,
    },
    {
      strategy: "Unspecified",
      symbol: "CQQQ",
      value: 4079.4800000000005,
      confidence: 0,
      riskpercent: 1.1,
    },
    {
      strategy: "Unspecified",
      symbol: "PALAF",
      value: 4534.25,
      confidence: 0,
      riskpercent: 1.2,
    },
    {
      strategy: "Unspecified",
      symbol: "CX",
      value: 5842.06,
      confidence: 0,
      riskpercent: 1.5,
    },
    {
      strategy: "Unspecified",
      symbol: "OIH 19Jan18 30 Put",
      value: 2130,
      confidence: 0,
      riskpercent: 0.6,
    },
    {
      strategy: "Unspecified",
      symbol: "PUMGF",
      value: 2150,
      confidence: 0,
      riskpercent: 0.6,
    },
    {
      strategy: "Unspecified",
      symbol: "NLS",
      value: 6750,
      confidence: 0,
      riskpercent: 1.8,
    },
    {
      strategy: "Unspecified",
      symbol: "AGNC",
      value: 289.77,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "AMZN",
      value: 2068.4,
      confidence: 1,
      riskpercent: 0.5,
    },
    {
      strategy: "Unspecified",
      symbol: "DOW",
      value: 4309.91,
      confidence: 0,
      riskpercent: 1.1,
    },
    {
      strategy: "Unspecified",
      symbol: "RYLD",
      value: 12935.78,
      confidence: 0,
      riskpercent: 3.4,
    },
    {
      strategy: "Unspecified",
      symbol: "CRSR 20Aug21 35 Call",
      value: 332,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "APPS 20Aug21 75 Call",
      value: 300,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "DD",
      value: 6712.03,
      confidence: 0,
      riskpercent: 1.8,
    },
    {
      strategy: "Unspecified",
      symbol: "MP 20Aug21 35 Put",
      value: 280,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "MCD",
      value: 6865.04,
      confidence: 0,
      riskpercent: 1.8,
    },
    {
      strategy: "Unspecified",
      symbol: "ETV",
      value: 8099.75,
      confidence: 0,
      riskpercent: 2.1,
    },
    {
      strategy: "Unspecified",
      symbol: "ABR",
      value: 2353.35,
      confidence: 0,
      riskpercent: 0.6,
    },
    {
      strategy: "Unspecified",
      symbol: "MTCH 18Jan19 53 Call",
      value: 10,
      confidence: 0,
      riskpercent: 0,
    },
    {
      strategy: "Unspecified",
      symbol: "URA",
      value: 1209.14,
      confidence: 0,
      riskpercent: 0.3,
    },
    {
      strategy: "Unspecified",
      symbol: "CLF 17Sep21 22 Put",
      value: 318,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "TREX 20Oct17 65 Call",
      value: 2408,
      confidence: 0,
      riskpercent: 0.6,
    },
    {
      strategy: "Unspecified",
      symbol: "BZUN",
      value: 11540.550000000001,
      confidence: 0,
      riskpercent: 3,
    },
    {
      strategy: "Unspecified",
      symbol: "COPX",
      value: 5384.28,
      confidence: 0,
      riskpercent: 1.4,
    },
    {
      strategy: "Unspecified",
      symbol: "O",
      value: 6948.84,
      confidence: 0,
      riskpercent: 1.8,
    },
    {
      strategy: "Unspecified",
      symbol: "CVX",
      value: 47.49000000000001,
      confidence: 0,
      riskpercent: 0,
    },
    {
      strategy: "Unspecified",
      symbol: "QYLD",
      value: 17066.35,
      confidence: 0,
      riskpercent: 4.5,
    },
    {
      strategy: "Unspecified",
      symbol: "APPS",
      value: 3672.43,
      confidence: 0,
      riskpercent: 1,
    },
    {
      strategy: "Unspecified",
      symbol: "IHI",
      value: 8055.16,
      confidence: 0,
      riskpercent: 2.1,
    },
    {
      strategy: "Unspecified",
      symbol: "LAC 20Aug21 17.5 Call",
      value: 189,
      confidence: 0,
      riskpercent: 0,
    },
    {
      strategy: "Unspecified",
      symbol: "EVA",
      value: 5870.110000000001,
      confidence: 0,
      riskpercent: 1.5,
    },
    {
      strategy: "Unspecified",
      symbol: "GOV",
      value: 2435.8600000000006,
      confidence: 0,
      riskpercent: 0.6,
    },
    {
      strategy: "Unspecified",
      symbol: "REGI",
      value: 5636.62,
      confidence: 0,
      riskpercent: 1.5,
    },
    {
      strategy: "Unspecified",
      symbol: "KMX 20Oct17 60 Call",
      value: 1862,
      confidence: 0,
      riskpercent: 0.5,
    },
    {
      strategy: "Unspecified",
      symbol: "UNFI 20Aug21 35 Put",
      value: 568,
      confidence: 0,
      riskpercent: 0.1,
    },
    {
      strategy: "Unspecified",
      symbol: "V 15Sep17 90 Call",
      value: 3090,
      confidence: 0,
      riskpercent: 0.8,
    },
    {
      strategy: "Unspecified",
      symbol: "REGI 17Sep21 70 Call",
      value: 135,
      confidence: 0,
      riskpercent: 0,
    },
    {
      strategy: "Unspecified",
      symbol: "EVT",
      value: 7933.11,
      confidence: 0,
      riskpercent: 2.1,
    },
    {
      strategy: "Unspecified",
      symbol: "JNJ",
      value: 6299.53,
      confidence: 0,
      riskpercent: 1.6,
    },
    {
      strategy: "Unspecified",
      symbol: "DE 19Jan18 110 Call",
      value: 954,
      confidence: 0,
      riskpercent: 0.2,
    },
    {
      strategy: "Unspecified",
      symbol: "CTVA",
      value: 2620.1400000000003,
      confidence: 0,
      riskpercent: 0.7,
    },
    {
      strategy: "Unspecified",
      symbol: "SIL",
      value: 4761.49,
      confidence: 0,
      riskpercent: 1.2,
    },
    {
      strategy: "Unspecified",
      symbol: "MFA",
      value: 4732.15,
      confidence: 0,
      riskpercent: 1.2,
    },
    {
      strategy: "Unspecified",
      symbol: "CIK",
      value: 8470.91,
      confidence: 0,
      riskpercent: 2.2,
    },
  ],
  chartProperties: {
    name: "Size By Strategy",
    chartType: "treemap",
    keys: ["underlying", "symbol", "value", "confidence"],
    colors: ["var(--chart-orange)"],
    legend: ["underlying"],
    domainType: "SIZE_BY_STRATEGY",
  },
};
