// useExportAccountController.js
import React from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { getTradesAsImportCSV } from "../../api/Trades";
import { AccountAtom } from "../../store";

export function useExportAccountController() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef(null);
  const [selectedAccount, setSelectedAccount] = React.useState(null);
  const accountState = useAtomValue(AccountAtom);

  const getCSV = async () => {
    if (!selectedAccount) return;
    const response = await getTradesAsImportCSV(parseInt(selectedAccount));
    const blob = response;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `trades_by_custodian-${new Date().toISOString()}.zip`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return {
    isOpen,
    onOpen,
    onClose,
    finalRef,
    selectedAccount,
    setSelectedAccount,
    accountState,
    getCSV,
  };
}
