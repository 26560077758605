import Big from "big.js";
import { calculateReturnOnPremium } from "../../../utils";

export const returnOnPremium = (props: any) => {
  const children = props.rowNode?.allLeafChildren?.map((child: any) => {
    return child.data;
  });
  if (children && children.length > 0) {
    const premium = children.reduce((acc: Big, child: any) => {
      return child.premium ? acc.plus(child.premium) : acc;
    }, new Big(0));

    const realisedPnl = children.reduce((acc: Big, child: any) => {
      return child.realisedPnl ? acc.plus(child.realisedPnl) : acc;
    }, new Big(0));

    const unrealisedPnl = children.reduce((acc: Big, child: any) => {
      return child.unrealisedPnl ? acc.plus(child.unrealisedPnl) : acc;
    }, new Big(0));

    return calculateReturnOnPremium(
      premium.toNumber(),
      realisedPnl.toNumber(),
      unrealisedPnl.toNumber()
    );
  }
  return "";
};
