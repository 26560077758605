import React from "react";
import { InsightDefinition, InsightType } from "../../../types/Insight";
import {
  InsightNumber2,
  InsightComparison,
  InsightChart,
  InsightTreeMap,
} from "..";
import { InsightTable } from "../InsightTable/InsightTable";

interface InsightRenderer2Props {
  insight: any;
  demo?: boolean;
}

export const InsightRenderer2 = (props: InsightRenderer2Props) => {
  const { insight, demo } = props;
  switch (insight.type as InsightType) {
    case InsightType.Number:
      return <InsightNumber2 insight={insight} demo={demo} />;
    case InsightType.Comparison:
      return <InsightComparison insight={insight} demo={demo} />;
    case InsightType.Treemap:
      return <InsightTreeMap insight={insight} demo={demo} />;
    case InsightType.Barchart:
    case InsightType.Linechart:
      return <InsightChart insight={insight} demo={demo} />;
    case InsightType.Table:
      return <InsightTable insight={insight} demo={demo} />;

    default:
      return <p>Undefined Insight</p>;
  }
};
