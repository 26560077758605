import React, { useCallback, useEffect, useRef } from "react";
import { usePricesController } from "./usePricesController";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { GridReadyEvent } from "ag-grid-enterprise";
import { GridContainer, columnTypes, distinctValue } from "../../components";
import { AgGridReact } from "ag-grid-react";
import { columnDefinitions } from "./columnDefinitions";
import { useGridState } from "../../components/grid/useGridState";
import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import {
  KnowledgeBaseHelperTopics,
  ToolbarFeatures,
  UserInstrumentPrice,
} from "../../types";
import { RiPriceTag2Fill, RiStockFill } from "react-icons/ri";
import { ToolbarDropDown } from "../../components/design_library/toolbar/ToolbarDropDown";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import moment from "moment";
import { SecondaryButton } from "../../components/design_library";
import { Toolbar } from "../../components/toolbar/Toolbar";
import PageHeading from "../../components/design_library/PageHeading";
import SidebarToggle from "../../components/toolbar/SidebarToggle";

export const Prices = () => {
  const expiryRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);
  const [price, setPrice] = React.useState<number | undefined>();
  const [priceExpiry, setPriceExpiry] = React.useState<
    moment.Moment | undefined
  >();

  const [selectedPrice, setSelectedPrice] =
    React.useState<UserInstrumentPrice>();

  const controller = usePricesController();

  const gridRef = useRef<GridApi | null>(null);

  const gridState = useGridState({ gridName: "Prices" });

  const scrollHoldRef = useRef(null);

  const onGridReady = useCallback((e: GridReadyEvent) => {
    gridRef.current = e.api;
    gridState.onGridReady(e);
  }, []);

  const onSelectionChanged = (e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();
    expiryRef.current!.value = "";
    priceRef.current!.value = "";
    if (selectedRows.length === 0) {
      setSelectedPrice(undefined);
      return;
    }
    setSelectedPrice(selectedRows[0] as UserInstrumentPrice);
    setPrice(selectedRows[0].userPrice);
    setPriceExpiry(
      selectedRows[0].userExpiry
        ? moment(selectedRows[0].userExpiry)
        : moment().endOf("day")
    );
  };

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.refreshCells();
    }
  }, [controller.prices]);

  return (
    <Flex h="full" direction="column" w="full">
      {/* To avoid AGGrid bouncing to bottom of page after a modal is closed... */}

      <Toolbar
        enabledOptions={[
          ToolbarFeatures.HELP,

          ToolbarFeatures.TITLE,
          ToolbarFeatures.DESCRIPTION,
        ]}
        helpKey={KnowledgeBaseHelperTopics.PRICES}
        title="User Prices"
        icon={<RiPriceTag2Fill />}
        description="
          User prices are used to override the current price of an instrument. 
          This is useful when you want to use a different price for an instrument in your strategy. 
          The user price will be used in place of the current price when calculating the P&L of the strategy."
      />

      {/* <ToolbarDropDown
        value={{
          label: (
            <HStack color="white !important">
              <RiStockFill fill="var(--accent)" />
              <Text>Underlying ({controller.underlyings.length - 1})</Text>
            </HStack>
          ),
          value: "All",
        }}
        className="tool-bar-select"
        placeholder={
          <HStack color="white !important">
            <RiStockFill fill="var(--accent)" />
            <Text>Underlying ({controller.underlyings.length - 1})</Text>
          </HStack>
        }
        options={controller.underlyings.map((underlying) => ({
          value: underlying,
          label: underlying,
        }))}
        onChange={(event: any) => {
          controller.selectUnderlying(
            event.value === "All" ? undefined : event.value
          );
        }}
      /> */}

      <Button ref={scrollHoldRef} h="0"></Button>

      <Flex flex={1} px={2}>
        <Allotment separator={true}>
          <GridContainer>
            <AgGridReact
              columnDefs={columnDefinitions} // Column Defs for Columns
              groupSelectsChildren={true}
              rowData={controller.prices} // Row Data for Rows
              columnTypes={columnTypes} // Optional - custom column types
              rowGroupPanelShow="always"
              rowSelection="single" // Options - allows click selection of rows
              rowHeight={31}
              suppressAggFilteredOnly={false}
              suppressAggFuncInHeader={true}
              suppressChangeDetection={false}
              animateRows={true}
              aggFuncs={{
                distinct: distinctValue,
              }}
              autoGroupColumnDef={{
                sortable: false,
                resizable: true,
                initialWidth: 180,
                headerName: "Group",
              }}
              sideBar={{
                toolPanels: [
                  {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                      suppressPivotMode: true,
                      suppressValues: true,
                    },
                  },
                ],
                position: "left",
                defaultToolPanel: "filters",
              }}
              getRowId={(x) => {
                return x.data.instrumentId;
              }}
              onSelectionChanged={onSelectionChanged}
              onGridReady={onGridReady}
              onFirstDataRendered={gridState.onFirstDataRendered}
              onColumnEverythingChanged={gridState.onColumnChanged}
              onColumnResized={gridState.onColumnResized}
              onSortChanged={gridState.onColumnChanged}
            />
          </GridContainer>
          <Allotment.Pane visible={true} maxSize={450}>
            <Flex w="full" h="full" p="2px" ml="1px">
              <Flex w="full" p={2} background={"var(--bg)"}>
                <VStack alignItems="start" w="full">
                  <HStack w="full" mt={1}>
                    <Text
                      fontFamily="Work Sans"
                      fontSize="16px"
                      fontWeight={500}
                      align="left"
                    >
                      User Price
                    </Text>
                  </HStack>

                  <Divider my={1} />

                  <FormControl mb="10px">
                    <HStack my={1} wrap={"wrap"}>
                      <FormLabel>Instrument:</FormLabel>
                      <FormLabel>{selectedPrice?.symbol}</FormLabel>
                    </HStack>
                  </FormControl>

                  <FormControl mb="10px">
                    <HStack my={1}>
                      <FormLabel>Current Price:</FormLabel>
                      <FormLabel>{selectedPrice?.currentPrice}</FormLabel>
                    </HStack>
                  </FormControl>

                  <FormControl mb="20px">
                    <HStack my={1}>
                      <FormLabel>User Price</FormLabel>
                      <Spacer />
                      <Button
                        size="xs"
                        onClick={(e) => {
                          setPrice(undefined);
                          priceRef.current!.value = "";
                        }}
                      >
                        Clear
                      </Button>
                    </HStack>
                    <Input
                      type="number"
                      ref={priceRef}
                      disabled={selectedPrice?.instrumentId === undefined}
                      value={price}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (!isNaN(value)) {
                          setPrice(value);
                        }
                      }}
                      onFocus={(e) => {
                        e.target.addEventListener(
                          "wheel",
                          (e) => {
                            e.preventDefault();
                          },
                          { passive: false }
                        );
                      }}
                    />
                  </FormControl>

                  <FormControl mb="20px">
                    <HStack my={1}>
                      <FormLabel>User Expiry</FormLabel>
                      <Spacer />
                      <Button
                        size="xs"
                        onClick={(e) => {
                          setPriceExpiry(moment().endOf("day"));
                        }}
                      >
                        EOD
                      </Button>
                      <Button
                        size="xs"
                        onClick={(e) => {
                          setPriceExpiry(moment().endOf("month"));
                        }}
                      >
                        EOM
                      </Button>
                      <Button
                        size="xs"
                        onClick={(e) => {
                          setPriceExpiry(moment().endOf("year"));
                        }}
                      >
                        EOY
                      </Button>
                    </HStack>
                    <Input
                      type="datetime-local"
                      ref={expiryRef}
                      disabled={selectedPrice?.instrumentId === undefined}
                      value={priceExpiry?.format("YYYY-MM-DDTHH:mm")}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (e.target.value) {
                          setPriceExpiry(moment(e.target.value));
                        }
                      }}
                      size="sm"
                      w="full"
                    />
                  </FormControl>

                  <HStack mt="30px" width="full">
                    <SecondaryButton
                      size="md"
                      colorScheme="green"
                      w="full"
                      isDisabled={selectedPrice?.instrumentId === undefined}
                      onClick={() =>
                        controller.saveUserPrice(
                          selectedPrice!.instrumentId,
                          price,
                          price
                            ? priceExpiry?.format("YYYY-MM-DDTHH:mm")
                            : undefined
                        )
                      }
                    >
                      Save Changes
                    </SecondaryButton>
                  </HStack>
                </VStack>
              </Flex>
            </Flex>
          </Allotment.Pane>
        </Allotment>
      </Flex>
    </Flex>
  );
};
