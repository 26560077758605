import { CUMMULATIVE_PNL } from "./CUMMULATIVE_PNL";
import { GET_PNL_BY_HOLDING_TIME } from "./GET_PNL_BY_HOLDING_TIME";
import { GET_PNL_BY_SEASON } from "./GET_PNL_BY_SEASON";
import { GET_PNL_BY_TRADE_COUNT } from "./GET_PNL_BY_TRADE_COUNT";
import { GET_WEEKDAY_PNL } from "./GET_WEEKDAY_PNL";
import { PNL_BY_DATE } from "./PNL_BY_DATE";
import { PNL_BY_BOOK } from "./PNL_BY_BOOK";
import { PNL_BY_STRATEGY } from "./PNL_BY_STRATEGY";
import { PNL_BY_UNDERLYING } from "./PNL_BY_UNDERLYING";
import { PNL_BY_WEEK } from "./PNL_BY_WEEK";
import { PNL_BY_MONTH } from "./PNL_BY_MONTH";
import { PNL_BY_YEAR } from "./PNL_BY_YEAR";

const InsightChartDemoDataMap = {
  CUMMULATIVE_PNL: CUMMULATIVE_PNL,
  PNL_BY_UNDERLYING: PNL_BY_UNDERLYING,
  PNL_BY_DATE: PNL_BY_DATE,
  GET_WEEKDAY_PNL: GET_WEEKDAY_PNL,
  GET_PNL_BY_HOLDING_TIME: GET_PNL_BY_HOLDING_TIME,
  GET_PNL_BY_SEASON: GET_PNL_BY_SEASON,
  GET_PNL_BY_TRADE_COUNT: GET_PNL_BY_TRADE_COUNT,
  PNL_BY_STRATEGY: PNL_BY_STRATEGY,
  PNL_BY_BOOK: PNL_BY_BOOK,
  PNL_BY_WEEK: PNL_BY_WEEK,
  PNL_BY_MONTH: PNL_BY_MONTH,
  PNL_BY_YEAR: PNL_BY_YEAR,
};

export default InsightChartDemoDataMap;
