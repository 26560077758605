export const PNL_BY_WEEK = {
  data: [
    {
      domain: "2023-11-03",
      realisedPnl: 103.54,
    },
    {
      domain: "2023-11-10",
      realisedPnl: 13.52,
    },
    {
      domain: "2023-11-17",
      realisedPnl: 220.25,
    },
    {
      domain: "2023-11-24",
      realisedPnl: 287.18,
    },
    {
      domain: "2023-12-01",
      realisedPnl: -242.33,
    },
    {
      domain: "2023-12-08",
      realisedPnl: 0,
    },
    {
      domain: "2023-12-15",
      realisedPnl: 456.47,
    },
    {
      domain: "2023-12-22",
      realisedPnl: 138.41,
    },
    {
      domain: "2023-12-29",
      realisedPnl: 0,
    },
    {
      domain: "2024-01-05",
      realisedPnl: 0,
    },
    {
      domain: "2024-01-12",
      realisedPnl: 400.74,
    },
    {
      domain: "2024-01-19",
      realisedPnl: 515.83,
    },
    {
      domain: "2024-01-26",
      realisedPnl: 380.1,
    },
    {
      domain: "2024-02-02",
      realisedPnl: 52.82,
    },
    {
      domain: "2024-02-09",
      realisedPnl: 883.54,
    },
    {
      domain: "2024-02-16",
      realisedPnl: 5.98,
    },
    {
      domain: "2024-02-23",
      realisedPnl: -239.52,
    },
    {
      domain: "2024-03-01",
      realisedPnl: 0,
    },
    {
      domain: "2024-03-08",
      realisedPnl: 1603.63,
    },
    {
      domain: "2024-03-15",
      realisedPnl: 38.95,
    },
    {
      domain: "2024-03-22",
      realisedPnl: 1224.2,
    },
    {
      domain: "2024-03-29",
      realisedPnl: -2049.88,
    },
    {
      domain: "2024-04-05",
      realisedPnl: -1812.02,
    },
    {
      domain: "2024-04-12",
      realisedPnl: -5017.84,
    },
    {
      domain: "2024-04-19",
      realisedPnl: 244.61,
    },
    {
      domain: "2024-04-26",
      realisedPnl: -847.31,
    },
    {
      domain: "2024-05-03",
      realisedPnl: 1737.86,
    },
    {
      domain: "2024-05-10",
      realisedPnl: -163.81,
    },
    {
      domain: "2024-05-17",
      realisedPnl: 886.69,
    },
    {
      domain: "2024-05-24",
      realisedPnl: 649.68,
    },
    {
      domain: "2024-05-31",
      realisedPnl: 0,
    },
    {
      domain: "2024-06-07",
      realisedPnl: 5697.92,
    },
    {
      domain: "2024-06-14",
      realisedPnl: 666.15,
    },
    {
      domain: "2024-06-21",
      realisedPnl: 531.92,
    },
    {
      domain: "2024-06-28",
      realisedPnl: 699.46,
    },
    {
      domain: "2024-07-05",
      realisedPnl: 5316.81,
    },
    {
      domain: "2024-07-12",
      realisedPnl: 912.66,
    },
    {
      domain: "2024-07-19",
      realisedPnl: 289.32,
    },
    {
      domain: "2024-07-26",
      realisedPnl: 430.12,
    },
    {
      domain: "2024-08-02",
      realisedPnl: 142.32,
    },
    {
      domain: "2024-08-09",
      realisedPnl: -971.78,
    },
    {
      domain: "2024-08-16",
      realisedPnl: -1439.52,
    },
    {
      domain: "2024-08-23",
      realisedPnl: 3427.4,
    },
    {
      domain: "2024-08-30",
      realisedPnl: -1219.86,
    },
    {
      domain: "2024-09-06",
      realisedPnl: -1742.4,
    },
    {
      domain: "2024-09-13",
      realisedPnl: 601.23,
    },
    {
      domain: "2024-09-20",
      realisedPnl: 970.35,
    },
    {
      domain: "2024-09-27",
      realisedPnl: 534.76,
    },
  ],
  chartProperties: {
    name: "PnL By Week",
    chartType: "bar",
    keys: ["realisedPnl"],
    colors: ["var(--chart-blue)"],
    legend: ["Realized PnL"],
    domainType: "date",
  },
};
