import { useState, useCallback, useEffect } from "react";
import { useAtomValue } from "jotai";
import { useColorMode, useToast, useColorModeValue } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { AppStateAtom } from "../../../store";
import {
  getAPIKeys,
  getAllLayoutsForUser,
  getPublicInsights,
} from "../../../api";
import { DashboardConfiguration } from "../../../types/DashboardConfiguration";

export interface UseEmbeddingConfigurationResponse {
  themeSetting: string;
  setThemeSetting: (theme: string) => void;
  fontSetting: string;
  setFontSetting: (font: string) => void;
  backgroundColorSetting: string;
  setBackgroundColorSetting: (color: string) => void;
  apiKeysList: any[];
  loading: boolean;
  selectedAccountId: string;
  setSelectedAccountId: (id: string) => void;
  selectedDashboardId: string;
  setSelectedDashboardId: (id: string) => void;
  layouts: DashboardConfiguration[];
  selectedAPIKey: string;
  setSelectedAPIKey: (key: string) => void;
  embedLink: string;
  referralId: string;
  setReferralId: (id: string) => void;
  poweredByText: string;
  setPoweredByText: (text: string) => void;
  toggleInsightDisplay: string;
  setToggleInsightDisplay: (display: string) => void;
  insights: any[];
  selectedInsightId: string;
  setSelectedInsightId: (id: string) => void;
  width: string;
  setWidth: (width: string) => void;
  height: string;
  setHeight: (height: string) => void;
  bgColorValue: string;
  copyToClipboard: (text: string) => void;
  fontFamily: string;
  footerScript: string;

  brandColorPrimary: string;
  setBrandColorPrimary: (color: string) => void;

  brandLogoUrl: string;
  setbrandLogoUrl: (url: string) => void;

  brandName: string;
  setbrandName: (name: string) => void;

  brandWebFont: string;
  setbrandWebFont: (font: string) => void;

  demoEnabled: boolean;
  setDemoEnabled: (enabled: boolean) => void;
}

export const useEmbeddingConfigurationController =
  (): UseEmbeddingConfigurationResponse => {
    const [searchParams] = useSearchParams();
    const theme = searchParams.get("theme");
    const fontFamily = searchParams.get("font") || "system-ui";

    const { colorMode, toggleColorMode } = useColorMode();
    const toast = useToast();

    const [themeSetting, setThemeSetting] = useState("dark");
    const [fontSetting, setFontSetting] = useState("system-ui");
    const [backgroundColorSetting, setBackgroundColorSetting] =
      useState("#000000");

    const [apiKeysList, setApiKeysList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedAccountId, setSelectedAccountId] = useState("");
    const [selectedDashboardId, setSelectedDashboardId] = useState("");
    const [layouts, setLayouts] = useState<DashboardConfiguration[]>([]);
    const [selectedAPIKey, setSelectedAPIKey] = useState("");
    const [embedLink, setEmbedLink] = useState("");
    const [referralId, setReferralId] = useState("");
    const [poweredByText, setPoweredByText] = useState("Powered by");
    const [toggleInsightDisplay, setToggleInsightDisplay] =
      useState("DASHBOARD");
    const [insights, setInsights] = useState([]);
    const [selectedInsightId, setSelectedInsightId] = useState(undefined);
    const [width, setWidth] = useState(900);
    const [height, setHeight] = useState(400);

    const [demoEnabled, setDemoEnabled] = useState(false);

    const [brandColorPrimary, setBrandColorPrimary] = useState("#d29a4b");
    const [brandLogoUrl, setbrandLogoUrl] = useState(
      "https://cdn.wealthbee.io/main/logos/wealthbee-logo-only.svg"
    );
    const [brandName, setbrandName] = useState("WealthBee");
    const [brandWebFont, setbrandWebFont] = useState("cursive");
    const appState = useAtomValue(AppStateAtom);

    const footerScript = ` <script> const iframe = document.getElementById("contentFrame"); iframe.onload = () => { iframe.contentWindow.postMessage("getHeight", "*"); }; window.addEventListener("message", (event) => { if (event.origin === "${window.location.origin}") { const newHeight = event.data.height; iframe.style.height = newHeight + "px"; } }); </script>`;

    const bgColorValue = useColorModeValue(
      "var(--light-gray)",
      "var(--dark-gray)"
    );

    const fetchKeys = useCallback(async () => {
      setLoading(true);
      const keys = await getAPIKeys();
      getDashboards();
      setApiKeysList(keys || []);
      if (keys.length > 0) {
        setSelectedAPIKey(keys[0].apiKey);
      }
      setLoading(false);
    }, [appState.user.id]);

    const getDashboards = useCallback(async () => {
      const fetchedLayouts = await getAllLayoutsForUser();
      setLayouts(fetchedLayouts);
      if (fetchedLayouts.length > 0) {
        setSelectedDashboardId(fetchedLayouts[0].configurationId);
      }
    }, [appState.user.id]);

    const getInsights = useCallback(async () => {
      const insights = await getPublicInsights();
      setInsights(insights);
      setSelectedInsightId(insights[0].id);
    }, []);

    useEffect(() => {
      if (selectedAccountId && apiKeysList.length > 0 && selectedAPIKey) {
        let link = `${
          window.location.origin
        }/embed?pid=${selectedAccountId}&did=${selectedDashboardId}&apikey=${selectedAPIKey}&theme=${themeSetting}&font=${fontSetting}&bgcolor=${backgroundColorSetting.replace(
          "#",
          ""
        )}&ref=${referralId}&refText=${poweredByText}&displayType=${toggleInsightDisplay}&iid=${selectedInsightId}&w=${width}&h=${height}`;

        if (demoEnabled === true) {
          link += `&brandColorPrimary=${brandColorPrimary.replace(
            "#",
            ""
          )}&brandLogoUrl=${encodeURIComponent(brandLogoUrl)}&brandName=${brandName}&brandWebFont=${brandWebFont}&demo=true`;
        }
        setEmbedLink(link);
      }
    }, [
      selectedAccountId,
      selectedDashboardId,
      apiKeysList,
      selectedAPIKey,
      themeSetting,
      fontSetting,
      backgroundColorSetting,
      referralId,
      poweredByText,
      toggleInsightDisplay,
      selectedInsightId,
      width,
      height,

      brandColorPrimary,
      brandLogoUrl,
      brandName,
      brandWebFont,
      demoEnabled,
    ]);

    useEffect(() => {
      fetchKeys();
      getInsights();
    }, []);

    useEffect(() => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }, []);

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
      toast({
        title: "Copied to clipboard",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    };

    return {
      themeSetting,
      setThemeSetting,
      fontSetting,
      setFontSetting,
      backgroundColorSetting,
      setBackgroundColorSetting,
      apiKeysList,
      loading,
      selectedAccountId,
      setSelectedAccountId,
      selectedDashboardId,
      setSelectedDashboardId,
      layouts,
      selectedAPIKey,
      setSelectedAPIKey,
      embedLink,
      referralId,
      setReferralId,
      poweredByText,
      setPoweredByText,
      toggleInsightDisplay,
      setToggleInsightDisplay,
      insights,
      selectedInsightId,
      setSelectedInsightId,
      width,
      setWidth,
      height,
      setHeight,
      bgColorValue,
      copyToClipboard,
      fontFamily,
      footerScript,

      brandColorPrimary,
      setBrandColorPrimary,

      brandLogoUrl,
      setbrandLogoUrl,

      brandName,
      setbrandName,

      brandWebFont,
      setbrandWebFont,

      demoEnabled,
      setDemoEnabled,
    };
  };
