import React from "react";
import { FormControl, FormLabel, Checkbox, HStack } from "@chakra-ui/react";
import { FormikProps } from "formik";
import { InsightTemplate } from "../../../../../types/Insight";

interface VisibilityFieldsProps {
  formik: FormikProps<InsightTemplate>;
}

const VisibilityFields: React.FC<VisibilityFieldsProps> = ({ formik }) => (
  <HStack>
    <FormControl>
      <FormLabel>Public</FormLabel>
      <Checkbox
        name="isPublic"
        isChecked={formik.values.isPublic}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </FormControl>
    <FormControl>
      <FormLabel>Official</FormLabel>
      <Checkbox
        name="official"
        isChecked={formik.values.official}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </FormControl>
    <FormControl>
      <FormLabel>Compatible with timeframes</FormLabel>
      <Checkbox
        name="compatibleWithTimeframes"
        isChecked={formik.values.compatibleWithTimeframes}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </FormControl>
    <FormControl>
      <FormLabel>Compatible with windows</FormLabel>
      <Checkbox
        name="compatibleWithWindows"
        isChecked={formik.values.compatibleWithWindows}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </FormControl>
  </HStack>
);

export default VisibilityFields;
