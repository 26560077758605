import React from "react";
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { FormikProps } from "formik";
import { InsightTemplate } from "../../../../../types/Insight";

interface TagsFieldProps {
  formik: FormikProps<InsightTemplate>;
  removeTag: (tag: string) => void;
  addTag: () => void;
  handleTagChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TagsField: React.FC<TagsFieldProps> = ({
  formik,
  removeTag,
  addTag,
  handleTagChange,
}) => (
  <FormControl>
    <FormLabel>Tags</FormLabel>
    <HStack spacing={2} wrap="wrap">
      {formik.values.tags?.map((tag: string) => (
        <Tag
          key={tag}
          size="md"
          borderRadius="full"
          variant="solid"
          colorScheme="teal"
        >
          <TagLabel>{tag}</TagLabel>
          <TagCloseButton onClick={() => removeTag(tag)} />
        </Tag>
      )) ?? []}
    </HStack>
    <HStack spacing={2} mt={2}>
      <Input
        value={formik.values.newTag || ""}
        onChange={handleTagChange}
        placeholder="New tag"
      />
      <Button onClick={addTag} colorScheme="teal">
        Add Tag
      </Button>
    </HStack>
  </FormControl>
);

export default TagsField;
