import {
  Box,
  Heading,
  Image,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Component } from "react";
import { Panel } from "../../../../../components/design_library";
import React from "react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export default class InteractiveBrokersInstructions extends Component {
  render() {
    return (
      <Panel>
        <Stack spacing="5">
          <Heading size="md" mt={4}>
            Instructions
          </Heading>
          <Link
            href="https://wealthbee.io/knowledgebase/ibkr-import/"
            isExternal
            display="flex"
          >
            <Text color="var(--accent)">
              Click here to read the instructions for how to import Interactive
              Brokers trades
            </Text>
            <ExternalLinkIcon ml="10px" />
          </Link>
        </Stack>
      </Panel>
    );
  }
}
