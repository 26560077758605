import React from "react";
import { InsightDefinition } from "../../../types/Insight";
import { InsightHeader2, InsightRenderer2, InsightWrapper2 } from ".";
import InsightErrorBoundary from "../../dashboard/canvas/insight/InsightRenderers/InsightErrorBoundary";
import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import { FaHandScissors } from "react-icons/fa";

interface InsightContainer2Props {
  insight: InsightDefinition;
  resizing?: boolean;
  embedded?: boolean;
}

export const InsightContainer2 = (props: InsightContainer2Props) => {
  const { insight, resizing, embedded } = props;

  return (
    <InsightWrapper2>
      <InsightHeader2 insight={insight} embedded={embedded} />

      <InsightErrorBoundary>
        {!resizing && <InsightRenderer2 insight={insight} demo={false} />}
        {resizing && (
          <Center h="80%" w="80%" m="auto">
            <VStack>
              <HStack>
                <FaHandScissors />
                <Text>Resizing mode...</Text>
              </HStack>
              <Text fontSize={"xs"} textAlign={"center"}>
                Drag to re-size!
              </Text>
            </VStack>
          </Center>
        )}
      </InsightErrorBoundary>
    </InsightWrapper2>
  );
};
