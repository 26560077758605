// Navbar.js
import React from "react";
import { Box } from "@chakra-ui/react";
import { SubscriptionStatus } from "../../components/subscription/SubscriptionStatus";
import { useNavbarController } from "./useNavbarController";
import { navigationGroups as navConfig } from "./data/navConfig";
import { SidebarContent } from "./components/SidebarContent";

export const Navbar = () => {
  const { isCollapsed, toggleSidebar, navigate, isUserAdmin } =
    useNavbarController();
  const navGroups = navConfig(isUserAdmin, isCollapsed);

  return (
    <Box
      fontSize="sm"
      className="navbar-hook"
      bg="var(--bg)"
      transition="width 0.2s ease"
      w={isCollapsed ? "70px" : "250px"}
      overflow="hidden"
      h="full"
    >
      <SidebarContent
        isCollapsed={isCollapsed}
        toggleSidebar={toggleSidebar}
        navigate={navigate}
        navigationGroups={navGroups}
      />
      <SubscriptionStatus />
    </Box>
  );
};
