// Theoretical
// Delayed
// EOD
// Live
// User
// Unknown
// default

export const TABLE_UNDERLYING_PRICES = {
  rows: [
    {
      symbol: "AAPL",
      price: 144.67,
      change: 0.12,
      price_type: "Delayed",
    },
    {
      symbol: "NVDA",
      price: 200.67,
      change: 1.12,
      price_type: "Theoretical",
    },

    {
      symbol: "GOOGL",
      price: 300.67,
      change: -2.12,
      price_type: "Live",
    },
    {
      symbol: "TSLA",
      price: 400.67,
      change: 3.12,
      price_type: "User",
    },
    {
      symbol: "AMZN",
      price: 500.67,
      change: -4.12,
      price_type: "EOD",
    },
    {
      symbol: "MSFT",
      price: 600.67,
      change: 5.12,
      price_type: "Unknown",
    },
  ],
};
