export const getPrecision = (value: number): number => {
  if (!isFinite(value)) return 0;
  var e = 1,
    p = 0;
  while (Math.round(value * e) / e !== value) {
    e *= 10;
    p++;
  }
  return p;
};

export const shortenNumber = (
  num: number,
  decimals = 1,
  prefix = "",
  showPlus = true
): string => {
  const absNum = Math.abs(num);

  // Decide how to display the sign
  const sign = num < 0 ? "-" : num > 0 && showPlus ? "+" : "";

  // Helper to format number + suffix
  const formatNumber = (divisor: number, suffix: string) =>
    // sign first, then prefix, then the shortened value
    `${sign}${prefix}${(absNum / divisor)
      .toFixed(decimals)
      .replace(/\.0$/, "")}${suffix}`;

  if (absNum >= 1.0e12) {
    // Trillions
    return formatNumber(1.0e12, "T");
  } else if (absNum >= 1.0e9) {
    // Billions
    return formatNumber(1.0e9, "B");
  } else if (absNum >= 1.0e6) {
    // Millions
    return formatNumber(1.0e6, "M");
  } else if (absNum >= 1.0e3) {
    // Thousands
    return formatNumber(1.0e3, "K");
  }

  // Numbers below 1,000: sign + prefix + the raw number
  return `${sign}${prefix}${absNum.toFixed(decimals).replace(/\.0$/, "")}`;
};
