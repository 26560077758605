import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { MdEditDocument } from "react-icons/md";
import { Trade } from "../../types";
import convertSymbolToReadable from "../../components/grid/utils/convertSymbolToReadable";
import { FaTags } from "react-icons/fa";
import NoteEditor from "../../components/texteditor/NoteEditor";
import { TagEntry } from "./TagEntry";

export default function Notes(props: any) {
  if (!props.notes && !props.tags) return undefined;
  if (props.notes && props.notes.length === 0 && props.tags.length === 0)
    return undefined;
  if (props?.notes === "<p></p>" && props?.tags?.length === 0) return undefined;

  return (
    <HStack
      fontSize={"18px"}
      textAlign={"center"}
      alignItems={"center"}
      justifyContent={"center"}
      height="100%"
      p={"0"}
      px="2"
    >
      {props.notes && props.notes.length > 0 && props.notes !== "<p></p>" && (
        <MdEditDocument overflow={"visible"} />
      )}
      {props.tags && props.tags.length > 0 && <FaTags overflow={"visible"} />}
    </HStack>
  );
}

export function NotesModal({
  isOpen,
  onClose,
  trade,
}: {
  isOpen: boolean;
  onClose: () => void;
  trade: Trade;
}) {
  if (!trade) return undefined;

  return (
    <Modal isOpen={isOpen} size="5xl" onClose={() => onClose()}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent bg={"var(--bg)"}>
        <ModalHeader>
          {convertSymbolToReadable(trade?.instrument?.symbol)}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="40px">
          <Box as="main" h="100%">
            <NoteEditor trade={trade} menuBarEnabled={true} />
            <TagEntry trade={trade} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
