import React from "react";
import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { FormikProps } from "formik";
import { InsightTemplate } from "../../../../../types/Insight";

interface CategoryFieldProps {
  formik: FormikProps<InsightTemplate>;
}

const CategoryField: React.FC<CategoryFieldProps> = ({ formik }) => (
  <FormControl isInvalid={formik.touched.category && !!formik.errors.category}>
    <FormLabel>Category</FormLabel>
    <Input
      name="category"
      value={formik.values.category}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
    />
    {formik.touched.category && formik.errors.category ? (
      <Text color="red.500">{formik.errors.category}</Text>
    ) : null}
  </FormControl>
);

export default CategoryField;
