export const PNL_BY_UNDERLYING = {
  data: [
    {
      domain: "ZTS",
      realisedPnl: -871.96,
      unrealisedPnl: 0,
    },
    {
      domain: "ZS",
      realisedPnl: 2259.96,
      unrealisedPnl: 0,
    },
    {
      domain: "ZEN",
      realisedPnl: -961.99,
      unrealisedPnl: 0,
    },
    {
      domain: "YUMC",
      realisedPnl: 425.36,
      unrealisedPnl: 0,
    },
    {
      domain: "YETI",
      realisedPnl: 348.97,
      unrealisedPnl: 0,
    },
    {
      domain: "XLY",
      realisedPnl: 257.04,
      unrealisedPnl: 0,
    },
    {
      domain: "XLU",
      realisedPnl: 499.64,
      unrealisedPnl: 0,
    },
    {
      domain: "XLK",
      realisedPnl: 930.69,
      unrealisedPnl: 0,
    },
    {
      domain: "XLF",
      realisedPnl: -650.59,
      unrealisedPnl: 0,
    },
    {
      domain: "XLB",
      realisedPnl: -100.14,
      unrealisedPnl: 0,
    },
    {
      domain: "XIV",
      realisedPnl: -621.59,
      unrealisedPnl: 0,
    },
    {
      domain: "XBI",
      realisedPnl: -777.9,
      unrealisedPnl: 0,
    },
    {
      domain: "X",
      realisedPnl: 195.94,
      unrealisedPnl: 0,
    },
    {
      domain: "WYNN",
      realisedPnl: 7398.09,
      unrealisedPnl: 0,
    },
    {
      domain: "WWE",
      realisedPnl: -241.83,
      unrealisedPnl: 0,
    },
    {
      domain: "WW",
      realisedPnl: 826.79,
      unrealisedPnl: 0,
    },
    {
      domain: "WU",
      realisedPnl: 1445.84,
      unrealisedPnl: 0,
    },
    {
      domain: "WPC",
      realisedPnl: -459.81,
      unrealisedPnl: 0,
    },
    {
      domain: "WMT",
      realisedPnl: 1168.27,
      unrealisedPnl: 0,
    },
    {
      domain: "WM",
      realisedPnl: -2456.16,
      unrealisedPnl: 0,
    },
    {
      domain: "WFC",
      realisedPnl: -563.04,
      unrealisedPnl: 0,
    },
    {
      domain: "WBA",
      realisedPnl: 605.97,
      unrealisedPnl: 0,
    },
    {
      domain: "VSTO",
      realisedPnl: -722.06,
      unrealisedPnl: 0,
    },
    {
      domain: "VPU",
      realisedPnl: -939.63,
      unrealisedPnl: 0,
    },
    {
      domain: "VMW",
      realisedPnl: 419.06,
      unrealisedPnl: 0,
    },
    {
      domain: "VHT",
      realisedPnl: 513,
      unrealisedPnl: 0,
    },
    {
      domain: "UUUU",
      realisedPnl: -781.83,
      unrealisedPnl: 0,
    },
    {
      domain: "URBN",
      realisedPnl: 340.02,
      unrealisedPnl: 0,
    },
    {
      domain: "URA",
      realisedPnl: 1221.23,
      unrealisedPnl: 0,
    },
    {
      domain: "UNH",
      realisedPnl: 3180.03,
      unrealisedPnl: 0,
    },
    {
      domain: "UNFI",
      realisedPnl: 445.88,
      unrealisedPnl: 0,
    },
    {
      domain: "ULTA",
      realisedPnl: -3264.96,
      unrealisedPnl: 0,
    },
    {
      domain: "UEC",
      realisedPnl: -440.26,
      unrealisedPnl: 0,
    },
    {
      domain: "UAA",
      realisedPnl: -1103.14,
      unrealisedPnl: 0,
    },
    {
      domain: "TXT",
      realisedPnl: 302.01,
      unrealisedPnl: 0,
    },
    {
      domain: "TXN",
      realisedPnl: -686.96,
      unrealisedPnl: 0,
    },
    {
      domain: "TSLA",
      realisedPnl: -1634.01,
      unrealisedPnl: 0,
    },
    {
      domain: "TQQQ",
      realisedPnl: -387.83,
      unrealisedPnl: 0,
    },
    {
      domain: "TOL",
      realisedPnl: 435.93,
      unrealisedPnl: 0,
    },
    {
      domain: "TLT",
      realisedPnl: -320.01,
      unrealisedPnl: 0,
    },
    {
      domain: "TJX",
      realisedPnl: 2760.9,
      unrealisedPnl: 0,
    },
    {
      domain: "TGT",
      realisedPnl: -193.99,
      unrealisedPnl: 0,
    },
    {
      domain: "TEL",
      realisedPnl: -917.94,
      unrealisedPnl: 0,
    },
    {
      domain: "STZ",
      realisedPnl: -1582,
      unrealisedPnl: 0,
    },
  ],
  chartProperties: {
    name: "PnL By Underlying",
    chartType: "bar",
    keys: ["realisedPnl", "unrealisedPnl"],
    colors: ["var(--chart-blue)", "var(--chart-orange)"],
    legend: ["Realized PnL", "Unrealized Pnl"],
    domainType: "string",
    showEveryDomainLabel: true,
    flipAxis: true,
  },
};
