export const GET_PNL_BY_HOLDING_TIME = {
  data: [
    {
      domain: "0 days",
      totalPnl: -817.66,
    },
    {
      domain: "1 days",
      totalPnl: -183.91,
    },
    {
      domain: "2 days",
      totalPnl: -61.75,
    },
    {
      domain: "3 days",
      totalPnl: 764.2,
    },
    {
      domain: "4 - 5 days",
      totalPnl: -1508.47,
    },
    {
      domain: "6 - 7 days",
      totalPnl: -2519.93,
    },
    {
      domain: "8 - 10 days",
      totalPnl: -7032.62,
    },
    {
      domain: "11 - 15 days",
      totalPnl: -4968.28,
    },
    {
      domain: "16 - 30 days",
      totalPnl: 11852.59,
    },
    {
      domain: "30+ days",
      totalPnl: 13067.3,
    },
  ],
  chartProperties: {
    name: "PnL By Holding Time",
    chartType: "bar",
    keys: ["totalPnl"],
    colors: ["var(--chart-green)"],
    legend: ["Total PnL"],
    domainType: "holdingTime",
  },
};
