import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  ModalCloseButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { IoRadioButtonOff } from "react-icons/io5";

export default function ModalHeader({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: any;
}) {
  return (
    <VStack align="start">
      <HStack p="4">
        <Flex
          minW="50px"
          minH="50px"
          w="50px"
          h="50px"
          bg="var(--dark-gray)"
          borderRadius={"md"}
          justifyContent={"center"}
          alignItems={"center"}
          border={"1px solid var(--gray)"}
        >
          {icon}
        </Flex>
        <VStack align="start" lineHeight="18px">
          <Text fontWeight={600}>{title}</Text>
          <Text>{description}</Text>
        </VStack>
        <ModalCloseButton />
      </HStack>
      <Divider />
    </VStack>
  );
}
