import { Box, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { ChevronRight, StockLogo } from "../../design_library";
import NavWrapper from "./NavWrapper";

export default function TransactionsPaneTutorial() {
  return (
    <NavWrapper
      previousEnabled={false}
      isFinalStep={false}
      nextDisabled={false}
    >
      <VStack w="100%" textAlign={"left"} alignItems={"flex-start"}>
        <Heading
          as="h2"
          fontSize="18px"
          mb={4}
          fontWeight={600}
          textAlign={"left"}
          w="100%"
        >
          Transactions Pane
        </Heading>
        <Text mt="10px">
          The transactions pane shows all the transactions that make up a
          position.
        </Text>
        <Text mt="10px">
          If you double click a transaction then you'll be able to add notes to
          it.
        </Text>
        <Text mt="10px">
          You can also right click on a transaction to see the options available
          to you.
        </Text>
      </VStack>
    </NavWrapper>
  );
}
