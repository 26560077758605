import { Box, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { BiWifi2, BiWifi, BiUser, BiWifiOff } from "react-icons/bi";
import { MdOnlinePrediction } from "react-icons/md";

export const Indicator = ({ priceConfidence }) => {
  const indicators = {
    Theoretical: {
      label:
        "Theoretical Price - Price is calculated by us based on black scholes model.",
      icon: <MdOnlinePrediction color="var(--green)" />,
    },
    Delayed: {
      label: "Pricing is delayed, this can be from 15 mins and longer.",
      icon: <BiWifi2 color="var(--accent)" />,
    },
    EOD: {
      label: "End of Day data - Priced from last close.",
      icon: <BiWifi2 color="var(--accent)" />,
    },
    Live: {
      label: "Live data is less than 1 minute old",
      icon: <BiWifi color="var(--green)" />,
    },
    User: {
      label: "User Price - Price is set by user.",
      icon: <BiUser color="var(--green)" />,
    },
    Unknown: {
      label: "Data is not available",
      icon: <BiWifiOff color="var(--red)" />,
    },
    default: {
      label: "...",
      icon: <BiWifiOff color="var(--blue)" />,
    },
  };

  const indicator = indicators[priceConfidence] || indicators["default"];

  return <IndicatorText label={indicator.label} icon={indicator.icon} />;
};

const IndicatorText = ({ label, icon }) => {
  return (
    <Box>
      <Tooltip label={label}>
        <Text fontSize="30px">{icon}</Text>
      </Tooltip>
    </Box>
  );
};
