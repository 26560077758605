import { useAtomValue } from "jotai";
import { useFilters } from "../../../hooks/useFilters";
import { AccountAtom, TimeFrameAtom } from "../../../store";
import { useQuery } from "@tanstack/react-query";
import { InsightStateEnum } from "../InsightStateEnum";
import { useEffect, useState } from "react";
import { resolveNamedTimeFrame } from "../../../utils";
import { runInsight } from "../../../api";
import { BaseInsightProps } from "../BaseInsightProps";
import InsightTableDemoDataMap from "./data";
import { useSearchParams } from "react-router-dom";

interface UseInsightTableController {
  insightState: InsightStateEnum;
  supported: boolean;
  rows: any[];
}

export const useInsightTableController = (
  props: BaseInsightProps
): UseInsightTableController => {
  const { insight } = props;

  const filters = useFilters();
  const accountState = useAtomValue(AccountAtom);
  const timeFrame = useAtomValue(TimeFrameAtom);

  const [searchParams] = useSearchParams();
  const pid = parseInt(searchParams.get("pid") ?? "");
  const accountId = pid || accountState?.selectedAccount?.id;

  const [insightState, setInsightState] = useState<InsightStateEnum>(
    InsightStateEnum.Loading
  );

  // uses react query to cache result
  const result = useQuery({
    queryKey: [
      props.demo === true ? "insight-table" : "insight-table-demo",

      insight.insightId,
      insight.query,
      insight.type,
      accountId,
      filters.selectedUnderlying,
      filters.selectedTag,
      filters.selectedStrategy,
      filters.selectedBook,
      filters.selectedPositionGroup,
      timeFrame.timeFrame,
    ],
    queryFn: async () => {
      if (props.demo === true && props?.insight?.query) {
        return InsightTableDemoDataMap[props?.insight?.query];
      } else {
        const timeframe = resolveNamedTimeFrame(timeFrame.timeFrame);

        return await runInsight(
          accountId,
          insight.insightId,
          insight.query!,
          insight.type,
          timeframe,
          filters.selectedUnderlying,
          filters.selectedTag,
          filters.selectedStrategy,
          filters.selectedBook,
          filters.selectedPositionGroup
        );
      }
    },
    enabled:
      accountId !== undefined &&
      insight !== undefined &&
      insight.insightId !== undefined &&
      insight.query !== undefined &&
      insight.type !== undefined,
  });

  useEffect(() => {
    if (props.demo) {
      setInsightState(InsightStateEnum.Success);
    } else {
      if (!result.isSuccess && !result.isError) {
        setInsightState(InsightStateEnum.Loading);
      } else if (result.isSuccess) {
        if (result.data) {
          setInsightState(InsightStateEnum.Success);
        } else {
          setInsightState(InsightStateEnum.NoData);
        }
        setInsightState(InsightStateEnum.Success);
      } else if (result.isError) {
        setInsightState(InsightStateEnum.Error);
      }
    }
  }, [result, props.demo]);

  return {
    insightState,
    supported:
      result.data?.supported === undefined ? true : result.data!.supported,
    rows: result?.data?.rows ?? [],
  };
};
