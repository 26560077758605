import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Progress,
  Text,
  Box,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { compilePositionsForAllusers, GetFullListOfUsers } from "../../api";

export default function RegeneratePositions() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [users, setUsers] = React.useState([]);
  const [started, setStarted] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [successfulCount, setSuccessfulCount] = React.useState(0);
  const [errorCount, setErrorCount] = React.useState(0);

  const getUsers = async () => {
    const usersList = await GetFullListOfUsers();
    setUsers(usersList);
  };

  const regeneratePositions = async () => {
    setStarted(true);
    const result = await compilePositionsForAllusers();

    setSuccessfulCount(result.data.success.length);
    setErrorCount(result.data.errors.length);
    setStarted(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Button size="sm" onClick={onOpen}>
        Regenerate user positions
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="var(--bg)">
          <ModalHeader textAlign={"center"} fontSize="16px">
            Regenerate User Positions
          </ModalHeader>
          <ModalBody textAlign={"center"}>
            <Text>
              Total Users:
              {users.length}
            </Text>
            <Text mb="20px" fontSize={"12px"}>
              (ETA 50ms per user = {(users.length * 50) / 1000}
              s)
            </Text>

            <Button
              mb="20px"
              colorScheme="blue"
              size="sm"
              w="full"
              onClick={() => {
                regeneratePositions();
              }}
              isDisabled={started}
            >
              {started ? "Regenerating..." : "Regenerate Positions"}
            </Button>

            <Box>
              <Text>
                Successful: {successfulCount} Errors: {errorCount}
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
