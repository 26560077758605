import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import "../../../components/design_library/WYSIWIGEditorStyling.css";

export default function KnowledgeBaseAdmin() {
  return (
    <Flex height="85vh" bg="var(--bg)" p="4">
      <Text>
        <Text fontSize="2xl" fontWeight="bold" color="var(--text-color)">
          User Guide
        </Text>
        <Text fontSize="lg" color="var(--text-color)">
          The userguide can now be updated on{" "}
          <a
            style={{
              color: "var(--chart-blue)",
            }}
            href="https://app.contentful.com/spaces/e8se1dkcv0am/views/entries"
          >
            Contentful
          </a>
        </Text>
      </Text>
    </Flex>
  );
}
