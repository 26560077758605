import React, { useEffect, useRef, useState } from "react";
import { getQueue } from "../../api";
import { Spinner } from "../misc";
import { HStack, Text, UseToastOptions, useToast } from "@chakra-ui/react";
import { useTransactions } from "../../hooks/useTransactions";
import { useAtom, useAtomValue } from "jotai";
import { AccountAtom, PricingQueueAtom } from "../../store";
import { usePositions } from "../../hooks";
import { PricingPollerStatus } from "../../types";
import { useNotifications } from "../../hooks/useNotifications";

export default function PricingQueuePoll() {
  const [status, setStatus] = useState<PricingPollerStatus>(
    PricingPollerStatus.POLLING
  );
  const transactionsHook = useTransactions();
  const accountState = useAtomValue(AccountAtom);
  const positionsHook = usePositions();
  const [pricingQueueState, setPricingQueueState] = useAtom(PricingQueueAtom);
  const initialised = useRef<boolean>(false);
  const notificationsHooks = useNotifications();

  const updateQueue = async () => {
    const queue = await getQueue();
    if (queue.status === PricingPollerStatus.POLLING) {
      // notificationsHooks.createLongRunningNotification(
      //   "P&L is being updated, please wait...",
      //   "Your positions and trades will be automatically updated once this has completed."
      // );
      setStatus(PricingPollerStatus.POLLING);
      setPricingQueueState({
        active: true,
      });

      setTimeout(() => {
        updateQueue();
      }, 10000);
    } else {
      setStatus(PricingPollerStatus.EMPTY);
      setStatus(PricingPollerStatus.EMPTY);
      transactionsHook.getTradesAndCashflows(accountState?.selectedAccount?.id);
      positionsHook.fetchPositions(accountState?.selectedAccount?.id);

      setPricingQueueState({
        active: false,
      });

      // notificationsHooks.clearLongRunningNotification();
    }
  };

  useEffect(() => {
    updateQueue();
  }, [pricingQueueState.active, status]);

  return null;
}
