export const GET_PNL_BY_TRADE_COUNT = {
  data: [
    {
      domain: "1 trade",
      averageDailyPnl: -51.68,
      profitableDays: 82,
      unprofitableDays: 88,
      totalDays: 170,
      profitabilityPercentage: 48.24,
    },
    {
      domain: "2 trades",
      averageDailyPnl: 84.55,
      profitableDays: 41,
      unprofitableDays: 41,
      totalDays: 82,
      profitabilityPercentage: 50,
    },
    {
      domain: "3 trades",
      averageDailyPnl: -338.46,
      profitableDays: 18,
      unprofitableDays: 15,
      totalDays: 33,
      profitabilityPercentage: 54.55,
    },
    {
      domain: "4 trades",
      averageDailyPnl: 209.36,
      profitableDays: 11,
      unprofitableDays: 10,
      totalDays: 21,
      profitabilityPercentage: 52.38,
    },
    {
      domain: "5 trades",
      averageDailyPnl: 1481.25,
      profitableDays: 6,
      unprofitableDays: 2,
      totalDays: 8,
      profitabilityPercentage: 75,
    },
    {
      domain: "6 trades",
      averageDailyPnl: -1873.39,
      profitableDays: 0,
      unprofitableDays: 1,
      totalDays: 1,
      profitabilityPercentage: 0,
    },
    {
      domain: "7 trades",
      averageDailyPnl: 5083.27,
      profitableDays: 2,
      unprofitableDays: 1,
      totalDays: 3,
      profitabilityPercentage: 66.67,
    },
    {
      domain: "8 trades",
      averageDailyPnl: -858.17,
      profitableDays: 1,
      unprofitableDays: 1,
      totalDays: 2,
      profitabilityPercentage: 50,
    },
    {
      domain: "11 trades",
      averageDailyPnl: -3146.46,
      profitableDays: 1,
      unprofitableDays: 1,
      totalDays: 2,
      profitabilityPercentage: 50,
    },
  ],
  chartProperties: {
    name: "PnL By Trades Per Day",
    chartType: "bar",
    keys: ["averageDailyPnl"],
    colors: ["var(--chart-green)"],
    legend: ["Average Daily PnL"],
    domainType: "text",
  },
};
