import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  VStack,
  Center,
  Spinner,
  HStack,
  Text,
  Flex,
} from "@chakra-ui/react";
import {
  Grid,
  Axis,
  BarStack,
  BarSeries,
  LineSeries,
  AreaSeries,
  XYChart,
  Tooltip,
} from "@visx/xychart";
import {
  curveCardinal,
  curveCatmullRom,
  curveLinear,
  curveNatural,
} from "@visx/curve";
import moment from "moment";
import { DataItem } from "../../../types";
import { BaseInsightProps } from "../BaseInsightProps";
import { useInsightChartController } from "./useInsightChartController";
import { NewTooltip } from "../../dashboard/canvas/insight/InsightRenderers/Shared/Tooltip";
import ExampleTag from "../shared/ExampleTag";
import { Show } from "../../../components";
import { InsightStateEnum } from "../InsightStateEnum";
import NoDataAvailable from "../components/NoDataAvailable";
import NoTimeFrameCompability from "../components/NoTimeframeCompatibility";
import InsightError from "../components/InsightError";
import InsightLoading from "../components/InsightLoading";
import InsightErrorRouter from "../components/InsightErrorRouter";
import currencyFormatter from "currency-formatter";
import { BiChart, BiScreenshot } from "react-icons/bi";

export const InsightChart = (props: BaseInsightProps) => {
  const { insight, demo } = props;

  const [containerWidth, setContainerWidth] = useState<number>(800);
  const [containerHeight, setContainerHeight] = useState<number>(400);

  const containerRef = useRef<HTMLDivElement>(null);

  const controller = useInsightChartController(props);

  const margin = { top: 10, left: 75, right: 20, bottom: 50 };
  const background = "transparent";
  const numTicks = 6;

  const accessors = useMemo(() => {
    return {
      xAccessor: (d: DataItem, key: string) => {
        if (controller.chartProperties?.flipAxis === true) {
          return d[key] as number;
        }
        return d.domain;
      },
      yAccessor: (d: DataItem, key: string) => {
        if (controller.chartProperties?.flipAxis === true) {
          return d.domain;
        }
        return d[key] as number;
      },
    };
  }, [controller.chartProperties, controller.data]);

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (!entries.length) return;
      const { width, height } = entries[0].contentRect;
      setContainerWidth(width);
      setContainerHeight(height);
    };

    const observer = new ResizeObserver((entries) => handleResize(entries));
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <VStack
      h="full"
      maxH="95%"
      spacing={4}
      align="start"
      ref={containerRef}
      className="insight-chart"
    >
      <Flex
        display={{
          base: "block",
          sm: "block",
          md: "none",
          lg: "none",
          xl: "none",
        }}
        w="full"
        h="full"
        alignContent={"center"}
      >
        <Flex flex={1} alignItems="center" flexDir={"column"}>
          <BiChart size={48} />
          <Text fontSize="sm" ml={2}>
            The chart insight cannot supported on small screens.
          </Text>
        </Flex>
      </Flex>

      <Box
        p={4}
        overflow={"visible"}
        w="full"
        h="full"
        display={{
          base: "none",
          sm: "none",
          md: "block",
          lg: "block",
          xl: "block",
        }}
      >
        {props.demo === true && <ExampleTag />}

        <InsightErrorRouter
          insightState={controller.insightState}
          supported={controller.supported}
        />
        <Show
          if={
            controller.supported === true &&
            controller.insightState === InsightStateEnum.Success
          }
        >
          <XYChart
            xScale={
              controller.chartProperties?.flipAxis === true
                ? {
                    type: "linear",
                  }
                : { type: "band", padding: 0.2 }
            }
            yScale={
              controller.chartProperties?.flipAxis === true
                ? { type: "band", padding: 0.2 }
                : {
                    type: "linear",
                  }
            }
            width={containerWidth - 20}
            height={containerHeight}
            margin={margin}
          >
            <rect
              width={containerWidth}
              height={containerHeight}
              fill={background}
              rx={14}
            />

            <Grid
              rows={true}
              columns={false}
              numTicks={numTicks}
              // animationTrajectory="center"
              lineStyle={{ stroke: "#717186", strokeWidth: 1, opacity: 0.3 }}
            />

            {/* Domain Axis */}
            <Axis
              orientation={
                controller.chartProperties?.flipAxis === true
                  ? "left"
                  : "bottom"
              }
              tickFormat={(value) => {
                if (
                  controller.chartProperties?.flipAxis !== true &&
                  controller.chartProperties?.domainType === "date"
                ) {
                  return moment(value, "YYYY-MM-DD").format("Do MMM YY");
                }

                return value;
              }}
              tickValues={
                controller.chartProperties?.flipAxis === true &&
                controller.chartProperties?.showEveryDomainLabel
                  ? controller.domainValues
                  : undefined
              }
              // animationTrajectory="center"
              stroke="#717186"
              tickStroke="#717186"
              tickLabelProps={() => ({
                stroke: "#717186",
              })}
            />

            <Axis
              orientation={
                controller.chartProperties?.flipAxis === true
                  ? "bottom"
                  : "left"
              }
              tickFormat={(value) => {
                if (
                  controller.chartProperties?.flipAxis === true &&
                  controller.chartProperties?.domainType === "date"
                ) {
                  return moment(value, "YYYY-MM-DD").format("Do MMM YY");
                }

                return currencyFormatter.format(value, {
                  code: "USD",
                  precision: 0,
                });
              }}
              tickValues={
                controller.chartProperties?.flipAxis !== true &&
                controller.chartProperties?.showEveryDomainLabel
                  ? controller.domainValues
                  : undefined
              }
              // animationTrajectory="center"
              stroke="#717186"
              numTicks={
                controller.chartProperties?.flipAxis !== true
                  ? numTicks
                  : undefined
              }
              tickStroke="#717186"
              tickLabelProps={() => ({
                stroke: "#717186",
              })}
            />

            {controller.chartProperties?.keys?.map((key, i) => {
              const name = controller.chartProperties?.name?.replaceAll(
                " ",
                "-"
              );

              switch (controller.chartProperties?.chartType) {
                case "line":
                  return (
                    <LineSeries
                      key={`1-${key}-${
                        controller.chartProperties?.flipAxis === true
                          ? "horizontal"
                          : "vertical"
                      }-${name}`}
                      dataKey={key}
                      data={controller.data as any[]}
                      xAccessor={(d) => accessors.xAccessor(d, key)}
                      yAccessor={(d) => accessors.yAccessor(d, key)}
                      curve={curveCardinal}
                    />
                  );
                case "area": {
                  const positiveData = (controller.data ?? []).map((d) => ({
                    ...d,
                    [key]: Math.max(d[key], 0),
                  }));

                  const negativeData = (controller.data ?? []).map((d) => {
                    return {
                      ...d,
                      [key]: Math.min(d[key], 0),
                    };
                  });

                  return (
                    <>
                      <AreaSeries
                        key={`area-positive-${key}`}
                        dataKey={key + "-alt"}
                        data={positiveData}
                        xAccessor={(d) => accessors.xAccessor(d, key)}
                        yAccessor={(d) => accessors.yAccessor(d, key)}
                        curve={curveLinear}
                        fill="var(--green)"
                        fillOpacity={0.8}
                        lineProps={{
                          stroke: "none",
                          strokeWidth: 0,
                        }}
                        enableEvents={true}
                      />

                      <AreaSeries
                        key={`area-negative-${key}`}
                        dataKey={key}
                        data={negativeData}
                        xAccessor={(d) => accessors.xAccessor(d, key)}
                        yAccessor={(d) => accessors.yAccessor(d, key)}
                        curve={curveLinear}
                        fill="var(--red)"
                        fillOpacity={0.8}
                        lineProps={{
                          stroke: "none",
                          strokeWidth: 0,
                        }}
                        enableEvents={true}
                      />

                      <LineSeries
                        dataKey="zero"
                        data={[
                          { domain: controller.domainValues[0], value: 0 },
                          {
                            domain:
                              controller.domainValues[
                                controller.domainValues.length - 1
                              ],
                            value: 0,
                          },
                        ]}
                        xAccessor={(d) => d.domain}
                        yAccessor={() => 0}
                        stroke="var(--accent)"
                        strokeWidth={1}
                      />
                    </>
                  );
                }
                // return (
                //   <>
                //     <AreaSeries
                //       key={`2-${key}-${
                //         controller.chartProperties?.flipAxis === true
                //           ? "horizontal"
                //           : "vertical"
                //       }`}
                //       dataKey={key}
                //       data={controller.data as any[]}
                //       xAccessor={(d) => accessors.xAccessor(d, key)}
                //       yAccessor={(d) => accessors.yAccessor(d, key)}
                //       curve={curveCatmullRom}
                //       fillOpacity={1.0}
                //       fill="var(--chart-blue)"
                //     />

                //     <LineSeries
                //       dataKey="zero"
                //       data={[
                //         { domain: controller.domainValues[0], value: 0 },
                //         {
                //           domain:
                //             controller.domainValues[
                //               controller.domainValues.length - 1
                //             ],
                //           value: 0,
                //         },
                //       ]}
                //       xAccessor={(d) => d.domain}
                //       yAccessor={() => 0}
                //       stroke="var(--red)"
                //       strokeWidth={1}
                //     />
                //   </>
                // );
                case "bar":
                  return (
                    <>
                      <BarStack>
                        {controller.chartProperties?.keys?.map((key, i) => {
                          const keyIdx =
                            controller.chartProperties?.keys?.indexOf(key);
                          const fillColour =
                            controller.chartProperties?.colors[keyIdx ?? 0] ??
                            "black";
                          return (
                            <BarSeries
                              key={`3-${key}-${
                                controller.chartProperties?.flipAxis === true
                                  ? "horizontal"
                                  : "vertical"
                              }-${name}`}
                              dataKey={key}
                              data={controller.data as any[]}
                              xAccessor={(d) => accessors.xAccessor(d, key)}
                              yAccessor={(d) => {
                                return accessors.yAccessor(d, key);
                              }}
                              barPadding={0.5}
                              // colorAccessor={() => fillColour}

                              // TODO - FIX THIS TO DERIVE IT BACK TO THE ORIGINAL COLOUR
                              colorAccessor={(d) => {
                                if (
                                  typeof accessors.xAccessor(d, key) !==
                                  "number"
                                ) {
                                  const value = accessors.yAccessor(
                                    d,
                                    key
                                  ) as number;

                                  return value >= 0
                                    ? "var(--green)"
                                    : "var(--red)";
                                } else {
                                  const value = accessors.xAccessor(
                                    d,
                                    key
                                  ) as number;
                                  if (key === "unrealisedPnl") {
                                    return value >= 0
                                      ? "var(--unrealised-gain)"
                                      : "var(--unrealised-loss)";
                                  } else if (key === "realisedPnl") {
                                    return value >= 0
                                      ? "var(--green)"
                                      : "var(--red)";
                                  } else {
                                    return fillColour;
                                  }
                                }
                              }}
                            />
                          );
                        })}
                      </BarStack>
                      <LineSeries
                        dataKey="zero"
                        data={[
                          { domain: controller.domainValues[0], value: 0 },
                          {
                            domain:
                              controller.domainValues[
                                controller.domainValues.length - 1
                              ],
                            value: 0,
                          },
                        ]}
                        xAccessor={(d) => d.domain}
                        yAccessor={() => 0}
                        stroke="var(--red)"
                        strokeWidth={1}
                      />
                    </>
                  );
              }
              return <></>;
            })}

            <Tooltip
              showHorizontalCrosshair
              showVerticalCrosshair
              showDatumGlyph
              renderTooltip={({ tooltipData, colorScale }) => {
                const x = accessors.xAccessor(
                  tooltipData.nearestDatum?.datum,
                  tooltipData.nearestDatum?.key as string
                );
                let y = accessors.yAccessor(
                  tooltipData.nearestDatum?.datum,
                  tooltipData.nearestDatum?.key as string
                );

                if (y === undefined) {
                  //Temp fix for alt data
                  y = accessors.yAccessor(
                    tooltipData.nearestDatum?.datum,
                    tooltipData.nearestDatum?.key.replace("-alt", "") as string
                  );
                }

                const keyIdx = controller.chartProperties?.keys?.indexOf(
                  tooltipData.nearestDatum?.key
                );

                if (keyIdx === undefined) return <></>;

                const value =
                  controller.chartProperties?.flipAxis === true ? x : y;
                const label =
                  controller.chartProperties?.flipAxis === true ? y : x;

                return (
                  <NewTooltip
                    domain={controller.chartProperties?.legend[keyIdx] ?? ""}
                    value={value as number}
                    label={
                      controller.chartProperties?.domainType === "date"
                        ? moment(label, "YYYY-MM-DD").format("Do MMM YY")
                        : label
                    }
                  />
                );
              }}
            />
          </XYChart>
        </Show>
      </Box>
    </VStack>
  );
};
