import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { Layout } from "react-grid-layout";

import { DashboardConfiguration } from "../../types/DashboardConfiguration";
import { InsightDefinition, InsightTemplate } from "../../types/Insight";
import {
  getDashboardConfiguration,
  getDashboardInsights,
  updateLayout,
} from "../../api";
import {
  AccountAtom,
  DashboardLayoutStateStore,
  SelectedDashboardIdAtom,
} from "../../store";

export interface UseDashboardController {
  layouts: Record<string, Layout[]>;
  insightDefinitions: InsightDefinition[];
  updateLayoutComp: (updatedLayout: Layout[]) => void;
  setBreakpoint: (breakpoint: string) => void;
  loading: boolean;
  insightBeingResized: boolean;
  setInsightBeingResized: (value: boolean) => void;
  selectedDashboardId?: string;
}

export const useDashboardController = (): UseDashboardController => {
  const [dashboardConfiguration, setDashboardConfiguration] = useState<
    DashboardConfiguration | undefined
  >();
  const [insights, setInsights] = useState<InsightTemplate[]>([]);
  const [insightBeingResized, setInsightBeingResized] = useState(false);

  const [dashboardLayoutStore, setDashboardLayoutState] = useAtom(
    DashboardLayoutStateStore
  );

  const selectedDashboardId = useAtomValue(SelectedDashboardIdAtom);
  const accountState = useAtomValue(AccountAtom);

  const generateLayoutData = useCallback(
    (
      layout: Record<string, Layout[]>,
      datasets: InsightTemplate[]
    ): Record<string, Layout[]> => {
      return dashboardLayoutStore.breakpoints.reduce((acc, breakpoint) => {
        acc[breakpoint] = layout[breakpoint].map((item) => ({
          ...item,
          data: datasets.find(
            (insight) => parseInt(insight.insightId) === parseInt(item.i)
          ),
        }));
        return acc;
      }, {} as Record<string, Layout[]>);
    },
    []
  );

  const loadDashboard = useCallback(async () => {
    handleResize();

    if (!selectedDashboardId || !accountState.selectedAccount?.id) return;

    try {
      const configResult = await getDashboardConfiguration(selectedDashboardId);
      if (configResult) {
        setDashboardConfiguration(configResult);

        const insightData = await getDashboardInsights(selectedDashboardId);
        if (insightData) {
          setInsights(insightData);
          const newLayoutData = generateLayoutData(
            configResult.layout,
            insightData ?? []
          );

          setDashboardLayoutState((prevState) => ({
            ...prevState,
            layouts: newLayoutData,
            gridLoading: false,
            enabledInsights: configResult.enabledInsights || [],
          }));
        }
      }
    } catch (error) {
      console.error("Error loading dashboard:", error);
    }
  }, [selectedDashboardId, accountState]);

  useEffect(() => {
    if (selectedDashboardId) {
      loadDashboard();
    }
  }, [selectedDashboardId]);

  const previousEnabledInsights = useRef(dashboardLayoutStore.enabledInsights);

  useEffect(() => {
    const currentEnabledInsights = dashboardLayoutStore.enabledInsights;

    if (
      currentEnabledInsights &&
      previousEnabledInsights.current &&
      JSON.stringify(currentEnabledInsights) !==
        JSON.stringify(previousEnabledInsights.current)
    ) {
      loadDashboard();
    }

    previousEnabledInsights.current = currentEnabledInsights;
  }, [dashboardLayoutStore.enabledInsights]);

  const setBreakpoint = useCallback(
    (breakpoint: string) => {
      if (breakpoint !== dashboardLayoutStore.currentBreakpoint) {
        setDashboardLayoutState((prevState) => ({
          ...prevState,
          currentBreakpoint: breakpoint,
        }));
      }
    },
    [dashboardLayoutStore.currentBreakpoint]
  );

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    if (width >= 1200) setBreakpoint("lg");
    else if (width >= 992) setBreakpoint("md");
    else if (width >= 768) setBreakpoint("sm");
    else if (width >= 576) setBreakpoint("xs");
    else if (width >= 250) setBreakpoint("xxs");
    else setBreakpoint("lg");
    window.dispatchEvent(new Event("resize"));
  }, [setBreakpoint]);

  const updateLayoutComp = useCallback(
    async (updatedLayout: Layout[]) => {
      const breakpoint = dashboardLayoutStore.currentBreakpoint;
      const currentLayoutData = dashboardLayoutStore.layouts[breakpoint];

      const updatedMergedLayout = updatedLayout.map((item) => ({
        ...item,
        data: currentLayoutData.find((i) => parseInt(i.i) === parseInt(item.i))
          ?.data,
      }));

      const layoutId =
        selectedDashboardId ??
        accountState.selectedAccount?.defaultDashboardConfigurationId;

      if (!layoutId) return;

      try {
        const updatedConfig = await updateLayout(
          layoutId,
          {
            layout: {
              ...dashboardLayoutStore.layouts,
              [breakpoint]: updatedMergedLayout,
            },
          },
          breakpoint
        );

        setDashboardLayoutState((prevState) => ({
          ...prevState,
          layouts: {
            ...prevState.layouts,
            [breakpoint]: updatedMergedLayout,
          },
          enabledInsights: updatedConfig.enabledInsights,
        }));
      } catch (error) {
        console.error("Error updating layout:", error);
      }
    },
    [
      dashboardLayoutStore.layouts,
      dashboardLayoutStore.currentBreakpoint,
      accountState,
      setDashboardLayoutState,
      selectedDashboardId,
    ]
  );

  const insightDefinitions: InsightDefinition[] = useMemo(() => {
    const breakpoint = dashboardLayoutStore.currentBreakpoint ?? "md";
    const layoutItems = dashboardLayoutStore.layouts[breakpoint] || [];
    if (insights.length > 0 && dashboardConfiguration?.layout && layoutItems) {
      return layoutItems
        .map((item) => {
          const insight = insights.find(
            (x) => parseInt(x.insightId) === parseInt(item.i)
          );
          return insight ? { ...item, ...insight } : (null as any);
        })
        .filter(Boolean);
    }

    return [];
  }, [dashboardLayoutStore.currentBreakpoint, dashboardLayoutStore.layouts]);

  return {
    layouts: dashboardLayoutStore.layouts,
    insightDefinitions,
    updateLayoutComp,
    setBreakpoint,
    loading: !dashboardConfiguration,
    insightBeingResized,
    setInsightBeingResized,
    selectedDashboardId,
  };
};
