import React from "react";

class InsightErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true, error });
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <div
          style={{
            padding: "20px",
            color: "var(--white)",
            textAlign: "left",
          }}
        >
          <h2>Something went wrong!</h2>
          <p>
            We're sorry, but there was an error. Please try again or contact
            support if it persists.
          </p>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {error && error.toString()}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

export default InsightErrorBoundary;
