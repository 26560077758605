import React from "react";
import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { FormikProps } from "formik";
import { InsightTemplate } from "../../../../../types/Insight";

interface TitleFieldProps {
  formik: FormikProps<InsightTemplate>;
}

const TitleField: React.FC<TitleFieldProps> = ({ formik }) => (
  <FormControl isInvalid={formik.touched.title && !!formik.errors.title}>
    <FormLabel>Title</FormLabel>
    <Input
      name="title"
      value={formik.values.title}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
    />
    {formik.touched.title && formik.errors.title ? (
      <Text color="red.500">{formik.errors.title}</Text>
    ) : null}
  </FormControl>
);

export default TitleField;
