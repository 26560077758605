// useNavbar.js
import { useNavigate } from "react-router";
import { useAtom, useAtomValue } from "jotai";
import { AppStateAtom, SidebarStateAtom } from "../../store";

export const useNavbarController = () => {
  const navigate = useNavigate();
  const appState = useAtomValue(AppStateAtom);
  const isUserAdmin = appState.user?.role === "admin";

  const [isCollapsed, setIsCollapsed] = useAtom(SidebarStateAtom);

  const toggleSidebar = () => {
    setIsCollapsed((prev) => {
      const newState = !prev;
      localStorage.setItem("collapsed", JSON.stringify(newState));
      return newState;
    });
  };

  return { isCollapsed, toggleSidebar, navigate, isUserAdmin };
};
