import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../../components";

export const columnDefinitions: ColDef[] = [
  createGroupColumn("Book", "book", ColumnTypeEnum.Text, {
    valueGetter: (params) => {
      if (
        params.data.book === "null" ||
        params.data.book === null ||
        params.data.book === "" ||
        params.data.book === undefined
      ) {
        return "";
      }
      return params.data.book;
    },

    rowGroup: true,
    hide: true,
    rowDrag: true,
    sort: "asc",
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn(
    "Underlying",
    "underlyingInstrument.symbol",
    ColumnTypeEnum.Text,
    {
      rowGroup: true,
      hide: true,
      sort: "asc",
      rowDrag: true,
      menuTabs: ["generalMenuTab"],

      valueGetter: (params) => {
        if (params?.data?.underlyingInstrument?.instrumentType === "Future") {
          return params.data.underlyingInstrument.name;
        } else {
          return params?.data?.underlyingInstrument?.symbol;
        }
      },
    }
  ),

  createGroupColumn("Position Group", "positionGroup", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    valueGetter: (params) => {
      if (
        params.data.positionGroup === "null" ||
        params.data.positionGroup === null ||
        params.data.positionGroup === "" ||
        params.data.positionGroup === undefined
      ) {
        return "Ungrouped";
      }
      return params.data.positionGroup;
    },
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Strategy", "strategy", ColumnTypeEnum.Text, {
    hide: true,
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Tags", "tags", ColumnTypeEnum.Tags, {
    rowGroup: false,
    hide: true,
    menuTabs: ["generalMenuTab"],
    valueGetter: (params) => {
      const data = params.data ?? params.node?.data ?? params.node?.aggData;
      if (!data) {
        return undefined;
      }
      return data.tags;
    },
  }),

  createGroupColumn("Date", "tradeDate", ColumnTypeEnum.Date, {
    sort: "asc",
    menuTabs: ["generalMenuTab"],
    aggFunc: "distinct",
  }),

  createGroupColumn("Broker Trade Id", "brokerTradeId", ColumnTypeEnum.Text, {
    hide: true,
    menuTabs: ["generalMenuTab"],
    aggFunc: "none",
  }),

  createGroupColumn("Symbol", "instrument.symbol", ColumnTypeEnum.Text, {
    valueGetter: (params) => {
      try {
        return params.data.instrument?.symbol?.replaceAll("_", " ");
      } catch (e) {
        return "N/A";
      }
    },
    menuTabs: ["generalMenuTab"],
    hide: true,
  }),

  createGroupColumn("Quantity", "quantity", ColumnTypeEnum.Numeric, {
    menuTabs: ["generalMenuTab"],
    hide: true,
    aggFunc: "none",
  }),

  createGroupColumn("Buy/Sell", "buySell", ColumnTypeEnum.Text, {
    hide: true,
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Trade price", "price", ColumnTypeEnum.Currency, {
    hide: true,
    cellRendererParams: {
      noFormatting: true,
    },
    menuTabs: ["generalMenuTab"],
    aggFunc: "none",
  }),

  createGroupColumn("Type", "tradeType", ColumnTypeEnum.Pill, {
    cellRendererParams: {
      exactMatch: true,
      colourMap: {
        Option: "var(--accent)",
        Equity: "var(--trade-stock)",
      },
      defaultColour: "var(--accent)",
    },
    menuTabs: ["generalMenuTab"],
    aggFunc: "distinct",
  }),

  createGroupColumn("Put/Call", "instrument.putCall", ColumnTypeEnum.Pill, {
    cellRendererParams: {
      exactMatch: true,
      colourMap: {
        Put: "var(--trade-call)",
        Call: "var(--trade-put)",
      },
      defaultColour: "var(--accent)",
    },
    menuTabs: ["generalMenuTab"],
    aggFunc: "distinct",
  }),

  createGroupColumn("Strike", "instrument.strike", ColumnTypeEnum.Numeric, {
    hide: true,
    menuTabs: ["generalMenuTab"],
    aggFunc: "distinct",
  }),

  createGroupColumn("Expiry Date", "instrument.expiry", ColumnTypeEnum.Date, {
    hide: true,
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn(
    "Gross proceeds",
    "grossProceeds",
    ColumnTypeEnum.Currency,
    {
      cellRendererParams: { showDirection: true },
      menuTabs: ["generalMenuTab"],
    }
  ),

  createGroupColumn("Net proceeds", "netProceeds", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    menuTabs: ["generalMenuTab"],
  }),

  createGroupColumn("Fees", "fees", ColumnTypeEnum.Currency, {
    hide: true,
    aggFunc: "sum",
    menuTabs: ["generalMenuTab"],
    valueGetter: (params) => {
      const data = params.data ?? params.node?.data ?? params.node?.aggData;
      if (!data) {
        return undefined;
      }
      return -Math.abs(parseFloat(data.fees + data.commission));
    },
  }),

  createGroupColumn("Market Price", "price", ColumnTypeEnum.Currency, {
    cellRendererParams: { noFormatting: true },
    hide: true,
    aggFunc: "none",
  }),
];
