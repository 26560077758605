import React from "react";
import {
  Button,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  CalendarIcon,
  CloseIcon,
  InfoIcon,
  TimeIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import { BiHelpCircle, BiHourglass, BiSolidHourglass } from "react-icons/bi";
import { IoHelp, IoHelpOutline } from "react-icons/io5";
import { FiHelpCircle } from "react-icons/fi";
import { HiOutlinePhoneIncoming } from "react-icons/hi";
import { InsightDefinition, InsightItem } from "../../../types/Insight";
import { MoveIcon } from "../../../components/design_library";
import { useLayout } from "../../dashboard/helpers/Layout";
import { Show } from "../../../components";
import { FaEllipsisH } from "react-icons/fa";

interface InsightHeaderProps {
  insight: InsightDefinition;
  preview?: boolean;
  embedded?: boolean;
}

export const InsightHeader2 = (props: InsightHeaderProps) => {
  const { insight, preview, embedded } = props;
  const layout = useLayout();

  if (embedded) {
    return (
      <HStack
        w="full"
        justifyContent="space-between"
        px="5px"
        h="24px"
      ></HStack>
    );
  }

  return (
    <HStack w="full" justifyContent="space-between" px="5px" h="24px">
      {!embedded && (
        <HStack>
          <Icon
            className="drag-handle"
            stroke="var(--light-gray)"
            backgroundColor="var(--bg)"
            strokeWidth={4}
            as={MoveIcon}
            cursor="grab"
          />
          <Text
            fontSize="12px"
            color="var(--menu-bar)"
            overflow={"hidden"}
            noOfLines={1}
          >
            {insight.title}
          </Text>
        </HStack>
      )}

      <HStack transform={"translateX(12px) translateY(0px)"}>
        {insight.compatibleWithTimeframes === true && (
          <Tooltip label={"This insight is compatible with time frames."}>
            <Icon
              as={CalendarIcon}
              boxSize={4}
              color="var(--green)"
              transform={"translateX(12px) translateY(0px)"}
            />
          </Tooltip>
        )}
        {insight.compatibleWithTimeframes === false && (
          <Tooltip label={"This insight is not compatible with time frames."}>
            <Icon
              as={CalendarIcon}
              boxSize={4}
              color="var(--chart-orange)"
              transform={"translateX(12px) translateY(0px)"}
            />
          </Tooltip>
        )}
        {insight.compatibleWithWindows === false && (
          <Tooltip label={"This insight is not compatible with time windows."}>
            <Icon
              as={TimeIcon}
              boxSize={4}
              color="var(--chart-orange)"
              transform={"translateX(12px) translateY(0px)"}
            />
          </Tooltip>
        )}
        {insight.compatibleWithWindows === true && (
          <Tooltip label={"This insight is compatible with time windows."}>
            <Icon
              as={TimeIcon}
              boxSize={4}
              color="var(--green)"
              transform={"translateX(12px) translateY(0px)"}
            />
          </Tooltip>
        )}

        <Tooltip label={insight.description}>
          <Icon
            as={InfoIcon}
            boxSize={4}
            color="var(--light-gray)"
            transform={"translateX(12px) translateY(0px)"}
          />
        </Tooltip>

        <Popover>
          <PopoverTrigger>
            <Button
              p={0}
              m={0}
              bg="transparent"
              zIndex={99}
              fontSize="12px"
              color="var(--light-gray)"
              _hover={{ bg: "transparent", color: "var(--accent)" }}
            >
              <FaEllipsisH />
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent bg="var(--bg)" maxW="260px">
              <PopoverArrow bg="var(--bg)" />
              <PopoverBody>
                <Button
                  bg="transparent"
                  zIndex={99}
                  fontSize="12px"
                  color="var(--light-gray)"
                  onClick={(e) => {
                    if (!preview) {
                      return layout.removeInsightFromLayouts(insight);
                    }
                  }}
                  w="full"
                >
                  <HStack w="full">
                    <CloseIcon />
                    <Text>{"Remove insight from dashboard"}</Text>
                  </HStack>
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </HStack>
    </HStack>
  );
};
