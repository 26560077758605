import React from "react";
import {
  Box,
  HStack,
  VStack,
  Heading,
  Text,
  Tag,
  Divider,
  Switch,
  Flex,
} from "@chakra-ui/react";
import { InsightTemplate } from "../../../../types/Insight";
import { InsightTypeIcon } from "../../../../components/insights/InsightTypeIcon";
import InsightErrorBoundary from "../../../dashboard/canvas/insight/InsightRenderers/InsightErrorBoundary";
import { InsightRenderer2 } from "../../container";
import { SecondaryButton } from "../../../../components/design_library";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useInsightsController } from "../../InsightsManager/useInsightsController";

interface InsightListItemProps {
  insight: InsightTemplate;
  enabled: boolean;
  handleToggleInsight: (insight: InsightTemplate) => void;
  isOwner: boolean;
  setPage: (page: string) => void;
  setInsightData: (insight: InsightTemplate) => void;
}

const InsightListItem: React.FC<InsightListItemProps> = ({
  insight,
  enabled,
  handleToggleInsight,
  isOwner,
  setPage,
  setInsightData,
}) => {
  const [toggled, setToggled] = React.useState(false);
  const controller = useInsightsController();

  return (
    <Box
      px={2}
      w="98vw"
      py={1}
      overflow={"hidden"}
      borderRadius={8}
      cursor={"pointer"}
      p="4"
      onClick={() => {
        setToggled(!toggled);
        handleToggleInsight(insight);
      }}
      bg={
        enabled
          ? {
              base: "rgba(0, 255, 0, 0.2)",
            }
          : {
              base: "rgba(175, 175, 175, 0.1)",
            }
      }
      border="1px solid rgba(255, 255, 255, 0.15)"
    >
      <VStack justifyContent={"flex-start"} w="full">
        <HStack w="full" alignItems={"flex-start"}>
          <VStack
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            w="full"
          >
            <Box
              display="flex"
              justifyContent={"center"}
              alignItems={"flex-start"}
              minW="260px"
            >
              <Box
                w="30px"
                h="30px"
                borderRadius="6px"
                bg={enabled ? "var(--green)" : "var(--gray)"}
                color="var(--white)"
                display="flex"
                justifyContent="center"
                alignItems="center"
                px={2}
                py={1}
                overflow={"hidden"}
                cursor={"pointer"}
                fontSize="20px"
                mr="12px"
                minW="30px"
              >
                <InsightTypeIcon type={insight.type} />
              </Box>

              <Heading
                fontSize={"16px"}
                pt="2"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                w="100%"
                h="100%"
                minW="260px"
                alignItems={"flex-start"}
                mr="20px"
              >
                {insight.title}
              </Heading>
            </Box>

            <Text
              w="100%"
              textAlign={"left"}
              color="var(--light-gray)"
              pt="6px"
            >
              {insight.description}
            </Text>
          </VStack>
          <HStack justifyContent="flex-end" textAlign={"center"}>
            <HStack>
              <HStack justifyContent="flex-end" w="100%" wrap={"wrap"}>
                {insight.official && <Tag colorScheme="green">Official</Tag>}
                {insight.isDefaultInsight && (
                  <Tag colorScheme="blue">Recommended</Tag>
                )}
                {insight.tags &&
                  insight.tags.map((tag) => (
                    <Tag textOverflow={"ellipsis"} key={tag}>
                      {tag}
                    </Tag>
                  ))}
              </HStack>

              <Text minW="100px" textAlign={"right"}>
                {enabled ? "Enabled" : "Not Enabled"}
              </Text>
              {/* <Switch
                  colorScheme="green"
                  size={"lg"}
                  isChecked={enabled || (toggled && !enabled) ? true : false}
                  onChange={() => {
                    setToggled(!toggled);
                    handleToggleInsight(insight);
                  }}
                /> */}
            </HStack>

            <HStack
              justifyContent="space-between"
              alignItems="center"
              zIndex={9999}
              opacity={0.4}
            >
              {isOwner && (
                <>
                  <SecondaryButton
                    bg="transparent"
                    onClick={() => {
                      setInsightData(insight);
                      setPage("creator");
                    }}
                    px="2px"
                  >
                    <EditIcon fontSize="20px" />
                  </SecondaryButton>
                  <SecondaryButton
                    bg="transparent"
                    onClick={() => {
                      controller.handleDelete(insight.insightId as number);
                    }}
                    px="2px"
                  >
                    <DeleteIcon fontSize="20px" />
                  </SecondaryButton>
                </>
              )}
            </HStack>
          </HStack>
        </HStack>
      </VStack>
    </Box>
  );
};

export default InsightListItem;
