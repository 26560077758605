export const TABLE_UPCOMING_EXPIRIES = {
  rows: [
    {
      positionId: 11417918,
      book: "Main Allocation",
      underlyingInstrumentId: 265,
      underlyingName: "X",
      underlyingType: "Equity",
      underlyingSymbol: "X",
      underlyingExchange: "US",
      positionDate: "2024-12-30",
      positionGroup: "Wheeling",
      strategy: "",
      tradeIds: [228806],
      instrumentId: 35685,
      name: "X_20250110_3400_Call",
      symbol: "X_20250110_3400_Call",
      exchange: "US",
      instrumentType: "Option",
      currency: "USD",
      multiplier: 100,
      putCall: "Call",
      strike: 34,
      expiry: "2025-01-10T05:00:00.000Z",
      quantity: -6,
      averagePrice: 1.4835,
      grossProceeds: 894,
      netProceeds: 890.1,
      realisedPnl: 0,
      unrealisedPnl: 599.1,
      costs: 3.9,
      premiumReceived: 890.1,
      winLoss: 0,
      daysInPosition: 6.18,
      totalDebits: 0,
      totalCredits: 890.1,
      tags: "",
      price: 0.485,
      priceDate: "2025-01-07",
      priceConfidence: "Delayed",
      underlyingPrice: 33.3,
      underlyingPriceDate: "2025-01-07",
      underlyingPriceConfidence: "EOD",
      positionKey: "Main Allocation-35685-Wheeling",
      costBasis: 890.1,
    },
  ],
};
