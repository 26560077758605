import { HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import currencyFormatter from "currency-formatter";
import { BiWifi, BiWifi2, BiWifiOff, BiUser } from "react-icons/bi";
import { MdOnlinePrediction } from "react-icons/md";
import { CustomTag, FastTag } from "../../design_library";
import { calculateDaysUntilExpiry } from "../utils";
import React from "react";
import { Indicator } from "./PriceIndicator";

export const PriceDetailRender = (params: any) => {
  if (!params?.node?.data) return null;
  const { price, priceDate, priceConfidence } =
    params?.node?.data?.priceData || {};
  const { currency } =
    params?.node?.data?.instrumentId || params?.node?.data?.instrument || {};

  if (params.node.data.quantity === 0) {
    return (
      <VStack
        h="100%"
        alignItems={"flex-start"}
        justifyContent={"center"}
        w="100%"
      >
        <FastTag h="20px" backgroundColor={"var(--trade-closed)"}>
          Closed
        </FastTag>
      </VStack>
    );
  }

  //Expired trade.

  if (
    calculateDaysUntilExpiry(
      params?.node?.data?.instrumentId || params?.node?.data?.instrument
    ) === 0
  ) {
    return (
      <VStack
        h="100%"
        alignItems={"flex-start"}
        justifyContent={"center"}
        w="100%"
      >
        <FastTag h="20px" backgroundColor={"var(--trade-expired)"}>
          Expired
        </FastTag>
      </VStack>
    );
  }

  if (!price && price !== 0) {
    return (
      <VStack
        h="100%"
        alignItems={"flex-start"}
        justifyContent={"center"}
        w="100%"
      >
        <Text fontSize="12px">Waiting for update...</Text>
      </VStack>
    );
  }

  return (
    <HStack bg="transparent">
      <Indicator priceConfidence={priceConfidence} />
      {priceConfidence !== "Unknown" && (
        <Text>{currencyFormatter.format(price, { code: currency })}</Text>
      )}
    </HStack>
  );
};
