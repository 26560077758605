import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { SecondaryButton } from "../../design_library";
import { useTour } from "@reactour/tour";
import { NegativeButton } from "../../design_library/NegativeButton";

export default function NavWrapper({
  children,
  previousEnabled,
  isFinalStep,
  nextDisabled,
  customFunction,
  overrideNextText,
}: {
  children?: React.ReactNode;
  previousEnabled: boolean;
  nextDisabled?: boolean;
  isFinalStep?: boolean;
  customFunction?: Function;
  overrideNextText?: string;
}) {
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } =
    useTour();
  const [loading, setLoading] = React.useState(false);
  return (
    <Box textAlign={"left"} fontSize={"14px"}>
      <VStack justifyContent={"flex-start"} alignItems={"flex-start"}>
        {children}
      </VStack>
      <HStack
        w="100%"
        justifyContent={"space-between"}
        px={4}
        py={2}
        pt="20px"
        fontSize={"12px"}
        flexDirection={"row-reverse"}
      >
        {nextDisabled === true && <Box></Box>}
        {nextDisabled === false && (
          <SecondaryButton
            justifySelf="flex-end"
            px="20px"
            color="var(--white)"
            onClick={async () => {
              if (customFunction) {
                await customFunction();
                setTimeout(() => {
                  setCurrentStep(currentStep + 1);
                }, 1000);
              } else {
                setCurrentStep(currentStep + 1);
              }
            }}
          >
            {isFinalStep
              ? overrideNextText || "Finish"
              : overrideNextText || "Next"}
          </SecondaryButton>
        )}

        {previousEnabled && (
          <Button
            px="20px"
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            Go back
          </Button>
        )}
        <NegativeButton
          bg="transparent"
          height="24px"
          onClick={() => {
            setIsOpen(false);
            window.location.href = "../app/dashboard";
          }}
        >
          Skip
        </NegativeButton>
      </HStack>
    </Box>
  );
}
