import { atom } from "jotai";
import { TimeFrame, TimeFrameEnum } from "../types";

const buildInitialState = (): TimeFrame => {
  const state: any = {
    timeFrame: TimeFrameEnum.UP_TO_TODAY,
  };

  return state;
};

export const TimeFrameAtom = atom<TimeFrame>(buildInitialState());
