import Big from "big.js";

export const calculateReturnOnPremium = (
  premium?: number,
  realisedPnl?: number,
  unRealisedPnl?: number
): number => {
  const totalPnl = (unRealisedPnl ?? 0) + (realisedPnl ?? 0);

  if (!premium || premium === 0 || totalPnl === 0) {
    return 0;
  }

  if (totalPnl != 0) {
    return new Big(totalPnl)
      .div(Math.abs(premium))
      .times(100)
      .round(2)
      .toNumber();
  } else {
    return 0;
  }
};
