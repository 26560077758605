import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Box,
  Button,
  Select,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { IoDownload } from "react-icons/io5";
import { SecondaryButton } from "../design_library";
import ModalHeader from "../design_library/ModalHeader";
import { useExportAccountController } from "./useExportAccountController";

export default function ExportAccount() {
  const {
    isOpen,
    onOpen,
    onClose,
    finalRef,
    selectedAccount,
    setSelectedAccount,
    accountState,
    getCSV,
  } = useExportAccountController();
  return (
    <>
      <Button mt={4} onClick={onOpen} size="sm">
        Export trades
      </Button>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="var(--bg)">
          <ModalHeader
            title="Export trades"
            description="Export your trades their original format."
            icon={<Box as={IoDownload} />}
          />
          <ModalBody>
            <Text mb="20px" fontSize="sm">
              Please note that this will only export the trades you have
              uploaded. Manually entered trades will not be exported in a
              useable format.
            </Text>

            <Select
              placeholder="Select account"
              size="sm"
              onChange={(e) => {
                setSelectedAccount(e.target.value);
              }}
            >
              {accountState.accounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.name}
                </option>
              ))}
            </Select>

            <SecondaryButton
              mt={4}
              w="full"
              isDisabled={
                selectedAccount !== null && selectedAccount !== ""
                  ? false
                  : true
              }
              onClick={() => {
                getCSV();
              }}
            >
              Export
            </SecondaryButton>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
