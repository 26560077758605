export const PNL_BY_BOOK = {
  data: [
    {
      domain: "Unbooked",
      realisedPnl: 8553.83,
      unrealisedPnl: 0,
    },
    {
      domain: "Equity Trading",
      realisedPnl: 12500.25,
      unrealisedPnl: 340.75,
    },
    {
      domain: "Fixed Income",
      realisedPnl: -2300.5,
      unrealisedPnl: 120.4,
    },
    {
      domain: "Commodities",
      realisedPnl: 4500.1,
      unrealisedPnl: 50.6,
    },
    {
      domain: "Forex",
      realisedPnl: 2200,
      unrealisedPnl: -100,
    },
    {
      domain: "Derivatives",
      realisedPnl: -750,
      unrealisedPnl: 200,
    },
    {
      domain: "Real Estate",
      realisedPnl: 9800.55,
      unrealisedPnl: 500.35,
    },
    {
      domain: "Private Equity",
      realisedPnl: -1500.75,
      unrealisedPnl: 300.2,
    },
    {
      domain: "Hedge Funds",
      realisedPnl: 6700.4,
      unrealisedPnl: 150.8,
    },
    {
      domain: "Venture Capital",
      realisedPnl: 4300.0,
      unrealisedPnl: 75.5,
    },
  ],
  chartProperties: {
    name: "PnL By Book",
    chartType: "bar",
    keys: ["realisedPnl", "unrealisedPnl"],
    colors: ["var(--chart-blue)", "var(--chart-orange)"],
    legend: ["Realized PnL", "Unrealized PnL"],
    domainType: "string",
    showEveryDomainLabel: true,
    flipAxis: true,
  },
};
