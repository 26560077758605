import React, { useEffect, useRef, useState } from "react";
import domtoimage from "dom-to-image";
import { Position, Trade } from "../../../../types";
import {
  Box,
  Text,
  VStack,
  HStack,
  Divider,
  useToast,
  Center,
  Select,
  SimpleGrid,
  Checkbox,
} from "@chakra-ui/react";
import convertSymbolToReadable from "../../../../components/grid/utils/convertSymbolToReadable";
import currencyFormatter from "currency-formatter";
import { SecondaryButton } from "../../../../components/design_library";
import { CopyIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Sparklines,
  SparklinesLine,
  SparklinesNormalBand,
} from "react-sparklines";
import { getChartData } from "../../../../api/Datafeeds";
import moment from "moment";
import { Spinner } from "../../../../components";
import PayoffChart from "../../../../components/optionpayoff/PayOffChart";
import { calculateReturnOnPremium } from "../../../../utils";

interface SharePositionsProps {
  data: Position;
  trades: Trade[];
}

const SharePositions = ({ data, trades }: SharePositionsProps) => {
  if (!data)
    return (
      <Center h="90%">
        <Text>
          Select a single position (multiple position support coming soon)
        </Text>
      </Center>
    );

  const toast = useToast();
  const componentRef = useRef<HTMLDivElement>(null);
  const [aspectRatio, setAspectRatio] = useState<string | null>(null);
  const [stockPriceArray, setStockPriceArray] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [displayNotes, setDisplayNotes] = useState(false);
  const [chartType, setChartType] = useState("SPARK");
  const [displayProperties, setDisplayProperties] = useState<
    Record<string, boolean>
  >({});

  const properties = [
    {
      label: "Status",
      key: "status",
      value: data.quantity !== 0 ? "Open" : "Closed",
      defaultEnabled: true,
      color: data.quantity !== 0 ? "var(--green)" : "var(--red)",
    },
    {
      label: "Quantity",
      key: "quantity",
      value: data.quantity,
      defaultEnabled: data.quantity !== 0 ? true : false,
      color: data.quantity > 0 ? "var(--green)" : "var(--red)",
    },
    {
      label: "Realized P&L",
      key: "realisedPnl",
      value: currencyFormatter.format(data.realisedPnl, {
        code: data.currency,
        precision: 0,
      }),
      defaultEnabled: data.quantity === 0 ? true : false,
      color: data.realisedPnl > 0 ? "var(--green)" : "var(--red)",
    },
    {
      label: "Unrealized P&L",
      key: "unrealisedPnl",
      value: currencyFormatter.format(data.unrealisedPnl, {
        code: data.currency,
        precision: 0,
      }),
      defaultEnabled: data.quantity !== 0 ? true : false,
      color: data.unrealisedPnl > 0 ? "var(--green)" : "var(--red)",
    },
    {
      label: "Days in Position",
      key: "daysInPosition",
      value: data.daysInPosition,
      defaultEnabled: true,
    },
    {
      label: "Average Price",
      key: "price",
      value: currencyFormatter.format(data.averagePrice, {
        code: data.currency,
      }),
      defaultEnabled: false,
    },

    {
      label: "Costs",
      key: "costs",
      value: currencyFormatter.format(data.costs, { code: data.currency }),
      defaultEnabled: false,
    },

    {
      label: "Gross Proceeds",
      defaultEnabled: false,
      key: "grossProceeds",
      value: currencyFormatter.format(data.grossProceeds, {
        code: data.currency,
        precision: 0,
      }),
    },
    {
      label: "Net Proceeds",
      defaultEnabled: false,
      key: "netProceeds",
      value: currencyFormatter.format(data.netProceeds, {
        code: data.currency,
        precision: 0,
      }),
    },
    {
      label: "Strike Price",
      defaultEnabled: false,
      key: "strike",
      value: data.instrumentType === "Option" ? data.instrument.strike : "N/A",
    },
    {
      label: "Premium",
      defaultEnabled: true,
      key: "premium",
      value: currencyFormatter.format(data.premium, {
        code: data.currency,
        precision: 0,
      }),
    },
    {
      label: "Cost Basis",
      defaultEnabled: true,
      key: "costBasis",
      value: currencyFormatter.format(data.costBasis, {
        code: data.currency,
        precision: 0,
      }),
    },
    {
      label: "% Return",
      defaultEnabled: true,
      key: "premium",
      value:
        calculateReturnOnPremium(
          data.premium,
          data.realisedPnl,
          data.unrealisedPnl
        ) + "%",
      color:
        calculateReturnOnPremium(
          data.premium,
          data.realisedPnl,
          data.unrealisedPnl
        ) > 0
          ? "var(--green)"
          : "var(--red)",
    },
    {
      label: "Market Price",
      defaultEnabled: false,
      key: "marketPrice",
      value: currencyFormatter.format(data.price, {
        code: data.currency,
        precision: 2,
      }),
    },
    {
      label: "Expiry",
      defaultEnabled: false,

      key: "expiry",
      value:
        data.instrumentType === "Option"
          ? moment.utc(data.instrument.expiry).format("Do MMM YY")
          : "N/A",
    },
  ];

  const aspectRatioOptions = [
    { value: "3/4", label: "3:4 (Twitter)" },
    { value: "1/1", label: "1:1 (Square - Instagram, Facebook, LinkedIn)" },
    { value: "16/9", label: "16:9 (YouTube, Facebook Video)" },
    { value: "4/3", label: "4:3 (Standard - Facebook)" },
    { value: "9/16", label: "9:16 (Portrait - Stories, TikTok, Snapchat)" },
  ];

  useEffect(() => {
    const initialState: Record<string, boolean> = {};
    properties.forEach(
      (prop) => (initialState[prop.key] = prop.defaultEnabled)
    );
    setDisplayProperties(initialState);
  }, []);

  const handleExport = async (format: "png" | "jpeg") => {
    if (!componentRef.current) return;

    try {
      const options = { useCORS: true, quality: 0.95 };
      const dataUrl =
        format === "jpeg"
          ? await domtoimage.toJpeg(componentRef.current, options)
          : await domtoimage.toPng(componentRef.current, options);

      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = `exported-image.${format}`;
      link.click();

      toast({
        title: "Export Successful",
        description: `The image has been exported as a ${format.toUpperCase()}.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Export Failed",
        description: "There was an issue exporting the image.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getStockData = async () => {
    setLoading(true);
    const earliestDate = Math.min(
      ...trades.map((trade) => moment(trade.tradeDate).unix())
    );
    let latestDate = Math.max(
      ...trades.map((trade) => moment(trade.tradeDate).unix())
    );

    if (latestDate === earliestDate) {
      setStockPriceArray([0, 0, 0, 0, 0, 0]);
      setLoading(false);
      return;
    }

    const stockData = await getChartData(
      data.underlyingSymbol,
      "1D",
      {
        from: earliestDate,
        to: latestDate,
      },
      null
    );

    if (stockData.success === true) {
      if (stockData.data) {
        const updatedData = stockData.data.map((x) => x.close);
        setStockPriceArray(updatedData);
      }
    } else {
      setStockPriceArray([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getStockData();
  }, [data]);

  const copyToClipboard = async () => {
    if (!componentRef.current) return;

    try {
      const blob = await domtoimage.toBlob(componentRef.current);
      if (blob) {
        const item = new ClipboardItem({ "image/png": blob });
        await navigator.clipboard.write([item]);
        toast({ title: "Image Copied", status: "success", duration: 3000 });
      }
    } catch {
      toast({ title: "Copy Failed", status: "error", duration: 3000 });
    }
  };

  if (!data)
    return (
      <Center h="100%">
        <Text fontSize="sm">
          Select a specific position to display more information.
        </Text>
      </Center>
    );

  const renderStat = (
    label: string,
    value: string | number,
    color?: string
  ) => (
    <VStack align="start" spacing={0} mr="24px">
      <Text fontSize="xs" fontWeight={600} minW="100px">
        {label}
      </Text>
      <Text fontSize="xl" p="0" color={color} minW="150px">
        {value}
      </Text>
    </VStack>
  );

  return (
    <VStack px="2" align="flex-start" h="100%" overflow={"scroll"}>
      <HStack alignItems={"flex-start"}>
        <Box>
          {loading ? (
            <Spinner />
          ) : (
            <Box
              ref={componentRef}
              minW="600px"
              borderWidth="1px"
              boxShadow="md"
              p={3}
              bg="var(--black)"
            >
              <VStack align="start" pb="10px">
                <HStack justify="space-between" w="full" align="start">
                  <HStack>
                    {/* <Image
                  maxH="30px"
                  src={`https://cdn.wealthbee.io/logos/${data.underlyingSymbol.toUpperCase()}.png`}
                  alt="stock logo"
                  fallback={<Box display="none"></Box>}
                /> */}
                    <Text fontWeight="bold" minW="400px">
                      {convertSymbolToReadable(
                        data.name || data.underlyingName
                      )}
                    </Text>
                  </HStack>
                  <Text
                    fontSize="sm"
                    color="gray.500"
                    minW="60px"
                    textAlign={"right"}
                  >
                    {data.instrumentType}
                  </Text>
                </HStack>

                <Divider />

                {chartType === "SPARK" && stockPriceArray.length > 0 && (
                  <Sparklines data={stockPriceArray}>
                    <SparklinesLine
                      color={
                        stockPriceArray[stockPriceArray.length - 1] >
                        stockPriceArray[0]
                          ? "var(--green)"
                          : "var(--red)"
                      }
                    />
                    <SparklinesNormalBand />
                  </Sparklines>
                )}

                {chartType === "PAYOFF" && data && (
                  <Box
                    p={0}
                    m={0}
                    height={"400px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w="100%"
                    minW="400px"
                  >
                    <PayoffChart
                      trades={[...trades]}
                      underlyingPrice={data.underlyingPrice}
                      positions={[data]}
                    />
                  </Box>
                )}

                <SimpleGrid columns={4} w="100%">
                  {properties
                    .filter((prop) => displayProperties[prop.key])
                    .map((prop) =>
                      renderStat(prop.label, prop.value, prop.color)
                    )}
                </SimpleGrid>

                {displayNotes && (
                  <>
                    <Divider />
                    <VStack w="100%">
                      {trades.reverse().map((trade) => {
                        return (
                          <VStack w="full" alignItems={"flex-start"}>
                            <HStack w="full" justifyContent={"space-between"}>
                              <Text
                                color="var(--menu-bar)"
                                fontSize="sm"
                                minW="200px"
                              >
                                {trade.buySell} {trade.quantity}x @{" "}
                                {trade.price}
                              </Text>
                              <Text
                                color="var(--menu-bar)"
                                fontSize="sm"
                                minW="100px"
                                textAlign={"right"}
                              >
                                {moment.utc(trade.tradeDate).calendar()}
                              </Text>
                            </HStack>
                            {trade.notes && trade?.notes.length > 0 && (
                              <HStack>
                                <Text
                                  fontSize="sm"
                                  key={trade.id}
                                  dangerouslySetInnerHTML={{
                                    __html: trade.notes,
                                  }}
                                />
                              </HStack>
                            )}
                          </VStack>
                        );
                      })}
                    </VStack>
                  </>
                )}
              </VStack>
              <Text
                fontFamily={"monospace"}
                color="var(--menu-bar)"
                textAlign={"right"}
                h={0}
                overflow={"visible"}
                transform={"translateY(-4px) translateX(4px)"}
                fontSize={"10px"}
              >
                wealthbee.io
              </Text>
            </Box>
          )}
        </Box>

        <VStack align="flex-start" width="full">
          <HStack spacing={4} mt={4} align="start">
            <SecondaryButton onClick={() => handleExport("jpeg")}>
              <DownloadIcon mr="2" />
              Export as JPEG
            </SecondaryButton>
            <SecondaryButton onClick={() => handleExport("png")}>
              <DownloadIcon mr="2" />
              Export as PNG
            </SecondaryButton>
            <SecondaryButton onClick={copyToClipboard}>
              <CopyIcon mr="2" />
              Copy to Clipboard
            </SecondaryButton>
          </HStack>

          <Text fontWeight="bold">Chart type</Text>
          <Select
            maxW={"300px"}
            placeholder="Select chart type"
            onChange={(e) => setChartType(e.target.value)}
          >
            <option value="SPARK">Sparkline</option>
            <option value="PAYOFF">Option Payoff</option>
          </Select>

          <Text fontWeight="bold">Toggle Properties</Text>
          <SimpleGrid columns={3} spacing={2}>
            {properties.map((prop) => (
              <Checkbox
                key={prop.key}
                isChecked={displayProperties[prop.key]}
                onChange={(e) =>
                  setDisplayProperties((prev) => ({
                    ...prev,
                    [prop.key]: e.target.checked,
                  }))
                }
              >
                {prop.label}
              </Checkbox>
            ))}

            <Checkbox
              isChecked={displayNotes}
              onChange={(e) => setDisplayNotes(e.target.checked)}
              colorScheme="green"
            >
              Display Notes
            </Checkbox>
          </SimpleGrid>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default SharePositions;
