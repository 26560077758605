import React, { useCallback, useEffect } from "react";
import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import "./EmbedDashboard.css";
import { useSearchParams } from "react-router-dom";
import InvalidAPIKey from "./components/InvalidAPIKey";
import { Responsive, WidthProvider } from "react-grid-layout";
import PoweredByWealthBee from "./components/PoweredByWealthBee";
import { useAtom } from "jotai";
import { getSpecificPublicInsight } from "../../../api";
import { getEmbeddedDashboardDatasets } from "../../../api/Embed";
import { AppStateAtom } from "../../../store";
import { InsightItem } from "../../../types";
import { InsightContainer2 } from "../../insights/container";
import moment from "moment";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function EmbedDashboard() {
  const [searchParams] = useSearchParams();
  const dashboardId = searchParams.get("did");
  const portfolioId = searchParams.get("pid");
  const theme = searchParams.get("theme");
  const showToolbar = searchParams.get("toolbar");
  const apiKey = searchParams.get("apikey"); //Public api key
  const accountid = searchParams.get("aid");
  const bgColor = searchParams.get("bgcolor");
  const ref = searchParams.get("ref");
  const width = searchParams.get("w");
  const height = searchParams.get("h");

  //Demo generator - brand colours, logo url, font, company name
  const brandColorPrimary = searchParams.get("brandColorPrimary");
  const brandLogoUrl = decodeURIComponent(searchParams.get("brandLogoUrl"));
  const brandName = searchParams.get("brandName");
  const brandWebFont = searchParams.get("brandWebFont");
  const demo = searchParams.get("demo");

  const displayType = searchParams.get("displayType");
  const selectedInsightId = searchParams.get("iid");

  const { colorMode, toggleColorMode } = useColorMode();
  const fontFamily = searchParams.get("font") || "system-ui";

  // Switch color mode based on the theme param
  useEffect(() => {
    if (theme === "dark" && colorMode !== "dark") toggleColorMode();
    if (theme === "light" && colorMode !== "light") toggleColorMode();
  }, [theme, colorMode, toggleColorMode]);

  const [loading, setLoading] = React.useState(true);
  const [layouts, setLayouts] = React.useState<any>({});
  const [breakpoints] = React.useState(["lg", "md", "sm", "xs", "xxs"]);
  const [currentBreakpoint, setCurrentBreakpoint] = React.useState("lg");
  const [apiKeyValidity, setApiKeyValidity] = React.useState(false);
  const [error, setError] = React.useState("");
  const [appState, setAppState] = useAtom(AppStateAtom);
  const [insightData, setInsightData] = React.useState<any>(null);

  useEffect(() => {
    localStorage.setItem("apiKey", searchParams.get("apikey"));
  }, [apiKey, searchParams]);

  const fetchInsightsDatasets = async (
    portfolioId: string,
    configurationId: string
  ) => {
    if (!configurationId) return;

    try {
      const response = await getEmbeddedDashboardDatasets(
        apiKey,
        configurationId,
        portfolioId
      );

      if (response.success === false) {
        setApiKeyValidity(false);
        setError(response.message);
        setLoading(false);
        return;
      }

      return response;
    } catch (error) {
      console.error("Error fetching insights datasets:", error);
    }
  };

  const generateLayoutData = (layout, datasets) => {
    const layoutData = { lg: [], md: [], sm: [], xs: [], xxs: [] };

    breakpoints.forEach((breakpoint) => {
      layoutData[breakpoint] = layout[breakpoint].map((item) => {
        const insight = datasets.find(
          (i) => parseInt(i.insightId) === parseInt(item.i)
        );
        return { ...item, data: insight };
      });
    });

    return layoutData;
  };

  const fetchLayouts = useCallback(async () => {
    setLoading(true);
    const response = await fetchInsightsDatasets(portfolioId, dashboardId);

    setLayouts(response.layout);

    const newLayoutData = generateLayoutData(response.layout, response.data);

    setLayouts(newLayoutData);
    setApiKeyValidity(true);

    setLoading(false);

    setUpIframeHeight();

    return;
  }, [dashboardId, portfolioId]);

  const fetchInsightData = async (insightId: any) => {
    const insight = await getSpecificPublicInsight(insightId);
    setInsightData(insight);
  };

  useEffect(() => {
    // Hide chat widget if exists
    if (window.$crisp) {
      $crisp.push(["do", "chat:hide"]);
    }
    fetchLayouts();

    if (displayType === "SINGLE_INSIGHT") {
      fetchInsightData(selectedInsightId);
    }
  }, [fetchLayouts]);

  const setUpIframeHeight = () => {
    window.addEventListener("message", (event) => {
      if (event.data === "getHeight") {
        const height = document.documentElement.scrollHeight;
        event.source.postMessage({ height: height }, event.origin);
      }
    });

    // Observe content size and update parent window on resize
    const observer = new ResizeObserver(() => {
      const height = document.documentElement.scrollHeight;
      window.parent.postMessage({ height: height }, "*");
    });

    observer.observe(document.body);
  };

  const setBreakpoint = useCallback((breakpoint: string) => {
    setCurrentBreakpoint(breakpoint);
  }, []);

  if (loading) {
    return (
      <Box h="100%" w="100%" fontFamily={fontFamily}>
        <Center h="100vh">
          <VStack>
            <Spinner size="xl" />
            <Text mt="10px">Analyzing positions...</Text>
          </VStack>
        </Center>
      </Box>
    );
  }

  if (apiKeyValidity === false) {
    return <InvalidAPIKey />;
  }
  const bgColorValue = bgColor !== null ? "#" + bgColor : "transparent";

  if (displayType === "DASHBOARD") {
    return (
      <Flex
        w="full"
        pb={demo === "true" ? "100px" : "0px"}
        direction="column"
        maxW={demo === "true" ? "1200px" : "100vw"}
        margin="auto"
        borderRadius={demo === "true" ? "10px" : "0px"}
        marginTop={demo === "true" ? "20px" : "0px"}
      >
        {demo === "true" && (
          <HStack
            w="full"
            p={4}
            minH="120px"
            px="40px"
            bg="var(--bg)"
            borderTopRadius={demo === "true" ? "10px" : "0px"}
            borderBottom={
              demo === "true" ? `4px solid #${brandColorPrimary}` : "none"
            }
            justifyContent={"space-between"}
            flexDir={{
              base: "column",
              md: "row",
            }}
          >
            <HStack>
              <Image
                src={brandLogoUrl}
                alt="brand logo"
                h="50px"
                objectFit="auto"
              />
              <Text fontSize="4xl" fontFamily={brandWebFont || "system-ui"}>
                {brandName}
              </Text>
            </HStack>
            <VStack
              alignItems={{
                base: "center",
                md: "flex-end",
              }}
            >
              <Text fontSize="2xl" fontFamily={brandWebFont || "system-ui"}>
                Monthly Portfolio Update
              </Text>
              <Text fontSize="md" fontFamily={brandWebFont || "system-ui"}>
                {moment().format("MMMM Do YYYY")}
              </Text>
            </VStack>
          </HStack>
        )}

        <Box
          fontFamily={`${fontFamily} !important`}
          bg={`${bgColorValue}`}
          pl="10px"
          pt="10px"
          pb="10px"
          minH={"100%"}
        >
          <ResponsiveGridLayout
            useCSSTransforms={false}
            className="layout"
            style={{
              minHeight: "100vh",
              width: "100%",
              padding: "5px",
              margin: "5px",
              fontFamily: `${fontFamily} !important`,
            }}
            layouts={layouts}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            autoSize
            margin={[10, 10]}
            containerPadding={[0, 0]}
            allowOverlap={false}
            onBreakpointChange={setBreakpoint}
            compactType="vertical"
            isDraggable={false}
            isResizable={false}
          >
            {layouts[currentBreakpoint].map((item: InsightItem) => (
              <div key={item.i}>
                <InsightContainer2 insight={item.data} embedded={true} />
              </div>
            ))}
          </ResponsiveGridLayout>

          <PoweredByWealthBee
            referralLink={"https://wealthbee.io/?via=" + ref}
          />
        </Box>
      </Flex>
    );
  } else {
    return (
      <div
        style={{
          backgroundColor: bgColorValue,
          padding: "0px",
          width: "100%",
          height: "100%",
          margin: "0px",
        }}
      >
        <div
          key={insightData.id}
          style={{
            width: width + "px",
            height: height + "px",
            maxWidth: width + "px",
            maxHeight: height + "px",
          }}
        >
          <InsightContainer2 insight={insightData} embedded={true} />
        </div>
      </div>
    );
  }
}
