import React from "react";
import { Text, HStack } from "@chakra-ui/react";
import { FaFlask } from "react-icons/fa";
import { SecondaryButton } from "../../../../components/design_library";

interface InsightsManagerTriggerButtonProps {
  onOpen: () => void;
}

const InsightsManagerTriggerButton: React.FC<
  InsightsManagerTriggerButtonProps
> = ({ onOpen }) => {
  return (
    <SecondaryButton
      onClick={onOpen}
      bg="var(--bg)"
      maxH="38px"
      border="solid 1px var(--accent)"
    >
      <Text
        display={{
          base: "none",
          lg: "block",
        }}
        color="var(--accent)"
        className="addInsightsButton"
      >
        <HStack>
          <Text>Edit Insights</Text>
          <FaFlask />
        </HStack>
      </Text>
      <Text
        ml="2"
        mr="2"
        display={{
          lg: "none",
          base: "block",
        }}
      >
        <FaFlask />
      </Text>
    </SecondaryButton>
  );
};

export default InsightsManagerTriggerButton;
